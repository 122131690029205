import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { truncateString, stringLengthMax } from '../../utils/utilFunctions';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';

import StyledWrapper from './style';

import useIDToTitle from '../../hooks/useIDToTitle';

import './BreadCrumb.css';

export default function BreadCrumb() {
	const location = useLocation();
	const pathname = window.location.pathname.split('/');

	let { programID, appID, reviewID } = useParams();
	programID = programID?.replace('p-', '');

	const programTitle = useIDToTitle('program');
	const appTitle = useIDToTitle('application');
	const reviewTitle = useIDToTitle('review');

	const [path, setPath] = useState();
	const isEditable = ['Team & Roles', 'Details'];

	const globalVariables = useSelector((state) => state?.globalProgramVariables);

	const current_application = globalVariables?.current_application;

	function isUniqueID(string) {
		const pattern = /^[A-Za-z]{3,4}-[A-Za-z0-9]{6}$/;
		if (pattern.test(string)) {
			return true;
		} else return false;
	}

	const capitalizeWords = (string) => {
		string = string?.replace('-group', '');
		if (isUniqueID(string)) {
			const currentEntity = current_application?.find((e) => e?.id === string);
			const entityName = currentEntity?.name;
			const reviewer = currentEntity?.reviewer;

			if (reviewer) {
				return `Review by ${reviewer} [${string}]`;
			} else {
				return entityName ? `${entityName} [${string}]` : `[${string}]`;
			}
		} else if (string === `p-${programID}`) {
			return programTitle ? `${programTitle} [${string}]` : `[${string}]`;
		} else {
			let removedSpaces = string.replaceAll('-', ' ').replaceAll('%20', ' ');
			return removedSpaces.replace(/(?:^|\s)\S/g, function (a) {
				return a.toUpperCase();
			});
		}
	};

	const createURL = (pageIndex) => {
		let url = '';
		pathname.forEach((page, index) => {
			index - 1 < pageIndex && (url += `${page}`);
			index + 1 <= pageIndex && (url += '/');
		});

		if (pathname[pageIndex] === `p-${programID}`) {
			url += '/overview';
		}

		if (pathname[pageIndex] === `reviews`) {
			url += '/assign-by-reviewer';
		}

		if (pathname[pageIndex] === `application-setup`) {
			url += '/application';
		}

		if (pathname[pageIndex]?.includes('rfi-')) {
			url += '/content';
		}

		return url;
	};
	const getTitleContent = (page) => {
		let pageTitle;

		switch (page) {
			case programID:
				pageTitle = programTitle || programID;
				break;
			case appID:
				pageTitle = appTitle;
				break;
			case reviewID:
				pageTitle = reviewTitle;
				break;
			default:
				pageTitle = page;
		}
		return pageTitle;
	};

	const getPageTitle = (page, currentPage) => {
		let pageTitle = getTitleContent(page);

		// ARM-141 Adding [] to current breadcrumb. Check to see if page title already has [], then do not add them in
		if (currentPage !== 'false' && pageTitle && !/^\[/.test(pageTitle) && /\]$/.test(pageTitle)) {
			return `[${pageTitle}]`;
		}
		return truncateString(pageTitle);
	};

	useEffect(() => {
		let formattedPath = pathname.map((e) => {
			return capitalizeWords(e);
		});
		setPath(formattedPath);
	}, [location, globalVariables]);

	return (
		<StyledWrapper.LinkContainer data-testid={`breadcrumbs-links-container`}>
			<HomeIcon color="action" data-testid={`breadcrumbs-links-homeIcon`} />
			<StyledWrapper.Links to="/" data-testid={`breadcrumbs-links-homeText`}>
				Home
			</StyledWrapper.Links>
			{path?.map((page, index) => {
				let currentPage = (path?.length - 1 === index).toString();
				return (
					<StyledWrapper.InnerContainer
						key={`BreadCrumb-${index}`}
						style={{ display: `${index === 0 && 'none'}` }}
					>
						{index !== 0 && (
							<div
								style={{ display: 'flex', gap: '10px' }}
								id={`breadcrumbs-link-${index}-container`}
							>
								<StyledWrapper.Divider data-testid="breadcrumb-divider">/</StyledWrapper.Divider>
								<Tooltip
									title={
										getTitleContent(page).length > stringLengthMax ? getTitleContent(page) : ''
									}
									arrow
									placement="top"
								>
									<StyledWrapper.Links
										id={`breadcrumbs-link-${index}-${currentPage}`}
										data-testid={`breadcrumbs-link-${index}`}
										to={createURL(index)}
										currentpage={currentPage}
									>
										{getPageTitle(page, currentPage)}
									</StyledWrapper.Links>
								</Tooltip>
								{isEditable.includes(page) && (
									<CreateIcon
										style={{ display: 'none' }}
										id={`breadcrumbs-link-pencilIcon`}
										data-testid={`breadcrumbs-link-${index}-pencilIcon`}
									/>
								)}
							</div>
						)}
					</StyledWrapper.InnerContainer>
				);
			})}
		</StyledWrapper.LinkContainer>
	);
}
