import { GPM } from '../gpmAPI';

const formBuilderApi = GPM.injectEndpoints({
	endpoints: (builder) => ({
		getForms: builder.query({
			query: (apiToken) => ({
				url: `form/forms/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${apiToken}`
				}
			})
		}),
		getIndividualFormSchema: builder.query({
			query: (options) => ({
				url: `form/fields/${options?.id}/retrieve-fields/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options.apiToken}`
				}
			}),
			providesTags: ['IndividualForms']
		}),
		getUniqueForm: builder.query({
			query: (options) => ({
				url: `form/forms/unique-id/${options?.uniqueID}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options.apiToken}`
				}
			}),
			providesTags: ['IndividualForms']
		}),
		getFormInstanceSchema: builder.query({
			query: (options) => ({
				url: `applications/form/by_unique_id/${options.uniqueID}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options.apiToken}`
				}
			}),
			providesTags: (result, error, queryArg) => [
				{ type: 'FormInstanceSchema', id: queryArg.uniqueID }
			]
		}),
		getIndividualForms: builder.query({
			query: (options) => ({
				url: `form/forms/${options?.id}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options.apiToken}`
				}
			}),
			providesTags: ['IndividualForms']
		}),

		createForm: builder.mutation({
			query: (options) => ({
				url: 'form/forms/',
				method: 'POST',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['Stages', 'IndividualForms']
		}),
		updateForm: builder.mutation({
			query: (options) => ({
				url: `form/forms/${options.id}/`,
				method: 'PATCH',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['Stages', 'IndividualForms']
		}),
		updateCoreInfoStage: builder.mutation({
			query: (options) => ({
				url: `form/forms/${options?.form_id}/change_stage/${options?.stage_id}/`,
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['Stages', 'IndividualForms']
		}),
		postFormSchema: builder.mutation({
			query: (options) => ({
				url: `form/fields/${options?.id}/bulk-create/`,
				method: 'POST',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages']
		}),

		postMultiForm: builder.mutation({
			query: (options) => ({
				url: `program/field/${options?.id}/bulk-create-multistep/`,
				method: 'POST',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms']
		}),
		postUploadFile: builder.mutation({
			query: (options) => ({
				url: `form/fields/upload_file/`,
				method: 'POST',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms']
		}),
		postFormValues: builder.mutation({
			query: (options) => ({
				url: `form/user-forms/${options?.formID}/store-values/`,
				method: 'POST',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: (result, error, queryArg) => [
				'Stages',
				'ApplicationProcess',
				'IndividualForms',
				{ type: 'FormInstanceSchema', id: queryArg.formID }
			]
		}),

		getFormAnswers: builder.query({
			query: (options) => ({
				url: `program/user-form/${options?.id}/retrieve-values/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			})
		}),
		returnToApplicant: builder.mutation({
			query: (options) => ({
				url: `applications/update_status/return_to_applicant/${options?.id}/`,
				method: 'PATCH',
				body: {
					status: options?.status
				},
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages', 'Reviewers']
		}),
		updateFormStatus: builder.mutation({
			query: (options) => ({
				url: `applications/update_status/form/${options?.id}/`,
				method: 'PATCH',
				body: {
					status: options?.status
				},
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: (result, error, queryArg) => [
				'IndividualForms',
				'Stages',
				'Reviewers',
				{ type: 'FormInstanceSchema', id: queryArg.id }
			]
		}),
		updateStageStatus: builder.mutation({
			query: (options) => ({
				url: `applications/update_status/stage/${options?.id}/`,
				method: 'PATCH',
				body: {
					status: options?.status
				},
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: (result, error, queryArg) => [
				'IndividualForms',
				'Stages',
				'Reviewers',
				{ type: 'FormInstanceSchema', id: queryArg.id }
			]
		}),
		updateAppProcessStatus: builder.mutation({
			query: (options) => ({
				url: `applications/update_status/process/${options?.id}/`,
				method: 'PATCH',
				body: {
					status: options?.status
				},
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: (result, error, queryArg) => [
				'IndividualForms',
				'Stages',
				'Reviewers',
				{ type: 'FormInstanceSchema', id: queryArg.id }
			]
		}),
		getIndividualApplicationStatus: builder.query({
			query: (options) => ({
				url: `applications/${options?.application_id}/status/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getApplicationFormTemplates: builder.query({
			query: (options) => ({
				url: `form/form_templates/applications/?page=${options?.page}&page_size=${options?.pageSize}${options?.finalQuery}&templates=${options?.templateGeneralFilter}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			providesTags: ['IndividualForms']
		}),
		getReviewFormTemplates: builder.query({
			query: (options) => ({
				url: `form/form_templates/reviews/?page=${options?.page}&page_size=${options?.pageSize}${options?.finalQuery}&templates=${options?.templateGeneralFilter}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			providesTags: ['IndividualForms']
		}),
		updateArchiveStatus: builder.mutation({
			query: (options) => ({
				url: `form/forms/${options?.id}/`,
				body: options?.payload,
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms']
		}),
		updateArchiveStatusStage: builder.mutation({
			query: (options) => ({
				url: `form/stage/${options?.stage_id}/`,
				body: options?.payload,
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages']
		}),
		updateArchiveStatusProcess: builder.mutation({
			query: (options) => ({
				url: `form/application-process/${options?.id}/`,
				body: options?.payload,
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages']
		}),
		getFormsBeingReviewed: builder.query({
			query: (options) => ({
				url: `/applications/form/forms_being_reviewed/`,
				body: options?.payload,
				method: 'POST',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			providesTags: ['IndividualForms']
		})
	}),
	overrideExisting: false
});

export const {
	useGetFormsQuery,
	useGetIndividualFormsQuery,
	useGetUniqueFormQuery,
	useGetIndividualFormSchemaQuery,
	useCreateFormMutation,
	useUpdateFormMutation,
	usePostUploadFileMutation,
	useUpdateCoreInfoStageMutation,
	usePostMultiFormMutation,
	usePostFormSchemaMutation,
	usePostFormValuesMutation,
	useGetFormAnswersQuery,
	useGetFormInstanceSchemaQuery,
	useReturnToApplicantMutation,
	useUpdateFormStatusMutation,
	useUpdateStageStatusMutation,
	useUpdateAppProcessStatusMutation,
	useGetIndividualApplicationStatusQuery,
	useGetApplicationFormTemplatesQuery,
	useGetReviewFormTemplatesQuery,
	useUpdateArchiveStatusMutation,
	useUpdateArchiveStatusStageMutation,
	useUpdateArchiveStatusProcessMutation,
	useGetFormsBeingReviewedQuery
} = formBuilderApi;
