import { getOldBlueColor } from '../../utils/utilFunctions';
export const ReassignIcon = ({ isActive, width, color }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.62 6.56471C5.02 8.02471 4 10.1147 4 12.4447C4 16.2447 6.66 19.4247 10.21 20.2347C10.67 20.3447 11 20.7447 11 21.2147C11 21.8647 10.39 22.3347 9.76 22.1947C5.32 21.1747 2 17.1947 2 12.4447C2 9.55471 3.24 6.96471 5.2 5.14471L3.86 3.79471C3.54 3.48471 3.76 2.94471 4.21 2.94471H8.5C8.78 2.94471 9 3.16471 9 3.44471V7.73471C9 8.18471 8.46 8.40471 8.15 8.09471L6.62 6.56471ZM14.24 2.69471C18.68 3.71471 22 7.69471 22 12.4447C22 15.3347 20.76 17.9247 18.8 19.7447L20.14 21.0947C20.46 21.4047 20.24 21.9447 19.79 21.9447H15.5C15.22 21.9447 15 21.7247 15 21.4447V17.1547C15 16.7047 15.54 16.4847 15.85 16.7947L17.38 18.3247C18.98 16.8647 20 14.7747 20 12.4447C20 8.64471 17.34 5.46471 13.79 4.65471C13.33 4.54471 13 4.14471 13 3.67471C13 3.02471 13.61 2.55471 14.24 2.69471ZM12 7.94473C13.1 7.94473 14 8.84473 14 9.94473C14 11.0447 13.1 11.9447 12 11.9447C10.9 11.9447 10 11.0447 10 9.94473C10 8.84473 10.9 7.94473 12 7.94473ZM7.69 14.4347C8.88 13.5047 10.37 12.9447 12 12.9447C13.63 12.9447 15.12 13.5047 16.31 14.4347C15.45 15.9247 13.85 16.9447 12 16.9447C10.15 16.9447 8.55 15.9247 7.69 14.4347Z"
				fill={isActive ? color || getOldBlueColor() : 'rgba(0, 0, 0, 0.38)'}
			/>
		</svg>
	);
};

export default ReassignIcon;
