import { useState, useEffect } from 'react';

import { Table } from '../../../components/Table';
import StyledWrapper from '../style';

import { Box, Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PrintActions from '../../../components/PrintActions/PrintActions';

const AllNotifications = ({ rows }) => {
	// useStates
	const [openRows, setOpenRows] = useState([]);
	const [finalRows, setFinalRows] = useState(rows || []);
	const [hasUnseenRows, setHasUnseenRows] = useState(true);
	const [refresh, setRefresh] = useState(true);

	const setAllAsSeen = () => {
		const seenRows = finalRows.map((e) => {
			return { ...e, seen: true };
		});
		setFinalRows(seenRows);
	};

	const toggleRow = (cellID) => {
		let findRow = openRows?.findIndex((e) => cellID === e);
		let finalClosedRows = openRows;
		findRow !== -1 ? openRows.splice(findRow, 1) : openRows.push(cellID);
		setOpenRows(finalClosedRows);
		setRefresh(!refresh);
	};

	useEffect(() => {
		let filteredRows = [...finalRows];
		filteredRows?.sort((a, b) => (a.seen > b.seen ? 1 : -1));
		setFinalRows(filteredRows);
	}, [rows]);

	useEffect(() => {
		let anyUnseenRows = finalRows?.find((e) => e.seen === false);
		setHasUnseenRows(anyUnseenRows === undefined ? true : false);
	}, [finalRows]);

	const columns = [
		{
			field: 'status',
			headerName: 'Status',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={openRows.includes(cellValues.id)}
					>
						<IconButton
							data-testid={'notification-bell'}
							color="default"
							style={{ padding: '0px', height: '40px', width: '40px' }}
						>
							<img
								src={cellValues?.row?.seen ? '/images/bell.svg' : '/images/bell-orange.svg'}
								alt="notification-bell"
							/>
						</IconButton>

						<IconButton
							style={{ padding: '0px', height: '40px', width: '40px' }}
							onClick={() => {
								toggleRow(cellValues.id);
							}}
						>
							{!openRows.includes(cellValues.id) ? (
								<KeyboardArrowRightIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</IconButton>
					</StyledWrapper.NotificationCellContainer>
				);
			},
			flex: 1
		},
		{
			field: 'entity',
			headerName: 'Entity',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={openRows.includes(cellValues.id)}
					>
						{!openRows.includes(cellValues.id) ? (
							<StyledWrapper.AllNotificationTitle>
								{cellValues.value}
							</StyledWrapper.AllNotificationTitle>
						) : (
							<Collapse in={openRows.includes(cellValues.id)}>
								<Box>{cellValues.value}</Box>
							</Collapse>
						)}
					</StyledWrapper.NotificationCellContainer>
				);
			},
			flex: 1
		},
		{
			field: 'title',
			headerName: 'Title',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={openRows.includes(cellValues.id)}
					>
						{!openRows.includes(cellValues.id) ? (
							<StyledWrapper.AllNotificationTitle>
								<b>{cellValues.value}</b>
							</StyledWrapper.AllNotificationTitle>
						) : (
							<Collapse in={openRows.includes(cellValues.id)}>
								<Box>
									<b>{cellValues.value}</b>
								</Box>
							</Collapse>
						)}
					</StyledWrapper.NotificationCellContainer>
				);
			},
			flex: 2
		},
		{
			field: 'body',
			headerName: 'Body',
			editable: true,
			renderCell: (cellValues) => {
				return (
					<StyledWrapper.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={openRows.includes(cellValues.id)}
					>
						{!openRows.includes(cellValues.id) ? (
							<StyledWrapper.UnexpandedText>{cellValues.value}</StyledWrapper.UnexpandedText>
						) : (
							<Collapse in={openRows.includes(cellValues.id)}>
								<Box>{cellValues.value}</Box>
							</Collapse>
						)}
					</StyledWrapper.NotificationCellContainer>
				);
			},
			flex: 5
		},
		{
			field: 'date',
			headerName: 'Timestamp',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={openRows.includes(cellValues.id)}
					>
						{!openRows.includes(cellValues.id) ? (
							<StyledWrapper.UnexpandedText>{cellValues.value}</StyledWrapper.UnexpandedText>
						) : (
							<Collapse in={openRows.includes(cellValues.id)}>
								<Box>{cellValues.value}</Box>
							</Collapse>
						)}
					</StyledWrapper.NotificationCellContainer>
				);
			},
			flex: 2
		}
	];

	return (
		<>
			<StyledWrapper.Toolbar>
				<StyledWrapper.NotificationsControlsContainer
					data-testid={`programs-allnotifications-container`}
				>
					<StyledWrapper.NotificationControl
						data-testid={`programs-allnotifications-allread`}
						isDisabled={hasUnseenRows}
						onClick={() => setAllAsSeen()}
					>
						Mark all as read
					</StyledWrapper.NotificationControl>
					<StyledWrapper.NotificationControlDivider />
					<StyledWrapper.NotificationControl
						data-testid={`programs-allnotifications-allexpanded`}
						isDisabled={openRows?.length === finalRows?.length}
						onClick={() => {
							let rowIds = finalRows?.map((e) => e.id);
							setOpenRows(rowIds);
						}}
					>
						Expand All
					</StyledWrapper.NotificationControl>
					<StyledWrapper.NotificationControlDivider />
					<StyledWrapper.NotificationControl
						data-testid={`programs-allnotifications-allcollapse`}
						isDisabled={openRows?.length === 0}
						onClick={() => {
							setOpenRows([]);
						}}
					>
						Collapse All
					</StyledWrapper.NotificationControl>
				</StyledWrapper.NotificationsControlsContainer>
			</StyledWrapper.Toolbar>

			<Table columns={columns} rows={finalRows} minWidth={'1000px'} />
		</>
	);
};

export default AllNotifications;
