import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 0px 0px 14px 0px;
`;

const InnerCardContainer = styled.div`
	height: 100%;
	overflow-y: scroll;
	padding: 0px 32px;
`;

const CardTitle = styled(Link)`
	font-weight: 500;
`;

const CardTitleNoLink = styled.p`
	font-weight: 500;
	margin: 0px;
`;

const CardRowContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	width: ${(props) => props?.width || '100%'};
	height: ${(props) => (props?.dropdownstate ? 'fit-content' : '52px')};

	.label-help-icon {
		visibility: hidden;
	}

	&:hover .label-help-icon {
		visibility: initial !important;
`;

// Notifications
const NotificationTitle = styled(Link)`
	width: 55%;
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: nowrap;
	overflow: hidden;
	margin: 0px 0px;
	font-size: 14px;
`;

const NotificationDate = styled.h6`
	margin: ${(props) => props?.margin || '0px 0px'};
	width: 38%;
	text-align: end;
`;

// GENERAL

const NothingToShow = styled.h6`
	text-align: center;
`;

export default {
	CardTitleContainer,
	InnerCardContainer,
	CardTitle,
	CardTitleNoLink,
	CardRowContainer,
	NotificationTitle,
	NotificationDate,
	NothingToShow
};
