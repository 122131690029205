import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Checkbox from '@mui/material/Checkbox';

const TableCustomCheckbox = {
	width: 100,
	minWidth: 50,
	className: 'testing',
	maxWidth: null,
	hide: false,
	hideable: true,
	sortable: false,
	resizable: false,
	filterable: false,
	groupable: true,
	pinnable: true,
	aggregable: false,
	editable: false,
	type: 'checkboxSelection',
	align: 'center',
	filterOperators: [
		{
			value: 'is'
		}
	],
	headerAlign: 'center',
	field: '__check__',
	disableColumnMenu: true,
	disableReorder: true,
	disableExport: true
};

export function ControlledCheckbox({
	id,
	disabled,
	onChange,
	isChecked,
	isChanged,
	enableChangedColor,
	isReviewStageAll,
	isReviewStage,
	toReviewDependancy,
	indeterminate
}) {
	const [checked, setChecked] = useState(isChecked);
	const toReview = useSelector((state) => state?.form?.to_review);

	const handleChange = (event) => {
		onChange && onChange(event);
		setChecked(event.target.checked);
	};

	useEffect(() => {
		isReviewStage && toReviewDependancy && setChecked(toReviewDependancy(id));
	}, [toReview]);

	useEffect(() => {
		isReviewStageAll && setChecked(isChecked);
	}, [isChecked]);

	return (
		<Checkbox
			indeterminate={indeterminate}
			id={id}
			disabled={disabled}
			checked={checked}
			onChange={handleChange}
			inputProps={{ 'aria-label': 'controlled' }}
			sx={
				isChanged &&
				enableChangedColor && {
					color: '#FF9800',
					'&.Mui-checked': {
						color: '#FF9800'
					},
					'&.Mui-disabled': {
						color: 'rgba(0, 0, 0, 0.26)'
					}
				}
			}
		/>
	);
}

export default TableCustomCheckbox;
