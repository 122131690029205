import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';
export const ReviewColorStageIcon = ({ width, customColor, isActive }) => {
	return isActive ? (
		<span style={{ display: 'flex' }}>
			<svg
				width={width || '30'}
				height={width || '30'}
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.17 4C9.7 4 10.21 4.21 10.59 4.59L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17ZM5 13C6.10091 10.0733 8.81818 8 12 8C15.1818 8 17.8991 10.0733 19 13C17.8991 15.9267 15.1818 18 12 18C8.81818 18 6.10091 15.9267 5 13ZM8.81818 13C8.81818 14.84 10.2436 16.3333 12 16.3333C13.7564 16.3333 15.1818 14.84 15.1818 13C15.1818 11.16 13.7564 9.66667 12 9.66667C10.2436 9.66667 8.81818 11.16 8.81818 13ZM10.0909 13C10.0909 11.8933 10.9436 11 12 11C13.0564 11 13.9091 11.8933 13.9091 13C13.9091 14.1067 13.0564 15 12 15C10.9436 15 10.0909 14.1067 10.0909 13Z"
					fill={customColor}
				/>
				<circle cx="18.5" cy="6" r="6" fill="white" />
				<path
					d="M18.4992 8.35503L20.5742 9.71461C20.9542 9.96377 21.4192 9.59544 21.3192 9.12961L20.7692 6.57294L22.6042 4.85044C22.9392 4.53628 22.7592 3.94044 22.3192 3.90253L19.9042 3.68044L18.9592 1.26461C18.7892 0.825859 18.2092 0.825859 18.0392 1.26461L17.0942 3.67503L14.6792 3.89711C14.2392 3.93503 14.0592 4.53086 14.3942 4.84503L16.2292 6.56753L15.6792 9.12419C15.5792 9.59002 16.0442 9.95836 16.4242 9.70919L18.4992 8.35503Z"
					fill={'#F57F17'}
				/>
			</svg>
		</span>
	) : (
		<span style={{ display: 'flex' }}>
			<svg
				width={width || '30'}
				height={width || '30'}
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.17 4C9.7 4 10.21 4.21 10.59 4.59L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17ZM5 13C6.10091 10.0733 8.81818 8 12 8C15.1818 8 17.8991 10.0733 19 13C17.8991 15.9267 15.1818 18 12 18C8.81818 18 6.10091 15.9267 5 13ZM8.81818 13C8.81818 14.84 10.2436 16.3333 12 16.3333C13.7564 16.3333 15.1818 14.84 15.1818 13C15.1818 11.16 13.7564 9.66667 12 9.66667C10.2436 9.66667 8.81818 11.16 8.81818 13ZM10.0909 13C10.0909 11.8933 10.9436 11 12 11C13.0564 11 13.9091 11.8933 13.9091 13C13.9091 14.1067 13.0564 15 12 15C10.9436 15 10.0909 14.1067 10.0909 13Z"
					fill={customColor}
				/>
			</svg>
		</span>
	);
};

export default ReviewColorStageIcon;
