import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ScoreCard from '../../../components/Cards/ScoreCard/ScoreCard';
import CompletionStatusCard from '../../IndividualApplication/components/CompletionStatusCard/CompletionStatusCard';
import { NotificationCard } from '../../../components/NotificationCard';
import CardCoreInfo from '../../../components/Cards/CardCoreInfo/CardCoreInfo';
import StyledWrapper from '../../IndividualApplication/style';
import ReviewFormsContainerTable from '../components/ReviewFormContainerTable/ReviewFormContainerTable';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TabPanel, a11yProps } from '../../../components/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useGetIndividualAppTable } from '../../../hooks/useGetIndividualAppTable';
import AppStagesTable from '../../IndividualApplication/components/AppStagesTable/AppStagesTable';
import ReviewDetails from './ReviewDetails';
import CompareReviews from './CompareReviews';

const ReviewFormContainer = ({ appId, handleAlert }) => {
	const navigate = useNavigate();
	const [value, setValue] = useState(0);
	const [chosenView, setChosenView] = useState(<></>);
	const tabs = ['DETAILS & STATUSES', 'MASTER LAYOUT', 'COMPARE REVIEWS'];

	const pathArray = location?.pathname?.split('/');

	let subPage = pathArray?.filter((e) => e?.includes('rfm-'))?.[0];
	const { data, isLoading, reFormat } = useGetIndividualAppTable({ appId, subPage });
	const title = data[0]?.title;
	const handleChange = (page) => {
		pathArray.pop();
		if (page === 2) {
			pathArray.push('compare-reviews');
		} else if (page === 1) {
			pathArray.push('layout');
		} else {
			pathArray.push('details');
		}
		navigate(pathArray?.join('/'));
	};

	useEffect(() => {
		const actualPage = pathArray[pathArray.length - 1];
		if (actualPage === 'compare-reviews') {
			setValue(2);
			setChosenView(2);
		} else if (actualPage === 'layout') {
			setValue(1);
			setChosenView(1);
		} else {
			setValue(0);
			setChosenView(0);
		}
	}, [pathArray, isLoading]);

	useEffect(() => {
		reFormat();
	}, [location.pathname]);

	return (
		<>
			{/* ENABLE IN PHASE 2 IF NEEDED */}
			<StyledWrapper.OutermostContainer style={{ width: '100%', margin: 'auto', gap: '15px' }}>
				{/*	<IconButton
				sx={{ marginLeft: 'auto' }}
				aria-label="moreOptions"
				data-testid={'programDetails-moreOptions-icon'}
			>
				<MoreHorizIcon />
			</IconButton>
			<Alert variant="outlined" severity={'warning'}>
				{'This Review Layout is not assigned. Assign at least one reviewer'}
			</Alert>
			<StyledWrapper.InnerContainer>
				<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
					<CardCoreInfo width={'100%'} height={'220px'} data={coreInfo} disableChangeStatus />
					<CompletionStatusCard
						width={'100%'}
						height={'400px'}
						chartData={chart1Data}
						title={'Aggregated Completion Status 0%*'}
						hintText={'* Overall (mandatory and optional)'}
					/>
				</div>
				<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
					<ScoreCard width={'100%'} customTitle={'Aggregated Score '} data={{}} />
					<NotificationCard
						width={'100%'}
						title={"Review's Notifications"}
						height={'578px'}
						boxOverflowHidden={true}
					/>
				</div>
			</StyledWrapper.InnerContainer>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
				<ReviewFormsContainerTable />
			</div> */}

				<Box sx={{ width: '100%' }}>
					<Box
						data-testid={`individualReview-box`}
						sx={{ padding: '0px 24px', marginBottom: '30px' }}
					>
						<Tabs
							value={value}
							onChange={(e, value) => {
								handleChange(value);
							}}
							aria-label="basic tabs example"
							style={{ width: 'fit-content' }}
						>
							{tabs.map((e, index) => {
								return (
									<Tab
										iconPosition="start"
										data-testid={`individualReview-tab-${index}`}
										key={`FormTab-${index}`}
										label={`${e}`}
										{...a11yProps(e + 1)}
									/>
								);
							})}
						</Tabs>
					</Box>

					<TabPanel data-testid={`individualReview-tab-panel`} value={value} index={value}>
						{chosenView === 0 ? (
							<AppStagesTable data={data} handleAlert={handleAlert} />
						) : chosenView === 1 ? (
							<ReviewDetails isFormsContainer isMasterLayout />
						) : (
							<CompareReviews title={title} />
						)}
					</TabPanel>
				</Box>
			</StyledWrapper.OutermostContainer>
		</>
	);
};

const chart1Data = [
	{
		id: 'Optional Unfilled',
		value: 2,
		color: '#CFD8DC',
		pattern: 'dots'
	},
	{
		id: 'Mandatory Unfilled',
		value: 3,
		color: '#1976D2',
		pattern: 'dots'
	}
];

const coreInfo = [
	{
		title: 'Status',
		value: 'Unassigned'
	},
	{
		title: 'Total Nr of Fields',
		value: '3 mandatory, 2 optional'
	},
	{
		title: 'Related Application',
		value: '[app-345342]',
		link: '/'
	}
];

export default ReviewFormContainer;
