import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { TabPanel, a11yProps } from '../../components/TabPanel';
import PrintActions from '../../components/PrintActions/PrintActions';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import ApplicationProcessTemplates from './subPages/ApplicationProcessTemplates/ApplicationProcessTemplates';
import SubmissionStageTemplates from './subPages/SubmissionTemplates/SubmissionTemplates';
import ApplicationTemplates from './subPages/ApplicationTemplates/ApplicationTemplates';
import ReviewTemplates from './subPages/ReviewTemplates/ReviewTemplates';
import ReviewStageTemplates from './subPages/ReviewStageTemplates/ReviewStageTemplates';

const Templates = () => {
	const tabs = [
		'APPLICATIONS',
		'SUBMISSION STAGES',
		'EVALUTATION STAGES',
		'APPLICATION FORMS',
		'REVIEW FORMS'
	];
	const navigate = useNavigate();
	const location = useLocation();

	//UseStates
	const [value, setValue] = useState(null);
	const [chosenView, setChosenView] = useState(<></>);

	const handleChange = (event, newValue) => {
		switch (newValue) {
			case 1:
				return navigate('/templates/submission-stages');
			case 2:
				return navigate('/templates/evaluation-stages');
			case 3:
				return navigate('/templates/base-application-forms');
			case 4:
				return navigate('/templates/base-review-forms');
			default:
				return navigate('/templates/applications');
		}
	};

	const selectView = (view) => {
		switch (view) {
			case 0:
				return <ApplicationProcessTemplates />;
			case 1:
				return <SubmissionStageTemplates />;
			case 2:
				return <ReviewStageTemplates />;
			case 3:
				return <ApplicationTemplates />;
			case 4:
				return <ReviewTemplates />;
			default:
				return null;
		}
	};

	useEffect(() => {
		setChosenView(selectView(value));
	}, [value]);

	useEffect(() => {
		if (location.pathname.includes('/templates/applications')) {
			setValue(0);
		} else if (location.pathname.includes('/templates/submission-stages')) {
			setValue(1);
		} else if (location.pathname.includes('/templates/evaluation-stages')) {
			setValue(2);
		} else if (location.pathname.includes('/templates/base-application-forms')) {
			setValue(3);
		} else if (location.pathname.includes('/templates/base-review-forms')) {
			setValue(4);
		} else {
			setValue(null);
		}
	}, [location]);

	return (
		<>
			<h4 data-testid={`templates-title`}>Systemwide Templates</h4>

			<Box sx={{ width: '100%' }}>
				<Box
					data-testid={`individualprograms-box`}
					sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						style={{ width: 'fit-content', marginBottom: '30px' }}
						indicatorColor="secondary"
					>
						{tabs.map((e, index) => {
							return (
								<Tab
									data-testid={`individualprograms-tab-${index}`}
									key={`FormTab-${index}`}
									label={`${e}`}
									{...a11yProps(e + 1)}
								/>
							);
						})}
					</Tabs>
					<PrintActions data-testid={`templates-actionButtons`} />
				</Box>

				<TabPanel data-testid={`individualprograms-tab-panel`} value={value} index={value}>
					{chosenView}
				</TabPanel>
			</Box>
		</>
	);
};

export default Templates;
