export const testTabs = [
	{
		title: 'Application',
		link: 'application'
	},
	{
		title: 'Program Details',
		link: 'program%20details'
	},
	// {
	// 	title: 'Team & Roles',
	// 	link: 'Team%20&%20Roles'
	// },
	// {
	// 	title: 'Roles Permissions',
	// 	link: 'Roles%20Permissions'
	// },
	{
		title: 'Budget',
		link: 'Budget'
	},
	{
		title: 'Goals & Metrics',
		link: 'Goals%20&%20Metrics'
	}
];

export const TeamAndRolesData = [
	{
		id: 1,
		role: 'Administrator',
		name: 'Christopher Walken',
		department: 'U.S. Department of Agriculture',
		email: 'cwalken@hollywood.org',
		addedDate: '05/10/2022 09:02 AM'
	},
	{
		id: 2,
		role: 'Editor',
		name: 'Al Pacino',
		department: 'Bureau of Labor Statistics',
		email: 'alpacino@gmail.com',
		addedDate: '02/15/2021 12:32 AM'
	},
	{
		id: 3,
		role: 'Full Member',
		name: 'Jack Nicholson',
		department: 'Economic Research Service',
		email: 'jackN@gmail.com',
		addedDate: '12/10/2022 06:22 AM'
	},
	{
		id: 4,
		role: 'Editor',
		name: 'Mel Gibson',
		department: 'Patent and Trademark Office',
		email: 'melG@gmail.com',
		addedDate: '29/02/2022 06:22 AM'
	},
	{
		id: 5,
		role: 'Unassigned',
		name: 'Jeff Bridges',
		department: 'Weights and Measures Division',
		email: 'jeffB@gmail.com',
		addedDate: '01/01/2023 02:00 AM'
	}
];
