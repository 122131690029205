import Store from 'beedle';
import { get, post } from './requests';

let _saveUrl;
let _onPost;
let _onLoad;

const store = new Store({
	actions: {
		setData(context, data, saveData) {
			context.commit('setData', data);
			if (saveData) this.save(data);
		},

		load(context, { loadUrl, saveUrl, data, saveAlways }) {
			_saveUrl = saveUrl;
			const saveA = saveAlways || saveAlways === undefined;
			context.commit('setSaveAlways', saveA);
			if (_onLoad) {
				_onLoad().then((x) => {
					if (data && data.length > 0 && x.length === 0) {
						data.forEach((y) => x.push(y));
					}
					this.setData(context, x);
				});
			} else if (loadUrl) {
				get(loadUrl).then((x) => {
					if (data && data.length > 0 && x.length === 0) {
						data.forEach((y) => x.push(y));
					}
					this.setData(context, x);
				});
			} else {
				this.setData(context, data);
			}
		},

		create(context, element) {
			const { data, saveAlways } = context.state;
			data.push(element);
			this.setData(context, data, saveAlways);
		},

		deletePlaceholders(context, element) {
			const { data, saveAlways } = context.state;
			data.forEach((e, index) => {
				if (e.element === 'CardPlaceHolder') {
					data.splice(index, 1);
				}
			});
			this.setData(context, data, saveAlways);
		},

		delete(context, element) {
			const { data, saveAlways } = context.state;
			data.splice(data.indexOf(element), 1);
			this.setData(context, data, saveAlways);
		},

		post(context) {
			const { data } = context.state;
			this.setData(context, data, true);
		},

		updateOrder(context, elements) {
			const { saveAlways } = context.state;
			const newData = elements.filter((x) => x && !x.parent_id);
			elements.filter((x) => x && x.parent_id).forEach((x) => newData.push(x));
			this.setData(context, newData, saveAlways);
		},

		save(data) {
			if (_onPost) {
				_onPost({ task_data: data });
			} else if (_saveUrl) {
				post(_saveUrl, { task_data: data });
			}
		}
	},

	mutations: {
		setData(state, payload) {
			// eslint-disable-next-line no-param-reassign
			state.data = payload;
			return state;
		},
		setSaveAlways(state, payload) {
			// eslint-disable-next-line no-param-reassign
			state.saveAlways = payload;
			return state;
		}
	},

	initialState: {
		data: [],
		saveAlways: true
	}
});

store.setExternalHandler = (onLoad, onPost) => {
	_onLoad = onLoad;
	_onPost = onPost;
};

export default store;
