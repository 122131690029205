import { useEffect } from 'react';

import ScoreCard from '../../../components/Cards/ScoreCard/ScoreCard';
import CompletionStatusCard from '../../IndividualApplication/components/CompletionStatusCard/CompletionStatusCard';
import { NotificationCard } from '../../../components/NotificationCard';
import { Instructions } from '../../../components/FormBuilder/customComponents/Instructions';
import ReviewStagesTable from '../components/ReviewStageTable/ReviewStagesTable';
import CardCoreInfo from '../../../components/Cards/CardCoreInfo/CardCoreInfo';
import StyledWrapper from '../../IndividualApplication/style';
import AppStagesTable from '../../IndividualApplication/components/AppStagesTable/AppStagesTable';
import { useGetIndividualAppTable } from '../../../hooks/useGetIndividualAppTable';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const ReviewStageOverview = ({ appId, subPage, handleAlert }) => {
	const { data, isLoading: isLoadingSetup } = useGetIndividualAppTable({ appId, subPage });
	const instructions = { content: data?.[0]?.instructions || '' };

	return (
		<>
			{isLoadingSetup ? (
				<>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<CircularProgress />
					</Box>
				</>
			) : (
				<StyledWrapper.OutermostContainer style={{ width: '100%', margin: 'auto', gap: '15px' }}>
					{/* Uncomment when needed in phase 2 */}
					{/* <StyledWrapper.InnerContainer>
					<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
						<CardCoreInfo width={'100%'} data={coreInfo} height={'300px'} />
						<CompletionStatusCard
							width={'100%'}
							chartData={chart1Data}
							title={'Aggregated Completion Status 56%*'}
							hintText={'* Overall (mandatory and optional)'}
						/>
					</div>
					<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
						<ScoreCard isStage />
						<NotificationCard
							data={MockData}
							title={'Stage Notifications'}
							height={'637px'}
							boxOverflowHidden={true}
						/>
					</div>
				</StyledWrapper.InnerContainer>
*/}
					<StyledWrapper.InnerContainer>
						<Instructions data={instructions || ''} hideLabel width={'100%'} />
					</StyledWrapper.InnerContainer>
					{/* 
				<StyledWrapper.InnerContainer>
					<ReviewStagesTable />
				</StyledWrapper.InnerContainer> */}

					<AppStagesTable data={data} handleAlert={handleAlert} />
				</StyledWrapper.OutermostContainer>
			)}
		</>
	);
};

export default ReviewStageOverview;
