export function handleValidation(type: string, value: string): string | undefined {
	let validation;
	switch (type) {
		case 'Temail':
			validation = isValidEmail(value);
			break;
		default:
			validation = undefined;
			break;
	}
	return validation;
}

export function isValidEmail(email: string): string | undefined {
	const validate =
		// eslint-disable-next-line
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email || ''
		);
	return validate ? undefined : 'Enter a valid email';
}
