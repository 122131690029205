/**
 * <ReactFormBuilder />
 */

import React from 'react';
import { IntlProvider } from 'react-intl';
import Preview from './preview';
import Toolbar from './toolbar';
import FormGenerator from './form';
import store from './stores/store';
import Registry from './stores/registry';
import AppLocale from './language-provider';

import { FormInfo } from '../FormBuilder/FormInfo';
import DependancyChecker from '../MultiFormViewer/DependancyChecker';
import { HelpIcon } from '../core/HelpIcon';

class ReactFormBuilder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editMode: false,
			editElement: null
		};
		this.editModeOn = this.editModeOn.bind(this);
		this.formId = props.formId;
		this.forceRefresh = props.forceRefresh;
	}

	editModeOn(data, e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.state.editMode) {
			this.setState({ editMode: !this.state.editMode, editElement: null });
		} else {
			this.setState({ editMode: !this.state.editMode, editElement: data });
		}
	}

	manualEditModeOff() {
		if (this.state.editMode) {
			this.setState({
				editMode: false,
				editElement: null
			});
		}
	}

	render() {
		const toolbarProps = {
			showDescription: this.props.show_description
		};

		const language = this.props.locale ? this.props.locale : 'en';
		const currentAppLocale = AppLocale[language];
		if (this.props.toolbarItems) {
			toolbarProps.items = this.props.toolbarItems;
		}

		return (
			<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
				<div style={{ width: '100%' }}>
					<div className="react-form-builder clearfix">
						<div className="form-builder-outermost-container">
							<div
								className="form-builder-inner-left"
								style={{
									width: !this.props.hideToolbox && this.props?.hasEditPermissions ? '80%' : '100%'
								}}
							>
								<div className="form-builder-title widthMargin">
									{this.props.formTitle || 'Form Layout'}
								</div>
								<div className="form-builder-inner-container">
									{this.props.formInfo && <FormInfo {...this.props} />}
									{/* <DependancyChecker/> */}
									<Preview
										files={this.props.files}
										manualEditModeOff={this.manualEditModeOff.bind(this)}
										showCorrectColumn={this.props.showCorrectColumn}
										parent={this}
										data={this.props.data}
										url={this.props.url}
										saveUrl={this.props.saveUrl}
										onLoad={this.props.onLoad}
										onPost={this.props.onPost}
										editModeOn={this.editModeOn}
										editMode={this.state.editMode}
										variables={this.props.variables}
										registry={Registry}
										editElement={this.state.editElement}
										renderEditForm={this.props.renderEditForm}
										saveAlways={this.props.saveAlways}
										hasBusinessLogic={this.props.formInfo?.hasBusinessLogic}
										readOnly={
											this.props.in_use && !this?.props?.isTemplate ? true : this.props.readOnly
										}
										enableSelection={this.props.enableSelection}
										selectionOnClick={this.props.selectionOnClick}
										hideToolbox={this.props?.hideToolbox}
										isTemplate={this?.props?.isTemplate}
										dateFinalized={this.props.dateFinalized}
									/>
								</div>
							</div>

							{!this.props.hideToolbox && this.props?.hasEditPermissions && (
								<div
									className={`form-builder-inner-right ${
										this.props?.readOnly && 'disabled-section'
									}`}
								>
									<div className="form-builder-title">
										Form Elements Toolbox{' '}
										<HelpIcon
											style={{ right: '20px' }}
											iconStyle={{ fill: 'white', opacity: '1' }}
											hint={'Drag and drop element in the editable area'}
										/>
									</div>

									<Toolbar
										{...toolbarProps}
										customItems={this.props.customToolbarItems}
										formId={this.formId}
										forceRefresh={this.forceRefresh}
										enableAddOnClick={this.props.enableAddOnClick}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</IntlProvider>
		);
	}
}

function ReactFormGenerator(props) {
	const language = props.locale ? props.locale : 'en';
	const currentAppLocale = AppLocale[language];
	return (
		<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
			<FormGenerator {...props} />
		</IntlProvider>
	);
}

const FormBuilders = {};
FormBuilders.ReactFormBuilder = ReactFormBuilder;
FormBuilders.ReactFormGenerator = ReactFormGenerator;
FormBuilders.ElementStore = store;
FormBuilders.Registry = Registry;

export default FormBuilders;

export { ReactFormBuilder, ReactFormGenerator, store as ElementStore, Registry };
