import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDependancies } from '../FormBuilderLibrary/slice';
import { checkAllConditions, checkConditions } from '../../utils/conditionalLogic';

export default function DependancyChecker(props) {
	const dispatch = useDispatch();
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const allDependancies = useSelector((state) => state?.form?.dependancies);
	const answers = useSelector((state) => state?.form?.form);

	useEffect(() => {
		const formSchema = props?.formSchema ? [props?.formSchema] : reduxFormSchema;

		if (formSchema?.length > 0 && formSchema[0]) {
			let allInputs = [];
			formSchema?.forEach((e) => {
				e !== undefined && allInputs?.push(...e);
			});
			let dependancies = allInputs.filter((e) => e?.dependency);
			dispatch(setDependancies(dependancies));
		}
	}, [reduxFormSchema]);

	useEffect(() => {
		const allAnswers = props?.answers ? [props?.answers] : answers;
		const formSchema = props?.formSchema ? [props?.formSchema] : reduxFormSchema;

		checkAllConditions(formSchema, allAnswers);
	}, [props, answers]);

	return <></>;
}
