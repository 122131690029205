import styled from 'styled-components';

const StatusContainer = styled.p`
	background-color: rgba(102, 102, 102, 1);
	border-radius: 0px 16px 16px 0px;
	padding: 0px 8px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-size: 0.8125rem;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props?.backgroundColor || '#009DAC'};
	color: ${(props) => props?.color || 'white'};
`;

const DecisionContainer = styled.p`
	background-color: rgba(102, 102, 102, 1);
	border-radius: 16px 0px 0px 16px;
	padding: 0px 8px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-size: 0.8125rem;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props?.color || 'white'};
`;

const ButtonContainer = styled.div`
	display: flex;
	width: fit-content;
	justify-content: center;
	align-items: center;
`;

export default {
	StatusContainer,
	DecisionContainer,
	ButtonContainer
};
