import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CustomDialog = styled(Dialog)`
	width: 100%;

	& .MuiDialog-paper {
		padding: 30px;
	}
`;

const CustomDialogTitle = styled(DialogTitle)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const CustomDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
`;

export default {
	CustomDialog,
	CustomDialogTitle,
	CustomDialogContent
};
