export function composeSearchParams(params: Record<string, unknown>): string {
	const searchParams = new URLSearchParams();
	Object.entries(params).forEach(([key, value]) => {
		if (value != null && value !== '') {
			searchParams.append(key, value.toString());
		}
	});

	return searchParams.toString();
}

export function getFinalQuery(finalQuery: string): string {
	if (finalQuery == null || finalQuery.replace('&', '').length === 0) {
		return '';
	}
	return finalQuery;
}
