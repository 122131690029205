import styled from 'styled-components';

const OutermostContainer = styled.div`
	display: flex;
	gap: 15px;
	width: 100%;
	justify-content: center;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: ${(props) => props?.width || 'fit-content'};
	gap: 15px;
	max-height: 1019px;
	width: 50%;
	height: auto;
	border-radius: 4px;
`;

export default {
	OutermostContainer,
	InnerContainer
};
