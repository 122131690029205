export const columns = [
	{
		field: 'status',
		headerName: 'Status',
		editable: false,
		flex: 1,
		resizable: true
	},
	{
		field: 'title',
		headerName: 'Title',
		editable: false,
		flex: 1,
		resizable: true
	},
	{
		field: 'body',
		headerName: 'Body',
		description: 'This column has a value getter and is not sortable.',
		flex: 4,
		resizable: true
	},
	{
		field: 'timestamp',
		headerName: 'Timestamp',
		editable: false,
		flex: 1,
		resizable: true
	}
];

export const rows = [
	{
		start: '18.05.2022',
		end: '04.04.2022',
		title: 'Computer Systems Analyst III',
		budget: 'Investment Managers',
		type: 'N/A',
		entity: 'Program',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		id: 1
	},
	{
		department: 'Jewelry',
		start: '17.08.2022',
		end: '11.02.2022',
		title: 'Analog Circuit Design manager',
		budget: 'Textiles',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		id: 2
	},
	{
		department: 'Automotive',
		start: '25.10.2022',
		end: '31.07.2022',
		title: 'Recruiter',
		budget: 'n/a',
		type: 'N/A',
		entity: 'Application',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$546009.34',
		id: 3
	},
	{
		department: 'Automotive',
		start: '12.08.2022',
		end: '24.05.2022',
		title: 'Budget/Accounting Analyst IV',
		budget: 'Computer Software: Prepackaged Software',
		type: 'N/A',
		entity: 'Review',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$635511.03',
		id: 4
	},
	{
		department: 'Baby',
		start: '22.06.2022',
		end: '24.04.2022',
		title: 'Civil Engineer',
		budget: 'n/a',
		type: 'N/A',
		entity: 'Application',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$680162.90',
		id: 5
	},
	{
		department: 'Automotive',
		start: '25.12.2021',
		end: '01.05.2022',
		title: 'Engineer I',
		budget: 'Computer Software: Prepackaged Software',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$717587.42',
		id: 6
	},
	{
		department: 'Toys',
		start: '26.06.2022',
		end: '22.11.2021',
		title: 'Health Coach III',
		budget: 'Oil & Gas Production',
		type: 'N/A',
		entity: 'Application',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$585876.63',
		id: 7
	},
	{
		department: 'Tools',
		start: '18.10.2022',
		end: '07.07.2022',
		title: 'Analog Circuit Design manager',
		budget: 'Restaurants',
		type: 'N/A',
		entity: 'Program',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$458511.54',
		id: 8
	},
	{
		department: 'Electronics',
		start: '06.07.2022',
		end: '29.08.2022',
		title: 'Nurse',
		budget: 'Biotechnology: Biological Products (No Diagnostic Substances)',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$546509.38',
		id: 9
	},
	{
		department: 'Baby',
		start: '25.08.2022',
		end: '17.10.2022',
		title: 'Senior Quality Engineer',
		budget: 'Integrated oil Companies',
		type: 'N/A',
		entity: 'System',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$293109.32',
		id: 10
	},
	{
		department: 'Electronics',
		start: '27.01.2022',
		end: '16.07.2022',
		title: 'Nurse',
		budget: 'Marine Transportation',
		type: 'N/A',
		entity: 'Program',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$743019.66',
		id: 11
	},
	{
		department: 'Music',
		start: '25.04.2022',
		end: '17.07.2022',
		title: 'Chemical Engineer',
		budget: 'Natural Gas Distribution',
		type: 'N/A',
		entity: 'Application',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$567310.90',
		id: 12
	},
	{
		department: 'Movies',
		start: '04.04.2022',
		end: '21.05.2022',
		title: 'Marketing Assistant',
		budget: 'Real Estate',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$459737.05',
		id: 13
	},
	{
		department: 'Baby',
		start: '25.02.2022',
		end: '07.11.2021',
		title: 'Automation Specialist III',
		budget: 'Semiconductors',
		type: 'N/A',
		entity: 'Program',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$270348.85',
		id: 14
	},
	{
		department: 'Books',
		start: '07.07.2022',
		end: '25.12.2021',
		title: 'Marketing Manager',
		budget: 'Consumer Electronics/Appliances',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$385078.53',
		id: 15
	},
	{
		department: 'Baby',
		start: '22.01.2022',
		end: '29.03.2022',
		title: 'Help Desk Operator',
		budget: 'n/a',
		type: 'N/A',
		entity: 'Program',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$211615.85',
		id: 16
	},
	{
		department: 'Music',
		start: '15.07.2022',
		end: '31.03.2022',
		title: 'Human Resources Assistant III',
		budget: 'Major Banks',
		type: 'N/A',
		entity: 'Review',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$268620.21',
		id: 17
	},
	{
		department: 'Tools',
		start: '03.05.2022',
		end: '25.09.2022',
		title: 'Junior Executive',
		budget: 'Metal Fabrications',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$636355.00',
		id: 18
	},
	{
		department: 'Computers',
		start: '06.06.2022',
		end: '05.08.2022',
		title: 'Junior Executive',
		budget: 'Electric Utilities: Central',
		type: 'N/A',
		entity: 'Program',
		seen: true,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$660796.99',
		id: 19
	},
	{
		department: 'Jewelry',
		start: '16.10.2022',
		end: '10.06.2022',
		title: 'Nurse',
		budget: 'n/a',
		type: 'N/A',
		entity: 'System',
		seen: false,
		body: '"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."',
		date: '08:11AM  08/10/2022',
		total: '$341816.15',
		id: 20
	}
];
