import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	getEntityPrefix,
	reOrderRows,
	removeDuplicates,
	onlySubpageRows,
	calculateMasterFormCompletion,
	is_within_range
} from '../utils/utilFunctions';
import { getLocalAuth } from '../utils/environmentUtils';
import { useGetIndividualApplicationQuery } from '../services/endpoints/programsEndpoints';
import { usePermissions } from './usePermissions';
import { GPM } from '../services/gpmAPI';

export const useGetIndividualAppTable = ({ appId, subPage }) => {
	const [searchParams] = useSearchParams();
	const prevSearchParams = useRef(searchParams.toString());

	const { userAccountTypeID } = usePermissions();
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	subPage = subPage?.replace('-group', '');
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const [rowData, setRowData] = useState([]);
	const [reFormat, setReformat] = useState(false);
	const [finalLoading, setFinalLoading] = useState(false);
	const uniqueID = appId?.split('-')?.[1];

	const formType = searchParams.get('formtype') || '';
	const iFrame = searchParams.get('iFrame') || '';
	const queryParams = useMemo(
		() => ({
			uniqueID,
			apiToken,
			formType,
			iFrame
		}),
		[uniqueID, apiToken, formType, iFrame]
	);

	const { refetch } = GPM.endpoints.getIndividualApplication.useQuerySubscription(queryParams);
	const { data, error } = GPM.endpoints.getIndividualApplication.useQueryState(queryParams);

	useEffect(() => {
		setFinalLoading(true);
		const app = error?.status === 404 ? {} : data;
		let copiedRowData = [];
		const ApplicationPrefix = getEntityPrefix({ type: 'application-process', isInstance: true });
		const BaseURL = `/programs/p-${programID}/applications/`;
		const initialRow = {
			hierarchy: [`${ApplicationPrefix}${app?.unique_identifier}`],
			type: 'Application Instance',
			appId: userAccountTypeID ? `${ApplicationPrefix}${app?.unique_identifier}` : '',
			id_link: `${BaseURL}${ApplicationPrefix}${app?.unique_identifier}/details`,
			completion: userAccountTypeID ? `${app?.completion || 0}%` : ' ',
			completion_number: app?.completion,
			status: app?.status,
			id: 0,
			programID: programID,
			instructions: app?.instructions,
			is_current_stage: app?.form_stage_id === ''
		};

		let allSubmitted = true;
		let hasPrevSubmissionForm = false;
		if (app?.all_stages) {
			app?.all_stages?.forEach((e, index) => {
				const StagePrefix = getEntityPrefix({
					type: e?.stage?.stage_type === 'application' ? 'submission-stage' : 'evaluation-stage',
					isInstance: true
				});
				const stageType = e?.stage?.stage_type?.includes('application')
					? 'Submission Stage'
					: 'Evaluation Stage';

				if (stageType === 'Submission Stage' && e?.status !== 'Submitted') {
					allSubmitted = false;
					hasPrevSubmissionForm = true;
				}

				let all_stages_submitted = null;
				const hasSubmissionStages = app?.all_stages?.find((e) => {
					return e?.stage?.stage_type?.includes('application');
				});

				if (stageType === 'Evaluation Stage' && hasSubmissionStages) {
					all_stages_submitted = allSubmitted;
					allSubmitted = !hasPrevSubmissionForm ? true : allSubmitted;
					hasPrevSubmissionForm = false;
				} else if (stageType === 'Evaluation Stage') {
					all_stages_submitted =
						!app?.form_stage_id ||
						app?.form_stage_id === '' ||
						app?.form_stage_id?.stage_instance_id === e?.stage_instance_id
							? true
							: false;
				}

				const masterStage = e?.stage;

				const isWithinDateRange = is_within_range(masterStage, app?.status?.includes('returned'));
				const hierarchy = [];
				!subPage && hierarchy.push(`${ApplicationPrefix}${app?.unique_identifier}`);
				hierarchy.push(`${StagePrefix}${e?.unique_identifier}`);
				if ((subPage && subPage === `${StagePrefix}${e?.unique_identifier}`) || !subPage) {
					copiedRowData.push({
						hierarchy: hierarchy,
						type: stageType,
						appId: `${StagePrefix}${e?.unique_identifier}`,
						id_link: `${BaseURL}${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/details`,
						completion: userAccountTypeID ? `${e?.completion || 0}%` : ' ',
						completion_number: e?.completion,
						status: e?.status,
						id: copiedRowData?.length + 1,
						title: e?.stage?.stage_name,
						instructions: e?.stage?.instructions,
						reviewers: e?.reviewers,
						app_status: app?.status,
						stage_status: e?.status,
						next_stage_started:
							app?.all_stages?.[index + 1] &&
							!['Unassigned', 'Not Started', 'In Progress (paused)']?.includes(
								app?.all_stages?.[index + 1]?.status
							),
						all_stages_submitted: all_stages_submitted,
						is_current_stage: e?.unique_identifier === app?.form_stage_id?.unique_identifier,
						isPortalUser: !userAccountTypeID,
						isWithinDateRange: isWithinDateRange
					});
				}

				//SHOW MASTER FORMS
				if (
					(subPage && subPage === `${StagePrefix}${e?.unique_identifier}`) ||
					(subPage && subPage?.includes('rfm-')) ||
					!subPage
				) {
					e?.master_forms?.forEach((x) => {
						const MasterFormPrefix = getEntityPrefix({
							type: 'review-form',
							isMaters: true
						});
						const hierarchy = [];
						!subPage && hierarchy.push(`${ApplicationPrefix}${app?.unique_identifier}`);
						!subPage?.includes('rfm-') && hierarchy.push(`${StagePrefix}${e?.unique_identifier}`);
						hierarchy.push(`${MasterFormPrefix}${x?.unique_identifier}`);

						// Check Review Container Status
						const masterFormStatus = () => {
							let completed = 0;
							let not_started = 0;
							let not_started_paused = 0;

							const filteredForms = e?.forms?.filter(
								(reviewForm) => reviewForm?.form?.form_id === x?.form_id
							);

							filteredForms?.forEach((reviewForm) => {
								if (reviewForm?.status === 'Completed') {
									completed += 1;
								} else if (reviewForm?.status === 'Not Started') {
									not_started += 1;
								} else if (reviewForm?.status === 'Not Started (paused)') {
									not_started_paused += 1;
								}
							});

							if (filteredForms?.length === 0) {
								return 'Unassigned';
							} else if (filteredForms?.length === not_started) {
								return 'Not Started';
							} else if (filteredForms?.length === not_started_paused) {
								return 'Not Started (paused)';
							} else if (filteredForms?.length === completed) {
								return 'Completed';
							} else {
								return e?.status;
							}
						};

						copiedRowData.push({
							hierarchy: hierarchy,
							type: 'Review Form(s)',
							appId: `${MasterFormPrefix}${x?.unique_identifier}`,
							id_link: `${BaseURL}${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${MasterFormPrefix}${x?.unique_identifier}-group/details`,
							completion: userAccountTypeID ? `${x?.completion || 0}%` : '',
							completion_number: x?.completion,
							status: userAccountTypeID ? masterFormStatus() : ' ',
							title: x?.label,
							id: copiedRowData?.length + 1,
							instructions: x?.instructions,
							app_status: app?.status,
							stage_status: e?.status,
							isPortalUser: !userAccountTypeID,
							is_current_stage: e?.unique_identifier === app?.form_stage_id?.unique_identifier
						});
						// SHOW INDIVIDUAL REVIEW CONTENT FOR MASTER FORM
						e?.forms?.forEach((i) => {
							const FormPrefix = getEntityPrefix({
								type: i?.form?.form_type === 'application' ? 'application-form' : 'review-form',
								isInstance: true
							});
							const hierarchy = [];
							!subPage && hierarchy.push(`${ApplicationPrefix}${app?.unique_identifier}`);
							(subPage === `${StagePrefix}${e?.unique_identifier}` || !subPage) &&
								hierarchy.push(`${StagePrefix}${e?.unique_identifier}`);
							e?.master_forms?.length > 0 &&
								hierarchy.push(`${MasterFormPrefix}${x?.unique_identifier}`);
							hierarchy.push(`${FormPrefix}${i?.unique_identifier}`);

							if (x?.form_id === i?.form?.form_id) {
								const title =
									i?.form?.form_type === 'review'
										? `Review by ${i?.reviewer?.first_name} ${i?.reviewer?.last_name}`
										: i?.form?.label;

								copiedRowData.push({
									hierarchy: hierarchy,
									type:
										i?.form?.form_type === 'application' ? 'Application Form' : 'Review Content',
									appId: `${FormPrefix}${i?.unique_identifier}`,
									id_link:
										i?.form?.form_type === 'application'
											? `${BaseURL}${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${FormPrefix}${i?.unique_identifier}/content`
											: `${BaseURL}${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${MasterFormPrefix}${x?.unique_identifier}-group/${FormPrefix}${i?.unique_identifier}/content`,
									completion: `${i?.completion || 0}%`,
									completion_number: i?.completion,
									status: i?.status,
									title: title,
									master_id: i?.form?.form_id,
									instructions: i?.form?.instructions,
									id: copiedRowData?.length + 1,
									app_status: app?.status,
									stage_status: e?.status,
									next_stage_started:
										app?.all_stages?.[index + 1] &&
										!['Unassigned', 'Not Started', 'In Progress (paused)']?.includes(
											app?.all_stages?.[index + 1]?.status
										),
									all_stages_submitted: all_stages_submitted,
									is_current_stage: e?.unique_identifier === app?.form_stage_id?.unique_identifier,
									isWithinDateRange: isWithinDateRange
								});
							}
						});
					});
				}

				//SHOW INDIVIDUAL FORMS
				if (!subPage?.includes('rfm-')) {
					e?.forms?.forEach((x) => {
						const FormPrefix = getEntityPrefix({
							type: x?.form?.form_type === 'application' ? 'application-form' : 'review-form',
							isInstance: true
						});

						const MasterFormPrefix = getEntityPrefix({
							type: x?.form?.form_type === 'application' ? 'application-form' : 'review-form',
							isMaters: true
						});

						if (
							copiedRowData?.findIndex(
								(j) => j?.appId === `${FormPrefix}${x?.unique_identifier}`
							) === -1
						) {
							const hierarchy = [];
							!subPage && hierarchy.push(`${ApplicationPrefix}${app?.unique_identifier}`);
							hierarchy.push(`${StagePrefix}${e?.unique_identifier}`);
							hierarchy.push(`${FormPrefix}${x?.unique_identifier}`);

							copiedRowData.push({
								hierarchy: hierarchy,
								type: x?.form?.form_type === 'application' ? 'Application Form' : 'Review Content',
								appId: `${FormPrefix}${x?.unique_identifier}`,
								id_link: `${BaseURL}${ApplicationPrefix}${app?.unique_identifier}/${StagePrefix}${e?.unique_identifier}/${FormPrefix}${x?.unique_identifier}`,
								completion: `${x?.completion || 0}%`,
								completion_number: x?.completion,
								status: x?.status,
								master_id: `${MasterFormPrefix}${x?.form?.unique_identifier}`,
								title: x?.form?.label,
								id: copiedRowData?.length + 1,
								app_status: app?.status,
								stage_status: e?.status,
								next_stage_started:
									app?.all_stages?.[index + 1] &&
									!['Unassigned', 'Not Started', 'In Progress (paused)']?.includes(
										app?.all_stages?.[index + 1]?.status
									),
								isWithinDateRange: isWithinDateRange,
								is_current_stage: e?.unique_identifier === app?.form_stage_id?.unique_identifier
							});
						}
					});
				}
			});
		}

		const onlySubPageRows = subPage ? onlySubpageRows(copiedRowData, subPage) : copiedRowData;
		const noDuplicatedRows = removeDuplicates(onlySubPageRows);
		userAccountTypeID && calculateMasterFormCompletion(noDuplicatedRows);

		let finalRows = [];
		!subPage && finalRows.push(initialRow);
		if (app?.stage_order?.length > 0) {
			const temp = reOrderRows(app?.stage_order, noDuplicatedRows);

			finalRows = [...finalRows, ...temp];
		} else {
			finalRows = [...finalRows, ...noDuplicatedRows];
		}

		setRowData(finalRows);
		setFinalLoading(false);
	}, [data, error, reFormat]);

	useEffect(() => {
		if (prevSearchParams.current !== searchParams.toString()) {
			prevSearchParams.current = searchParams.toString();
			refetch({ force: true, skip: false });
		}
	}, [searchParams]);

	return {
		data: rowData,
		isLoading: finalLoading,
		refetch,
		reFormat: () => setReformat(!reFormat)
	};
};
