import styled from 'styled-components';

const ButtonsContainer = styled.div`
	display: flex;
	width: fit-content;
	gap: 6px;
	& button {
		min-width: fit-content;
	}
`;

export default {
	ButtonsContainer
};
