import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { setFormMetaInfo, setToReviewForms } from '../../components/FormBuilderLibrary/slice';
import StyledWrapper from './style';

import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Button from '@mui/material/Button';
import { getEntityPrefix } from '../../utils/utilFunctions';

export const ProcessMetaInfo = ({
	readOnly: readOnlyProp,
	individualStage,
	isLoading,
	setMetaInfoChanged
}) => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const stageCreationParam = searchParams.get('stageCreation');
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);

	const [expanded, setExpanded] = useState('panel1');
	const [readOnly, setReadOnly] = useState(readOnlyProp);
	const [dateChanged, setDateChanged] = useState(false);
	const [loadingState, setLoadingState] = useState(isLoading);
	const [data, setData] = useState({
		title: '',
		id: '',
		description: '',
		instructions: '',
		bl: false,
		sequential: false
	});

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const saveMetaInfo = () => {
		dispatch(setFormMetaInfo(data));
	};

	const handleDataChange = (inputName, value) => {
		setMetaInfoChanged && setMetaInfoChanged(true);
		setData({
			...data,
			[inputName]: value
		});
	};

	useEffect(() => {
		dateChanged && saveMetaInfo();
		dateChanged && setDateChanged(false);
	}, [dateChanged]);

	useEffect(() => {
		setData(formMetaInfo);
		setReadOnly(readOnlyProp);
	}, [formMetaInfo, readOnlyProp]);

	useEffect(() => {
		const Prefix =
			stageCreationParam === 'process'
				? getEntityPrefix({
						type: 'application-process',
						isBaseTemplate: true
				  })
				: getEntityPrefix({
						type:
							individualStage?.stage_type === 'application'
								? 'submission-stage'
								: 'evaluation-stage',
						isBaseTemplate: true
				  });

		const stageInfo = {
			title: individualStage?.stage_name || individualStage?.process_name,
			id: individualStage?.unique_identifier && `${Prefix}${individualStage?.unique_identifier}`,
			description: individualStage?.description,
			instructions: individualStage?.instructions,
			type: individualStage?.stage_type,
			stage_forms: individualStage?.forms,
			bl: false
		};
		dispatch(setFormMetaInfo(stageInfo));
		dispatch(setToReviewForms(individualStage?.review_info || []));
		setLoadingState(isLoading);
	}, [individualStage, isLoading]);

	return (
		<>
			{loadingState ? (
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '40px',
						border: '1px solid #90a4ae'
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<StyledWrapper.MetaInfoContainer>
					<StyledWrapper.Accordion
						disableGutters
						elevation={0}
						square
						expanded={expanded === 'panel1'}
						onChange={handleChange('panel1')}
					>
						<StyledWrapper.AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="form-meta-header-content"
							id="form-meta-header"
						>
							REVIEW APPLICATION PROCESS META SETTINGS & INFO
						</StyledWrapper.AccordionSummary>
						<StyledWrapper.AccordionDetails>
							<StyledWrapper.FormInfoOutermost>
								<StyledWrapper.FormInfoRow>
									<TextField
										disabled={readOnly}
										required
										sx={{ width: '50%' }}
										id="formBuilder-meta-input-title"
										label="Template Name"
										value={data?.title}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('title', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
									<TextField
										disabled
										sx={{ width: '50%' }}
										id="formBuilder-meta-input-id"
										label="Template ID"
										value={data?.id || '[]'}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('id', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
								</StyledWrapper.FormInfoRow>
								<StyledWrapper.FormInfoRow>
									<TextField
										disabled={readOnly}
										sx={{ width: '100%;' }}
										id="formBuilder-meta-input-instructions"
										label="Template Description"
										value={data?.description}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('description', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
								</StyledWrapper.FormInfoRow>
								<StyledWrapper.FormInfoRow>
									<TextField
										disabled={readOnly}
										sx={{ width: '100%;' }}
										id="formBuilder-meta-input-instructions"
										label={
											stageCreationParam === 'process'
												? 'Application Process Overall Instructions'
												: 'Stage Instructions'
										}
										value={data?.instructions}
										color={'warning'}
										onChange={(event) => {
											handleDataChange('instructions', event.target.value);
										}}
										onBlur={saveMetaInfo}
									/>
								</StyledWrapper.FormInfoRow>
								<button type="submit" className="meta-info-form" style={{ display: 'none' }} />
							</StyledWrapper.FormInfoOutermost>
							<br />
							{/* <StyledWrapper.FormRowTitle>
								BUSINESS LOGIC & RULES
								<SettingsSuggestIcon />
							</StyledWrapper.FormRowTitle>
							<StyledWrapper.FormInfoOutermost>
								<StyledWrapper.FormInfoRow justifyContent={'space-between'}>
									<FormControlLabel
										disabled={readOnly}
										control={
											<Switch
												checked={data?.sequential}
												onChange={(newValue) =>
													// handleDataChange('sequential', newValue?.target?.checked)
													{
														dispatch(
															setFormMetaInfo({
																...data,
																sequential: newValue?.target?.checked
															})
														);
													}
												}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label={`Sequential Steps ${formMetaInfo?.sequential ? 'ON' : 'OFF'}`}
									/>
									<Button
										disabled={readOnly}
										sx={{ background: 'white', textTransform: 'capitalize' }}
										variant="outlined"
										startIcon={<SettingsSuggestIcon />}
									>
										Set Inter-Form Business Logic
									</Button>
								</StyledWrapper.FormInfoRow>
							</StyledWrapper.FormInfoOutermost> */}
						</StyledWrapper.AccordionDetails>
					</StyledWrapper.Accordion>
				</StyledWrapper.MetaInfoContainer>
			)}
		</>
	);
};
