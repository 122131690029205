import StyledWrapper from './style';

import { PrimaryButton } from '../../components/ButtonPrimary';
import { getGNURL } from '../../utils/environmentUtils';

const LoginRedirect = () => {
	const onSubmit = async (e) => {
		e.preventDefault();
		location.replace(getGNURL());
	};

	return (
		<StyledWrapper.LoginPage onSubmit={onSubmit}>
			<div>
				<StyledWrapper.Logo src="/images/login-logo.svg" alt="ECivisLogo" />
				<StyledWrapper.Container>
					{/* Title */}
					<StyledWrapper.Title>Login into ARM</StyledWrapper.Title>
					<StyledWrapper.Subtitle>
						Application & Review Management
						<br />
						Alpha version 1.1.0
					</StyledWrapper.Subtitle>

					<StyledWrapper.ButtonContainer>
						<PrimaryButton type="submit" text="Go To Grants Network To Login" />
					</StyledWrapper.ButtonContainer>
				</StyledWrapper.Container>
			</div>
			<StyledWrapper.SingleSignIn>
				<h6 data-testid={`login-single-sign-on`}>
					To access Application and Review Management system, you have to login to Grants Network.
				</h6>
			</StyledWrapper.SingleSignIn>
			<StyledWrapper.GYT src="/images/euna-full-logo.svg" alt="gyt-disclaimer" />
		</StyledWrapper.LoginPage>
	);
};

export default LoginRedirect;
