import { useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { InfoBar } from '../InfoBar';
import Typography from '@mui/material/Typography';

import StyledWrapper from './style';

import { applicationTitle } from '../../constants/Layout';

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	marginLeft: '0px',
	width: '100%',
	backgroundColor: 'white',
	boxShadow:
		'0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
	zIndex: theme.zIndex.drawer + 5,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

export default function NavBar({ open, userAccountTypeID }) {
	const [mobileView, setMobileView] = useState();

	// Check Window Size
	function handleResize() {
		if (window.innerWidth <= 768 && !mobileView) {
			setMobileView(true);
		} else if (window.innerWidth > 768 && mobileView) {
			setMobileView(false);
		}
	}
	window.addEventListener('resize', handleResize);

	return (
		<AppBar data-testid={'navbar-appbar'} position="absolute" open={open}>
			<Toolbar
				id={'navbar-toolbar'}
				data-testid={'navbar-toolbar'}
				style={{
					justifyContent: 'space-between',
					gap: `${open && !mobileView ? '60px' : '30px'}`,
					paddingLeft: `${open && !mobileView ? '43px' : '23px'}`
				}}
				sx={{
					pr: '24px' // keep right padding when drawer closed
				}}
			>
				<StyledWrapper.LogoWrapper data-testid={'navbar-logo-container'} open={open && !mobileView}>
					<img
						data-testid={'navbar-logo'}
						src={open && !mobileView ? '/images/login-logo.svg' : '/images/logo-icon.svg'}
						alt="logo"
						onClick={() => {
							location.href = '/';
						}}
						style={{ cursor: 'pointer' }}
					/>
				</StyledWrapper.LogoWrapper>

				{!mobileView && (
					<Typography
						data-testid={'navbar-logo-mobile-text'}
						component="h1"
						variant="h6"
						color="#009DAC"
						noWrap
						sx={{ flexGrow: 1 }}
					>
						{applicationTitle}
					</Typography>
				)}
				<InfoBar />
			</Toolbar>
		</AppBar>
	);
}
