import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from './style';

import { SectionCard } from '../../components/SectionCard';

import Button from '@mui/material/Button';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { StageUsageTable } from './components/StageAppSelection/StageUsageTable';
import { UsageTable } from './components/StageAppSelection/UsageTable';
import ArchiveDialog from '../../components/ArchiveDialog/ArchiveDialog';
import { useGetTemplatesUsageInfoQuery } from '../../services/endpoints/templatesEndpoints';
import { getLocalAuth } from '../../utils/environmentUtils';

export const TemplateUsage = ({ isForm }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const id =
		searchParams?.get('formId') || searchParams?.get('stageId') || searchParams?.get('processId');
	const type = searchParams?.get('formId')
		? 'form'
		: searchParams?.get('stageId')
		? 'stage'
		: 'application-process';
	const [archiveOpen, setArchiveOpen] = useState(false);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const { data } = useGetTemplatesUsageInfoQuery({ apiToken, type: type, templateID: id });

	return (
		<>
			<SectionCard
				width={'100%'}
				height={'fit-content'}
				display={'flex'}
				padding={'30px'}
				justifyContent={'center'}
			>
				{/* <StyledWrapper.ButtonRow>
					<Button
						variant="outlined"
						startIcon={<ArchiveIcon color="warning" />}
						onClick={() => {
							setArchiveOpen(true);
						}}
					>
						ARCHIVE TEMPLATE
					</Button>
					<Button variant="outlined" startIcon={<DeleteForeverIcon color="warning" />}>
						DELETE TEMPLATE
					</Button>
				</StyledWrapper.ButtonRow> */}

				<StyledWrapper.TemplateInfoContainer>
					{!searchParams?.get('processId') && (
						<StyledWrapper.InfoRow>
							<StyledWrapper.InfoTitle>
								{`Nr of ${
									searchParams?.get('formId') ? 'Stage' : 'Application Process'
								} Templates linked to:`}
							</StyledWrapper.InfoTitle>
							<StyledWrapper.InfoDesc>{data?.linked_count || 0}</StyledWrapper.InfoDesc>
						</StyledWrapper.InfoRow>
					)}

					<StyledWrapper.InfoRow>
						<StyledWrapper.InfoTitle>
							{searchParams?.get('processId')
								? `Applied in Applications Process Master Setup:`
								: `Applied in setup directly:`}
						</StyledWrapper.InfoTitle>
						<StyledWrapper.InfoDesc>
							{data?.applied_count || 0} {data?.applied_count === 1 ? 'time' : 'times'}
						</StyledWrapper.InfoDesc>
					</StyledWrapper.InfoRow>
				</StyledWrapper.TemplateInfoContainer>
				<StyledWrapper.TemplateInfoContainer>
					<StyledWrapper.InfoRow>
						<StyledWrapper.InfoTitle>Created by:</StyledWrapper.InfoTitle>
						<StyledWrapper.InfoDesc>{`${data?.created_by_user?.firstName} ${data?.created_by_user?.lastName}`}</StyledWrapper.InfoDesc>
					</StyledWrapper.InfoRow>

					<StyledWrapper.InfoRow>
						<StyledWrapper.InfoTitle>Last Edited By:</StyledWrapper.InfoTitle>
						<StyledWrapper.InfoDesc>{`${data?.updated_by_user?.firstName || ''} ${
							data?.updated_by_user?.lastName || ''
						}`}</StyledWrapper.InfoDesc>
					</StyledWrapper.InfoRow>
				</StyledWrapper.TemplateInfoContainer>
			</SectionCard>

			<UsageTable />

			<ArchiveDialog isOpen={archiveOpen} type={type} />
		</>
	);
};
