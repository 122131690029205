import { getOldBlueColor } from '../../utils/utilFunctions';

export const EmailActionIcons = ({ isDelete, isResend, width }) => {
	return isResend ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.6159 13.0013C19.9406 13.0363 21.1154 13.6412 21.9152 14.5811L22.5751 13.9262C22.7301 13.7712 23 13.8812 23 14.1062V16.2508C23 16.3908 22.89 16.5008 22.75 16.5008H20.6055C20.3805 16.5008 20.2705 16.2308 20.4305 16.0759L21.2053 15.296C20.5605 14.5111 19.5907 14.0012 18.5009 14.0012C16.3413 14.0012 14.6316 15.9709 15.0715 18.2056C15.3415 19.5904 16.4763 20.6952 17.861 20.9402C19.7606 21.2801 21.4503 20.0703 21.8852 18.3605C21.9452 18.1506 22.1452 18.0006 22.3651 18.0006C22.6901 18.0006 22.935 18.3055 22.855 18.6155C22.3601 20.5602 20.6005 22 18.5009 22C15.9714 22 13.9318 19.9153 14.0017 17.3707C14.0717 14.931 16.1763 12.9413 18.6159 13.0013Z"
				fill={getOldBlueColor()}
			/>
			<path
				d="M12.08 19H4C2.9 19 2 18.1 2 17V5C2 3.9 2.9 3 4 3H20C21.1 3 22 3.9 22 5V11.68C21.09 11.25 20.08 11 19 11C15.13 11 12 14.13 12 18C12 18.34 12.03 18.67 12.08 19ZM11.47 11.67C11.79 11.87 12.21 11.87 12.53 11.67L19.6 7.25C19.85 7.09 20 6.82 20 6.53C20 5.86 19.27 5.46 18.7 5.81L12 10L5.3 5.81C4.73 5.46 4 5.86 4 6.53C4 6.82 4.15 7.09 4.4 7.25L11.47 11.67Z"
				fill={getOldBlueColor()}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.4286 20.1111C15.4286 20.6 15.8143 21 16.2857 21H19.7143C20.1857 21 20.5714 20.6 20.5714 20.1111V14.7778H15.4286V20.1111ZM16.7871 17.2622C16.62 17.0889 16.62 16.8089 16.7871 16.6356C16.9543 16.4622 17.2243 16.4622 17.3914 16.6356L18 17.2622L18.6043 16.6356C18.7714 16.4622 19.0414 16.4622 19.2086 16.6356C19.3757 16.8089 19.3757 17.0889 19.2086 17.2622L18.6043 17.8889L19.2086 18.5156C19.3757 18.6889 19.3757 18.9689 19.2086 19.1422C19.0414 19.3156 18.7714 19.3156 18.6043 19.1422L18 18.5156L17.3957 19.1422C17.2286 19.3156 16.9586 19.3156 16.7914 19.1422C16.6243 18.9689 16.6243 18.6889 16.7914 18.5156L17.3957 17.8889L16.7871 17.2622ZM20.5714 13.4444H19.5L19.1957 13.1289C19.1186 13.0489 19.0071 13 18.8957 13H17.1043C16.9929 13 16.8814 13.0489 16.8043 13.1289L16.5 13.4444H15.4286C15.1929 13.4444 15 13.6444 15 13.8889C15 14.1333 15.1929 14.3333 15.4286 14.3333H20.5714C20.8071 14.3333 21 14.1333 21 13.8889C21 13.6444 20.8071 13.4444 20.5714 13.4444Z"
				fill="#D32F2F"
			/>
			<path
				d="M12.08 19H4C2.9 19 2 18.1 2 17V5C2 3.9 2.9 3 4 3H20C21.1 3 22 3.9 22 5V11.68C21.09 11.25 20.08 11 19 11C15.13 11 12 14.13 12 18C12 18.34 12.03 18.67 12.08 19ZM11.47 11.67C11.79 11.87 12.21 11.87 12.53 11.67L19.6 7.25C19.85 7.09 20 6.82 20 6.53C20 5.86 19.27 5.46 18.7 5.81L12 10L5.3 5.81C4.73 5.46 4 5.86 4 6.53C4 6.82 4.15 7.09 4.4 7.25L11.47 11.67Z"
				fill="#D32F2F"
			/>
		</svg>
	);
};

export default EmailActionIcons;
