import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import StyledWrapper from './style';

import useDisabledButton from './useDisabledButton.hook';

import { PrimaryButton } from '../../components/ButtonPrimary';
import { InputPassword } from '../../components/InputPassword';
import { INDEX } from '../../constants/routes';
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox';
import ErrorDisplay from '../../components/FormErrorDisplay/ErrorDisplay';
import { logIn } from './slice';
import { usePostLoginMutation } from '../../services/endpoints/authenticationEndpoints';
import { setUser } from './slice';
import { Input } from '../../components/core/Input';

const Login = () => {
	const {
		register,
		formState: { errors }
	} = useForm({
		defaultValues: {
			username: '',
			password: ''
		}
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [loginError, setLoginError] = useState(false);
	const { disabled, setDisabled } = useDisabledButton();

	const [callLogin, result] = usePostLoginMutation();

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			await handleLogin()
				.then((e) => {
					if (e?.data?.access_token) {
						setLoginError(false);
						setDisabled(true);
						dispatch(logIn());
						navigate(INDEX);
					} else {
						throw new Error('Login Error');
					}
				})
				.catch(() => {
					throw new Error('Login Error');
				});
		} catch (e) {
			setLoginError(true);
			const { statusCode } = e;
			if (statusCode === 401 || statusCode === 404) setLoginError(true);
		} finally {
			setDisabled(false);
		}
	};

	const handleLogin = async () => {
		const token = searchParams.get('token');
		const payload = { jwt: token };
		const response = await callLogin(payload);
		response?.data && dispatch(setUser(response.data));
		return response;
	};

	return (
		<StyledWrapper.LoginPage onSubmit={onSubmit}>
			<div>
				<StyledWrapper.Logo src="/images/login-logo.svg" alt="ECivisLogo" />
				<StyledWrapper.Container>
					{/* Title */}
					<StyledWrapper.Title>Login into GPM</StyledWrapper.Title>
					<StyledWrapper.Subtitle>
						Grantor Program Manager, Alpha version 1.1.0
					</StyledWrapper.Subtitle>

					{loginError && <ErrorDisplay errorCode={loginError ? '0x513125123' : ''} />}

					<StyledWrapper.InputContainer>
						{/* <InputEmail
							label="Email"
							nameInput={'email'}
							register={register}
							required
							error={errors}
							id={'login-email-input'}
						/> */}
						<Input
							type="text"
							label={'Username'}
							fieldName={'username'}
							register={register}
							required={true}
							error={errors['username']}
							id={'login-username-input'}
						/>
						<InputPassword
							register={register}
							nameInput={'password'}
							label={'Password'}
							required
							error={errors}
							id={'login-password-input'}
						/>
						<InputCheckbox
							register={register}
							fieldName="checkbox"
							defaultChecked
							label="Remember me"
							id={'login-checkbox'}
						/>
					</StyledWrapper.InputContainer>
					<StyledWrapper.ButtonContainer>
						<PrimaryButton
							loading={result?.isLoading}
							disabled={disabled}
							type="submit"
							text="Login"
						/>
					</StyledWrapper.ButtonContainer>
					<StyledWrapper.LinkContainer data-testid={`login-link-container`}>
						<StyledWrapper.Link href="#" data-testid={`login-link-create`}>
							Create account
						</StyledWrapper.Link>
						<StyledWrapper.Link href="#" data-testid={`login-link-forgot`}>
							Forgot password?
						</StyledWrapper.Link>
					</StyledWrapper.LinkContainer>
				</StyledWrapper.Container>
			</div>
			<StyledWrapper.SingleSignIn>
				<h6 data-testid={`login-single-sign-on`}>Single Sign-On available.</h6>
				<h6 data-testid={`login-sso`}>
					<StyledWrapper.Link href="#">&nbsp;Continue with SSO...</StyledWrapper.Link>
				</h6>
			</StyledWrapper.SingleSignIn>
			<StyledWrapper.GYT src="/images/gyt-tech.svg" alt="gyt-disclaimer" />
		</StyledWrapper.LoginPage>
	);
};

export default Login;
