export const getGNURL = () => {
	const currentUrl = window.location.href;

	// LOCAL and STAGE
	if (currentUrl?.includes('localhost') || currentUrl?.includes('arm.stage.ecivis.com'))
		return 'https://gn.stage.ecivis.com';

	// STAGE-FT
	if (currentUrl?.includes('arm-ft.stage.ecivis.com')) return 'https://gn-ft.stage.ecivis.com';

	// UAT aka TEST
	if (currentUrl?.includes('arm.test.ecivis.com')) return 'https://gn.test.ecivis.com';

	//PREVIEW
	if (currentUrl?.includes('arm-ui.preview.ecivis.com')) return 'https://gn.preview.ecivis.com';

	// PROD
	if (currentUrl?.includes('arm.aws-prod.ecivis.com')) return 'https://gn.ecivis.com';

	// Fallback to stage
	return 'https://gn.stage.ecivis.com';
};

export const getPortalURL = () => {
	const currentUrl = window.location.href;

	// LOCAL and STAGE
	if (currentUrl?.includes('localhost') || currentUrl?.includes('arm.stage.ecivis.com'))
		return 'https://portal.stage.ecivis.com';

	// STAGE-FT
	if (currentUrl?.includes('arm-ft.stage.ecivis.com')) return 'https://portal-ft.stage.ecivis.com';

	// UAT aka TEST
	if (currentUrl?.includes('arm.test.ecivis.com'))
		return 'https://portal.test.ecivis.com';

	//PREVIEW
	if (currentUrl?.includes('arm-ui.preview.ecivis.com')) return 'https://portal.preview.ecivis.com';

	// PROD
	if (currentUrl?.includes('arm.aws-prod.ecivis.com')) return 'https://portal.ecivis.com';

	// Fallback to stage
	return 'https://portal.stage.ecivis.com';
};

export const getLocalAuth = (accountSource) => {
	//If we are in an iFrame that means we need to use portal token
	const isiFrame = new URLSearchParams(window.location.search).get('iFrame');

	if (!accountSource) {
		return '';
	}
	if (isiFrame) {
		return JSON.parse(localStorage.getItem('gpm-auth-portal'))?.access_token;
	}
	return accountSource === ACCOUNT_SOURCE.GRANTS_NETWORK
		? JSON.parse(localStorage.getItem('gpm-auth-gn'))?.access_token
		: accountSource === ACCOUNT_SOURCE.PORTAL
		? JSON.parse(localStorage.getItem('gpm-auth-portal'))?.access_token
		: '';
};

export const ARM_TOKEN_COOKIE = 'armLoginToken';
export const ACCOUNT_SOURCE = {
	GRANTS_NETWORK: 'grantsNetwork',
	PORTAL: 'portal'
};
