import styled from 'styled-components';

const PrintActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	width: ${(props) => props?.width};
	margin-left: ${(props) => props?.marginLeft};
	margin-top: ${(props) => props?.marginTop};
	margin-bottom: ${(props) => props?.marginBottom};

	@media (max-width: 768px) {
		margin-top: 0px;
		margin-left: 0px;
		justify-content: flex-start;
	}
`;

export default {
	PrintActionsContainer
};
