import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import StyledWrapper from '../../IndividualProgram/style';
import { checkOverflow } from '../../../utils/utilFunctions';
import AppFormIcon from '../../../assets/customIcons/appFormIcon';
import AppProcessIcon from '../../../assets/customIcons/applicantProcess';
import SubmissionStageIcon from '../../../assets/customIcons/submissionStageIcon';
import ReviewFormIcon from '../../../assets/customIcons/reviewFormIcon';
import { formatTimestamp } from '../../../utils/dateUtils';
import { getEntityPrefix, getOldBlueColor } from '../../../utils/utilFunctions';
import { getLocalAuth } from '../../../utils/environmentUtils';
import { ButtonDropdown } from '../../../components/core/ButtonDropdown/ButtonDropdown';
import ReviewStageIcon from '../../../assets/customIcons/reviewStageIcon';
import { setFormMetaInfo } from '../../../components/FormBuilderLibrary/slice';
import { useUpdateArchiveStatusStageMutation } from '../../../services/endpoints/formBuilderEndpoints';
import {
	usePostCopyTemplateMutation,
	usePostUnlinkTemplateMutation
} from '../../../services/endpoints/templatesEndpoints';
import { TableTitle } from '../../../components/TableTitle/TableTitle';

// openTooltip, setOpenTooltip
export const StageTemplateColumns = (columnWidths, handleAlert) => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();

	const [updateArchiveStatus] = useUpdateArchiveStatusStageMutation();
	const [unlinkTemplate] = usePostUnlinkTemplateMutation();
	const [copyTemplate] = usePostCopyTemplateMutation();
	const dispatch = useDispatch();

	const handleCopy = (element, type) => {
		let unlinkTemplateOptions = {
			apiToken: apiToken,
			payload: {
				element: element,
				type: type
			}
		};
		copyTemplate(unlinkTemplateOptions)
			.unwrap()
			.then(async () => {
				handleAlert('Template Copied Successfully!', 'success');
			})
			.catch(() => {
				handleAlert('Template Copying Failed!', 'error');
			});
	};

	const handleUnlinking = (parent, child, type) => {
		let unlinkTemplateOptions = {
			apiToken: apiToken,
			payload: {
				parent: parent,
				child: child,
				type: type
			}
		};
		unlinkTemplate(unlinkTemplateOptions)
			.unwrap()
			.then(async () => {
				handleAlert('Template Unlinked Successfully!', 'success');
			})
			.catch(() => {
				handleAlert('Template Unlinking Failed!', 'error');
			});
	};

	const handleArchiveUpdate = (id, archive_status) => {
		let updateFormOptions = {
			stage_id: id,
			apiToken: apiToken,
			payload: { is_archive: archive_status, is_template: true }
		};
		updateArchiveStatus(updateFormOptions)
			.unwrap()
			.then(async () => {
				handleAlert('Archive Status Changed Successfully!', 'success');
			})
			.catch(() => {
				handleAlert('Archive Status Change Failed!', 'error');
			});
	};

	const columns = [
		{
			field: 'title',
			description: 'Template Title',

			hideDescendantCount: true,
			headerName: 'Template',
			renderCell: (cellValues) => {
				const apiRef = useGridApiContext();
				const rowType = cellValues.row.type?.toLowerCase();
				const row = cellValues.api?.getRow(cellValues.id);
				const { table_id: displayId, title, type: entityType } = row;
				const hasChildren = cellValues.rowNode.children?.length > 0;
				const hasParent = cellValues.rowNode.parent != null;
				const titleData = {
					displayId,
					title,
					entityType,
					hasParent,
					isParent: entityType == 'Submission Stage' || entityType == 'Evaluation Stage',
					hasChildren,
					row_id: row.id,
					expanded: cellValues.rowNode.childrenExpanded,
					isArchived: cellValues.row?.is_archived,
					isTemplate: true
				};
				const paddingLeft = (cellValues.row?.hierarchy?.length - 1) * 28;
				const tableStyle = { paddingLeft: paddingLeft };
				const handleExpand = (row_id, expanded) => {
					apiRef.current.setRowChildrenExpansion(row_id, !expanded);
				};
				const handleCardClick = () => {
					dispatch(setFormMetaInfo({ id: cellValues?.row.table_id }));
					if (rowType?.includes('submission stage')) {
						setSearchParams({
							...searchParams,
							stageCreation: 'application',
							stageId: cellValues.row.stage_id
						});
					} else if (rowType?.includes('evaluation stage')) {
						setSearchParams({
							...searchParams,
							stageCreation: 'evaluation',
							stageId: cellValues.row.stage_id
						});
					} else if (
						['application', 'review', 'application form', 'review form'].includes(rowType)
					) {
						dispatch(
							setFormMetaInfo({
								label: cellValues.label,
								second_label: cellValues.second_label,
								instructions: cellValues.instructions,
								description: cellValues.description,
								form_id: `${getEntityPrefix({ type: entityType, isBaseTemplate: true })}${
									cellValues.row.table_id
								}`
							})
						);
						setSearchParams({
							...searchParams,
							formBuilder: rowType?.includes('application') ? 'application' : 'review',
							formId: cellValues.row.form_id
						});
					} else {
						setSearchParams({
							...searchParams,
							stageCreation: 'process',
							processId: cellValues.row.process_id
						});
					}
				};
				return (
					<TableTitle
						titleData={titleData}
						style={tableStyle}
						handleCardClick={handleCardClick}
						handleExpand={handleExpand}
					/>
				);
			},
			flex: columnWidths.__tree_data_group__ || 1,
			minWidth: 400,
			maxWidth: 400
		},
		{
			field: 'type',
			headerName: 'Type',
			description: 'Template Type',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
					/>
				);
			},
			flex: 1
		},
		{
			field: 'description',
			headerName: 'Description',
			description: 'Description',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
					/>
				);
			},
			flex: columnWidths.description || 3,
			minWidth: 400
		},
		{
			field: 'published',
			headerName: 'Published',
			description: 'Published',
			type: 'date',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
						isDate={true}
					/>
				);
			},
			flex: columnWidths.published && 1
		},
		{
			field: 'updated',
			headerName: 'Updated',
			description: 'Updated',
			type: 'date',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
						isDate={true}
					/>
				);
			},
			flex: columnWidths.updated && 1
		},
		{
			field: 'linked_count',
			headerName: 'Linked Up',
			description: 'Linked Up',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						emptyDefaultValue={true}
						isDisabled={isDisabled}
					/>
				);
			},
			flex: columnWidths.linked_count && 1,
			align: 'center',
			headerAlign: 'center'
		},
		{
			field: 'action',
			headerName: 'Actions',
			description: 'Actions',
			renderCell: (cellValues) => {
				const id = cellValues?.row?.form_id || cellValues?.row?.stage_id;
				const isArchived = cellValues?.row?.is_archived;
				const isLinked = cellValues?.row?.linked_count > 0;
				return (
					<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
						{cellValues?.row?.type.includes('Stage') ? (
							<>
								<Tooltip arrow placement="top" title={'Copy'}>
									<IconButton aria-label="copy">
										<AddToPhotosIcon
											sx={{ fill: `${getOldBlueColor()}` }}
											onClick={() => handleCopy(cellValues?.row?.stage_id, 'stage')}
										/>
									</IconButton>
								</Tooltip>

								<Tooltip arrow placement="top" title={isArchived ? 'Unarchive' : 'Archive'}>
									<IconButton aria-label="archive">
										{isArchived ? (
											<UnarchiveIcon
												color={'primary'}
												onClick={() => handleArchiveUpdate(id, false)}
											/>
										) : (
											<ArchiveIcon
												color={'warning'}
												onClick={() => handleArchiveUpdate(id, true)}
											/>
										)}
									</IconButton>
								</Tooltip>

								<Tooltip
									arrow
									placement="top"
									title={isLinked ? 'Unlink all parents to enable' : 'Delete'}
								>
									<IconButton
										aria-label="delete"
										onClick={() => {
											!isLinked &&
												setSearchParams({
													...searchParams,
													deleteModal: id,
													type: cellValues?.row?.type?.includes('Stage') ? 'stage' : 'form'
												});
										}}
									>
										<DeleteForeverIcon
											color={cellValues?.row?.isLocked || isLinked ? 'action' : 'error'}
										/>
									</IconButton>
								</Tooltip>
								{!isArchived && (
									<ActionButton
										type={cellValues?.row?.type}
										clickedRow={cellValues?.row}
										allRows={cellValues?.api?.getRowModels()}
									/>
								)}
							</>
						) : (
							<>
								{cellValues?.row?.isDeletable || cellValues?.row?.is_adhoc ? (
									<Tooltip
										arrow
										placement="top"
										title={isArchived ? 'Unarchive parent to enable' : 'Delete'}
									>
										<IconButton
											aria-label="delete"
											onClick={() => {
												!isArchived &&
													setSearchParams({
														...searchParams,
														deleteModal: id,
														type: cellValues?.row?.type?.includes('Stage') ? 'stage' : 'form'
													});
											}}
										>
											<DeleteForeverIcon
												color={cellValues?.row?.isLocked || isArchived ? 'action' : 'error'}
											/>
										</IconButton>
									</Tooltip>
								) : (
									<Tooltip
										arrow
										placement="top"
										title={isArchived ? 'Unarchive parent to enable' : 'Detach'}
									>
										<IconButton
											aria-label="detach"
											disabled={cellValues?.row?.isLocked}
											onClick={() =>
												!isArchived &&
												handleUnlinking(cellValues?.row?.stage_id, cellValues?.row?.form_id, 'form')
											}
										>
											<LinkOffIcon color={isArchived ? 'action' : 'error'} />
										</IconButton>
									</Tooltip>
								)}
							</>
						)}
					</div>
				);
			},
			minWidth: 300
		}
	];

	return columns;
};

export const TemplateTitle = ({ rowValues }) => {
	const dispatch = useDispatch();
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const cardRowTitleRef = useRef(null);

	const rowType = rowValues?.type?.toLowerCase();
	const entityType = rowType?.includes('process')
		? 'application-process'
		: rowType?.includes('application form')
		? 'application-form'
		: 'review-form';
	const isArchived = rowValues?.is_archived;

	useEffect(() => {
		if (cardRowTitleRef.current) {
			setIsOverflowing(cardRowTitleRef.current.scrollWidth > cardRowTitleRef.current.clientWidth);
		}
	}, [rowValues]);

	const handleCardClick = () => {
		dispatch(setFormMetaInfo({ id: rowValues?.table_id }));
		if (rowType?.includes('submission stage')) {
			setSearchParams({
				...searchParams,
				stageCreation: 'application',
				stageId: rowValues?.stage_id
			});
		} else if (rowType?.includes('evaluation stage')) {
			setSearchParams({
				...searchParams,
				stageCreation: 'evaluation',
				stageId: rowValues?.stage_id
			});
		} else if (['application', 'review', 'application form', 'review form']?.includes(rowType)) {
			dispatch(
				setFormMetaInfo({
					label: rowValues?.label,
					second_label: rowValues?.second_label,
					instructions: rowValues?.instructions,
					description: rowValues?.description,
					form_id: `${getEntityPrefix({ type: entityType, isBaseTemplate: true })}${
						rowValues?.table_id
					}`
				})
			);
			setSearchParams({
				...searchParams,
				formBuilder: rowType?.includes('application') ? 'application' : 'review',
				formId: rowValues?.form_id
			});
		} else {
			setSearchParams({
				...searchParams,
				stageCreation: 'process',
				processId: rowValues?.process_id
			});
		}
	};

	return (
		<StyledWrapper.TemplateRowContainer
			data-testid={`template-${rowValues?.id}`}
			width={'100%'}
			onClick={handleCardClick}
		>
			<Tooltip arrow placement="top" title={'Open Template'}>
				<div style={{ alignItems: 'center', display: 'flex' }}>
					{rowType?.includes('process') ? (
						<AppProcessIcon isTemplate />
					) : rowType?.includes('submission stage') ? (
						<SubmissionStageIcon isTemplate />
					) : rowType?.includes('evaluation stage') ? (
						<ReviewStageIcon isTemplate />
					) : rowType?.includes('application') ? (
						<AppFormIcon isTemplate />
					) : (
						<ReviewFormIcon isTemplate />
					)}
				</div>
			</Tooltip>
			<Tooltip arrow placement="top" title={isOverflowing ? rowValues?.title : ''}>
				<StyledWrapper.TemplateRowTitle ref={cardRowTitleRef} display="inline">
					{rowValues?.title}
				</StyledWrapper.TemplateRowTitle>
			</Tooltip>
			<StyledWrapper.TemplateRowId status={!isArchived}>
				[{rowValues?.table_id}]
			</StyledWrapper.TemplateRowId>
		</StyledWrapper.TemplateRowContainer>
	);
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate,
	emptyDefaultValue,
	isDisabled,
	marginLeft
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : !emptyDefaultValue && 'n/a'}
		>
			<StyledWrapper.CellContainer
				marginLeft={marginLeft}
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						isDisabled={isDisabled}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : !emptyDefaultValue && 'n/a'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						isDisabled={isDisabled}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : !emptyDefaultValue && 'n/a'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
export const groupingColDef = (columnWidths) => {
	return {
		resizable: false,
		minWidth: 0,
		width: 0
	};
};

export const ActionButton = ({ clickedRow }) => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const addFormButton = {
		title: 'ADD FORM',
		id: 198567,
		tipPlacement: 'left',
		variant: 'outlined',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		tipTitle: 'Not available.',
		options: [
			{
				disabled: false,
				title:
					clickedRow?.type === 'Submission Stage'
						? 'Select Application Form Template'
						: 'Select Review Form Template',
				onClick: () => {
					dispatch(
						setFormMetaInfo({
							stage_title: clickedRow?.title
						})
					);
					setSearchParams({
						...searchParams,
						templateSelection:
							clickedRow?.type === 'Submission Stage' ? 'application-form' : 'review-form',
						stageId: clickedRow?.stage_id
					});
				}
			}
		]
	};

	return <ButtonDropdown data={addFormButton} noDropShadow />;
};
