import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Overview from './Overview/Overview';
import AllNotifications from './AllNotifications/AllNotifications';
import ManageApplication from './ManageApplication/ManageApplication';
import ManageReviews from './ManageReviews/ManageReviews';

import { TabPanel, a11yProps } from '../../components/TabPanel';

import { rows } from '../../pages/IndividualProgram/AllNotifications/MockData';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import { useGetIndividualProgramsQuery } from '../../services/endpoints/programsEndpoints';
import { IndividualApplication } from '../IndividualApplication';
import { IndividualReview } from '../IndividualReview';
import { ProgramSetup } from '../ProgramSetup';
import useIDToTitle from '../../hooks/useIDToTitle';
import PrintActions from '../../components/PrintActions/PrintActions';
import { saveVariable } from '../../components/BaseLayout/slice';
import { getLocalAuth } from '../../utils/environmentUtils';

const IndividualProgram = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const userAccountTypeID = useSelector((state) => state.login?.user?.userAccountTypeID);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	let { programID, appID, reviewID } = useParams();
	programID = programID?.replace('p-', '');

	const programTitle = useIDToTitle('program');
	const currentItemID = appID || reviewID;

	const tabs = ['Overview', 'Applications', 'Reviews', 'Application Setup'];

	// API CALL
	const { data } = useGetIndividualProgramsQuery(
		{ apiToken, id: programID },
		{ skip: !userAccountTypeID }
	);

	//UseStates
	const [value, setValue] = useState();
	const [toggleReviewModal, setToggleReviewModal] = useState(false);
	const [chosenView, setChosenView] = useState(<></>);

	const handleChange = (event, newValue) => {
		let newPage = newValue.toLowerCase().replaceAll(' ', '-');
		newPage = newPage === 'reviews' ? 'reviews/assign-by-reviewer' : newPage;

		let goToUrl = `/programs/p-${programID}/${
			newPage === 'application-setup' ? 'application-setup/application' : newPage
		}`;
		navigate(goToUrl);
	};

	const selectView = (view) => {
		switch (view) {
			case 0:
				return <Overview />;
			case 1:
				return <ManageApplication />;
			case 2:
				return <ManageReviews />;
			// case 3:
			// 	return <AllNotifications rows={rows} />;
			case 3:
				return <ProgramSetup />;
			default:
				return <></>;
		}
	};

	const toolTipText = (tabName) => {
		const isFinalized = data?.app_process_finished;
		if (tabName !== 'Application Setup' && !isFinalized) {
			return 'Disabled until Application Process is finalized.';
		}

		return (
			tabName === 'Application Setup' && !isFinalized && 'Application Process Setup not completed'
		);
	};

	const isTabDisabled = (tabName) => tabName !== 'Application Setup' && !data?.app_process_finished;

	useEffect(() => {
		dispatch(
			saveVariable([
				'program',
				{
					id: data?.programId,
					title: data?.title,
					app_process_finished: data?.app_process_finished
				}
			])
		);
	}, [data]);

	useEffect(() => {
		setChosenView(selectView(value));
	}, [value]);

	useEffect(() => {
		const pathArray = location?.pathname?.split('/');
		const conditionals = ['Review%20Overview', 'Details'];
		const openModal = conditionals.includes(pathArray[pathArray?.length - 1]);

		openModal && setToggleReviewModal(true);
	}, [location, reviewID]);

	useEffect(() => {
		const path = location?.pathname?.split('/');
		const selectedView = path[3];

		switch (selectedView) {
			case 'overview':
				setValue(0);
				return;

			case 'applications':
				setValue(1);
				return;
			case 'reviews':
				setValue(2);
				return;
			// case 'notifications':
			// 	setValue(3);
			// return;
			case 'application-setup':
				setValue(3);
				return;
			default:
				setValue(0);
		}
	}, [location]);

	return (
		<>
			{userAccountTypeID && (
				<h4 data-testid={`programs-title`}>
					{programTitle && `Program: ${data?.title} [p-${programID}]`}
				</h4>
			)}

			{!userAccountTypeID && (
				<Tooltip key={`FormTab-Go-Back`} placement="top" title={'Go Back'} arrow>
					<IconButton
						aria-label="go-back"
						size="large"
						onClick={() => {
							window.history.back();
						}}
					>
						<ArrowBackIcon />
					</IconButton>
				</Tooltip>
			)}

			<Box sx={{ width: '100%' }}>
				{userAccountTypeID && (
					<Box data-testid={`individualprograms-box`}>
						<Tabs
							value={value}
							aria-label="basic tabs example"
							style={{ width: 'fit-content', height: '24px', marginTop: '30px' }}
							indicatorColor="secondary"
						>
							{tabs.map((e, index) => {
								return (
									<Tooltip key={`FormTab-${index}`} placement="top" title={toolTipText(e)} arrow>
										<Tab
											onClick={(x) => handleChange(x, e)}
											sx={{
												minHeight: '0px',
												padding: '0px 16px',
												height: '24px',
												'&.Mui-disabled': { pointerEvents: 'all' }
											}}
											icon={
												e === 'Application Setup' && (
													<SettingsIcon sx={{ fill: !data?.app_process_finished && '#FF9800' }} />
												)
											}
											iconPosition="start"
											data-testid={`individualprograms-tab-${index}`}
											label={`${e}`}
											disabled={isTabDisabled(e)}
											{...a11yProps(e + 1)}
										/>
									</Tooltip>
								);
							})}
						</Tabs>
					</Box>
				)}

				<TabPanel data-testid={`individualprograms-tab-panel`} value={value} index={value}>
					{chosenView}
				</TabPanel>

				{/* {currentItemID && value === 2 && (
					<IndividualReview
						reviewId={currentItemID}
						openModal={toggleReviewModal}
						closeModal={() => setToggleReviewModal(false)}
					/>
				)} */}
			</Box>
		</>
	);
};

export default IndividualProgram;
