import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardTitleContainer = styled.div`
	display: flex;
	justify-content: ${(props) => props?.justifyContent || 'center'};
	align-items: center;
	gap: 10px;
	margin: 0px 0px 14px 0px;
`;

const CardTitle = styled(Link)`
	font-weight: 500;
`;

const CardTitleNoLink = styled.p`
	font-weight: 500;
	margin: 0px;
`;

export default {
	CardTitleContainer,
	CardTitle,
	CardTitleNoLink
};
