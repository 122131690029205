import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SectionCard } from '../../../components/SectionCard';
import NestedPieChart from '../../../components/Graphs/NestedPieChart/NestedPieChart';
import ProgressBar from '../../../components/Graphs/ProgressBar/ProgressBar';
import { HelpIcon } from '../../../components/core/HelpIcon';
import { getChipColor } from '../../../utils/utilFunctions';
import { getLocalAuth } from '../../../utils/environmentUtils';
import { useGetReviewsPieChartQuery } from '../../../services/endpoints/programsEndpoints';

import Tooltip from '@mui/material/Tooltip';

import StyledWrapper from '../style';

const Reviews = () => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const [innerPieChartData, setInnerPieChartData] = useState([]);
	const [outerPieChartData, setOuterPieChartData] = useState([]);

	const { data } = useGetReviewsPieChartQuery(
		{ apiToken, programID },
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		if (data) {
			let tempArr = [];
			const allUnassigned = data?.total_review_stages === data?.unassigned;
			allUnassigned &&
				tempArr.push({
					id: 'Placeholder',
					value: data?.unassigned || 1,
					color: '#90A4AE'
				});
			data?.total_review_stages > 0 &&
				!allUnassigned &&
				tempArr.push({
					id: 'Unassigned',
					value: data?.unassigned || 0,
					color: '#ffffff'
				});
			Object.entries(data?.assigned_statuses || {}).forEach(([key, value]) => {
				tempArr.push({
					id: key,
					value: value,
					color: getChipColor(key)
				});
			});

			setOuterPieChartData(tempArr);

			setInnerPieChartData([
				{
					id: 'Unassigned',
					value: data?.unassigned || 0,
					color: '#ffffff'
				},
				{
					id: 'Assigned',
					value: data?.assigned || 0,
					color: '#D9D9D9'
				}
			]);
		}
	}, [data]);

	return (
		<SectionCard minWidth={'49%'} width={'100%'} noscroll={true}>
			<StyledWrapper.CardTitleContainer data-testid={`overview-reviews-container`}>
				{/* <Tooltip title={`View all Program Reviews`} placement="top" arrow> */}
				<StyledWrapper.CardTitleNoLink
					data-testid={`overview-reviews-title`}
					// to={`/programs/p-${programID}/reviews/assign-by-reviewer`}
				>
					Total Reviews ({data?.total_review_stages || 0})
				</StyledWrapper.CardTitleNoLink>
				{/* </Tooltip> */}
			</StyledWrapper.CardTitleContainer>

			{data?.total_review_stages > 0 ? (
				<>
					<StyledWrapper.CardRowContainer width={'92%'}>
						<StyledWrapper.CardRowTitle
							data-testid={`overview-reviews-timeRemaining`}
							margin={'15px 0px 0px 0px'}
						>
							Time Remaining <HelpIcon />
						</StyledWrapper.CardRowTitle>
						<StyledWrapper.CardRowInfo>
							<ProgressBar
								testTitle={'overview-reviews'}
								data={{
									startDate: new Date(data?.min_start_date),
									endDate: new Date(data?.max_end_date),
									isCountdown: true
								}}
							/>
						</StyledWrapper.CardRowInfo>
					</StyledWrapper.CardRowContainer>

					<StyledWrapper.ChartContainer data-testid={'nivo-nested-pieChart'}>
						<NestedPieChart
							testTitle={'overview-reviews'}
							outerData={outerPieChartData}
							innerData={innerPieChartData}
							totalReviews={data?.total_review_stages || 0}
						/>
					</StyledWrapper.ChartContainer>
				</>
			) : (
				<StyledWrapper.CardRowInfo width={'100%'} maxWidth={'100%'} align={'center'}>
					{'No Reviews'}
				</StyledWrapper.CardRowInfo>
			)}
		</SectionCard>
	);
};

export default Reviews;
