// eslint-disable-next-line
export const appOrStage = [
	'Application Instance',
	'Submission Stage',
	'Evaluation Stage',
	'Review Stage'
];

export const disabledStatuses = [
	'Pending Decision',
	'On Hold',
	'Rec. Award',
	'Denied',
	'Full Review',
	'Closed'
];
