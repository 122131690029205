import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';
import StyledWrapper from './style';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const MUIRadioButton = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			<div className="inner-form-group" name={name}>
				<StyledWrapper.CustomLabel
					error={props?.data?.error}
					value={defaultValue?.length > 0}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={disabled ? true : false}
				>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue={defaultValue}
						name="radio-buttons-group"
						error={props?.data?.error}
					>
						<div
							style={{
								display: 'flex',
								margin: '0px',
								flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
							}}
						>
							{props?.data?.options.map((option) => {
								const this_key = `preview_${option.key}`;
								const radioButtonProps = {};
								radioButtonProps.name = `option_${option.key}`;

								radioButtonProps.type = 'radio';
								radioButtonProps.value = option.key;
								radioButtonProps.inputProps = { actualValue: option?.text };
								if (props.mutable) {
									radioButtonProps.defaultChecked =
										props.defaultValue !== undefined && props.defaultValue.indexOf(option.key) > -1;
								}
								if (props.read_only) {
									radioButtonProps.disabled = true;
								}

								return (
									<div key="RadioGroup">
										<FormControlLabel
											className="disableWhiteBackground"
											style={{ marginBottom: '0px' }}
											disabled={radioButtonProps.disabled || disabled}
											control={
												<Radio
													id={`fid_${this_key}`}
													{...radioButtonProps}
													inputProps={{
														'data-value': option?.value
													}}
												/>
											}
											label={option?.text}
										/>
									</div>
								);
							})}
						</div>
					</RadioGroup>
				</StyledWrapper.BorderOutline>
			</div>

			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});
MUIRadioButton.displayName = 'MUI_RadioButton';
Registry.register('MUI_RadioButton', MUIRadioButton);
