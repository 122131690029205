import { Icon as IconKit } from 'react-icons-kit';

export default function Icon({ icon, onClick, className, style }) {
	return (
		<IconKit
			icon={icon}
			className={className}
			data-testid={'iconKit'}
			onClick={onClick}
			style={{ cursor: 'pointer', ...style }}
		/>
	);
}
