import { getOldBlueColor } from '../../utils/utilFunctions';

export const CopyIcon = ({ isActive, width, isWarning }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 16 16"
			fill={isActive ? getOldBlueColor() : isWarning ? '#ED6C02' : 'rgba(0, 0, 0, 0.54)'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM2.16667 15.5V13.8333H0.5C0.5 14.75 1.24167 15.5 2.16667 15.5ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM13.8333 0.5H5.5C4.575 0.5 3.83333 1.25 3.83333 2.16667V10.5C3.83333 11.4167 4.575 12.1667 5.5 12.1667H13.8333C14.75 12.1667 15.5 11.4167 15.5 10.5V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 10.5H5.5V2.16667H13.8333V10.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5Z"
				fill={isActive ? getOldBlueColor() : isWarning ? '#ED6C02' : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default CopyIcon;
