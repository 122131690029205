import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const OuterContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: visible;
`;

const OuterPie = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
	z-index: 5;
`;

const InnerPie = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 25%;
	height: 25%;
	z-index: 6;
	transform: translate(-50%, -50%);
`;

const PieChartLink = styled(Link)`
	fill: #1565c0 !important;
	font-size: 12px;

	${({ title }) =>
		title === 'overview-reviews' &&
		css`
			text-decoration: none;
			pointer-events: none;
		`}
`;

const PieChartLabel = styled(Link)`
	fill: rgba(0, 0, 0, 0.6) !important;
	font-size: 12px;

	&:hover {
		text-decoration: none;
		cursor: default;
	}
`;

const tooltip = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: white;
	padding: 5px 10px;
	border-radius: 3px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	gap: 10px;
	margin: 0px;

	& div {
		color: red !important;
	}
`;

const TTColorBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15px;
	height: 15px;
	border-radius: 2px;
	background-color: ${(props) => (!props?.pattern ? props?.color : '')};
	background-image: ${(props) =>
		props?.color &&
		props?.pattern &&
		`radial-gradient(#FFFFFF 0%, transparent 0%),radial-gradient(${props?.color}
			30%, transparent 50%)`};

	background-size: 5px 5px;
	background-position: 0 0, 30px 30px;
	background-repeat: repeat;
`;

export default {
	OuterContainer,
	OuterPie,
	InnerPie,
	PieChartLink,
	tooltip,
	TTColorBox,
	PieChartLabel
};
