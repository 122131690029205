import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getEunaOrangeColor } from '../../utils/utilFunctions';

const ListItem = ({ item, index }) => {
	const sideNavState = useSelector((state) => state.sideNav.value);

	const [openSubList, setOpenSubList] = useState(false);
	const location = useLocation();
	const [isSelected, setIsSelected] = useState(false);

	const handleClick = () => {
		setOpenSubList(!openSubList);
	};

	useEffect(() => {
		setIsSelected(location.pathname.includes(item?.linkTo || 'undefined'));
	}, [location]);

	return (
		<>
			{item?.subMenu ? (
				<>
					<ListItemButton selected onClick={handleClick}>
						<ListItemIcon>{item?.icon}</ListItemIcon>
						<ListItemText primary={item?.title} />
						{openSubList ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={openSubList} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{item?.subMenu.map((subItem, index) => {
								let SubIcon = subItem?.icon;
								return (
									<Link
										key={`${item?.title}-subItem-${index}`}
										to={subItem?.linkTo || ''}
										style={{ textDecoration: 'none', color: 'black' }}
									>
										<ListItemButton sx={{ pl: sideNavState ? 4 : 2 }}>
											<ListItemIcon>
												<SubIcon />
											</ListItemIcon>
											<ListItemText primary={subItem?.title} />
										</ListItemButton>
									</Link>
								);
							})}
						</List>
					</Collapse>
				</>
			) : (
				<Link
					data-testid={`sidenav-list-link-${index}`}
					to={item?.linkTo || ''}
					style={{ textDecoration: 'none', color: 'black' }}
				>
					<ListItemButton
						sx={{
							pl: 3,
							borderRight: isSelected && `2px solid ${getEunaOrangeColor()}`
						}}
						data-testid={`sidenav-list-button-${index}`}
						selected={isSelected}
					>
						<ListItemIcon>{item?.icon}</ListItemIcon>
						<ListItemText data-testid={`sidenav-list-text-${index}`} primary={item.title} />
					</ListItemButton>
				</Link>
			)}
		</>
	);
};

export default ListItem;
