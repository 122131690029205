import { GPM } from '../gpmAPI';

const authenticationAPI = GPM.injectEndpoints({
	endpoints: (builder) => ({
		postLogin: builder.mutation({
			query: (payload) => ({
				url: 'authentication/',
				method: 'POST',
				body: payload
			})
		}),
		refreshToken: builder.mutation({
			query: (payload) => ({
				url: 'refresh_token/',
				method: 'POST',
				body: payload
			})
		}),
		getUserProfile: builder.query({
			query: (apiToken) => ({
				url: `user_profile/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${apiToken}`
				}
			})
		})
	}),
	overrideExisting: false
});

export const { usePostLoginMutation, useRefreshTokenMutation, useGetUserProfileQuery } =
	authenticationAPI;
