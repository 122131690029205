import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';

const DetailTitleContainer = styled.div`
	position: relative;
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	background: #e8ecee;
	margin-bottom: 32px;
	padding: 0px 10px;
`;

const DetailTitle = styled.p`
	display: flex;
	gap: 15px;
	font-weight: 500;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin: ${(props) => props?.margin || '19px auto'};
`;

const SubTitle = styled.p`
	display: flex;
	gap: 15px;
	font-weight: 500;
	width: 100%;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.6);
	margin: ${(props) => props?.margin || '19px auto'};
`;

const ActionButtonContainer = styled.div`
	position: absolute;
	right: 15px;
	display: flex;
	height: fit-content;
	gap: 10px;
	padding: 10px;
	@media (max-width: 1600px) {
		position: relative;
	}
`;

const CustomIconButton = styled(IconButton)`
	background-color: rgba(255, 255, 255, 0.3) !important;
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 30px 0px;
	z-index: 2;

	&:hover {
		color: #0077b3;
	}
`;

const CustomAccordion = styled(Accordion)`
	box-shadow: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);

	& .MuiAccordionSummary-expandIconWrapper {
		position: absolute;
		right: 2%;
	}

	& .MuiAccordionSummary-content {
		margin: 0px !important;
	}

	&::before,
	&::after {
		display: none;
	}
`;

export default {
	DetailTitleContainer,
	DetailTitle,
	SubTitle,
	ActionButtonContainer,
	CustomIconButton,
	TitleContainer,
	CustomAccordion
};
