import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { resetFormData } from '../components/FormBuilderLibrary/slice';
import { checkMissingGroupings } from '../components/FormBuilder/utils';

import Button from '@mui/material/Button';

export const useBuilderPreviewButton = (useSearchParam) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const entireFormSchema = useSelector((state) => state.form.formSchema);
	const [disablePreviewButton, setDisablePreviewButton] = useState(false);

	const previewDisabledState = () => {
		let isDisabled = true;
		if (entireFormSchema?.length > 0) {
			entireFormSchema[0]?.length > 0 && (isDisabled = false);
		}
		setDisablePreviewButton(isDisabled); // return setDisablePreviewButton(disabledState);
	};

	const handleOnClick = async () => {
		try {
			const hasMissingGrouping = checkMissingGroupings(entireFormSchema);

			let goToViewer = true;
			entireFormSchema?.forEach((e) => {
				e.length === 0 && (goToViewer = false);
			});
			dispatch(resetFormData());

			useSearchParam && searchParams.set('viewType', 'preview');
			goToViewer && !hasMissingGrouping
				? useSearchParam
					? setSearchParams(searchParams)
					: navigate(`/multi-form-viewer?form=${searchParams.get('form')}`)
				: hasMissingGrouping
				? alert('Groupings must have a start and close element.')
				: alert('Please fill out all steps.');
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		previewDisabledState();
	}, [entireFormSchema]);

	return {
		state: disablePreviewButton,
		previewOnClick: handleOnClick,
		button: (
			<Button
				data-testid={'formBuilder-previewForm'}
				variant="contained"
				className="float-right"
				style={{ marginRight: '10px' }}
				disabled={disablePreviewButton}
				onClick={handleOnClick}
			>
				Preview Form
			</Button>
		)
	};
};
