//Icons
// import HomeIcon from '@mui/icons-material/Home';
// import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
// import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ProgramIcon from '../assets/customIcons/programIcon';
import ApplicantProcessIcon from '../assets/customIcons/applicantProcess';

export const INDEX = `/`;
export const LOGIN = `/login`;
export const WITHOUTPROTECTED = `/without-protection`;
export const ERROR = `*`;
export const SESSION_EXPIRED = `/session-expired`;

// SubRoutes
export const DASHBOARD = '/dashboard';
export const PROGRAMS = '/programs';
export const SETTINGS = '/settings';
export const PROFILE = '/profile';
export const TEMPLATES = '/templates/*';
export const TEMPLATES_APP_PROCESS = '/templates/applications';
export const FORMBUILDER = '/form-builder';
export const MULTIFORMVIEWER = '/multi-form-viewer';
export const SYSTEMWIDE_NOTIFICATIONS = '/systemwide-notifications';
export const TEMPLATE_CREATION = '/templates/applications/create';
export const REVIEWS = '/reviews';
export const APPLICATIONS = '/applications';
export const INVITATIONS = '/invitations';

//LINKS DE COMPANY ADMIN
// TODO: Add in isActive Logic so icons change color when selected
export const PrimaryLinks = [
	// {
	// 	icon: HomeIcon,
	// 	title: 'Dashboard',
	// 	linkTo: DASHBOARD,
	// 	available: true
	// },
	{
		icon: <ProgramIcon width={'28px'} isMulti />,
		title: 'Programs',
		linkTo: PROGRAMS,
		available: true
	},
	{
		icon: <ApplicantProcessIcon isTemplate isActive={false} width={'28px'} />,
		title: 'Templates',
		linkTo: TEMPLATES_APP_PROCESS,
		available: true
	}
	// {
	// 	icon: CircleNotificationsIcon,
	// 	title: 'Notifications',
	// 	linkTo: SYSTEMWIDE_NOTIFICATIONS,
	// 	available: true
	// }
];

export const SecondaryLinks = [
	// {
	//   icon: SettingsIcon,
	//   title: "Settings",
	//   linkTo: "",
	//   available: true,
	// },
	// {
	//   icon: LogoutIcon,
	//   title: "Logout",
	//   linkTo: LOGIN,
	//   available: true,
	// }
];
