import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactFormGenerator } from '../../../../components/FormBuilderLibrary';
import {
	useGetFormInstanceSchemaQuery,
	useGetIndividualFormSchemaQuery,
	useGetIndividualFormsQuery
} from '../../../../services/endpoints/formBuilderEndpoints';
import { setToReviewForms } from '../../../../components/FormBuilderLibrary/slice';
import { ControlledCheckbox } from '../../../../components/core/Checkbox/TableCustomCheckbox';

import StyledWrapper from './style';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getLocalAuth } from '../../../../utils/environmentUtils';

export const IndividualFormSelection = ({
	key,
	appId,
	title,
	expanded,
	allExpanded,
	handleExpansion,
	readOnly,
	isReviewing,
	isMasterLayout,
	prefetchedValues,
	prefetchedSchema
}) => {
	const dispatch = useDispatch();
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	const [formSchema, setFormSchema] = useState([]);
	const [allFieldIds, setAllFieldIds] = useState([]);
	const [isAllChecked, setIsAllChecked] = useState(false);

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const toReview = useSelector((state) => state?.form?.to_review);

	const apiOptions = {
		uniqueID: appId?.replace('afi-', ''),
		apiToken: apiToken
	};
	const { data: apiFormData } = !prefetchedSchema && useGetFormInstanceSchemaQuery(apiOptions);

	const addEntireFormToReview = (formId) => {
		const tempForms = [...toReview] || [];
		const alreadySelected = tempForms?.findIndex((e) => e?.formId === formId);

		if (alreadySelected === -1) {
			tempForms?.push({
				formId: formId,
				review_inputs: allFieldIds
			});
		} else {
			tempForms?.splice(alreadySelected, 1);
		}

		dispatch(setToReviewForms(tempForms));
	};

	useEffect(() => {
		if (apiFormData?.form_schema) {
			let data = [];
			data =
				apiFormData?.form_schema?.[0] !== undefined && !data[0] ? apiFormData?.form_schema : data;

			if (isReviewing) {
				const currentlyReviewing = toReview?.find(
					(e) => e?.formId?.split('-')?.[1] === apiFormData?.form?.unique_identifier
				);

				const filteredForm = data?.filter((e) =>
					currentlyReviewing?.review_inputs?.includes(e?.field_id)
				);
				setFormSchema(filteredForm || []);
			} else {
				setFormSchema(data);
			}

			setAllFieldIds(data?.map((e) => e?.field_id));
		}
	}, [apiFormData?.form_schema]);

	useEffect(() => {
		setIsAllChecked(toReview?.find((e) => e?.formId === appId)?.review_inputs?.length > 0);
	}, [toReview]);

	return (
		<>
			<StyledWrapper.CustomAccordion
				expanded={expanded?.indexOf(`panel-${appId}`) !== -1 || allExpanded}
				key={key}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon style={{ color: isHovered ? '#0077b3' : 'inherit' }} />}
					aria-controls="panel1a-content"
					data-testid="appForm-coreInfo"
				>
					<StyledWrapper.DetailTitle margin={'0px'}>
						{!isReviewing && (
							<ControlledCheckbox
								id={appId}
								isChecked={isAllChecked}
								isReviewStageAll={true}
								disabled={readOnly}
								onChange={(e) => {
									addEntireFormToReview(appId, e?.target?.value);
								}}
							/>
						)}
						<StyledWrapper.TitleContainer
							onClick={() => handleExpansion(`panel-${appId}`)}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							{isReviewing ? `${title}` : `${title} - (${appId})`}
						</StyledWrapper.TitleContainer>
					</StyledWrapper.DetailTitle>
				</AccordionSummary>
				<AccordionDetails>
					<ReactFormGenerator
						disableFooterButtons={true}
						download_path=""
						back_action={''}
						back_name="Back"
						answer_data={!isMasterLayout ? prefetchedValues || apiFormData?.field_values : []}
						action_name="Save"
						form_action="/"
						form_method="POST"
						activeStep={1}
						totalSteps={0}
						variables={[]}
						formIndex={0}
						read_only={true}
						data={prefetchedSchema ? prefetchedSchema : formSchema ? formSchema : []}
						enableSelection={!isReviewing && true}
						selectionsDisabled={!isReviewing ? readOnly : true}
						formId={appId}
					/>
				</AccordionDetails>
			</StyledWrapper.CustomAccordion>
		</>
	);
};
