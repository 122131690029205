import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: no-wrap;
	gap: 15px;
	justify-content: center;
	align-items: center;
`;

const GrayLine = styled.div`
	display: flex;
	width: 100%;
	border-radius: 13px;
	height: 8px;
	background: #eceff1;
	margin: 25px 0px;
`;

const TitleText = styled.h4`
	min-width: fit-content;
`;

const UnsavedText = styled.h4`
	min-width: fit-content;
	color: rgba(237, 108, 2, 0.6);
`;

export default {
	Container,
	GrayLine,
	TitleText,
	UnsavedText
};
