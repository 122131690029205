import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import StyledWrapper2 from '../../../IndividualProgram/style';
import { Table } from '../../../../components/Table';
import { formatTimestamp } from '../../../../utils/dateUtils';

import { checkOverflow, getEntityPrefix } from '../../../../utils/utilFunctions';
import Tooltip from '@mui/material/Tooltip';
import usePaginatedCall from '../../../../hooks/usePaginatedCall';
import { useGetTemplatesLinkageQuery } from '../../../../services/endpoints/templatesEndpoints';
import { TableTitle } from '../../../../components/TableTitle/TableTitle';

export const LinkedToTable = (columnWidths) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const id =
		searchParams?.get('formId') || searchParams?.get('stageId') || searchParams?.get('processId');
	const type = searchParams?.get('formId')
		? 'form'
		: searchParams?.get('stageId')
		? 'stage'
		: 'application-process';
	const finalPagination = usePaginatedCall(useGetTemplatesLinkageQuery, {
		type: type,
		templateID: id,
		filterBaseName: 'LINKED_TO_TABLE'
	});
	const [columnWidth, setColumnWidth] = useState({});

	const [openTooltip, setOpenTooltip] = useState(0);
	const baseURL = window.location.host;
	const columns = [
		{
			field: 'created_on',
			headerName: 'Timestamp',
			description: 'Timestamp',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
						isDate
					/>
				);
			},
			flex: columnWidths.created_on || 1
		},
		{
			field: 'created_by',
			headerName: 'User',
			description: 'User',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				const user = cellValues?.row?.created_by_user;
				cellValues.formattedValue = `${user?.firstName} ${user?.lastName}`;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
					/>
				);
			},
			flex: columnWidths.created_by || 1
		},
		{
			field: 'id',
			headerName: 'Entity',
			description: 'Entity',
			renderCell: (cellValues) => {
				const type = cellValues.row?.stage_type?.toLowerCase() || 'process';
				const isArchived = cellValues.row?.is_archived;
				const title =
					cellValues.row?.stage_name ||
					cellValues.row?.application_name ||
					cellValues.row?.process_name;
				const entityType =
					type == 'application'
						? 'Submission Stage'
						: type == 'evaluation'
						? 'Evaluation Stage'
						: 'Application Process';
				const displayId =
					getEntityPrefix({ type: entityType, isBaseTemplate: true }) +
					cellValues.row?.unique_identifier;
				const titleData = {
					title,
					entityType,
					displayId,
					isTemplate: true
				};
				const handleCardClick = () => {
					const page =
						type == 'application'
							? 'submission-stages'
							: type == 'evaluation'
							? 'evaluation-stages'
							: 'applications';
					const idType = cellValues.row?.stage_id ? 'stageId' : 'processId';
					const id = cellValues.row.stage_id || cellValues.row?.process_id;
					const path = `${baseURL}/templates/${page}?stageCreation=${type}&${idType}=${id}`;
					window.location.href = `${window.location.protocol}//${path}`;
				};
				return <TableTitle titleData={titleData} handleCardClick={handleCardClick} />;
			},
			flex: columnWidths.appId || 1,
			minWidth: 250
		},
		{
			field: 'description',
			headerName: 'Description',
			description: 'Description',
			renderCell: (cellValues) => {
				const isDisabled = cellValues?.row?.is_archived;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDisabled={isDisabled}
					/>
				);
			},
			flex: columnWidths.description || 1
		}
	];

	const BasicCellText = ({
		cellValues,
		openTooltip,
		setOpenTooltip,
		StartIcon,
		isLink,
		isDate,
		emptyDefaultValue,
		isDisabled
	}) => {
		const formattedText = isDate
			? formatTimestamp(cellValues?.formattedValue)
			: cellValues?.formattedValue;

		return (
			<Tooltip
				open={openTooltip === `${cellValues?.field}-${cellValues?.stage_id}`}
				onOpen={(e) =>
					checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.stage_id}`)
				}
				onClose={() => setOpenTooltip(0)}
				arrow
				placement="top"
				title={cellValues?.formattedValue ? formattedText : !emptyDefaultValue && 'n/a'}
			>
				<StyledWrapper2.CellContainer
					data-testid={`cellContainer-${cellValues?.field}-${cellValues?.stage_id}`}
				>
					{cellValues?.formattedValue && StartIcon && <StartIcon />}
					{!isLink ? (
						<StyledWrapper2.CardRowInfo
							width={'100%'}
							isDisabled={isDisabled}
							data-testid={`rowInfo-${cellValues?.field}-${cellValues?.stage_id}`}
						>
							{cellValues?.formattedValue ? formattedText : !emptyDefaultValue && 'n/a'}
						</StyledWrapper2.CardRowInfo>
					) : (
						<StyledWrapper2.CardRowInfoLink
							width={'100%'}
							isDisabled={isDisabled}
							data-testid={`rowLink-${cellValues?.field}-${cellValues?.stage_id}`}
						>
							{cellValues?.formattedValue ? formattedText : !emptyDefaultValue && 'n/a'}
						</StyledWrapper2.CardRowInfoLink>
					)}
				</StyledWrapper2.CellContainer>
			</Tooltip>
		);
	};

	return (
		<>
			<Table
				hideFilters
				key={'Linkage-Table'}
				disableSelectionOnClick
				getRowId={(cell) => cell?.stage_id || cell?.form_id || cell?.process_id}
				columns={columns}
				rowHeight={true}
				enableAutoPageSize
				onColumnResize={(e) => {
					const newColumnSizes = e?.colDef?.field;
					const width = e?.width;
					setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
				}}
				rows={finalPagination?.rows?.results || []}
				rowCount={finalPagination?.rowCount}
				pagination={finalPagination?.pagination}
				pageSize={finalPagination?.pageSize}
				paginationMode={finalPagination?.paginationMode}
				onPageChange={(e) => finalPagination?.onPageChange(e)}
				onPageSizeChange={(e) => finalPagination?.onPageSizeChange(e)}
				page={finalPagination?.page}
				loading={finalPagination?.loading}
				sortingMode={finalPagination?.sortingMode}
				onSortModelChange={(e) => finalPagination?.onSortModelChange(e)}
				filterModel={finalPagination?.filterModel}
				sortModel={finalPagination?.sortModel}
			/>
		</>
	);
};
