import styled from 'styled-components';

const OutermostContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const TextContainer = styled.div`
	display: flex;
	justify-content: ${(props) => (props?.isCountdown ? 'flex-end' : 'space-between')};
`;

const Text = styled.h6`
	color: black;
	margin: 0px;
`;

const BarContainer = styled.div`
	display: flex;
	height: 6px;
	width: 100%;
	border-radius: 12px;
	background-color: rgba(158, 158, 158, 0.2);
`;

const ProgressIndicator = styled.div`
	display: flex;
	margin-left: ${(props) =>
		props?.isCountdown ? props.progress.toString() + '%' : (props.progress - 2).toString() + '%'};
	width: ${(props) => (props?.isCountdown ? '100%' : '6px')};
	background-color: ${(props) =>
		100 - props?.progress < 40
			? 100 - props?.progress < 20
				? 'rgba(239, 83, 80, 0.4)'
				: 'rgba(255, 152, 0, 0.4)'
			: 'rgba(76, 175, 80, 0.4)'};
`;

export default {
	OutermostContainer,
	TextContainer,
	Text,
	BarContainer,
	ProgressIndicator
};
