import styled from 'styled-components';
import { Link } from 'react-router-dom';
const LinkContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	min-width: 100%;
`;

const InnerContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

//TODO: Fix this currentpage logic. The class names are the same for the breadcrumbs. So the color is applied to all breadcrumbs not just the currentpage breadcrumb
const Links = styled(Link)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.15px;
	color: ${(props) => (props?.currentpage ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)')};
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const Divider = styled.p`
	margin: 0px;
`;

export default {
	LinkContainer,
	InnerContainer,
	Links,
	Divider
};
