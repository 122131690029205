import { useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Tooltip from '@mui/material/Tooltip';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import StyledWrapper from '../../../IndividualProgram/style';
import { formatTimestamp } from '../../../../utils/dateUtils';
import { getChipColor, isDecisionChip } from '../../../../utils/utilFunctions';
import Chip from '@mui/material/Chip';

import { ActionButtons } from '../../../../components/ActionButtons/ActionButtons';
import { DecisionChip } from '../../../../components/core/DecisionChip/DecisionChip';
import { usePermissions } from '../../../../hooks/usePermissions';
import { appOrStage } from '../../../../constants/statuses';
import { TableTitle } from '../../../../components/TableTitle/TableTitle';

// openTooltip, setOpenTooltip
export const AppStagesColumns = (columnWidths, data, handleAlert) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [openTooltip, setOpenTooltip] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const { userAccountTypeID } = usePermissions();
	const pathArray = location?.pathname?.split('/');
	let subPage = pathArray[pathArray.length - 1];

	let columns = [
		{
			field: 'title',
			hideDescendantCount: true,
			headerName: 'Entity',
			renderCell: (cellValues) => {
				const apiRef = useGridApiContext();
				const row = cellValues.api?.getRow(cellValues.id);
				const { status, appId: displayId, title, id_link, type } = row;
				const hasChildren = cellValues.rowNode.children?.length > 0;
				const hasParent = cellValues.rowNode.parent != null;
				const titleData = {
					displayId: displayId || cellValues.rowNode.groupingKey,
					title: title || 'Application',
					status,
					entityType: type,
					hasChildren,
					hasParent,
					row_id: row.id,
					expanded: cellValues.rowNode.childrenExpanded
				};
				const paddingLeft = (cellValues.row?.hierarchy?.length - 1) * 28;
				const tableStyle = { paddingLeft: paddingLeft };
				const handleExpand = (row_id, expanded) => {
					apiRef.current.setRowChildrenExpansion(row_id, !expanded);
				};
				const handleCardClick = () => {
					navigate(
						`${id_link}${
							searchParams.get('formtype') !== '' ? `?formtype=${searchParams.get('formtype')}` : ''
						}${searchParams?.get('iFrame') === 'true' ? '&iFrame=true' : ''}`
					);
				};
				return (
					<>
						<TableTitle
							titleData={titleData}
							handleCardClick={handleCardClick}
							style={tableStyle}
							handleExpand={handleExpand}
						/>
					</>
				);
			},
			flex: 1,
			minWidth: 400,
			sortable: false,
			marginLeft: 100
		},
		{
			field: 'type',
			headerName: 'Type',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper.CellContainer margin={!cellValues?.formattedValue && 'auto'}>
						<Tooltip arrow placement="top" title={cellValues?.formattedValue}>
							<StyledWrapper.CardRowInfo
								width={'100%'}
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
							>
								{cellValues?.formattedValue && cellValues?.formattedValue !== 'no one'
									? cellValues?.formattedValue
									: '-'}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</StyledWrapper.CellContainer>
				);
			},
			flex: columnWidths.title || 1,
			minWidth: 250,
			sortable: false
		},
		{
			field: 'completion',
			headerName: 'Completion',
			description: 'Completion',
			renderCell: (cellValues) => {
				return cellValues?.row?.isPortalUser ? (
					<></>
				) : (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: columnWidths.completion || 0.4,
			minWidth: 130,
			sortable: false
		},
		{
			field: 'status',
			headerName: 'Status',
			renderCell: (cellValues) => {
				const hideStatus = appOrStage?.includes(cellValues?.row?.type) && !userAccountTypeID;

				return (
					<>
						{cellValues?.value && cellValues?.value !== ' ' && !hideStatus && (
							<Tooltip arrow placement="top" title={cellValues?.formattedValue}>
								{isDecisionChip(cellValues?.formattedValue) ? (
									<DecisionChip label={cellValues?.formattedValue} />
								) : (
									<Chip
										data-testid={`${cellValues?.field}-${cellValues?.id}`}
										label={cellValues?.formattedValue}
										sx={{
											overflow: 'hidden',
											backgroundColor: getChipColor(cellValues?.formattedValue),
											color: cellValues?.formattedValue === 'Unassigned' ? 'black' : 'white'
										}}
										size="small"
									/>
								)}
							</Tooltip>
						)}
					</>
				);
			},
			flex: columnWidths.status || 0.8,
			minWidth: 150,
			sortable: false
		},
		{
			field: 'action',
			headerName: 'Actions',
			description: 'Actions',
			renderCell: (cellValues) => {
				return (
					<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
						<ActionButtons
							item={cellValues?.row}
							type={cellValues?.row?.type?.toLowerCase()}
							status={cellValues?.row?.status?.toLowerCase()}
							handleAlert={handleAlert}
						/>
					</div>
				);
			},
			minWidth: 450,
			flex: columnWidths.action || 2,
			sortable: false
		}
	];

	return columns;
};
export const groupingColDef = (columnWidths) => {
	return {
		resizable: false,
		minWidth: 0,
		width: 0
	};
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate,
	marginLeft,
	iconColor,
	isCurrentStage,
	className
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<StyledWrapper.CellContainer
			className={className}
			marginLeft={marginLeft}
			data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
		>
			{cellValues?.formattedValue && StartIcon && (
				<StartIcon isCurrentStage={isCurrentStage} customColor={iconColor} isActive />
			)}
			<Tooltip arrow placement="top" title={cellValues?.formattedValue ? formattedText : 'n/a'}>
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : 'n/a'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : 'n/a'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</Tooltip>
		</StyledWrapper.CellContainer>
	);
};
