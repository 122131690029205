import ApplicantProcessIcon from '../../assets/customIcons/applicantProcess';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import AppFormIcon from '../../assets/customIcons/appFormIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewFormIcon';

import {
	useGetApplicationFormTemplatesQuery,
	useGetReviewFormTemplatesQuery
} from '../../services/endpoints/formBuilderEndpoints';
import { getEntityPrefix } from '../../utils/utilFunctions';
import {
	useGetAllEvalStagesTemplatesQuery,
	useGetAllProcessTemplatesQuery,
	useGetAllSubmissionStagesTemplatesQuery
} from '../../services/endpoints/templatesEndpoints';

export const getTemplatesIcon = (type) => {
	const currentType = type?.toLowerCase();
	switch (currentType) {
		case 'application':
			return <ApplicantProcessIcon isTemplate />;
		case 'submission-stage':
			return <SubmissionStageIcon isTemplate />;
		case 'evaluation-stage':
			return <ReviewStageIcon isTemplate />;
		case 'application-form':
			return <AppFormIcon isTemplate />;
		case 'review-form':
			return <ReviewFormIcon isTemplate />;
	}
};

export const getTemplatesApiCall = (type) => {
	const currentType = type?.toLowerCase();
	switch (currentType) {
		case 'application':
			return useGetAllProcessTemplatesQuery;
		case 'submission-stage':
			return useGetAllSubmissionStagesTemplatesQuery;
		case 'evaluation-stage':
			return useGetAllEvalStagesTemplatesQuery;
		case 'application-form':
			return useGetApplicationFormTemplatesQuery;
		case 'review-form':
			return useGetReviewFormTemplatesQuery;
	}
};

export const getTemplatesName = (type) => {
	const currentType = type?.toLowerCase();
	switch (currentType) {
		case 'application':
			return 'Application Templates';
		case 'submission-stage':
			return 'Submission Stage Templates';
		case 'evaluation-stage':
			return 'Evaluation Stage Templates';
		case 'application-form':
			return 'Base Application Form Templates';
		case 'review-form':
			return 'Base Review Form Templates';
	}
};

export const getTemplatesMessage = (type) => {
	const currentType = type?.toLowerCase();
	switch (currentType) {
		case 'application':
			return 'Pick a Template to be used for setting up your entire Application.';
		case 'submission-stage':
			return 'Pick a Template to be used for setting up your Submission Stage.';
		case 'evaluation-stage':
			return 'Pick a Template to be used for setting up your Evaluation Stage.';
		case 'application-form':
			return 'Pick a Template to be used for setting up your Application Form.';
		case 'review-form':
			return 'Pick a Template to be used for setting up your Review Form.';
	}
};

export const formatProcessApiCall = (data) => {
	const Prefix = getEntityPrefix({ type: 'application-process', isBaseTemplate: true });
	return data?.map((e) => {
		return {
			id: e?.process_id,
			appId: `${Prefix}${e?.unique_identifier}`,
			title: e?.process_name,
			description: e?.description,
			published: e?.created_on,
			updated: e?.last_updated_on,
			submitter: `${e?.updated_by_user?.firstName} ${e?.updated_by_user?.lastName}`,
			hierarchy: [e?.process_id],
			stages: e?.stages
		};
	});
};

export const formatStageApiCall = (data, type) => {
	const Prefix = getEntityPrefix({ type: type, isBaseTemplate: true });

	return data?.map((e) => {
		return {
			id: e?.stage_id,
			appId: `${Prefix}${e?.unique_identifier}`,
			title: e?.stage_name,
			description: e?.description,
			published: e?.created_on,
			updated: e?.last_updated_on,
			submitter: `${e?.updated_by_user?.firstName} ${e?.updated_by_user?.lastName}`,
			hierarchy: [e?.stage_id],
			forms: e?.forms
		};
	});
};

export const formatFormApiCall = (data, type) => {
	const Prefix = getEntityPrefix({ type: type, isBaseTemplate: true });

	return data?.map((e) => {
		return {
			id: e?.form_id,
			appId: `${Prefix}${e?.unique_identifier}`,
			title: e?.label,
			description: e?.description,
			published: e?.created_on,
			updated: e?.last_updated_on,
			submitter: `${e?.updated_by_user?.firstName} ${e?.updated_by_user?.lastName}`,
			hierarchy: [e?.form_id]
		};
	});
};

export const formatApiCall = (type, results) => {
	const currentType = type?.toLowerCase();
	switch (currentType) {
		case 'application':
			return formatProcessApiCall(results);
		case 'submission-stage':
			return formatStageApiCall(results, 'submission-stage');
		case 'evaluation-stage':
			return formatStageApiCall(results, 'evaluation-stage');
		case 'application-form':
			return formatFormApiCall(results, 'application-form');
		case 'review-form':
			return formatFormApiCall(results, 'review-form');
	}
};
