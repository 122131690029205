import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { FilterCard } from './FilterCard';
import StyledWrapper from './style';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

export const TableFilters = ({
	columns,
	allColumns,
	apiRef,
	setColumns,
	handleFilterSave,
	resetColumnFilters,
	activeFilters,
	visibleColumns,
	updateOrderedColumns,
	isOpened = false
}) => {
	const [panelState, setPanelState] = useState(isOpened || activeFilters?.length > 0);
	const [currentFilters, setCurrentFilters] = useState(activeFilters ?? []);
	const [originalColumnOrder, setOriginalColumnOrder] = useState([]);
	const [columnOrderChanged, setColumnOrderChanged] = useState(false);

	function handleOnDragEnd(result) {
		const items = Array.from(allColumns);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		checkColumnOrder(items);
		setColumns(items);

		if (updateOrderedColumns) {
			updateOrderedColumns(items);
		}
	}

	const checkColumnOrder = (items) => {
		let columnOrderChanged = false;

		for (let i = 0; i < items.length; i++) {
			if (items[i].field !== originalColumnOrder[i].field) {
				columnOrderChanged = true;
				break;
			}
		}

		setColumnOrderChanged(columnOrderChanged);
	};

	const resetColumnOrder = () => {
		setColumns(originalColumnOrder);

		if (updateOrderedColumns) {
			updateOrderedColumns(originalColumnOrder);
		}

		setColumnOrderChanged(false);
	};

	const handleFilterChange = (currentFilter, removeItem = false) => {
		let temp = [...currentFilters];
		const index = temp.findIndex((item) => item.columnField === currentFilter.columnField);

		const lowerCaseField = currentFilter?.columnField?.toLowerCase();
		if (lowerCaseField?.includes('id') && lowerCaseField?.includes('-')) {
			currentFilter.value = currentFilter.value?.split('-')?.[1];
		}

		if (index !== -1 || removeItem) {
			!currentFilter?.value ? temp.splice(index, 1) : temp.splice(index, 1, currentFilter);
		} else if (currentFilter?.value) {
			temp.push(currentFilter);
		}

		setCurrentFilters(temp);
	};

	function clearInputValues(divId) {
		const div = document.getElementById(divId);
		if (!div) {
			console.error(`Div with ID "${divId}" not found.`);
			return;
		}

		const inputs = div.getElementsByTagName('input');
		const selects = div.getElementsByTagName('select');

		for (let i = 0; i < inputs.length; i++) {
			const input = inputs[i];
			if (input.type !== 'checkbox' && input.type !== 'radio') {
				input.value = '';
			}
		}

		for (let i = 0; i < selects.length; i++) {
			const select = selects[i];
			select.selectedIndex = 1;
		}
	}

	useEffect(() => {
		const debounceId = setTimeout(() => {
			handleFilterSave(currentFilters);
		}, 500);

		return () => {
			clearTimeout(debounceId);
		};
	}, [currentFilters]);

	useEffect(() => {
		const columnOrder = [];

		allColumns.forEach((column) => {
			columnOrder.push(column);
		});

		setOriginalColumnOrder(columnOrder);
	}, []);

	const noActiveFilters = currentFilters.length === 0;

	const resetDisabled =
		noActiveFilters && visibleColumns.length === allColumns.length && !columnOrderChanged;

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<StyledWrapper.OuterMostContainer
				open={panelState}
				data-testid={'tableFilters-outer-container'}
				id={'filters-container'}
			>
				<Tooltip
					arrow
					placement="top"
					title={`There ${activeFilters?.length > 0 ? 'are' : 'are no'} filters applied`}
				>
					<StyledWrapper.FilterIndicator show={activeFilters?.length > 0}>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2.78441 2.20909L11.1177 2.20909L6.94274 7.45909L2.78441 2.20909ZM0.492743 1.88409C2.17608 4.04242 5.28441 8.04242 5.28441 8.04242L5.28441 13.0424C5.28441 13.5008 5.65941 13.8758 6.11774 13.8758H7.78441C8.24274 13.8758 8.61774 13.5008 8.61774 13.0424V8.04242C8.61774 8.04242 11.7177 4.04242 13.4011 1.88409C13.8261 1.33409 13.4344 0.542419 12.7427 0.542419L1.15108 0.542419C0.459409 0.542419 0.0677428 1.33409 0.492743 1.88409Z"
								fill={activeFilters?.length > 0 ? `#FF9800` : '#D8D8D8'}
							/>
						</svg>
					</StyledWrapper.FilterIndicator>
				</Tooltip>
				<StyledWrapper.ToggleButton
					open={panelState}
					data-testid={`tableFilters-toggle-button`}
					onClick={() => setPanelState(!panelState)}
				>
					<StyledWrapper.SideBarChevron open={panelState}>
						<ChevronLeftRoundedIcon data-testid={`tableFilters-toggle-icon`} />
					</StyledWrapper.SideBarChevron>
				</StyledWrapper.ToggleButton>

				<StyledWrapper.FiltersTitleContainer>
					<StyledWrapper.FiltersTitle>Order, Visibility & Filters</StyledWrapper.FiltersTitle>
					<StyledWrapper.FiltersClearContainer>
						<Tooltip arrow placement="top" title="Clear all filters">
							<IconButton
								aria-label="clear filters"
								sx={{ background: 'white', width: '25px', height: '25px', marginLeft: 'auto' }}
								onClick={() => {
									setCurrentFilters([]);
									clearInputValues('filters-container');
								}}
								disabled={noActiveFilters}
							>
								<FilterAltOffIcon color={noActiveFilters ? '' : 'primary'} sx={{ width: '20px' }} />
							</IconButton>
						</Tooltip>

						<Tooltip arrow placement="top" title="Reset all to default">
							<IconButton
								aria-label="reset filters"
								sx={{ background: 'white', width: '25px', height: '25px', marginLeft: 'auto' }}
								onClick={() => {
									resetColumnFilters();
									setCurrentFilters([]);
									clearInputValues('filters-container');
									resetColumnOrder();
								}}
								disabled={resetDisabled}
							>
								<ReplayRoundedIcon color={resetDisabled ? '' : 'primary'} sx={{ width: '20px' }} />
							</IconButton>
						</Tooltip>
					</StyledWrapper.FiltersClearContainer>
				</StyledWrapper.FiltersTitleContainer>
				<Droppable droppableId="filterCards">
					{(provided) => (
						<StyledWrapper.InnerContainer
							{...provided.droppableProps}
							ref={provided.innerRef}
							open={panelState}
							data-testid={'tableFilters-inner-container'}
						>
							{columns
								?.filter((e) => visibleColumns?.includes(e?.field))
								.map((column, index) => (
									<Draggable
										draggableId={`filterCard-${column.field}`}
										key={`filterCard-${column.field}`}
										index={index}
									>
										{(provided) => (
											<div ref={provided.innerRef} {...provided.draggableProps}>
												<FilterCard
													apiRef={apiRef}
													column={column}
													order={index}
													isVisible={true}
													handleFilterChange={handleFilterChange}
													dragHandle={{ ...provided.dragHandleProps }}
													activeValues={activeFilters?.find(
														(av) => av.columnField === column.field
													)}
												/>
											</div>
										)}
									</Draggable>
								))}

							{columns
								?.filter((e) => !visibleColumns?.includes(e?.field))
								.map((column, index) => (
									<Draggable
										draggableId={`filterCard-${column.field}`}
										key={`filterCard-${column.field}`}
										index={index}
									>
										{(provided) => (
											<div ref={provided.innerRef} {...provided.draggableProps}>
												<FilterCard
													apiRef={apiRef}
													column={column}
													isVisible={false}
													order={index}
													handleFilterChange={handleFilterChange}
													dragHandle={{ ...provided.dragHandleProps }}
													activeValues={activeFilters?.find(
														(av) => av.columnField === column.field
													)}
												/>
											</div>
										)}
									</Draggable>
								))}
						</StyledWrapper.InnerContainer>
					)}
				</Droppable>
			</StyledWrapper.OuterMostContainer>
		</DragDropContext>
	);
};
