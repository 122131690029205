import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const CardRowInfo = styled.h6`
	width: ${(props) => props?.width || '55%'};
	color: black;
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: nowrap;
	overflow: hidden;
	text-align: ${(props) => props?.centerText && 'center!important;'};
	// margin: 14px 0px;
`;

const CardRowInfoButton = styled(Button)`
	width: 100% !important;
	display: flex !important;
	justify-content: ${(props) =>
		props?.centerText ? 'center!important;' : 'flex-start!important;'};
	gap: 10px;
	& p {
		color: #1976d2;
		margin: 0px;
		text-align: start;
		width: 100%;
		text-overflow: ellipsis;
		max-width: 33ch;
		white-space: ${(props) => (props?.dropdownstate ? 'normal' : 'nowrap')};
		overflow: hidden;
		// margin: 15px 0px;
		font-size: 14px;
	}
`;

// Notifications
const NotificationTitle = styled(Link)`
	width: 55%;
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: nowrap;
	overflow: hidden;
	margin: 0px 0px;
	font-size: 14px;
`;

const NotificationDate = styled.h6`
	margin: ${(props) => props?.margin || '0px 0px'};
	width: 38%;
	text-align: end;
`;

const AllNotificationTitle = styled.div`
	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
`;

const UnexpandedText = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 30vw;
	min-width: 400px;
`;

const ProgramsCellContainer = styled.div`
	margin-bottom: ${(props) => (props?.isOpen ? 'auto' : '0px')};
	display: flex;
	padding: 10px 0px;
	height: fit-content;
	align-items: center;
	min-height: 60px;
`;

const Toolbar = styled.div`
	display: flex;
	gap: 30px;
	justify-content: flex-end;
`;

const NotificationsControlsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	margin-bottom: 20px;
`;

const NotificationsActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	margin-bottom: 20px;
`;

const NotificationControl = styled(Link)`
	font-size: 12px;
	pointer-events: ${(props) => (props?.isDisabled ? 'none' : 'initial')};
	cursor: ${(props) => (props?.isDisabled ? 'initial' : 'pointer')};
	opacity: ${(props) => (props?.isDisabled ? '0.5' : '1')};
	&:hover {
		text-decoration: ${(props) => (props?.isDisabled ? 'none' : 'underline')};
	}
`;

const NotificationControlDivider = styled.div`
	width: 1px;
	height: 14px;
	background: #e0e0e0;
`;

// GENERAL
const CenteredText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	cursor: ${(props) => (props?.isLink ? 'pointer' : 'initial')};
`;

export default {
	CardRowInfo,
	CardRowInfoButton,
	NotificationTitle,
	NotificationDate,
	AllNotificationTitle,
	Toolbar,
	NotificationsControlsContainer,
	NotificationsActionsContainer,
	NotificationControl,
	NotificationControlDivider,
	UnexpandedText,
	ProgramsCellContainer,
	CenteredText
};
