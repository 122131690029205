import './App.css';
import BaseLayout from '../components/BaseLayout/BaseLayout';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_API_KEY);

function App() {
	return (
		<div className="App">
			<BaseLayout />
		</div>
	);
}

export default App;
