import styled from 'styled-components';

const LoginPage = styled.div({
	backgroundColor: '#F5F5F5',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100vw',
	height: 'fit-content',
	minHeight: '100vh',
	overflow: 'scroll'
});

const Container = styled.form({
	backgroundColor: '#FFFFFF',
	boxShadow:
		'0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
	padding: '16px',
	maxWidth: '343px',
	borderRadius: '4px'
});

const Logo = styled.img({
	position: 'relative',
	left: '50%',
	transform: 'translateX(-50%)',
	margin: '20px auto'
});

const Title = styled.h5({
	margin: '8px 0px'
});

const Subtitle = styled.h6({
	margin: '0px 0px 30px 0px'
});

const InputContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: '15px'
});

const ButtonContainer = styled.div({
	margin: '30px 0px 5px 0px'
});

const LinkContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	margin: '20px 0px'
});

const Link = styled.a`
	&:hover {
		text-decoration: underline;
	}
`;

const GYT = styled.img`
	position: fixed;
	left: 0px;
	bottom: 0px;
	@media (max-width: 768px) {
		position: relative;
		margin-top: 15px;
	}
`;

const SingleSignIn = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
});

export default {
	LoginPage,
	Container,
	Logo,
	Title,
	Subtitle,
	InputContainer,
	ButtonContainer,
	LinkContainer,
	Link,
	GYT,
	SingleSignIn
};
