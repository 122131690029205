import { useSelector } from 'react-redux';

import StyledWrapper from './style';
import { formatDateWithTime } from '../../utils/dateUtils';

export default function LastEdited({ data, editingRow, alreadyEditing }) {
	const username = useSelector((state) => state?.login?.user?.userName);

	return (
		<StyledWrapper.LastEdited data-testid={'applicationprocess-lastedited'}>
			{data?.firstName &&
				`Last edited by: ${data?.firstName} ${data?.lastName}, ${
					data?.time && formatDateWithTime(data?.time)
				}`}
			{editingRow && ` / You (${username}) editing now.`}
			{alreadyEditing && (
				<>
					{' / '}
					<StyledWrapper.AlreadyEditingText>
						There is another user editing right now. Edit mode disabled.
					</StyledWrapper.AlreadyEditingText>
				</>
			)}
		</StyledWrapper.LastEdited>
	);
}
