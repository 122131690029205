import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';
export const AssignedFormIcon = ({
	isActive,
	isBlueprint,
	isTemplate,
	isArchived,
	width,
	status
}) => {
	const color = getIconColor(status);

	return isBlueprint ? (
		<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22.4317C17.7614 22.4317 22.4319 17.7612 22.4319 11.9998C22.4319 6.2384 17.7614 1.56787 12 1.56787C6.23864 1.56787 1.56812 6.2384 1.56812 11.9998C1.56812 17.7612 6.23864 22.4317 12 22.4317ZM14.5504 4.1468C14.2496 3.84597 13.8458 3.67972 13.4263 3.67972H7.75001C6.87918 3.67972 6.16668 4.39222 6.16668 5.26305V12.8051C7.72854 12.6425 9.72053 13.217 11.5373 14.4901C13.5759 15.9186 14.8629 17.8547 15.0556 19.5131H17.25C18.1208 19.5131 18.8333 18.8006 18.8333 17.9297V9.0868C18.8333 8.66722 18.6671 8.26347 18.3663 7.97055L14.5504 4.1468ZM13.2917 8.42972V4.86722L17.6458 9.22139H14.0833C13.6479 9.22139 13.2917 8.86514 13.2917 8.42972ZM7.91009 17.516C7.91009 16.9228 8.37655 16.4439 8.95439 16.4439C9.53224 16.4439 9.9987 16.9228 9.9987 17.516C9.9987 18.1093 9.53224 18.5882 8.95439 18.5882C8.37655 18.5882 7.91009 18.1093 7.91009 17.516ZM8.95439 14.8357C7.21389 14.8357 5.72749 15.9471 5.12527 17.516C5.72749 19.085 7.21389 20.1964 8.95439 20.1964C10.6949 20.1964 12.1813 19.085 12.7835 17.516C12.1813 15.9471 10.6949 14.8357 8.95439 14.8357ZM8.95439 19.303C7.99363 19.303 7.21389 18.5024 7.21389 17.516C7.21389 16.5297 7.99363 15.7291 8.95439 15.7291C9.91516 15.7291 10.6949 16.5297 10.6949 17.516C10.6949 18.5024 9.91516 19.303 8.95439 19.303Z"
				fill={isActive ? getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	) : isTemplate ? (
		<svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.868 22.4942C18.6293 22.4942 23.2999 17.8237 23.2999 12.0623C23.2999 6.3009 18.6293 1.63037 12.868 1.63037C7.10656 1.63037 2.43604 6.3009 2.43604 12.0623C2.43604 17.8237 7.10656 22.4942 12.868 22.4942ZM15.4183 4.2093C15.1175 3.90847 14.7138 3.74222 14.2942 3.74222H8.61793C7.7471 3.74222 7.0346 4.45472 7.0346 5.32555V12.8676C8.59646 12.705 10.5885 13.2795 12.4052 14.5526C14.4438 15.9811 15.7308 17.9172 15.9236 19.5756H18.1179C18.9888 19.5756 19.7013 18.8631 19.7013 17.9922V9.1493C19.7013 8.72972 19.535 8.32597 19.2342 8.03305L15.4183 4.2093ZM14.1596 8.49222V4.92972L18.5138 9.28389H14.9513C14.5158 9.28389 14.1596 8.92764 14.1596 8.49222ZM8.77801 17.5785C8.77801 16.9853 9.24447 16.5064 9.82231 16.5064C10.4002 16.5064 10.8666 16.9853 10.8666 17.5785C10.8666 18.1718 10.4002 18.6507 9.82231 18.6507C9.24447 18.6507 8.77801 18.1718 8.77801 17.5785ZM9.82231 14.8982C8.08181 14.8982 6.59541 16.0096 5.99319 17.5785C6.59541 19.1475 8.08181 20.2589 9.82231 20.2589C11.5628 20.2589 13.0492 19.1475 13.6514 17.5785C13.0492 16.0096 11.5628 14.8982 9.82231 14.8982ZM9.82231 19.3655C8.86155 19.3655 8.08181 18.5649 8.08181 17.5785C8.08181 16.5922 8.86155 15.7916 9.82231 15.7916C10.7831 15.7916 11.5628 16.5922 11.5628 17.5785C11.5628 18.5649 10.7831 19.3655 9.82231 19.3655Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M1.18422 1.62791C0.944206 0.866205 1.64988 0.144343 2.41683 0.367025L4.82142 1.0652C5.80871 1.35186 5.77279 2.76278 4.77218 2.99882L4.6446 3.02892C4.26786 3.11779 3.97625 3.41609 3.89595 3.79474L3.86876 3.92296C3.65547 4.92866 2.24572 4.99658 1.93675 4.01604L1.18422 1.62791Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M1.29751 22.5171C1.05749 23.2788 1.76316 24.0007 2.53011 23.778L4.9347 23.0798C5.922 22.7932 5.88607 21.3822 4.88546 21.1462L4.75788 21.1161C4.38115 21.0272 4.08953 20.7289 4.00923 20.3503L3.98204 20.2221C3.76875 19.2164 2.359 19.1484 2.05003 20.129L1.29751 22.5171Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M24.5521 1.60789C24.7921 0.846186 24.0864 0.124323 23.3195 0.347006L20.9149 1.04518C19.9276 1.33184 19.9635 2.74276 20.9641 2.9788L21.0917 3.0089C21.4685 3.09777 21.7601 3.39607 21.8404 3.77472L21.8676 3.90294C22.0809 4.90864 23.4906 4.97656 23.7996 3.99602L24.5521 1.60789Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M24.5521 22.5171C24.7921 23.2788 24.0864 24.0007 23.3195 23.778L20.9149 23.0798C19.9276 22.7932 19.9635 21.3822 20.9641 21.1462L21.0917 21.1161C21.4685 21.0272 21.7601 20.7289 21.8404 20.3503L21.8676 20.2221C22.0809 19.2164 23.4906 19.1484 23.7996 20.129L24.5521 22.5171Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5504 4.55058C14.2496 4.24975 13.8459 4.0835 13.4263 4.0835H7.75002C6.87919 4.0835 6.16669 4.796 6.16669 5.66683V13.2088C7.72854 13.0463 9.72054 13.6207 11.5373 14.8938C13.5759 16.3223 14.8629 18.2585 15.0556 19.9168H17.25C18.1209 19.9168 18.8334 19.2043 18.8334 18.3335V9.49058C18.8334 9.071 18.6671 8.66725 18.3663 8.37433L14.5504 4.55058ZM13.2917 8.8335V5.271L17.6459 9.62516H14.0834C13.6479 9.62516 13.2917 9.26891 13.2917 8.8335ZM2.5 18.5002C3.28636 16.4515 5.22727 15.0002 7.5 15.0002C9.77273 15.0002 11.7136 16.4515 12.5 18.5002C11.7136 20.5488 9.77273 22.0002 7.5 22.0002C5.22727 22.0002 3.28636 20.5488 2.5 18.5002ZM5.22727 18.5002C5.22727 19.7882 6.24545 20.8335 7.5 20.8335C8.75455 20.8335 9.77273 19.7882 9.77273 18.5002C9.77273 17.2122 8.75455 16.1668 7.5 16.1668C6.24545 16.1668 5.22727 17.2122 5.22727 18.5002ZM6.13636 18.5002C6.13636 17.7255 6.74545 17.1002 7.5 17.1002C8.25455 17.1002 8.86364 17.7255 8.86364 18.5002C8.86364 19.2748 8.25455 19.9002 7.5 19.9002C6.74545 19.9002 6.13636 19.2748 6.13636 18.5002Z"
				fill={color}
			/>
		</svg>
	);
};

export default AssignedFormIcon;
