// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarGraph = (props) => (
	<ResponsiveBar
		data-testid={'responsive-bar-chart'}
		data={props.data}
		keys={props.key}
		indexBy="item"
		// margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
		padding={0.05}
		valueScale={{ type: 'linear' }}
		indexScale={{ type: 'band', round: true }}
		colors={{ datum: 'data.color' }}
		borderRadius={5}
		borderColor={{
			from: 'color',
			modifiers: [['darker', 1.6]]
		}}
		axisTop={null}
		axisRight={null}
		axisBottom={null}
		axisLeft={null}
		enableGridY={false}
		enableLabel={false}
		labelSkipWidth={12}
		labelSkipHeight={12}
		labelTextColor={{
			from: 'color',
			modifiers: [['darker', 1.6]]
		}}
		legends={[]}
		isInteractive={false}
		role="responsive-bar-chart"
		ariaLabel="bar chart"
	/>
);

export default BarGraph;
