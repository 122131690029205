import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';

import StyledWrapper from './style';

import { Table } from '../Table';
import { ItemPageTitle } from '../ItemPageTitle';
import { BulkAssignRows } from '../../pages/IndividualProgram/ManageReviews/MockData';
import AssignReviewColumns from './AssignReviewColumns';
import ReassignIcon from '../../assets/customIcons/reassignIcon';
import LinkReviewIcon from '../../assets/customIcons/linkReviewIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewForm';
import TrashcanIcon from '../../assets/customIcons/trashcanIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import { getUniqueValues } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import {
	useBulkReassignDeleteMutation,
	useBulkReassignKeepMutation,
	useBulkUnassignDeleteMutation,
	useGetAvailableReviewersQuery
} from '../../services/endpoints/reviewEndpoints';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '70%',
	maxWidth: '1060px',
	bgcolor: 'background.paper',
	borderRadius: '4px',
	boxShadow:
		'0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
	p: 4
};

export default function BulkReassignReview({ openModal, closeModal, type, item, handleAlert }) {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const reviewerPoolPagination = usePaginatedCall(useGetAvailableReviewersQuery, {
		filterBaseName: 'BULK_ASSIGN_REVIEW'
	});

	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [subModal, setSubModal] = useState(searchParams.get('reassignModal'));
	const [chosenReviewer, setOuterReviewer] = useState();
	const [finalRows, setFinalRows] = useState(BulkAssignRows);
	const outerReviewerRef = useRef();
	outerReviewerRef.current = chosenReviewer;

	const initialColumns = AssignReviewColumns(
		openTooltip,
		setOpenTooltip,
		true,
		outerReviewerRef.current,
		setOuterReviewer,
		'Reassign Reviewer Pool'
	);

	const [unassignDelete] = useBulkUnassignDeleteMutation();
	const [reassignDelete] = useBulkReassignDeleteMutation();
	const [reassignKeep] = useBulkReassignKeepMutation();

	const getBulkPageTitle = () => {
		let stages = Object.values(item);
		stages = getUniqueValues(stages);

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ReviewStageIcon />
				{'Bulk '}
				{type?.includes('reassign') ? 'REASSIGN' : 'UNASSIGN'}
				{' the selected evaulation stage(s): '}
				{stages?.map((e, index) => {
					return (
						<>
							{`[${e}]`}
							{index !== stages?.length - 1 && ' and '}
						</>
					);
				})}
				{' & '}
				{type?.includes('keep') ? 'KEEP CONTENT' : 'DELETE CONTENT'}
			</div>
		);
	};

	const getSubmitButtonText = () => {
		return (
			<>
				{type?.includes('reassign') ? 'REASSIGN' : 'UNASSIGN'}
				{' and '}
				{type?.includes('keep') ? 'KEEP CONTENT' : 'DELETE CONTENT'}
				<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
					{type?.includes('reassign') ? (
						<ReassignIcon isActive={chosenReviewer} color={'white'} />
					) : (
						<LinkReviewIcon isActive={true} color={'white'} />
					)}
					{type?.includes('keep') ? (
						<ReviewFormIcon isActive={chosenReviewer} color={'white'} />
					) : (
						<TrashcanIcon
							isActive={chosenReviewer || !type?.includes('reassign')}
							color={'white'}
						/>
					)}
				</div>
			</>
		);
	};

	const handleClose = () => {
		closeModal && closeModal();
		setIsLoading(false);
		setOpen(false);
	};

	useEffect(() => {
		openModal && setOpen(true);
	}, [openModal]);

	const handleSubmit = async () => {
		setIsLoading(true);

		for (let key in item) {
			if (item.hasOwnProperty(key)) {
				const updatedValues = item[key].map((item) => item.replace('esi-', ''));
				item[key] = updatedValues;
			}
		}
		const options = {
			payload: item,
			new_reviewer: chosenReviewer,
			apiToken: apiToken
		};

		if (type === 'unassign & delete') {
			unassignDelete(options)
				.unwrap()
				.then(async () => {
					handleAlert('Bulk Unassign & Delete Succesfull!', 'success');
					handleClose();
				})
				.catch(() => {
					handleAlert('Bulk Unassign & Delete Failed!', 'error');
					handleClose();
				});
		} else if (type === 'reassign & keep') {
			reassignKeep(options)
				.unwrap()
				.then(async () => {
					handleAlert('Bulk Reassign & Keep Succesfull!', 'success');
					handleClose();
				})
				.catch(() => {
					handleAlert('Bulk Reassign & Keep Failed!', 'error');
					handleClose();
				});
		} else if (type === 'reassign & delete') {
			reassignDelete(options)
				.unwrap()
				.then(async () => {
					handleAlert('Bulk Reassign & Delete Succesfull!', 'success');
					handleClose();
				})
				.catch(() => {
					handleAlert('Bulk Reassign & Delete Failed!', 'error');
					handleClose();
				});
		}
	};

	return (
		<div>
			{!subModal && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<StyledWrapper.Title>{getBulkPageTitle()}</StyledWrapper.Title>
						<StyledWrapper.Description>
							{!type?.includes('keep') && <WarningIcon color={'warning'} />}
							{type?.includes('keep')
								? `The review(s) content is kept`
								: 'The review(s) content will be lost. This action cannot be undone.'}
						</StyledWrapper.Description>

						{!type?.includes('unassign') && (
							<>
								<ItemPageTitle text={'Reviewers Pool'} />
								<Table
									columns={initialColumns}
									enableAutoPageSize
									rowHeight={true}
									customHeight={'50vh'}
									hideFilters
									disableSelectionOnClick
									getRowId={(cell) => cell?.reviewer_id}
									rows={reviewerPoolPagination?.rows?.results || []}
									rowCount={reviewerPoolPagination?.rowCount}
									pagination={reviewerPoolPagination?.pagination}
									pageSize={reviewerPoolPagination?.pageSize}
									paginationMode={reviewerPoolPagination?.paginationMode}
									onPageChange={(e) => reviewerPoolPagination?.onPageChange(e)}
									onPageSizeChange={(e) => reviewerPoolPagination?.onPageSizeChange(e)}
									page={reviewerPoolPagination?.page}
									loading={reviewerPoolPagination?.loading}
									sortingMode={reviewerPoolPagination?.sortingMode}
									onSortModelChange={(e) => reviewerPoolPagination?.onSortModelChange(e)}
									filterModel={reviewerPoolPagination?.filterModel}
									sortModel={reviewerPoolPagination?.sortModel}
								/>
							</>
						)}

						<StyledWrapper.ButtonContainer>
							<Button onClick={() => handleClose()} variant="outlined" size="small">
								CANCEL
							</Button>
							<LoadingButton
								loading={isLoading}
								disabled={!chosenReviewer && !type?.includes('unassign')}
								onClick={handleSubmit}
								variant="contained"
								size="large"
							>
								{getSubmitButtonText()}
							</LoadingButton>
						</StyledWrapper.ButtonContainer>
					</Box>
				</Modal>
			)}
		</div>
	);
}
