import React from 'react';
import { Registry } from '../../FormBuilderLibrary';

export const MUIGroupingStart = React.forwardRef((props, _ref) => {
	return (
		<>
			{props?.data?.label !== '' && (
				<div
					className="GroupingStart"
					style={{
						height: 'fit-content',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '20px 0px'
					}}
				>
					<div
						style={{
							width: '10px',
							backgroundColor: '#ECEFF1',
							borderRadius: '13px',
							height: '3px'
						}}
					/>
					<p
						style={{
							padding: '0px 5px',
							color: 'rgba(0, 0, 0, 0.6)',
							minWidth: 'fit-content',
							margin: '0px',
							fontWeight: props?.data?.bold && 500
						}}
					>
						{props?.data?.label}
					</p>
					<div
						style={{
							width: '100%',
							backgroundColor: '#ECEFF1',
							borderRadius: '13px',
							height: '3px'
						}}
					/>
				</div>
			)}
		</>
	);
});

MUIGroupingStart.displayName = 'MUI_GroupingStart';
Registry.register('MUI_GroupingStart', MUIGroupingStart);
