import styled from 'styled-components';

const Container = styled.label({
	display: 'flex',
	alignItems: 'center'
});

const Label = styled.label({
	margin: '0px'
});

export default {
	Container,
	Label
};
