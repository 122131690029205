import { useEffect } from 'react';
import ResizePanel from 'react-resize-panel';

import StyledWrapper from './style';

export default function ResizePanelContainer({ innerProps, panelStyle, children }) {
	useEffect(() => {
		let outermostContainer = document.getElementsByClassName('resizePanel-container');
		let container = document.getElementsByClassName('customVerticalResizeBar');

		Array.from(outermostContainer).forEach(function (element, index) {
			element.addEventListener('mousemove', (e) => {
				try {
					const containerRect = container[index].getBoundingClientRect();
					const cursorY = e.clientY - containerRect.top;
					const containerHeight = containerRect.height;

					const caratTop = (cursorY / containerHeight) * 100 - 7;

					container[index].style.setProperty('--carat-before-top', `${caratTop + 7}%`);
					container[index].style.setProperty('--carat-after-top', `${caratTop + 7}%`);
				} catch (error) {
					console.error(error);
				}
			});
		});
	}, []);

	return (
		<StyledWrapper.ResizePanelContainer {...innerProps} className="resizePanel-container">
			<ResizePanel
				className={'resizePanelContainerinner'}
				direction="e"
				style={{ width: panelStyle?.width || '200px' }}
				handleClass="customVerticalResizeBar"
				borderClass="customResizeBorder"
			>
				{children}
			</ResizePanel>
		</StyledWrapper.ResizePanelContainer>
	);
}
