import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { handleValidation } from '../../../utils/validationInput';
import Icon from '../Icon/icon';
import { eye, eyeBlocked } from 'react-icons-kit/icomoon';
import './InputCss.css';

const INPUT_COLOR = 'gray';

export default function Input({
	type,
	label,
	fieldName,
	register,
	required,
	error,
	onChange,
	readOnly,
	value,
	id
}) {
	// This states handle status icon-eye on input type password
	const [showPassword, handleShowPassword] = useState(false);

	return (
		<div className="TextField-Container">
			<TextField
				error={error}
				type={showPassword && type === 'password' ? 'text' : type}
				id="outlined-error-helper-text"
				data-testid={id}
				label={label}
				inputProps={{ role: 'textbox' }}
				role="input"
				defaultValue={value}
				helperText={error?.message}
				required={required}
				disabled={readOnly}
				{...register(fieldName, {
					required,
					validate: (value) => handleValidation(type, value),
					onChange: (e) => {
						onChange && onChange(e);
					}
				})}
				style={{ width: '100%' }}
			/>

			{/* BUTTON EYE */}
			{type === 'password' && (
				<Icon
					icon={showPassword ? eye : eyeBlocked}
					className="eyebtn"
					onClick={() => handleShowPassword(!showPassword)}
					style={{
						color: INPUT_COLOR
					}}
				/>
			)}
		</div>
	);
}
