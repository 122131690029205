import { useState, useEffect, forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import StyledWrapper from './style';

import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import { stripTextHTML } from '../utils';

import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

export const CustomLogic = forwardRef((props, _ref) => {
	const andOrRef = useRef(null);
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const [chosenField, setChosenField] = useState([]);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [currentDependacies, setCurrentDependancies] = useState(props?.element?.dependency);
	const [saveButton, setSaveButton] = useState([]);
	const [fields, setFields] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [values, setValue] = useState({
		field: '',
		condition: '',
		value: '',
		optionText: ' '
	});

	useEffect(() => {
		let allFields = [];
		reduxFormSchema.forEach((e) => {
			const filteredFields = e?.filter((x) => props?.element?.field_name != x?.field_name);
			allFields.push(...filteredFields);
		});

		setFields(allFields);
	}, [reduxFormSchema]);

	useEffect(() => {
		const currentField = fields.find((e) => {
			return e?.field_name === values?.field;
		});
		setChosenField(currentField);
		showSaveButton();
	}, [values]);

	function findMatchingDependencyIndex(dependency) {
		if (!currentDependacies) return -1;
		const lastEntry = currentDependacies[currentDependacies?.length - 1];
		const and_or = andOrRef?.current?.value;
		if (Array.isArray(lastEntry) && and_or === 'or') {
			const matchingIndex = lastEntry?.findIndex((item) => {
				if (typeof item === 'object') {
					return Object.keys(dependency).every((key) => item[key] === dependency[key]);
				}
				return false;
			});

			return matchingIndex;
		} else if (typeof lastEntry === 'object' && and_or === 'and') {
			// If the last entry is an object, search for any object that matches the dependency
			const matchingIndex = currentDependacies?.findIndex((item) => {
				if (typeof item === 'object') {
					return Object.keys(dependency).every((key) => item[key] === dependency[key]);
				}
				return false;
			});

			return matchingIndex;
		} else if (typeof lastEntry === 'object' && and_or === 'or') {
			return Object.keys(dependency).every((key) => lastEntry[key] === dependency[key]); // No match found
		} else {
			return -1; // No match found
		}
	}

	const saveDependancy = (updatedValues = values) => {
		const alreadyExists = findMatchingDependencyIndex(updatedValues);
		if (alreadyExists === -1 || alreadyExists === false) {
			const and_or = andOrRef?.current?.value;
			let newDependancies = Array.isArray(currentDependacies)
				? currentDependacies
				: [currentDependacies];

			if (and_or === 'or') {
				const lastEntry = newDependancies[newDependancies.length - 1];
				if (Array.isArray(lastEntry)) {
					lastEntry.push(updatedValues);
				} else if (typeof lastEntry === 'object') {
					newDependancies.pop();
					newDependancies.push([lastEntry, updatedValues]);
				}
			} else {
				newDependancies.push(updatedValues);
			}
			newDependancies = newDependancies.filter((item) => item !== undefined && item !== null);
			setCurrentDependancies(newDependancies);
			props.element.dependency = newDependancies;
			props.update(newDependancies);
		} else {
			setAlertBalloon({ isOpen: true, message: `Dependency Already Exists!` });
			setTimeout(() => {
				setAlertBalloon(false);
			}, 1000);
		}
	};

	const showSaveButton = () => {
		const condition1 = values.condition === 'exists';
		const condition2 = values.condition === 'does not exist';
		const condition3 = values.value !== '';

		setSaveButton(condition1 || condition2 || condition3);
	};

	const deleteDependency = (outerIndex, innerIndex) => {
		let newDependancies = Array.isArray(currentDependacies)
			? currentDependacies
			: [currentDependacies];

		if (outerIndex >= 0 && outerIndex < newDependancies.length) {
			const outerItem = newDependancies[outerIndex];

			if (Array.isArray(outerItem)) {
				// If the outer item is an array, remove the innerIndex from it
				if (innerIndex >= 0 && innerIndex < outerItem.length) {
					outerItem.splice(innerIndex, 1);
				}
			} else if (typeof outerItem === 'object') {
				// If the outer item is an object, remove it from newDependancies
				newDependancies.splice(outerIndex, 1);
			}
		}

		props.element.dependency = newDependancies;
		props.update(newDependancies);
	};

	const showCurrentDependancy = (dependency, outerIndex, innerIndex) => {
		const label = fields?.find((e) => e?.field_name === dependency?.field);
		const formattedLabel = stripTextHTML(label?.label);
		let value =
			dependency?.condition !== 'exists' && dependency?.condition !== 'does not exist'
				? dependency?.value
				: '';

		value =
			dependency?.optionText && dependency?.optionText !== ' ' ? dependency?.optionText : value;

		return (
			dependency && (
				<StyledWrapper.CurrentDependanciesContainer>
					<StyledWrapper.CurrentDependancies>
						<StyledWrapper.dependancy data-testid={'conditionalLogic-currendDependancy'}>
							{formattedLabel} {dependency?.condition} {value}
						</StyledWrapper.dependancy>
						<IconButton
							data-testid={'conditionalLogic-currendDependancy-clear'}
							aria-label="delete"
							size="small"
							color="error"
							onClick={() => deleteDependency(outerIndex, innerIndex)}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					</StyledWrapper.CurrentDependancies>
				</StyledWrapper.CurrentDependanciesContainer>
			)
		);
	};

	const showAllDependancies = () => {
		let allDependancies = Array.isArray(currentDependacies)
			? currentDependacies
			: [currentDependacies];

		return (
			<>
				<label data-testid={'conditionalLogic-currendDependancy-label'}>
					<StyledWrapper.AndOrLabel>Show if:</StyledWrapper.AndOrLabel>
				</label>
				{allDependancies?.map((dependency, index) => {
					return Array.isArray(dependency) ? (
						<StyledWrapper.GroupedDependancies>
							{dependency?.map((innerDependency, innerIndex) => (
								<>
									{showCurrentDependancy(innerDependency, index, innerIndex)}
									{innerIndex !== dependency?.length - 1 && (
										<StyledWrapper.AndOrLabel>or</StyledWrapper.AndOrLabel>
									)}
								</>
							))}
						</StyledWrapper.GroupedDependancies>
					) : (
						<>
							{showCurrentDependancy(dependency, index)}
							{index !== allDependancies?.length - 1 && (
								<StyledWrapper.AndOrLabel>and</StyledWrapper.AndOrLabel>
							)}
						</>
					);
				})}
			</>
		);
	};

	return (
		<>
			<StyledWrapper.AllDependancies>{showAllDependancies()}</StyledWrapper.AllDependancies>

			<Collapse in={alertBalloon}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label="close"
							color="error"
							size="small"
							onClick={() => {
								setAlertBalloon(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
				>
					{alertBalloon?.message}
				</Alert>
			</Collapse>
			<StyledWrapper.LogicContainer>
				{currentDependacies?.length > 0 && (
					<StyledWrapper.InputContainer>
						<StyledWrapper.EditSelect variant="outlined" fullWidth>
							<InputLabel variant="outlined" htmlFor="uncontrolled-native">
								And/Or
							</InputLabel>
							<NativeSelect
								inputProps={{
									name: 'value',
									id: 'uncontrolled-native-value',
									color: 'warning',
									ref: andOrRef
								}}
								data-testid={'conditionalLogic-and-or-select'}
							>
								<option value={'and'}>and</option>
								<option value={'or'}>or</option>
							</NativeSelect>
						</StyledWrapper.EditSelect>
					</StyledWrapper.InputContainer>
				)}

				<StyledWrapper.InputContainer>
					<StyledWrapper.EditSelect variant="outlined" fullWidth>
						<InputLabel variant="outlined" htmlFor="uncontrolled-native">
							Related Field
						</InputLabel>
						<NativeSelect
							inputProps={{
								name: 'relatedField',
								id: 'uncontrolled-native-relatedField',
								color: 'warning'
							}}
							data-testid={'conditionalLogic-field-select'}
							onChange={(data) => {
								setValue({ field: data.target.value, condition: '', value: '', option: '' });
								setRefresh(!refresh);
							}}
						>
							<option value hidden>
								unset
							</option>
							{fields.map((item, index) => {
								if (item.label) {
									return (
										<option
											key={`relatedFields-${index}`}
											data-testid={'conditionalLogic-field-option'}
											value={item.field_name}
										>
											{stripTextHTML(item?.label)}{' '}
											{item?.unique_identifier
												? `(ff-${item?.unique_identifier})`
												: `(tmp-${item?.temp_order})`}
										</option>
									);
								}
							})}
						</NativeSelect>
					</StyledWrapper.EditSelect>
				</StyledWrapper.InputContainer>

				<StyledWrapper.InputContainer>
					<StyledWrapper.EditSelect variant="outlined" fullWidth>
						<InputLabel variant="outlined" htmlFor="uncontrolled-native">
							Condition
						</InputLabel>
						<NativeSelect
							inputProps={{
								name: 'condition',
								id: 'uncontrolled-native-condition',
								color: 'warning'
							}}
							data-testid={'conditionalLogic-condition-select'}
							onChange={(data) => {
								setValue({ ...values, condition: data.target.value, value: '', option: '' });
							}}
							value={values?.condition}
						>
							<option value hidden>
								unset
							</option>
							{(chosenField?.element === 'Dropdown' ||
								chosenField?.key === 'StatePicker' ||
								chosenField?.element === 'RadioButtons' ||
								chosenField?.element === 'Checkboxes' ||
								chosenField?.element === 'TextInput' ||
								chosenField?.key === 'MUI_TextArea' ||
								chosenField?.key === 'MUI_Input' ||
								chosenField?.key === 'MUI_Checkbox' ||
								chosenField?.key === 'MUI_RadioButton' ||
								chosenField?.key === 'MUI_Dropdown') && (
								<>
									<option value="is" data-testid={'conditionalLogic-condition-option'}>
										is
									</option>
									<option value="not" data-testid={'conditionalLogic-condition-option'}>
										{"isn't"}
									</option>
								</>
							)}

							{(chosenField?.element === 'TextInput' ||
								chosenField?.key === 'MUI_TextArea' ||
								chosenField?.key === 'MUI_Input') && (
								<>
									<option value="contains" data-testid={'conditionalLogic-condition-option'}>
										contains
									</option>
									<option value="not-contains" data-testid={'conditionalLogic-condition-option'}>
										does not contain
									</option>
									<option value="starts-with" data-testid={'conditionalLogic-condition-option'}>
										starts with
									</option>
									<option value="ends-with" data-testid={'conditionalLogic-condition-option'}>
										ends with
									</option>
								</>
							)}

							{['MUI_NumberInput', 'MUI_Dropdown']?.includes(chosenField?.key) && (
								<>
									<option value="min" data-testid={'conditionalLogic-condition-option'}>
										is greater than or equal to
									</option>
									<option value="max" data-testid={'conditionalLogic-condition-option'}>
										is less than or equal to
									</option>
								</>
							)}

							{chosenField?.element === 'DatePicker' && (
								<>
									<option value="since" data-testid={'conditionalLogic-condition-option'}>
										since
									</option>
									<option value="before" data-testid={'conditionalLogic-condition-option'}>
										before
									</option>
								</>
							)}

							<option value="exists" data-testid={'conditionalLogic-condition-option'}>
								exists
							</option>
							<option value="does not exist" data-testid={'conditionalLogic-condition-option'}>
								does not exist
							</option>
						</NativeSelect>
					</StyledWrapper.EditSelect>
				</StyledWrapper.InputContainer>

				<StyledWrapper.InputContainer>
					{['is', 'not', 'min', 'max']?.includes(values?.condition) ? (
						chosenField?.element === 'Dropdown' ||
						chosenField?.key === 'StatePicker' ||
						chosenField?.element === 'RadioButtons' ||
						chosenField?.element === 'Checkboxes' ||
						chosenField?.key === 'MUI_Checkbox' ||
						chosenField?.key === 'MUI_RadioButton' ||
						chosenField?.key === 'MUI_Dropdown' ? (
							<>
								<StyledWrapper.EditSelect variant="outlined" fullWidth>
									<InputLabel variant="outlined" htmlFor="uncontrolled-native">
										Value
									</InputLabel>
									<NativeSelect
										inputProps={{
											name: 'value',
											id: 'uncontrolled-native-value',
											color: 'warning'
										}}
										data-testid={'conditionalLogic-value-select'}
										onChange={(data) => {
											setValue({
												...values,
												value: data.target.value,
												optionText: data?.target.options[data?.target?.selectedIndex].text
											});
										}}
									>
										<option value hidden>
											unset
										</option>
										{chosenField?.options?.map((e, index) => {
											return (
												<option
													key={`chosenFields-conditionalLogic-${index}`}
													data-testid={'conditionalLogic-value-option'}
													value={
														chosenField?.element === 'Dropdown' ||
														chosenField?.key === 'StatePicker'
															? e?.value
															: e?.key
													}
												>
													{e?.text}
												</option>
											);
										})}
									</NativeSelect>
								</StyledWrapper.EditSelect>
							</>
						) : (
							<>
								<StyledWrapper.EditTextField
									onChange={(data) => {
										setValue({ ...values, value: data.target.value });
									}}
									data-testid={'conditionalLogic-value-label'}
									label="Value"
									variant="outlined"
									color="warning"
									type={chosenField?.key === 'MUI_NumberInput' ? 'number' : 'text'}
								/>
							</>
						)
					) : null}

					{['contains', 'not-contains', 'starts-with', 'ends-with']?.includes(
						values?.condition
					) && (
						<>
							<StyledWrapper.EditTextField
								onChange={(data) => {
									setValue({ ...values, value: data.target.value });
								}}
								data-testid={'conditionalLogic-value-label'}
								label="Value"
								variant="outlined"
								color="warning"
							/>
						</>
					)}
				</StyledWrapper.InputContainer>

				<StyledWrapper.ButtonsContainer>
					{saveButton && (
						<Tooltip title="Add" placement="bottom">
							<IconButton
								data-testid={'conditionalLogic-save-button'}
								aria-label="addDependancy"
								size="small"
								color="success"
								onClick={() => saveDependancy()}
							>
								<DoneIcon fontSize="small" data-testid={'conditionalLogic-save-icon'} />
							</IconButton>
						</Tooltip>
					)}
				</StyledWrapper.ButtonsContainer>
			</StyledWrapper.LogicContainer>
		</>
	);
});

CustomLogic.displayName = 'CustomLogic';
