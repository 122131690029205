import { TextField } from '@mui/material';
export default function DefaultTextInputField({ currentFilter, setCurrentFilter }) {
	return (
		<TextField
			sx={{ fontSize: '14px' }}
			value={currentFilter.value ?? ''}
			onChange={(e) => {
				setCurrentFilter({ ...currentFilter, value: e.target.value });
			}}
			label="Value"
			variant="standard"
		/>
	);
}
