import React from 'react';
import { Registry } from '../../FormBuilderLibrary';

export const MUIGroupingEnd = React.forwardRef(() => {
	return (
		<>
			<div
				className="GroupingEnd"
				style={{
					height: 'fit-content',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<div
					style={{ width: '100%', backgroundColor: '#ECEFF1', borderRadius: '13px', height: '3px' }}
				/>
			</div>
		</>
	);
});

MUIGroupingEnd.displayName = 'MUI_GroupingEnd';
Registry.register('MUI_GroupingEnd', MUIGroupingEnd);
