import StyledWrapper from '../style';

import CardCoreInfo from '../../../components/Cards/CardCoreInfo/CardCoreInfo';
import ScoreCard from '../../../components/Cards/ScoreCard/ScoreCard';
import CompletionStatusCard from '../components/CompletionStatusCard/CompletionStatusCard';
import { NotificationCard } from '../../../components/NotificationCard';
import { Instructions } from '../../../components/FormBuilder/customComponents/Instructions';
import AppStagesTable from '../components/AppStagesTable/AppStagesTable';
import { useGetIndividualAppTable } from '../../../hooks/useGetIndividualAppTable';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const ApplicationOverview = (props) => {
	const { data, isLoading: isLoadingSetup, refetch } = useGetIndividualAppTable(props);
	const instructions = { content: data?.[0]?.instructions || '' };

	return (
		<>
			{isLoadingSetup ? (
				<>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<CircularProgress />
					</Box>
				</>
			) : (
				<StyledWrapper.OutermostContainer style={{ width: '100%', gap: '15px' }}>
					{/* <StyledWrapper.InnerContainer>
					<CardCoreInfo data={coreInfo} />
					<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
						<ScoreCard />
						<NotificationCard
							data={MockData}
							title={'Application Process Notifications'}
							height={'322px'}
							boxOverflowHidden={true}
						/>
					</div>
				</StyledWrapper.InnerContainer>
				<StyledWrapper.InnerContainer>
					<CompletionStatusCard
						chartData={chart1Data}
						title={'Application Forms Completion Status 40%'}
					/>
					<CompletionStatusCard
						chartData={chart2Data}
						title={'Reviews Forms Completion Status 20%'}
					/>
				</StyledWrapper.InnerContainer>
				<StyledWrapper.InnerContainer>
					<Instructions data={''} hideLabel width={'100%'} />
				</StyledWrapper.InnerContainer> */}

					<>
						<StyledWrapper.InnerContainer>
							<Instructions data={instructions || ''} hideLabel width={'100%'} />
						</StyledWrapper.InnerContainer>
						<AppStagesTable data={data} handleAlert={props?.handleAlert} />
					</>
				</StyledWrapper.OutermostContainer>
			)}
		</>
	);
};

export default ApplicationOverview;
