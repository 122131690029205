/**
 * <ToolbarGroupItem />
 */

import React, { useState } from 'react';

import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function ToolbarGroupItem(props) {
	const { name, group, renderItem } = props;

	const [show, setShow] = useState(false);

	function onClick() {
		setShow(!show);
	}

	const classShow = 'collapse' + (show ? ' show' : '');
	return (
		<li>
			<div className="toolbar-group-item">
				<Button
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						color: 'rgba(0, 0, 0, 0.6)',
						fontSize: '14px!important',
						padding: '12px'
					}}
					variant="text"
					endIcon={show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					onClick={onClick}
				>
					{name}
				</Button>
				<div className={classShow}>
					<ul data-testid={`toolbox-group-${name.toLowerCase().replaceAll('/', '-')}`}>
						{group.map(renderItem)}
					</ul>
				</div>
			</div>
		</li>
	);
}

export default ToolbarGroupItem;
