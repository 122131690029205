export const ProgressBarData = {
	startDate: new Date('03/01/2018'),
	endDate: new Date('12/31/2020'),
	isCountdown: false
};

export const ApplicationsData = {
	startDate: new Date('03/01/2022'),
	endDate: new Date('07/31/2023'),
	isCountdown: true
};

export const ReviewsData = {
	startDate: new Date('10/1/2022'),
	endDate: new Date('11/24/2022'),
	isCountdown: true
};

export const BarGraphKeys = ['value'];
