import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { goalsFormSchema } from './utils';

import { useGetGoalsAndMetricsQuery } from '../../../../services/endpoints/programsEndpoints';
import { Table } from '../../../../components/Table';
import { MultiFormViewer } from '../../../../components/MultiFormViewer';
import StyledWrapper from '../style';
import StyledWrapper2 from '../../../IndividualProgram/style';
import { GoalsAndMetricsColumns } from './GoalsAndMetricsColumns';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { usePermissions } from '../../../../hooks/usePermissions';
import { getGNURL } from '../../../../utils/environmentUtils';
import { getLocalAuth } from '../../../../utils/environmentUtils';

const GoalsAndMetrics = () => {
	const FormContainer = useRef(null);
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);

	const { data: individualGoals, isLoading: isLoadingGoals } = useGetGoalsAndMetricsQuery(
		{
			id: programID,
			apiToken
		},
		{ refetchOnMountOrArgChange: true }
	);

	const [formAnswers, setFormAnswers] = useState([]);
	const [finalRows, setFinalRows] = useState([]);
	const { hasEditPermissions } = usePermissions();

	const toggleRow = (rowID, type, isOpen) => {
		if (type === 'expandAll' || type === 'collapseAll') {
			setFinalRows(
				finalRows.map((row) => {
					return {
						...row,
						isOpen: type === 'expandAll'
					};
				})
			);
		} else {
			setFinalRows((finalRows) =>
				finalRows.map((row) => {
					if (row.id === rowID) {
						return {
							...row,
							isOpen: Number(!isOpen)
						};
					} else {
						return row;
					}
				})
			);
		}
	};

	useEffect(() => {
		const goalsURL = individualGoals?.solicitationId
			? `${getGNURL()}/GO/srmng_solicitationEdit/SOLICITATIONID/${
					individualGoals?.solicitationId
			  }#goal`
			: '';

		const formAnswers = [
			{
				name: 'linkInput_F23DDD24-ED40-40B3-BD36-4719B121EB93',
				custom_name: 'linkInput_F23DDD24-ED40-40B3-BD36-4719B121EB93',
				value: ['Program Goals', goalsURL]
			},
			{
				name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
				custom_name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
				value: goalsURL
			}
		];
		setFormAnswers(formAnswers);

		let filteredRows = individualGoals?.goals ? [...individualGoals?.goals] : [];

		filteredRows = filteredRows?.map((e, index) => {
			return {
				...e,
				subgoalslength: e?.details?.length,
				id: index,
				isOpen: 0
			};
		});

		setFinalRows(filteredRows);
	}, [individualGoals]);

	return (
		<>
			{isLoadingGoals ? (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '50vh',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<StyledWrapper.FormContainer hideSecondResizeIcon ref={FormContainer}>
					<StyledWrapper.FormHeader>
						<StyledWrapper.LastEdited data-testid={'goalsandmetrics-description'}>
							The purpose of program goals is to capture grant outcomes from your applicants that
							align to program priorities. Common goals include home construction, business
							revitalization, job creation, persons assisted, and program attendance. Once awarded,
							applicants can submit progress reports against program goals.
						</StyledWrapper.LastEdited>
					</StyledWrapper.FormHeader>

					{hasEditPermissions && (
						<MultiFormViewer
							formAnswers={[[], [formAnswers]]}
							formInstructions={''}
							form={[goalsFormSchema]}
							readOnly={true}
							hideInstructions
							disableFooterButtons
							disableActionButtons
							hideActionButtons
						/>
					)}

					{finalRows?.length > 0 ? (
						<>
							<StyledWrapper2.Toolbar>
								<StyledWrapper2.NotificationsControlsContainer
									data-testid={`programs-allnotifications-container`}
								>
									<StyledWrapper2.NotificationControlDivider />
									<StyledWrapper2.NotificationControl
										data-testid={`programs-allnotifications-allexpanded`}
										onClick={() => toggleRow(null, 'expandAll')}
									>
										Expand All
									</StyledWrapper2.NotificationControl>
									<StyledWrapper2.NotificationControlDivider />
									<StyledWrapper2.NotificationControl
										data-testid={`programs-allnotifications-allcollapse`}
										onClick={() => toggleRow(null, 'collapseAll')}
									>
										Collapse All
									</StyledWrapper2.NotificationControl>
								</StyledWrapper2.NotificationsControlsContainer>
							</StyledWrapper2.Toolbar>
							<Table
								customHeight={'45vh'}
								loading={isLoadingGoals}
								disableSelectionOnClick
								columns={GoalsAndMetricsColumns(toggleRow)}
								rows={finalRows}
								minWidth={'1000px'}
							/>
						</>
					) : (
						<p style={{ width: '100%', textAlign: 'center' }}>There are no Goals/Subgoals set.</p>
					)}
				</StyledWrapper.FormContainer>
			)}
		</>
	);
};

export default GoalsAndMetrics;
