import StyledWrapper from './style';
import { getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

export const DecisionChip = ({ label }) => {
	return (
		<StyledWrapper.ButtonContainer>
			<StyledWrapper.DecisionContainer>Decision</StyledWrapper.DecisionContainer>
			<StyledWrapper.StatusContainer
				color={getChipTextColor(label)}
				backgroundColor={getChipColor(label)}
			>
				{label}
			</StyledWrapper.StatusContainer>
		</StyledWrapper.ButtonContainer>
	);
};
