import styled from 'styled-components';

const Title = styled.h4`
	width: 100%;
	text-align: center;
	margin-bottom: 30px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 18px;
`;

const Description = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 15px;
	flex-wrap: wrap;
	justify-content: ${(props) => props?.center && 'center'};
	text-align: ${(props) => props?.center && 'center'};
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 22px;
	margin-top: ${(props) => props?.marginTop && '40px'};
`;

export default {
	Title,
	Description,
	ButtonContainer
};
