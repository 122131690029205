import styled from 'styled-components';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';

import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getEcivisBlueColor, getEunaOrangeColor } from '../../utils/utilFunctions';

interface ToggleButtonProps {
	active?: boolean;
	expanded?: boolean;
	isChild?: boolean;
	lastChild?: boolean;
	initialDropdown?: boolean;
	activeOverview?: boolean;
	customInnerWidth?: string;
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: ${(p: ToggleButtonProps) => p.customInnerWidth || 'fit-content'};
	overflow: hidden;
	padding-right: 0px;
	flex-wrap: no-wrap;
	gap: 5px;

	& .individualStage:after {
		content: ${(p: ToggleButtonProps) => (p.expanded ? 'close-quote' : '')};
		width: 14px;
		height: 29px;
		background-color: transparent;
		position: absolute;
		top: -16px;
		left: -12px;
		border-left: 1px solid rgba(0, 0, 0, 0.6);
		border-bottom: 1px solid rgba(0, 0, 0, 0.6);
	}
`;

const ResizeContainer = styled.div`
	width: 100%;
`;

const GrayLine = styled.div`
	display: flex;
	width: 100%;
	border-radius: 13px;
	height: 8px;
	background: #eceff1;
`;

const TitleText = styled.h4`
	min-width: fit-content;
`;

//Nav Link
const linkContainer = styled.div`
	position: relative;
	width: 110%;
	display: flex;
	align-items: center;
	height: 24px;
	padding: 18px 0px;
	margin-left: ${(p: ToggleButtonProps) => (p.isChild ? '0px!important' : '0px')};
`;

const NavLink = styled.p`
	cursor: pointer;
	width: 100%;
	color: ${(p: ToggleButtonProps) => (p.active ? `${getEcivisBlueColor()}` : 'rgba(0, 0, 0, 0.6)')};
	margin-top: 0px;
	margin-bottom: 0px;
	// margin-left: ${(p: ToggleButtonProps) => (p.isChild ? '0px!important' : '0px')};
	border-right: ${(p: ToggleButtonProps) =>
		p.active ? `2px solid ${getEunaOrangeColor()}` : '0px'};
	margin-right: 24px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

//Dropdown
const DropdownContainer = styled(Accordion)`
	// width: ${(p: ToggleButtonProps) => (p.initialDropdown ? 'fit-content' : '100%')};
	width: 100%;
	overflow: ${(p: ToggleButtonProps) => p.initialDropdown && 'hidden'};
	height: ${(p: ToggleButtonProps) => (p.expanded ? 'fit-content' : '24px')};
	background-color: none !important;
	box-shadow: none !important;
	margin-left: ${(p: ToggleButtonProps) => (p.isChild ? '0px!important' : '0px')};
	margin-top: 0px !important;
	margin-bottom: 0px !important;

	&::before {
		display: none;
	}

	& div {
		background-color: transparent !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		padding: 0px;
		padding-right: 1px;
		align-items: center !important;
	}

	& p {
		margin: 0px;
	}

	& #applicationProcess-header p {
		border-right: ${(p: ToggleButtonProps) =>
			p.activeOverview ? `2px solid ${getEunaOrangeColor()}` : '0px'};
		color: ${(p: ToggleButtonProps) =>
			p.activeOverview ? `${getEcivisBlueColor()}` : 'rgba(0, 0, 0, 0.6)'};
		width: 100%;
	}

	& .stageDropdown p {
		color: ${(p: ToggleButtonProps) =>
			p.active ? `${getEcivisBlueColor()}` : 'rgba(0, 0, 0, 0.6)'};
	}

	& svg {
		background-color: white !important;
		z-index: 10;
	}

	& .childElement:before {
		content: ${(p: ToggleButtonProps) => (p.isChild && !p.lastChild ? 'close-quote' : '')};
		width: 7px;
		height: 153%;
		position: absolute;
		top: -22px;
		left: -44px;
		border-left: 1px solid rgba(0, 0, 0, 0.6);
		border-bottom: 0px solid rgba(0, 0, 0, 0.6);
	}

	& .childElement:after {
		content: close-quote;
		width: 7px;
		height: 29px;
		background-color: transparent;
		position: absolute;
		top: -16px;
		left: -6px;
		border-left: 1px solid rgba(0, 0, 0, 0.6);
		border-bottom: 1px solid rgba(0, 0, 0, 0.6);
	}

	& .isStage:first-child:before {
		//ADDS LEFT LINE TO VERTICAL NAVIGATION
		// content: ${(p: ToggleButtonProps) => (p.expanded ? 'close-quote' : '')};
		display: ${(p: ToggleButtonProps) => (p.expanded ? 'block' : '')};
		width: 7px;
		height: 100%;
		position: absolute;
		background: transparent;
		top: 0px;
		left: -12px;
		border-left: 1px solid rgba(0, 0, 0, 0.6);
		border-bottom: 0px solid rgba(0, 0, 0, 0.6);
	}
`;

const CustomAccordionSummary = styled(AccordionSummary)`
	& .MuiAccordionSummary-content {
		background-color: red;
		width: 100%;
	}
`;

//Icons
const PlainCircleIcon = styled(PanoramaFishEyeIcon)`
	color: rgba(0, 0, 0, 0.6);
	width: 16px !important;
	height: 16px !important;
	cursor: pointer;
	margin-right: 5px;
`;

const PlusIcon = styled(ControlPointIcon)`
	color: rgba(0, 0, 0, 0.6);
	width: 16px !important;
	height: 16px !important;
	cursor: pointer;
	margin-right: 5px;
`;

const MinusIcon = styled(RemoveCircleOutlineIcon)`
	color: rgba(0, 0, 0, 0.6);
	width: 16px !important;
	height: 16px !important;
	cursor: pointer;
	margin-right: 5px;
`;

export default {
	ResizeContainer,
	Container,
	GrayLine,
	TitleText,
	linkContainer,
	NavLink,
	DropdownContainer,
	CustomAccordionSummary,
	PlainCircleIcon,
	PlusIcon,
	MinusIcon
};
