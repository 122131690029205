export const EllipsisIcon = () => {
	return (
		<svg width="17" height="4" viewBox="0 0 17 4" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.94434 0C1.84434 0 0.944336 0.9 0.944336 2C0.944336 3.1 1.84434 4 2.94434 4C4.04434 4 4.94434 3.1 4.94434 2C4.94434 0.9 4.04434 0 2.94434 0ZM14.9443 0C13.8443 0 12.9443 0.9 12.9443 2C12.9443 3.1 13.8443 4 14.9443 4C16.0443 4 16.9443 3.1 16.9443 2C16.9443 0.9 16.0443 0 14.9443 0ZM8.94434 0C7.84434 0 6.94434 0.9 6.94434 2C6.94434 3.1 7.84434 4 8.94434 4C10.0443 4 10.9443 3.1 10.9443 2C10.9443 0.9 10.0443 0 8.94434 0Z"
				fill="black"
				fillOpacity="0.54"
			/>
		</svg>
	);
};

export default EllipsisIcon;
