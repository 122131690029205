import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { stringToHTML } from '../utils';
import { getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getElementIcon } from '../utils';
import StyledWrapper from './style';

export const MyInput = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, data, isFormBuilder } = props;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	const isCoreInfoApplication = data?.custom_options?.includes('core-application');

	return (
		<>
			{!disabled && !data?.custom_options?.includes('completely-disabled') ? (
				<>
					<TextField
						error={props?.data?.error}
						required={props?.data?.required}
						id="outlined-error-helper-text"
						name={name}
						inputRef={ref}
						disabled={disabled}
						defaultValue={defaultValue}
						style={{ width: '100%' }}
						label={
							<>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<div>
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</div>
								)}
							</>
						}
						multiline
						InputLabelProps={{ shrink: isFormBuilder || undefined }}
					/>
					<FormHelperText error={props?.data?.error}>
						{props?.data?.error
							? props?.data?.error?.error
							: parsedHelperText?.[0]
							? parsedHelperText
							: props?.data?.help_message}
					</FormHelperText>
				</>
			) : (
				<FormControl sx={{ width: '100%' }} variant="outlined" name={name}>
					<StyledWrapper.CustomLabel
						value={defaultValue}
						disabled={
							disabled == 'disabled' || data?.custom_options?.includes('completely-disabled')
						}
						required={props?.data?.required}
						htmlFor="outlined-adornment-password"
						shrink={isFormBuilder || isCoreInfoApplication || defaultValue || undefined}
					>
						{isFormBuilder && getElementIcon(props.data?.key)}
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						{isFormBuilder && (
							<div>
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</div>
						)}
					</StyledWrapper.CustomLabel>
					<OutlinedInput
						error={props?.data?.error}
						id="outlined-error-helper-text"
						name={name}
						inputRef={ref}
						disabled={disabled || data?.custom_options?.includes('completely-disabled')}
						defaultValue={defaultValue}
						value={defaultValue}
						style={{ width: '100%', minHeight: '56px' }}
						label={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						multiline
						type={'text'}
						InputLabelProps={{
							shrink: isFormBuilder || isCoreInfoApplication || defaultValue || undefined
						}}
						inputComponent={() => {
							return (
								<div style={{ display: 'flex', gap: '10px' }}>
									{(props?.data?.chip || isCoreInfoApplication) && (
										<Chip
											id={'programDetails-statusChip'}
											data-testid={`programDetails-statusChip`}
											label={props?.data?.chip || 'Status'}
											sx={{
												backgroundColor: getChipColor(props?.data?.chip || 'Status'),
												color: getChipTextColor(props?.data?.chip),
												WebkitTextFillColor:
													props?.data?.chip === 'Unassigned' ? 'black' : 'white!important'
											}}
											size="small"
										/>
									)}
									<p
										style={{
											width: data?.isLink ? data?.customWidth || '48%' : '100%',
											textOverflow: data?.isLink ? 'ellipsis' : 'none',
											overflow: 'hidden',
											whiteSpace: data?.isLink ? 'nowrap' : 'auto',
											margin: '0px'
										}}
									>
										{defaultValue ? defaultValue : isCoreInfoApplication && '[..]'}
									</p>

									{data?.isLink && (
										<IconButton
											onClick={() => {
												navigator.clipboard.writeText(defaultValue);
											}}
											aria-label="fingerprint"
											color="inherit"
											style={{ padding: '0px' }}
										>
											<ContentCopyIcon />
										</IconButton>
									)}
								</div>
							);
						}}
					/>
					<FormHelperText error={props?.data?.error}>
						{props?.data?.error
							? props?.data?.error?.error
							: parsedHelperText?.[0]
							? parsedHelperText
							: props?.data?.help_message}
					</FormHelperText>
				</FormControl>
			)}
		</>
	);
});

MyInput.displayName = 'MUI_Input';
Registry.register('MUI_Input', MyInput);
