import { createSlice } from '@reduxjs/toolkit';
import {
	DATRGRID_DEFAULT_ORDER_MODEL,
	DATAGRID_DEFAULT_FILER_MODEL,
	DEFAULT_FILTER_BASE_NAME
} from '../../constants/Layout';

const initialState = {
	program: {},
	application: {},
	form: {},
	review: {},
	filters: {
		programs: {
			ordreModel: DATRGRID_DEFAULT_ORDER_MODEL,
			filterModel: DATAGRID_DEFAULT_FILER_MODEL
		},
		[DEFAULT_FILTER_BASE_NAME]: {
			ordreModel: DATRGRID_DEFAULT_ORDER_MODEL,
			filterModel: DATAGRID_DEFAULT_FILER_MODEL
		}
	}
};

export const globalProgramSliceReducer = createSlice({
	name: 'GlobalProgramVariables',
	initialState,
	reducers: {
		saveVariable: (state, action) => {
			state[action?.payload[0]] = action?.payload[1];
		},
		clearDefaultFilter: (state, action) => {
			if (!state.filters) {
				state.filters = {};
			}
			state.filters[DEFAULT_FILTER_BASE_NAME] = {
				ordreModel: DATRGRID_DEFAULT_ORDER_MODEL,
				filterModel: DATAGRID_DEFAULT_FILER_MODEL
			};
		},
		clearAllFilters: (state, action) => {
			if (!state.filters) {
				state.filters = {};
			}
			if (state.filters['PROGRAMS'] !== undefined) {
				state.filters['PROGRAMS'].filterModel = [];
			}
			if (state.filters['programs'] !== undefined) {
				state.filters['programs'].filterModel = [];
			}
			if (state.filters['PROGRAM_APPLICATIONS'] !== undefined) {
				state.filters['PROGRAM_APPLICATIONS'].filterModel = [];
			}
		}
	}
});

// Action creators are generated for each case reducer function
export const { saveVariable, clearDefaultFilter, clearAllFilters } =
	globalProgramSliceReducer.actions;

export default globalProgramSliceReducer.reducer;
