/**
 * <ToolbarItem />
 */

import React from 'react';
import { DragSource } from 'react-dnd';
import ItemTypes from './ItemTypes';
import ID from './UUID';
import SettingsIcon from '@mui/icons-material/Settings';

const cardSource = {
	beginDrag(props) {
		return {
			id: ID.uuid(),
			index: -1,
			data: props.data,
			onCreate: props.onCreate
		};
	}
};

class ToolbarItem extends React.Component {
	render() {
		const { connectDragSource, data, onClick } = this.props;
		const queryParameters = new URLSearchParams(window.location.search);
		const isReview = queryParameters.get('formBuilder') === 'review';

		if (!connectDragSource) return null;
		if (isReview && data.excludeReview) return null;

		return connectDragSource(
			<li
				onClick={onClick}
				className="formbuilder-toolbar-item"
				data-testid={`formbuilder-toolbar-item`}
			>
				<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
					{typeof data?.icon !== 'string' ? data?.icon : <SettingsIcon />}
					{data.name}
				</div>
			</li>
		);
	}
}

export default DragSource(ItemTypes.CARD, cardSource, (connect) => ({
	connectDragSource: connect.dragSource()
}))(ToolbarItem);
