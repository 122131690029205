import { useState, useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import useAuthentication from '../../hooks/useAuthentication';
import LoginRedirect from '../../pages/LoginRedirect';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';

const ProtectedRoute = ({ component: Component }) => {
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { hasAccess } = useAuthentication();

	const [accessGranted, setAccessGranted] = useState('loading');

	const checkAccess = async () => {
		try {
			const response = await hasAccess();
			setAccessGranted(response);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		checkAccess();
	}, [location, searchParams]);

	return accessGranted === 'loading' ? (
		<LoadingPage />
	) : accessGranted === true ? (
		<Component />
	) : (
		<LoginRedirect />
	);
};

export default ProtectedRoute;
