import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { getOldBlueColor } from '../../../utils/utilFunctions';

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-items: center;
	gap: 26px;

	@media (max-width: 768px) {
		justify-content: flex-start;
		width: fit-content;
	}
`;

const CardRowContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	width: ${(props) => props?.width || '100%'};
	height: ${(props) => (props?.dropdownstate ? 'fit-content' : '52px')};

	.label-help-icon {
		visibility: hidden;
	}

	&:hover .label-help-icon {
		visibility: initial !important;
`;

const CardRowTitle = styled.h6`
	margin: ${(props) => props?.margin || '0px 0px'};
	position: relative;
	width: ${(props) => props?.width || '33%'};
	text-align: end;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
`;

const CardRowInfo = styled.h6`
	display: ${(props) => props?.display || 'block'};
	align-items: center;
	justify-content: ${(props) => props?.justifyContent || 'space-between'};
	gap: 20px;
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	color: ${(props) => (props?.status ? 'rgba(0, 0, 0, 0.38)' : 'black')};
	pointer-events: ${(props) => props?.status && 'none'};
	text-overflow: ellipsis;
	max-width: ${(props) => props?.maxWidth || '33ch'};
	white-space: nowrap;
	overflow: hidden;
	margin: 0px;
`;

const CardRowInfoLink = styled(Link)`
	display: block;
	align-items: center;
	gap: 10px;
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: ${(props) => (props?.dropdownstate ? 'normal' : 'nowrap')};
	overflow: hidden;
	font-size: 14px;
	margin: auto 0px;
	line-height: normal;
	cursor: ${(props) => props?.cursor};
`;

const CardRowSubText = styled.h6`
	font-size: 14px;
`;

const CardRowInfoButton = styled(Button)`
	width: ${(props) => props?.width || '100%'}!important;
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center;
	gap: 6px;
	& p {
		color: ${getOldBlueColor()};
		margin: 0px;
		text-align: start;
		width: 100%;
		text-overflow: ellipsis;
		max-width: 33ch;
		white-space: ${(props) => (props?.dropdownstate ? 'normal' : 'nowrap')};
		overflow: hidden;
		font-size: 14px;
		text-transform: lowercase;
	}
`;

export default {
	OverviewContainer,
	CardRowContainer,
	CardRowTitle,
	CardRowInfo,
	CardRowInfoLink,
	CardRowSubText,
	CardRowInfoButton
};
