import { createSlice } from '@reduxjs/toolkit';

export interface SideNavState {
	value: boolean;
}

const initialState: SideNavState = {
	value: false
};

export const sideNavSlice = createSlice({
	name: 'sideNavState',
	initialState,
	reducers: {
		toggleSideNav: (state) => {
			state.value = !state.value;
		}
	}
});

// Action creators are generated for each case reducer function
export const { toggleSideNav } = sideNavSlice.actions;

export default sideNavSlice.reducer;
