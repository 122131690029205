import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut } from '../pages/Login/slice';
import { clearAllFilters } from '../components/BaseLayout/slice';
import { ACCOUNT_SOURCE, getLocalAuth } from '../utils/environmentUtils';

const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL });

const baseQueryWithReauth = async (args, api, extraOptions) => {
	const accountSource = api.getState()?.login?.accountSource;
	const apiToken = getLocalAuth(accountSource);

	let result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		// try to get a new token
		const refreshResult = await baseQuery(
			{
				url: 'refresh_token/',
				method: 'post',
				body: {
					refresh_token: apiToken?.refreshToken
				}
			},
			api,
			extraOptions
		);
		if (refreshResult.data) {
			// store the new token
			if (accountSource === ACCOUNT_SOURCE.GRANTS_NETWORK) {
				localStorage.setItem('gpm-auth-gn', JSON.stringify(refreshResult.data));
			} else if (accountSource === ACCOUNT_SOURCE.PORTAL) {
				localStorage.setItem('gpm-auth-portal', JSON.stringify(refreshResult.data));
			}
			// retry the initial query
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(clearAllFilters());
			api.dispatch(logOut());
			window.location.href = '/session-expired';
		}
	}
	return result;
};

export const GPM = createApi({
	baseQuery: baseQueryWithReauth,
	tagTypes: ['IndividualForms', 'Stages', 'ApplicationProcess', 'Reviewers', 'FormInstanceSchema'],
	endpoints: () => ({})
});
