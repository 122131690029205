import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AppStagesColumns, groupingColDef } from './appStagesColumns';
import StyledWrapper from './style';

// import { SectionCard } from '../../../../components/SectionCard';
import { Table } from '../../../../components/Table';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
// import Tooltip from '@mui/material/Tooltip';

const AppStagesTable = ({ data, handleAlert }) => {
	const [searchParams] = useSearchParams();
	const [columnWidth, setColumnWidth] = useState({});

	const [refresh, setRefresh] = useState(false);
	const [loadingCancel, setLoadingCancel] = useState(false);

	const initialColumns = AppStagesColumns(columnWidth, data, handleAlert);

	// Constants
	const getTreeDataPath = (row) => row.hierarchy;

	useEffect(() => {
		setRefresh(!refresh);
	}, [data, searchParams]);

	useEffect(() => {
		setLoadingCancel(true);
		setTimeout(function () {
			setLoadingCancel(false);
		}, 1000);
	}, [location.pathname]);

	return (
		// UNCOMMENT WHEN NEEDED IN PHASE 2
		// <SectionCard noscroll={true} width={'100%'}>
		// 	<StyledWrapper.CardTitleContainer data-testid={`overview-applications-title-container`}>
		// 		<Tooltip title={`View all Program Applications`} placement="top" arrow>
		// 			<StyledWrapper.CardTitleNoLink data-testid={`reviewOverview-title`}>
		// 				Entire Application Process & Entities
		// 			</StyledWrapper.CardTitleNoLink>
		// 		</Tooltip>
		// 	</StyledWrapper.CardTitleContainer>
		<>
			{loadingCancel ? (
				<>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<CircularProgress />
					</Box>
				</>
			) : (
				<StyledWrapper.TableContainer>
					<Table
						style={{ margin: 'auto' }}
						enableAutoPageSize
						treeData
						getTreeDataPath={getTreeDataPath}
						disableSelectionOnClick
						columns={initialColumns}
						rows={data}
						groupingColDef={() => groupingColDef(columnWidth)}
						rowHeight={true}
						onColumnResize={(e) => {
							const newColumnSizes = e?.colDef?.field;
							const width = e?.width;
							setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
						}}
					/>
				</StyledWrapper.TableContainer>
			)}
		</>

		// </SectionCard>
	);
};

export default AppStagesTable;
