import StyledWrapper from './style';

export default function ItemPageTitle({ text, removeGrayLine, isUnsaved }) {
	return (
		<StyledWrapper.Container>
			{text ? (
				<>
					{!removeGrayLine && <StyledWrapper.GrayLine data-testid={'itemPageTitle-grayline'} />}
					<StyledWrapper.TitleText data-testid={'itemPageTitle-text'}>
						{text}
					</StyledWrapper.TitleText>
					{isUnsaved && ' / '}
					{isUnsaved && <StyledWrapper.UnsavedText>unsaved</StyledWrapper.UnsavedText>}
					{!removeGrayLine && <StyledWrapper.GrayLine data-testid={'itemPageTitle-grayline'} />}
				</>
			) : (
				<>{!removeGrayLine && <StyledWrapper.GrayLine data-testid={'itemPageTitle-grayline'} />}</>
			)}
		</StyledWrapper.Container>
	);
}
