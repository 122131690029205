import { useState } from 'react';

import { CompareReviewColumns } from './compareReviewColumns';

import { Table } from '../../../../components/Table';
import usePaginatedCall from '../../../../hooks/usePaginatedCall';
import { useGetCompareReviewsQuery } from '../../../../services/endpoints/reviewEndpoints';

const CompareReviewsTable = ({ title }) => {
	const [columnWidth, setColumnWidth] = useState({});

	const initialColumns = CompareReviewColumns(columnWidth, title);

	const reviewerPoolPagination = usePaginatedCall(useGetCompareReviewsQuery, {
		filterBaseName: 'COMPARE_REVIEWS'
	});

	return (
		<>
			<Table
				style={{ margin: 'auto' }}
				enableAutoPageSize
				columns={initialColumns}
				rowHeight={true}
				onColumnResize={(e) => {
					const newColumnSizes = e?.colDef?.field;
					const width = e?.width;
					setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
				}}
				customHeight={'50vh'}
				disableSelectionOnClick
				getRowId={(cell) => cell?.form_instance_id}
				rows={reviewerPoolPagination?.rows?.results || []}
				rowCount={reviewerPoolPagination?.rowCount}
				pagination={reviewerPoolPagination?.pagination}
				pageSize={reviewerPoolPagination?.pageSize}
				paginationMode={reviewerPoolPagination?.paginationMode}
				onPageChange={(e) => reviewerPoolPagination?.onPageChange(e)}
				onPageSizeChange={(e) => reviewerPoolPagination?.onPageSizeChange(e)}
				page={reviewerPoolPagination?.page}
				loading={reviewerPoolPagination?.loading}
				sortingMode={reviewerPoolPagination?.sortingMode}
				onSortModelChange={(e) => reviewerPoolPagination?.onSortModelChange(e)}
				filterModel={reviewerPoolPagination?.filterModel}
				sortModel={reviewerPoolPagination?.sortModel}
			/>
		</>
	);
};

export default CompareReviewsTable;
