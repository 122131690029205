import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import StyledWrapper from './style';

import AssignReviewColumns from './AssignReviewColumns';
import { ItemPageTitle } from '../ItemPageTitle';
import { CustomModal } from '../Modal';
import { Table } from '../Table';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import {
	useBulkAssignReviewMutation,
	useGetAvailableReviewersQuery
} from '../../services/endpoints/reviewEndpoints';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import { getLocalAuth } from '../../utils/environmentUtils';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LoadingButton from '@mui/lab/LoadingButton';

export const BulkEvalAssignmentsModal = ({
	openModal,
	closeModal,
	item,
	chosenStages,
	handleAlert
}) => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const finalPagination = usePaginatedCall(useGetAvailableReviewersQuery, {
		filterBaseName: 'BULK_EVAL_ASSIGNMENTS'
	});
	const [bulkAssign] = useBulkAssignReviewMutation();

	const [open, setOpen] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [reviewersToAssign, setReviewersToAssign] = useState([]);
	const [reviewerPool, setReviewerPool] = useState([]);
	const selectedReviewersState = useRef();
	selectedReviewersState.current = reviewersToAssign;
	const reviewerPoolState = useRef();
	reviewerPoolState.current = reviewerPool;

	const handleReviewerSelect = (row) => {
		const foundIndex = selectedReviewersState.current?.findIndex(
			(e) => e?.reviewer_id === row?.reviewer_id
		);
		if (foundIndex >= 0) {
			// If the reviewer_id exists in reviewersToAssign, remove it and add it back to reviewerPool
			const updatedReviewers = selectedReviewersState.current.filter(
				(id) => id?.reviewer_id !== row?.reviewer_id
			);
			setReviewersToAssign(updatedReviewers);
			setReviewerPool([...reviewerPoolState.current, row]);
		} else {
			// If the reviewer_id doesn't exist in reviewersToAssign, add it and remove it from reviewerPool
			const updatedReviewers = [...selectedReviewersState.current, row];
			setReviewersToAssign(updatedReviewers);
			setReviewerPool(
				reviewerPoolState.current?.filter((id) => id?.reviewer_id !== row?.reviewer_id)
			);
		}
	};

	const AssignedColumns = AssignReviewColumns(
		openTooltip,
		setOpenTooltip,
		false,
		chosenStages,
		() => {},
		'Assigned',
		item?.appId,
		handleReviewerSelect,
		true
	);
	const ReviewPoolColumns = AssignReviewColumns(
		openTooltip,
		setOpenTooltip,
		false,
		chosenStages,
		() => {},
		'Reviewer Pool',
		item?.appId,
		handleReviewerSelect,
		true
	);

	const getPageTitle = () => {
		const pageTitle = (
			<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				<ReviewStageIcon />
				{!chosenStages ? (
					`Assignments of Evaluation Stage: ${item?.title} [${item?.appId}]`
				) : (
					<>
						{`Bulk assign the selected evaluation stage(s):`}{' '}
						{chosenStages?.map((e, index) => {
							return (
								<>
									{`[${e?.stageId}]`}
									{index !== chosenStages?.length - 1 && ' and '}
								</>
							);
						})}
					</>
				)}
			</div>
		);
		return pageTitle;
	};

	const handleClose = () => {
		closeModal && closeModal();
		setOpen(false);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const payload = {
			reviewers: reviewersToAssign?.map((e) => e?.reviewer_id),
			stages: chosenStages?.map((e) => e?.stageId?.replace('esi-', ''))
		};

		const options = {
			payload: payload,
			apiToken: apiToken
		};

		await bulkAssign(options)
			.unwrap()
			.then(async () => {
				handleAlert('Bulk Assigned Succesfully!', 'success');
			})
			.catch(() => handleAlert('Bulk Assigned Failed!', 'error'));

		setIsLoading(false);
		handleClose();
	};

	useEffect(() => {
		setOpen(openModal);
	}, [openModal]);

	useEffect(() => {
		if (finalPagination?.rows?.results) {
			setReviewerPool(finalPagination.rows.results);
		}
	}, [finalPagination?.rows?.results]);

	return (
		<>
			<CustomModal
				width={'100%'}
				height={'100%'}
				isOpen={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<StyledWrapper.OutermostContainer>
					<StyledWrapper.BackIconContainer>
						<IconButton
							onClick={handleClose}
							aria-label="delete"
							size="large"
							style={{ fontSize: '26px', background: '#E0E0E0' }}
						>
							<KeyboardBackspaceIcon style={{ fontSize: '26px' }} />
						</IconButton>
					</StyledWrapper.BackIconContainer>
					<ItemPageTitle removeGrayLine text={getPageTitle() || ''} />
					<StyledWrapper.InnerContainer padding={'0px 55px'} margin={'40px 0px 0px 0px'}>
						<ItemPageTitle text={chosenStages ? 'Assigned to' : 'Assigned'} />
						<Table
							disableSelectionOnClick
							hideFilters
							getRowId={(cell) => cell?.reviewer_id}
							columns={AssignedColumns}
							rowHeight={true}
							customHeight={'30vh'}
							enableAutoPageSize
							rows={reviewersToAssign || []}
						/>
						<ItemPageTitle text={'Reviewers Pool'} />
						<Table
							columns={ReviewPoolColumns}
							enableAutoPageSize
							rowHeight={true}
							hideFilters
							disableSelectionOnClick
							getRowId={(cell) => cell?.reviewer_id}
							customHeight={'30vh'}
							rows={finalPagination?.rows?.results || []}
							rowCount={finalPagination?.rowCount}
							pagination={finalPagination?.pagination}
							pageSize={finalPagination?.pageSize}
							paginationMode={finalPagination?.paginationMode}
							onPageChange={(e) => finalPagination?.onPageChange(e)}
							onPageSizeChange={(e) => finalPagination?.onPageSizeChange(e)}
							page={finalPagination?.page}
							loading={finalPagination?.loading}
						/>
					</StyledWrapper.InnerContainer>
				</StyledWrapper.OutermostContainer>

				<StyledWrapper.ButtonContainer>
					<Button onClick={handleClose} variant="outlined" size="small">
						CANCEL
					</Button>
					<LoadingButton
						loading={isLoading}
						disabled={isLoading || reviewersToAssign?.length === 0}
						onClick={handleSubmit}
						variant="contained"
						size="large"
					>
						Save
					</LoadingButton>
				</StyledWrapper.ButtonContainer>
			</CustomModal>
		</>
	);
};
