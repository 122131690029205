import React, { useRef, useState, useEffect } from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { getElementIcon, stringToHTML, useFileUtils } from '../utils';
import StyledWrapper from './style';

import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraIcon from '@mui/icons-material/Camera';

export const MUICamera = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;
	const { sendFileToServer, displayImage } = useFileUtils();

	function handleClick() {
		setLoading(true);
	}

	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [photoUrl, setPhotoUrl] = useState('');
	const [filePath, setFilePath] = useState(defaultValue);
	const [loading, setLoading] = React.useState(false);

	const initializeWebcam = async () => {
		setOpen(true);
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			if (videoRef.current) {
				videoRef.current.srcObject = stream;
				setOpen(true);
			}
		} catch (error) {
			console.error('Error accessing webcam:', error);
		}
	};

	const handleCapture = async () => {
		const video = videoRef.current;
		const canvas = canvasRef.current;

		if (video && canvas) {
			const context = canvas.getContext('2d');
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			context.drawImage(video, 0, 0, canvas.width, canvas.height);

			const photoDataUrl = canvas.toDataURL('image/jpeg');

			setLoading(true);

			try {
				const fileData = await fetch(photoDataUrl).then((response) => response.blob());

				const filePath = await sendFileToServer('captured-image.jpg', fileData);

				setFilePath(filePath);
				setLoading(false);
				handleClose();
			} catch (error) {
				console.error('Error:', error);
				setLoading(false);
			}
		}
	};

	const handleClickOpen = () => {
		initializeWebcam();
	};

	const handleClose = () => {
		setOpen(false);
		setPhotoUrl('');
		if (videoRef.current) {
			const stream = videoRef.current.srcObject;
			if (stream) {
				const tracks = stream.getTracks();
				tracks.forEach((track) => track.stop());
			}
		}
	};

	useEffect(async () => {
		if (filePath) {
			const triggerBlur = document.getElementById('hiddenBlurTriggerInput');
			triggerBlur?.click();

			// downloadFile(filePath)
			const response = await displayImage(filePath);

			// .then((data) => {

			// 	setPhotoUrl(data);
			// })
			// .catch((error) => {
			// 	console.error('Error:', error);
			// });
		}
	}, [filePath]);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			<div className="inner-form-group" name={name}>
				<StyledWrapper.CustomLabel
					value={defaultValue?.length > 0}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={disabled ? true : false}
				>
					{!filePath ? (
						<div>
							<LoadingButton
								onClick={handleClickOpen}
								disabled={disabled}
								variant="outlined"
								startIcon={<CameraAltIcon />}
							>
								{'Open Webcam'}
							</LoadingButton>
							<Dialog open={open} onClose={handleClose} fullWidth>
								<DialogContent>
									<video ref={videoRef} autoPlay playsInline muted />
									{videoRef.current && (
										<React.Fragment>
											<canvas ref={canvasRef} style={{ display: 'none' }} />
											<DialogActions>
												<LoadingButton
													onClick={handleCapture}
													disabled={disabled}
													variant="outlined"
													startIcon={<CameraIcon />}
												>
													{'Capture Photo'}
												</LoadingButton>
											</DialogActions>
										</React.Fragment>
									)}
								</DialogContent>
							</Dialog>
							{filePath && <img src={filePath} alt="Captured" />}
						</div>
					) : (
						<div style={{ display: 'flex', gap: '10px' }}>
							<img
								src={'blob:http://localhost:3000/38b9fc05-ce66-4dba-8b90-3f5a50ea9093'}
								alt="Captured"
							/>
							<LoadingButton
								hidden
								id={`${props?.data?.field_name}-download`}
								value={filePath}
								variant="contained"
								startIcon={<DownloadIcon />}
							>
								{'Download File'}
							</LoadingButton>
							{!disabled && (
								<IconButton aria-label="delete" color={'error'}>
									<CloseIcon />
								</IconButton>
							)}
						</div>
					)}
				</StyledWrapper.BorderOutline>
			</div>

			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});

MUICamera.displayName = 'MUI_Camera';
Registry.register('MUI_Camera', MUICamera);
