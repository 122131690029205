import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';

const NoApplicantProtection = ({ component: Component }) => {
	const userAccount = useSelector((state) => state.login?.user);
	const [hasAccess, setHasAccess] = useState('loading');

	useEffect(() => {
		if (userAccount?.userAccountTypeID) {
			setHasAccess(true);
		} else if (userAccount && !userAccount?.userAccountTypeID) {
			setHasAccess(false);
		} else {
			setHasAccess('loading');
		}
	}, [userAccount]);

	if (hasAccess === 'loading') {
		return <LoadingPage />;
	}

	if (hasAccess === true) {
		return Component ? <Component /> : null;
	}

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Alert variant="outlined" severity="error">
				Access Denied! - This user does not have access to the current page.
			</Alert>
		</div>
	);
};

export default NoApplicantProtection;
