import StyledWrapper from './style';

import { IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

export default function PrintActions(props) {
	return (
		<StyledWrapper.PrintActionsContainer
			width={props?.width}
			marginLeft={props?.marginLeft}
			marginTop={props?.marginTop}
			marginBottom={props?.marginBottom}
			data-testid={`programs-reviewToolbar-actionButtons`}
		>
			{/* <IconButton aria-label="email">
				<EmailIcon />
			</IconButton> */}

			{/* <IconButton aria-label="printPDF" onClick={() => window.print()}>
				<PictureAsPdfIcon />
			</IconButton>

			<IconButton aria-label="print" onClick={() => window.print()}>
				<LocalPrintshopIcon />
			</IconButton> */}
		</StyledWrapper.PrintActionsContainer>
	);
}
