import { useState, useEffect } from 'react';
import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';

interface BackDialogProps {
	title: string;
	isOpen: boolean;
	handleClose: () => void;
	handleStay: () => void;
}

export default function BackDialog({ title, isOpen, handleClose, handleStay }: BackDialogProps) {
	const [open, setOpen] = useState<boolean>(isOpen);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<>
			<StyledWrapper.CustomDialog
				disableEscapeKeyDown
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
					<WarningIcon color={'warning'} />
					You have unsaved changes!
				</StyledWrapper.CustomDialogTitle>
				<StyledWrapper.CustomDialogContent>
					<DialogContentText sx={{ fontWeight: '500' }}>
						{`You are trying to navigate away from this page`}
						<br />
						{`Are you sure?`}
					</DialogContentText>
				</StyledWrapper.CustomDialogContent>
				<DialogActions style={{ justifyContent: 'center' }}>
					<Button size="large" variant="outlined" color="warning" onClick={handleClose} autoFocus>
						YES - NAVIGATE AND LOSE CHANGES
					</Button>
					<Button size="large" variant="contained" autoFocus onClick={handleStay}>
						NO - STAY ON PAGE
					</Button>
				</DialogActions>
			</StyledWrapper.CustomDialog>
		</>
	);
}
