import styled from 'styled-components';

interface ToggleButtonProps {
	open?: boolean;
}

const ToggleButton = styled.button`
	background-color: white;
	position: absolute;
	top: 20px;
	right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	z-index: 1201;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	// border-top-right-radius: 50px;
	border-right: 0px;
	padding: 0px;
	width: 20px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	// transform: ${(p: ToggleButtonProps) => !p.open && 'rotate(180deg)'};
`;

const MainContainer = styled.div`
	position: relative;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	height: 100%;
`;

const SideBarChevron = styled.div`
	position: relative;
	width: 24px;
	height: 24px;
	margin-left: 5px;
	transform: ${(p: ToggleButtonProps) => !p.open && 'scaleX(-1)'};
`;

export default {
	MainContainer,
	ToggleButton,
	SideBarChevron
};
