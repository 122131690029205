import { getOldBlueColor } from '../../utils/utilFunctions';

export const InfoOutlineIcon = ({ isActive, width }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.16669 5.83464H10.8334V7.5013H9.16669V5.83464ZM9.16669 9.16797H10.8334V14.168H9.16669V9.16797ZM10 1.66797C5.40002 1.66797 1.66669 5.4013 1.66669 10.0013C1.66669 14.6013 5.40002 18.3346 10 18.3346C14.6 18.3346 18.3334 14.6013 18.3334 10.0013C18.3334 5.4013 14.6 1.66797 10 1.66797ZM10 16.668C6.32502 16.668 3.33335 13.6763 3.33335 10.0013C3.33335 6.3263 6.32502 3.33464 10 3.33464C13.675 3.33464 16.6667 6.3263 16.6667 10.0013C16.6667 13.6763 13.675 16.668 10 16.668Z"
				fill={isActive ? getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default InfoOutlineIcon;
