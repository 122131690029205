import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { saveVariable } from '../components/BaseLayout/slice';
import { DEFAULT_FILTER_BASE_NAME } from '../constants/Layout';

const useDefaultFilterChange = (filterBaseName) => {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.globalProgramVariables?.filters);

	const handleDefaultFilterChange = useCallback(
		(filterPayload) => {
			if (filters) {
				dispatch(
					saveVariable([
						'filters',
						{
							...filters,
							[filterBaseName ?? DEFAULT_FILTER_BASE_NAME]: {
								filterModel: [filterPayload]
							}
						}
					])
				);
			}
		},
		[filters, DEFAULT_FILTER_BASE_NAME]
	);

	return handleDefaultFilterChange;
};

export default useDefaultFilterChange;
