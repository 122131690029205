import styled from 'styled-components';

const Toolbar = styled.div`
	display: flex;
	gap: 30px;
	justify-content: flex-end;
`;

const CardRowInfo = styled.h6`
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	color: black;
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
	gap: 10px;
	margin: ${(props) => props?.rowHovered && '0px'};
`;

const FormHeader = styled.div`
	display: flex;
	margin: ${(props) => props?.margin || '0px'};
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 20px;

	& .MuiOutlinedInput-notchedOutline,
	.MuiFormLabel-root {
		border-color: ${(props) => !props?.readOnly && ' #ff9800 !important'};
		color: ${(props) => !props?.readOnly && ' #ff9800 !important'};
	}
`;

const LastEditedActions = styled.div`
	display: flex;
	gap: 15px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	& button {
		width: fit-content;
		min-width: fit-content;
	}
`;

const ActionButtonContainer = styled.div`
	display: flex;
	gap: 10px;
	margin-left: auto;
	align-items: center;
`;

const ShowDropdownContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;

	& .MuiOutlinedInput-notchedOutline,
	.MuiFormLabel-root {
		border-color: ${(props) => !props?.readOnly && 'grey!important'};
		color: ${(props) => !props?.readOnly && 'grey!important'};
	}
`;

export default {
	Toolbar,
	CardRowInfo,
	FormHeader,
	LastEditedActions,
	ShowDropdownContainer,
	ActionButtonContainer
};
