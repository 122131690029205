import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

export default function DateInputField({ currentFilter, setCurrentFilter }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				label={'value'}
				value={currentFilter?.value ?? null}
				onChange={(e) => {
					const selectedDate = new Date(e);

					const year = selectedDate.getFullYear();
					const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
					const day = selectedDate.getDate().toString().padStart(2, '0');
					const finalDate = `${year}-${month}-${day}`;

					setCurrentFilter({ ...currentFilter, value: finalDate });
				}}
				variant="standard"
				renderInput={(params) => (
					<TextField
						sx={{ fontSize: '14px', width: '100%' }}
						data-testid={`datepicker-textfield`}
						{...params}
						onChange={(event) => {
							const inputValue = event.target.value;

							if (!inputValue) {
								setCurrentFilter({ ...currentFilter, value: null });
							}
						}}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}
