export const TrashcanIcon = ({ isActive, width, color }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.00033 16.7708C5.00033 17.6875 5.75033 18.4375 6.66699 18.4375H13.3337C14.2503 18.4375 15.0003 17.6875 15.0003 16.7708V6.77083H5.00033V16.7708ZM7.64199 11.4292C7.31699 11.1042 7.31699 10.5792 7.64199 10.2542C7.96699 9.92917 8.49199 9.92917 8.81699 10.2542L10.0003 11.4292L11.1753 10.2542C11.5003 9.92917 12.0253 9.92917 12.3503 10.2542C12.6753 10.5792 12.6753 11.1042 12.3503 11.4292L11.1753 12.6042L12.3503 13.7792C12.6753 14.1042 12.6753 14.6292 12.3503 14.9542C12.0253 15.2792 11.5003 15.2792 11.1753 14.9542L10.0003 13.7792L8.82533 14.9542C8.50033 15.2792 7.97533 15.2792 7.65033 14.9542C7.32533 14.6292 7.32533 14.1042 7.65033 13.7792L8.82533 12.6042L7.64199 11.4292ZM15.0003 4.27083H12.917L12.3253 3.67917C12.1753 3.52917 11.9587 3.4375 11.742 3.4375H8.25866C8.04199 3.4375 7.82533 3.52917 7.67533 3.67917L7.08366 4.27083H5.00033C4.54199 4.27083 4.16699 4.64583 4.16699 5.10417C4.16699 5.5625 4.54199 5.9375 5.00033 5.9375H15.0003C15.4587 5.9375 15.8337 5.5625 15.8337 5.10417C15.8337 4.64583 15.4587 4.27083 15.0003 4.27083Z"
				fill={isActive ? color || '#D32F2F' : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default TrashcanIcon;
