import styled from 'styled-components';
import { Typography } from '@mui/material';

const CardRowContainer = styled.div`
	width: ${(props) => props?.width || '55%'};
	display: flex;
	align-items: center;
	color: black;
	cursor: pointer;
	max-width: 60ch;
	white-space: nowrap;
	gap: 4px;
	text-align: ${(props) => props?.centerText && 'center!important;'};
	// margin: 14px 0px;
	color: rgba(25, 118, 210, 1);
	}
`;

const CardRowTitle = styled(Typography)`
	text-decoration-color: rgba(25, 118, 210, 0.4) !important;
	color: rgba(25, 118, 210, 1);
	font-size: 14px;
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;

	:hover {
		text-decoration: underline;
	}
`;

const CardRowId = styled.span`
	color : ${(props) =>
		props.status ? 'rgba(0, 0, 0, 0.38)!important' : 'rgba(0, 0, 0, 0.6)!important'};
}

	`;

export default { CardRowContainer, CardRowId, CardRowTitle };
