import { useEffect, useState, useRef } from 'react';

import StyledWrapper from '../../pages/IndividualProgram/style';
import { checkOverflow } from '../../utils/utilFunctions';
import { formatTimestamp } from '../../utils/dateUtils';
import PersonIcon from '../../assets/customIcons/personIcon';
import AppProcessIcon from '../../assets/customIcons/applicantProcess';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import { getChipColor, getChipTextColor } from '../../utils/utilFunctions';
import ReviewFormIcon from '../../assets/customIcons/reviewForm';
import ApplicantProcess from '../../assets/customIcons/applicantProcess';
import ReviewColorStageIcon from '../../assets/customIcons/reviewColorStageIcon';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import TableCustomCheckbox from '../core/Checkbox/TableCustomCheckbox';

// openTooltip, setOpenTooltip
export const BulkRevToEvalColumns = (columnWidths, stateRef, expandRow) => {
	const [openTooltip, setOpenTooltip] = useState(0);
	const columns = [
		{
			field: 'submitter',
			headerName: 'Submitter',
			renderCell: (cellValues) => {
				return (
					<>
						<SimpleTooltip cellValues={cellValues} />
					</>
				);
			},
			flex: columnWidths.title || 1,
			resizable: true
		},
		{
			field: 'title',
			headerName: 'Application (Project Title)',
			renderCell: (cellValues) => {
				return (
					<>
						<TitleTooltip cellValues={cellValues} />
					</>
				);
			},
			flex: columnWidths.title || 1,
			resizable: true
		},
		{
			field: 'stageName',
			headerName: 'Evaluation Stage Name',
			renderCell: (cellValues) => {
				return (
					<>
						<StageTooltip cellValues={cellValues} />
					</>
				);
			},
			flex: !columnWidths.stageName && 1,
			resizable: true
		},
		{
			field: 'status',
			headerName: 'Stage Status',
			renderCell: (cellValues) => {
				return (
					<>
						<StatusTooltip cellValues={cellValues} />
					</>
				);
			},
			flex: !columnWidths.status && 1,
			maxWidth: 175
		},
		{
			field: 'totalReviewers',
			headerName: 'Number of Reviewers',
			renderCell: (cellValues) => {
				const isInt = Number.isInteger(cellValues?.formattedValue);
				return (
					<BasicCellText
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
					/>
				);
			},
			flex: columnWidths.reviewer || 1,
			maxWidth: 205,
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			resizable: true
		},
		{
			field: 'reviewers',
			headerName: 'Assigned Reviewers',
			renderCell: (cellValues) => {
				return (
					<>
						<SimpleTooltip cellValues={cellValues} />
					</>
				);
			},
			flex: columnWidths.reviewFormName || 1,
			resizable: true
		}
	];

	return columns;
};

const SimpleTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth > cardRowRef.current.clientWidth);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<StyledWrapper.CardRowInfo
				ref={cardRowRef}
				data-testid={`${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue}
			</StyledWrapper.CardRowInfo>
		</Tooltip>
	);
};

const StatusTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth < 130);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<Chip
				ref={cardRowRef}
				data-testid={`${cellValues?.field}-${cellValues?.id}`}
				label={cellValues?.formattedValue}
				sx={{
					backgroundColor: getChipColor(cellValues?.formattedValue),
					color: getChipTextColor(cellValues?.formattedValue)
				}}
				size="small"
			/>
		</Tooltip>
	);
};

const StageTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth > cardRowRef.current.clientWidth);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				<ReviewColorStageIcon
					isActive={cellValues.row.is_current_stage}
					customColor={getChipColor(cellValues.row.status)}
				/>
				<StyledWrapper.CardRowInfo ref={cardRowRef}>
					{cellValues?.formattedValue}
				</StyledWrapper.CardRowInfo>
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

const TitleTooltip = ({ cellValues }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowRef = useRef(null);
	useEffect(() => {
		if (cardRowRef.current) {
			setIsOverflowing(cardRowRef.current.scrollWidth > cardRowRef.current.clientWidth);
		}
	}, [cellValues]);

	return (
		<Tooltip arrow placement="top" title={isOverflowing ? `${cellValues?.formattedValue}` : ''}>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				<ApplicantProcess />
				<StyledWrapper.CardRowInfo ref={cardRowRef}>
					{cellValues?.formattedValue}
				</StyledWrapper.CardRowInfo>
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate,
	isActive,
	emptyCell,
	isBlueprint,
	isDisabled,
	isCurrent,
	isCurrentStage
}) => {
	const formattedText =
		isDate && cellValues?.formattedValue !== '--'
			? formatTimestamp(cellValues?.formattedValue)
			: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={''}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && (
					<StartIcon
						isCurrentStage={isCurrentStage}
						isCurrent={isCurrent}
						isActive={isActive}
						isBlueprint={isBlueprint}
					/>
				)}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						status={isDisabled}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : emptyCell ? '' : ''}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						status={isDisabled}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : emptyCell ? '' : ''}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
