import ScoreCard from '../../../components/Cards/ScoreCard/ScoreCard';
import CompletionStatusCard from '../components/CompletionStatusCard/CompletionStatusCard';
import { NotificationCard } from '../../../components/NotificationCard';
import { Instructions } from '../../../components/FormBuilder/customComponents/Instructions';
import CardCoreInfo from '../../../components/Cards/CardCoreInfo/CardCoreInfo';
import StyledWrapper from '../style';
import SubmissionStageTable from '../components/SubmissionStageTable/SubmissionStageTable';
import AppStagesTable from '../components/AppStagesTable/AppStagesTable';
import { useGetIndividualAppTable } from '../../../hooks/useGetIndividualAppTable';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const SubmissionStageOverview = ({ appId, subPage, handleAlert }) => {
	const { data, isLoading: isLoadingSetup } = useGetIndividualAppTable({ appId, subPage });
	const instructions = { content: data?.[0]?.instructions || '' };

	return (
		<>
			{isLoadingSetup ? (
				<>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<CircularProgress />
					</Box>
				</>
			) : (
				<StyledWrapper.OutermostContainer style={{ width: '100%', gap: '15px' }}>
					{/* <StyledWrapper.InnerContainer>
					<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
						<CardCoreInfo width={'100%'} data={coreInfo} height={'300px'} />
						<CompletionStatusCard
							width={'100%'}
							chartData={chart1Data}
							title={'Aggregated Completion Status 56%*'}
						/>
					</div>
					<div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
						<ScoreCard isStage />
						<NotificationCard
							data={MockData}
							title={'Stage Notifications'}
							height={'637px'}
							boxOverflowHidden={true}
						/>
					</div>
				</StyledWrapper.InnerContainer> */}

					<StyledWrapper.InnerContainer>
						<Instructions data={instructions || ''} hideLabel width={'100%'} />
					</StyledWrapper.InnerContainer>

					{/* <StyledWrapper.InnerContainer>
					<SubmissionStageTable />
				</StyledWrapper.InnerContainer> */}

					<AppStagesTable data={data} handleAlert={handleAlert} />
				</StyledWrapper.OutermostContainer>
			)}
		</>
	);
};

const TableMockData = [
	{
		hierarchy: ['d3f3d408-48e7-4e21-947c-642c1f4291b8'],
		type: 'Submission Stage',
		title: 'Submission Stage',
		appId: 'd3f3d408-48e7-4e21-947c-642c1f4291b8',
		businessLogic: null,
		instructions: 'none',
		startDate: '2023-05-08T17:25:46.107000Z',
		endDate: '2023-05-08T17:25:46.107000Z',
		id: 2,
		review_info: [],
		status: 'In Progress',
		completion: 'xx%'
	},
	{
		hierarchy: ['d3f3d408-48e7-4e21-947c-642c1f4291b8', '45580dc1-ed81-4d2d-9d8c-592fbd2b805a'],
		type: 'Application Form',
		title: 'App Form A.1.1',
		appId: '45580dc1-ed81-4d2d-9d8c-592fbd2b805a',
		businessLogic: null,
		order: 1,
		id: 3,
		stage_id: 'd3f3d408-48e7-4e21-947c-642c1f4291b8',
		status: 'In Progress',
		completion: 'xx%'
	}
];

const MockData = [
	{ title: 'Wilkinson, Bednar and Turcotte', date: '08:15AM  09/06/2022', seen: false },
	{ title: 'Moore-Waelchi', date: '08:11AM  08/10/2022', seen: true },
	{ title: 'Ferry-Dicki', date: '06:15AM  06/05/2022', seen: false },
	{ title: 'Ziemann, Hand and Mraz', date: '08:11AM  05/23/2022', seen: false },
	{ title: 'Legros, Kemmer and Cummings Incorporated', date: '04:11AM  07/15/2022', seen: false },
	{ title: 'Bergstrom and Sons', date: '05:32AM  11/14/2021', seen: true },
	{ title: 'Terry and Sons and Partner Offices', date: '08:00PM  12/05/2021', seen: false },
	{ title: 'Kiehn Inc', date: '02:14AM  12/31/2021', seen: false },
	{ title: 'Dach-Beahan', date: '07:02AM  01/15/2022', seen: true },
	{ title: 'Gorczany, Kuhic and Hand Offices', date: '03:15AM  04/13/2022', seen: true },
	{ title: 'Durgan Inc', date: '01:15PM  10/17/2021', seen: true },
	{ title: 'Olson Inc', date: '01:44AM  10/29/2021', seen: false },
	{ title: 'Stoltenberg Group', date: '02:24AM  09/28/2021', seen: false },
	{ title: 'Prohaska and Sons', date: '03:55PM  10/01/2021', seen: false },
	{ title: 'Kshlerin-Rempel', date: '11:15AM  02/05/2022', seen: false },
	{ title: 'Hartmann Liability Corporation Incorporated', date: '12:05AM  09/24/2022', seen: false }
];

const chart1Data = [
	{
		id: 'Optional Filled',
		value: 17,
		color: '#CFD8DC'
	},
	{
		id: 'Optional Unfilled',
		value: 16,
		color: '#CFD8DC',
		pattern: 'dots'
	},
	{
		id: 'Mandatory Unfilled',
		value: 15,
		color: '#1976D2',
		pattern: 'dots'
	},
	{
		id: 'Mandatory Filled',
		value: 23,
		color: '#1976D2'
	}
];

const coreInfo = [
	{
		title: 'Stage Status',
		value: 'In Progress'
	},
	{
		title: 'Stage Name',
		value: 'Evaluation Stage Pellentesque Dolor Si'
	},
	{
		title: 'Start/End Dates',
		start_date: '03/01/2022',
		end_date: '07/31/2022'
	},
	{
		title: 'Application Forms',
		value: '2 (1 assigned, 1 unassigned)',
		link: '/review'
	}
];

export default SubmissionStageOverview;
