import { useEffect, useState } from 'react';

import StyledWrapper from './style';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Tooltip from '@mui/material/Tooltip';
import BooleanInputField from './BooleanInputField';
import DateInputField from './DateInputField';
import StatusInputField from './StatusInputField';
import DefaultTextInputField from './DefaultTextInputField';
import NumericInputField from './NumericInputField';
import {
	regularFilters,
	statusFilters,
	dateFilters,
	booleanFilters,
	numberFilters,
	allStatuses,
	setValueAttributes,
	booleanFields,
	numericFields
} from './filterConfig';

export const FilterCard = ({
	column,
	order,
	apiRef,
	dragHandle,
	handleFilterChange,
	isVisible,
	activeValues = null
}) => {
	const getDefaultFilter = () => {
		let operatorValue;

		if (column.type === 'date') {
			operatorValue = 'is';
		} else if (booleanFields?.includes(column.field?.toLowerCase())) {
			operatorValue = 'equals';
		} else if (numericFields?.includes(column.field?.toLowerCase())) {
			operatorValue = '=';
		} else {
			operatorValue = 'contains';
		}
		return {
			columnField: column.field,
			operatorValue,
			id: column.field
		};
	};

	const [expanded, setExpanded] = useState(isVisible);
	const [currentFilter, setCurrentFilter] = useState(activeValues || getDefaultFilter());

	const toggleVisibility = (item, isExpanded) => {
		apiRef.current.setColumnVisibility(item, isExpanded);
	};

	const renderOperatorOptions = () => {
		const filterOptions =
			(column.field?.toLowerCase().includes('status') && statusFilters) ||
			(column.type === 'number' && numberFilters) ||
			(column.type === 'date' && dateFilters) ||
			(booleanFields?.includes(column.field?.toLowerCase()) && booleanFilters) ||
			regularFilters;

		return (
			<>
				<option value={' '} disabled>
					Select Option...
				</option>
				{filterOptions?.map((e, index) => (
					<option key={`${column.field}-option-${index}`} value={e?.value}>
						{e?.label}
					</option>
				))}
			</>
		);
	};

	const renderValueInput = () => {
		const isBooleanField = booleanFields?.includes(column.field?.toLowerCase());
		const isStatusField = column.field?.toLowerCase().includes('status');
		const isDateField = column.type === 'date';
		const isNumericField = numericFields?.includes(column.field?.toLowerCase());
		if (isBooleanField) {
			return (
				<BooleanInputField currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
			);
		} else if (isStatusField && currentFilter?.operatorValue === 'equals') {
			return <StatusInputField currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />;
		} else if (isDateField) {
			return <DateInputField currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />;
		} else if (isNumericField) {
			return (
				<NumericInputField currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
			);
		} else {
			return (
				<DefaultTextInputField currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
			);
		}
	};

	const handleOperatorChange = (selectedValue) => {
		if (setValueAttributes.includes(selectedValue)) {
			setCurrentFilter({
				...currentFilter,
				operatorValue: selectedValue,
				value: selectedValue
			});
		} else {
			setCurrentFilter((prev) => {
				let newValue = prev.value ?? '';

				if (selectedValue === 'equals') {
					if (column.field?.toLowerCase().includes('status')) {
						newValue =
							allStatuses.find((s) => s.value === prev.value)?.value || allStatuses[0].value;
					} else if (column.type === 'date') {
						newValue = prev.value && !isNaN(Date.parse(prev.value)) ? prev.value : null;
					}
				}

				return {
					...prev,
					operatorValue: selectedValue,
					value: newValue
				};
			});
		}
	};
	useEffect(() => {
		setCurrentFilter(activeValues || getDefaultFilter());
	}, [activeValues]);

	useEffect(() => {
		if (
			currentFilter.value !== undefined &&
			(currentFilter.value !== activeValues?.value ||
				currentFilter.operatorValue !== activeValues?.operatorValue)
		) {
			let shouldRemoveFilter = false;
			if (!setValueAttributes.includes(currentFilter.operatorValue)) {
				if (column.type === 'number' && isNaN(parseFloat(currentFilter.value)))
					shouldRemoveFilter = true;
				if (column.type === 'date' && currentFilter.value) {
					const dateValue = Date.parse(currentFilter.value);
					if (isNaN(dateValue)) {
						shouldRemoveFilter = true;
					}
				}
			}

			handleFilterChange(currentFilter, shouldRemoveFilter);
		}
	}, [currentFilter]);

	useEffect(() => {
		toggleVisibility(column?.field, expanded);
	}, [expanded]);

	return (
		<StyledWrapper.FilterCardContainer data-testid={'filter-card-container'}>
			<StyledWrapper.CustomAccordion expanded={expanded}>
				<AccordionSummary>
					<StyledWrapper.CardHeader active={!!activeValues}>
						<Tooltip arrow placement="top" title={`Drag UP or DOWN to change column order`}>
							<div {...dragHandle}>
								<DragIndicatorIcon className="dragIndicator" />
							</div>
						</Tooltip>
						<Tooltip
							arrow
							placement="top"
							title={`Turn Column Visibility ${expanded ? 'OFF' : 'ON'}`}
						>
							<IconButton
								aria-label="show or hide card"
								sx={{ background: 'white', width: '20px', height: '20px' }}
								onClick={() => setExpanded((originalExpanded) => !originalExpanded)}
							>
								{expanded ? (
									<VisibilityOffIcon color="primary" sx={{ width: '16px' }} />
								) : (
									<VisibilityIcon color="primary" sx={{ width: '16px' }} />
								)}
							</IconButton>
						</Tooltip>
						{column?.headerName}
						<Tooltip arrow placement="top" title={`Remove Filter`}>
							<IconButton
								aria-label="show or hide card"
								sx={{ background: 'white', width: '20px', height: '20px', marginLeft: 'auto' }}
								onClick={() => {
									setCurrentFilter({ ...currentFilter, value: null });
								}}
								disabled={!activeValues}
							>
								<FilterAltOffIcon color={!activeValues ? '' : 'primary'} sx={{ width: '16px' }} />
							</IconButton>
						</Tooltip>
					</StyledWrapper.CardHeader>
				</AccordionSummary>
				<AccordionDetails>
					<StyledWrapper.CardBody>
						<StyledWrapper.OrderIndicator active={!!activeValues}>
							{order + 1}
						</StyledWrapper.OrderIndicator>
						<StyledWrapper.CardLogicContainer>
							<FormControl fullWidth>
								<InputLabel variant="standard">Operator</InputLabel>

								<NativeSelect
									sx={{ fontSize: '14px' }}
									value={currentFilter.operatorValue ?? 'contains'}
									className="skipClearFilter"
									name={'operator'}
									inputProps={{
										name: 'operator'
									}}
									onChange={(e) => {
										handleOperatorChange(e.target.value);
									}}
								>
									{renderOperatorOptions()}
								</NativeSelect>
							</FormControl>
							{!setValueAttributes.includes(currentFilter.operatorValue) && renderValueInput()}
						</StyledWrapper.CardLogicContainer>
					</StyledWrapper.CardBody>
				</AccordionDetails>
			</StyledWrapper.CustomAccordion>
		</StyledWrapper.FilterCardContainer>
	);
};
