import styled from 'styled-components';

const LastEdited = styled.h6`
	margin: 0px;
`;

const LastEditedActions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
	margin-top: ${(props) => props?.marginTop};
	gap: 5px;
`;

export default {
	LastEdited,
	LastEditedActions
};
