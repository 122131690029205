import styled from 'styled-components';

const Container = styled.label({
	display: 'flex',
	alignItems: 'center',
	gap: '15px',
	padding: '16px',
	background: '#ED6C02',
	borderRadius: '4px',
	marginBottom: '30px'
});

const TextContainer = styled.label({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center'
});

const ErrorTitle = styled.p({
	fontWeight: 500,
	margin: '0px 0px 6px 0px',
	color: 'white'
});

const ErrorCode = styled.h6({
	margin: '0px',
	color: 'white'
});

export default {
	Container,
	TextContainer,
	ErrorTitle,
	ErrorCode
};
