// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { patternDotsDef } from '@nivo/core';
import StyledWrapper from './style';

const NestedPieChart = ({ outerData, innerData, testTitle, totalReviews }) => {
	return (
		<StyledWrapper.OuterContainer>
			<StyledWrapper.OuterPie className="outerPie">
				<ResponsivePie
					data-testid={`outerPie-${testTitle}`}
					data={outerData}
					margin={{ top: 70, right: 70, bottom: 70, left: 70 }}
					innerRadius={0.5}
					padAngle={0.7}
					// startAngle={88}
					cornerRadius={3}
					activeOuterRadiusOffset={8}
					borderWidth={0}
					colors={{ datum: 'data.color' }}
					enableArcLabels={false}
					arcLinkLabel={(d) =>
						!['Unassigned'].includes(d?.id) && (
							<StyledWrapper.PieChartLink
								//temp fix
								title={testTitle}
								// to={`/programs/Analog-Circuit-Design-manager/manage-reviews`}
								alt={'pieChart-link'}
							>
								{d?.id === 'Placeholder' ? 'Unassigned' : d?.id} ({d?.value})
							</StyledWrapper.PieChartLink>
						)
					}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsOffset={-24}
					arcLinkLabelsDiagonalLength={33}
					arcLinkLabelsStraightLength={13}
					arcLinkLabelsTextColor="#333333"
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]]
					}}
					legends={[]}
					onMouseEnter={() => {
						let allOuterPies = document.getElementsByClassName('outerPie');
						for (let i = 0; i < allOuterPies.length; i++) {
							allOuterPies[i].style.zIndex = 30;
						}
					}}
					onMouseLeave={() => {
						let allOuterPies = document.getElementsByClassName('outerPie');
						for (let i = 0; i < allOuterPies.length; i++) {
							allOuterPies[i].style.zIndex = 5;
						}
					}}
					tooltip={({ datum: { id, value, color } }) =>
						!['Unassigned', 'Placeholder'].includes(id) && (
							<StyledWrapper.tooltip>
								<StyledWrapper.TTColorBox color={color}></StyledWrapper.TTColorBox>
								{id}: <strong>{parseInt((value / totalReviews) * 100)}%</strong>
							</StyledWrapper.tooltip>
						)
					}
				/>
			</StyledWrapper.OuterPie>

			<StyledWrapper.InnerPie>
				<ResponsivePie
					data-testid={`innerPie-${testTitle}`}
					data={innerData}
					innerRadius={0}
					padAngle={0.7}
					cornerRadius={3}
					borderWidth={0}
					colors={{ datum: 'data.color' }}
					enableArcLabels={true}
					enableArcLinkLabels={false}
					arcLabelsRadiusOffset={0.5}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor="#333333"
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]]
					}}
					defs={[
						patternDotsDef('dots', {
							size: 3,
							padding: 5,
							stagger: true,
							background: '#ffffff',
							color: '#D9D9D9'
						})
					]}
					// 2. defining rules to apply those patterns
					fill={[{ match: (d) => d.id === 'Unassigned', id: 'dots' }]}
					legends={[]}
					tooltip={({ datum: { id, value, color } }) => (
						<StyledWrapper.tooltip>
							<StyledWrapper.TTColorBox color={color}></StyledWrapper.TTColorBox>
							{id}: <strong>{parseInt((value / totalReviews) * 100)}%</strong>
						</StyledWrapper.tooltip>
					)}
				/>
			</StyledWrapper.InnerPie>
		</StyledWrapper.OuterContainer>
	);
};

export default NestedPieChart;
