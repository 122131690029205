import PropTypes from 'prop-types';

import StyledWrapper from './style';

import Box from '@mui/material/Box';

export function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<StyledWrapper.TabPanel
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ width: '100%' }}>{children}</Box>}
		</StyledWrapper.TabPanel>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

export function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}
