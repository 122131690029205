import React from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';
import StyledWrapper from './style';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { getElementIcon } from '../utils';

export const MUICheckbox = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);

	return (
		<>
			<div className="inner-form-group" name={name}>
				<StyledWrapper.CustomLabel
					value={defaultValue?.length > 0}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={disabled ? true : false}
				>
					<FormGroup
					// required={props?.data?.required}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
							}}
						>
							{props?.data?.options.map((option) => {
								const this_key = `preview_${option.key}`;
								const checkboxProps = {};
								checkboxProps.name = `option_${option.key}`;

								checkboxProps.type = 'checkbox';
								checkboxProps.value = option.key;

								checkboxProps.defaultChecked =
									props.defaultValue !== undefined && props.defaultValue.indexOf(option.key) > -1;

								return (
									<div key="Checkbox">
										<FormControlLabel
											error={props?.data?.error}
											className="disableWhiteBackground"
											disabled={props?.disabled}
											control={
												<Checkbox
													id={`fid_${this_key}`}
													{...checkboxProps}
													disabled={props?.disabled}
													inputProps={{
														'data-value': option?.value
													}}
												/>
											}
											label={option?.text}
										/>
									</div>
								);
							})}
						</div>
					</FormGroup>
				</StyledWrapper.BorderOutline>
			</div>
			<FormHelperText error={props?.data?.error}>{props?.data?.help_message || ''}</FormHelperText>
		</>
	);
});

MUICheckbox.displayName = 'MUI_Checkbox';
Registry.register('MUI_Checkbox', MUICheckbox);
