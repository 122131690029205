export const mockRows = [
	{
		id: '1',
		status: 'Under Review',
		reviewId: 'RV-458906',
		score: 100,
		assignedDate: '11/01/2022',
		startDate: '04/31/2023',
		submittedDate: '11/01/2022',
		modifiedDate: '04/31/2023',
		reviewerName: 'Santana Clausse',
		applicationStatus: 'Denied',
		applicationId: 'app-452208',
		reviews: 2,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	},
	{
		id: '2',
		status: 'Draft',
		reviewId: 'RV-458906',
		score: 53,
		assignedDate: '11/22/2022',
		startDate: '12/10/2022',
		submittedDate: '11/22/2022',
		modifiedDate: '12/10/2022',
		reviewerName: 'Horace Earneston',
		applicationStatus: 'Under Review',
		applicationId: 'app-269001',
		reviews: 1,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	},
	{
		id: '3',
		status: 'Submitted',
		reviewId: 'RV-633251',
		score: 16,
		assignedDate: '11/20/2022',
		startDate: '12/05/2022',
		submittedDate: '11/20/2022',
		modifiedDate: '12/05/2022',
		reviewerName: 'Tamatoua Rava',
		applicationStatus: 'Recommended Award',
		applicationId: 'app-015036',
		reviews: 1,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	},
	{
		id: '4',
		status: 'Unassigned',
		reviewId: 'RV-000121',
		applicationStatus: 'Under Review',
		applicationId: 'app-269001',
		reviews: 2,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	},
	{
		id: '5',
		status: 'Unassigned',
		reviewId: 'RV-102857',
		applicationStatus: 'Under Review',
		applicationId: 'app-269001',
		reviews: 2,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	},
	{
		id: '6',
		status: 'Pending Commission',
		reviewId: 'RV-102857',
		score: 89,
		assignedDate: '11/20/2022',
		startDate: '12/05/2022',
		submittedDate: '11/20/2022',
		modifiedDate: '12/05/2022',
		reviewerName: 'Santana Clausse',
		applicationStatus: 'Under Review',
		applicationId: 'app-269001',
		reviews: 2,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	},
	{
		id: '7',
		status: 'Pending Commission',
		reviewId: 'RV-102857',
		score: 89,
		assignedDate: '11/20/2022',
		startDate: '12/05/2022',
		submittedDate: '11/20/2022',
		modifiedDate: '12/05/2022',
		reviewerName: 'Santana Clausse',
		applicationStatus: 'Awarded',
		applicationId: 'app-269001',
		reviews: 2,
		title: 'Cochise County 9-1-1 Department',
		submitter: 'Arizona Department of Defense'
	}
];

export const BulkAssignRows = [
	{
		id: '1',
		reviewer: 'Cindyana Maree',
		assignments: '18',
		notStarted: '15',
		inProgress: '20',
		submitted: '1589'
	},
	{
		id: '2',
		reviewer: 'Gregory Beck',
		assignments: '31',
		notStarted: '21',
		inProgress: '10',
		submitted: '485'
	},
	{
		id: '3',
		reviewer: 'Horace Earneston',
		assignments: '101',
		notStarted: '100',
		inProgress: '1',
		submitted: '58'
	},
	{
		id: '4',
		reviewer: 'Santana Clause',
		assignments: '4',
		notStarted: '4',
		inProgress: '0',
		submitted: '0'
	},
	{
		id: '5',
		reviewer: 'Tamatoua Rave',
		assignments: '23',
		notStarted: '1',
		inProgress: '22',
		submitted: '3852'
	},
	{
		id: '6',
		reviewer: 'Xander Bean',
		assignments: '17',
		notStarted: '2',
		inProgress: '15',
		submitted: '3999'
	},
	{
		id: '7',
		reviewer: 'And So On',
		assignments: '22',
		notStarted: '12',
		inProgress: '31',
		submitted: '441'
	},
	{
		id: '8',
		reviewer: 'And So On',
		assignments: '43',
		notStarted: '12',
		inProgress: '31',
		submitted: '321'
	},
	{
		id: '9',
		reviewer: 'And So On',
		assignments: '21',
		notStarted: '11',
		inProgress: '10',
		submitted: '520'
	},
	{
		id: '10',
		reviewer: 'And So On',
		assignments: '10',
		notStarted: '7',
		inProgress: '3',
		submitted: '0'
	}
];
