import styled from 'styled-components';

const OutermostContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const InnerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: no-wrap;
	gap: 15px;
	align-items: flex-start;
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export default {
	OutermostContainer,
	InnerContainer,
	MainContent
};
