import { useLocation } from 'react-router-dom';

import { MultiStepForm } from '../../components/MultiStepForm';

const MultiFormBuilder = () => {
	const location = useLocation();

	return (
		<div>
			<h4>Form Builder</h4>

			{location.pathname?.includes('form-creation') ? <MultiStepForm /> : ''}
		</div>
	);
};

export default MultiFormBuilder;
