import { useState } from 'react';
import { useSelector } from 'react-redux';

import { FormBuilder } from '../../components/FormBuilder';

const CreateTemplate = () => {
	const formDependancies = useSelector((state) => state.form.dependancies);
	// useStates
	const [schemaState, setSchemaState] = useState([]);

	return (
		<div>
			<h4>Create Template</h4>

			{schemaState && (
				<FormBuilder
					data={schemaState}
					formInfo={{ title: 'test', hasBusinessLogic: formDependancies?.length > 0 }}
					saveData={(schema) => {
						setSchemaState(schema);
					}}
					formId={0}
					enableAddOnClick
				/>
			)}
		</div>
	);
};

export default CreateTemplate;
