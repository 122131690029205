import styled from 'styled-components';
import Button from '@mui/material/Button';

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 30px;
	margin: 5px 0px;
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Title = styled.h6`
	margin: 0px;
	color: rgba(0, 0, 0, 0.87);
	max-width: 162px;
`;

const Message = styled.h6`
	margin: 0px;
	white-space: wrap;
	text-align: start;
	overflow: hidden;
	max-width: 30ch;
`;

const MessageContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 5px;
	max-width: 162px;
	@media (max-width: 500px) {
		flex-wrap: wrap;
	}
`;

const ItemButton = styled(Button)`
	display: flex !important;
	justify-content: flex-start !important;
	padding: 6px 16px !important;
	margin: 0px -16px 0px -16px !important;
	gap: 10px;
	width: 250px;
	text-transform: initial !important;
`;

export default {
	Container,
	InfoContainer,
	Title,
	MessageContainer,
	Message,
	ItemButton
};
