import StyledWrapper2 from '../../../IndividualProgram/style';
import { Box, Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { numberToMoney } from '../../../../utils/numberUtils';

export const GoalsAndMetricsColumns = (toggleRow) => {
	const columns = [
		{
			field: 'description',
			headerName: 'Goal',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper2.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={cellValues.row.isOpen}
					>
						{!cellValues.row.isOpen ? (
							<StyledWrapper2.UnexpandedText>{cellValues.value}</StyledWrapper2.UnexpandedText>
						) : (
							<Collapse in={!!cellValues.row.isOpen}>
								<Box>{cellValues.value}</Box>
							</Collapse>
						)}
					</StyledWrapper2.NotificationCellContainer>
				);
			},
			flex: 1
		},
		{
			field: 'details',
			headerName: '',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper2.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={cellValues.row.isOpen}
					>
						{!cellValues.row.isOpen ? (
							<></>
						) : (
							<Collapse in={!!cellValues.row.isOpen}>
								{cellValues?.value?.length > 0 && (
									<Box>
										<p style={{ marginBottom: '11px' }}>Subgoals:</p>
										{cellValues?.value?.map((e, index) => {
											return (
												<span key={index} style={{ marginLeft: '5px' }}>
													{`${index + 1}. ${e?.description}`}
													<br />
												</span>
											);
										})}
									</Box>
								)}
							</Collapse>
						)}
					</StyledWrapper2.NotificationCellContainer>
				);
			},
			flex: 3,
			disableColumnMenu: true,
			disableReorder: true,
			sortable: false,
			resizable: false
		},
		{
			field: 'targetAmount',
			headerName: 'Target',
			renderCell: (cellValues) => {
				cellValues.formattedValue = 0;

				if (typeof cellValues.value === 'string') {
					cellValues.formattedValue = cellValues.value.replaceAll(',', '');
				}

				cellValues.formattedValue = numberToMoney(parseInt(cellValues.formattedValue));

				return (
					<StyledWrapper2.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={cellValues.row.isOpen}
					>
						{!cellValues.row.isOpen ? (
							<StyledWrapper2.UnexpandedText>
								{cellValues.formattedValue}
							</StyledWrapper2.UnexpandedText>
						) : (
							<Collapse in={!!cellValues.row.isOpen}>
								<Box>{cellValues.formattedValue}</Box>
							</Collapse>
						)}
					</StyledWrapper2.NotificationCellContainer>
				);
			},
			flex: 1
		},
		{
			field: 'subgoalslength',
			headerName: 'Subgoals',
			renderCell: (cellValues) => {
				cellValues.formattedValue = 0;

				if (typeof cellValues.value === 'string') {
					cellValues.formattedValue = cellValues.value.replaceAll(',', '');
				}

				cellValues.formattedValue = numberToMoney(parseInt(cellValues.formattedValue));

				return (
					<StyledWrapper2.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={cellValues.row.isOpen}
					>
						{!cellValues.row.isOpen ? (
							<StyledWrapper2.UnexpandedText>
								{cellValues.formattedValue}
							</StyledWrapper2.UnexpandedText>
						) : (
							<Collapse in={!!cellValues.row.isOpen}>
								<Box>{cellValues.formattedValue}</Box>
							</Collapse>
						)}
					</StyledWrapper2.NotificationCellContainer>
				);
			},
			flex: 1
		},
		{
			field: 'dropdown',
			headerName: '',
			renderCell: (cellValues) => {
				return (
					<StyledWrapper2.NotificationCellContainer
						data-testid={`${cellValues?.field}-${cellValues?.id}`}
						isOpen={cellValues.row.isOpen}
					>
						<IconButton
							onClick={() => toggleRow(cellValues.id, null, cellValues.row.isOpen)}
							aria-label="fingerprint"
							color="success"
						>
							{cellValues.row.isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
						</IconButton>
					</StyledWrapper2.NotificationCellContainer>
				);
			}
		}
	];

	return columns;
};
