import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Tooltip from '@mui/material/Tooltip';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import StyledWrapper from '../../../IndividualProgram/style';

import {
	clearMetaInfo,
	setFormMetaInfo,
	setToReviewForms
} from '../../../../components/FormBuilderLibrary/slice';

import PersonLockIcon from '../../../../assets/customIcons/personLockIcon';
import InfoOutlineIcon from '../../../../assets/customIcons/infoOutlineIcon';
import { ButtonDropdown } from '../../../../components/core/ButtonDropdown/ButtonDropdown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { formatTimestamp } from '../../../../utils/dateUtils';
import { TableTitle } from '../../../../components/TableTitle/TableTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { usePermissions } from '../../../../hooks/usePermissions';

// openTooltip, setOpenTooltip
export const ProcessColumns = (columnWidths, openTooltip, setOpenTooltip) => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [finalColumns, setFinalColumns] = useState([]);
	const { hasEditPermissions } = usePermissions();
	const appProcessState = useRef();
	appProcessState.current = hasEditPermissions;

	useEffect(() => {
		appProcessState.current = hasEditPermissions;

		let columns = [
			{
				field: 'Entity',
				headerName: 'Entity',
				renderCell: (cellValues) => {
					const apiRef = useGridApiContext();

					const row = cellValues.api?.getRow(cellValues.id);
					const { status, uniqueIdentifier: displayId, title, appId, type } = row;
					const hasChildren = cellValues.rowNode.children?.length > 0;
					const hasParent = cellValues.rowNode.parent != null;
					const titleData = {
						displayId,
						title,
						status,
						entityType: type,
						hasParent,
						hasChildren,
						row_id: row.id,
						expanded: cellValues.rowNode.childrenExpanded,
						isBlueprint: true
					};
					const paddingLeft = (cellValues.row?.hierarchy?.length - 1) * 28;
					const tableStyle = { paddingLeft: paddingLeft };
					const handleExpand = (row_id, expanded) => {
						apiRef.current.setRowChildrenExpansion(row_id, !expanded);
					};
					const handleCardClick = () => {
						dispatch(setFormMetaInfo({ id: cellValues.formattedValue }));

						switch (type) {
							case 'Submission Stage':
								setSearchParams({
									...searchParams,
									stageCreation: 'application',
									stageId: appId
								});
								break;
							case 'APPLICATION PROCESS':
								setSearchParams({
									...searchParams,
									stageCreation: 'process',
									processId: appId
								});
								break;
							case 'Evaluation Stage':
								setSearchParams({
									...searchParams,
									stageCreation: 'evaluation',
									stageId: appId
								});
								break;
							case 'Application Form':
								setSearchParams({
									...searchParams,
									formBuilder: 'application',
									formId: appId
								});
								break;
							case 'Review Form':
								setSearchParams({
									...searchParams,
									formBuilder: 'review',
									formId: appId
								});
								break;
						}
					};
					const isSubmission = type?.toLowerCase().includes('submission stage');
					const isAppProcess = type?.toLowerCase().includes('application process');
					return (
						<>
							{isSubmission ? (
								<>
									<StyledWrapper.BlockIndicator>
										{row?.submissionStageCount}
										<KeyboardDoubleArrowDownIcon sx={{ height: '20px' }} />
									</StyledWrapper.BlockIndicator>
								</>
							) : (
								!isAppProcess && <StyledWrapper.BlockVerticalLine />
							)}
							<TableTitle
								titleData={titleData}
								handleCardClick={handleCardClick}
								style={tableStyle}
								handleExpand={handleExpand}
							/>
						</>
					);
				},
				minWidth: 350,
				flex: !columnWidths.__tree_data_group__ && 3
			},
			{
				field: 'type',
				headerName: 'Type',
				description: 'Type',
				renderCell: (cellValues) => {
					return (
						<BasicCellText
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
						/>
					);
				},
				minWidth: 200,
				flex: !columnWidths.title && 2
			},

			{
				field: 'businessLogic',
				headerName: 'BL',
				description: 'Business Logic',
				renderCell: (cellValues) => {
					return cellValues?.formattedValue ? (
						<Tooltip arrow placement="top" title={'Includes Business Logic'}>
							<SettingsSuggestIcon color="action" />
						</Tooltip>
					) : (
						'--'
					);
				},
				width: 70,
				resizable: false
			},
			{
				field: 'instructions',
				headerName: 'Instructions',
				description: 'Instructions',
				renderCell: (cellValues) => {
					return cellValues?.formattedValue && cellValues?.formattedValue?.length > 0 ? (
						<Tooltip arrow placement="top" title={'Includes Instructions'}>
							<InfoOutlineIcon width={'20px'} height={'20px'} />
						</Tooltip>
					) : (
						'--'
					);
				},
				width: 130,
				resizable: false
			},
			{
				field: 'startDate',
				headerName: 'Start',
				description: 'Start Date',
				renderCell: (cellValues) => {
					return (
						<BasicCellText
							isDate
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
						/>
					);
				},
				minWidth: 150,
				flex: !columnWidths.startDate && 1
			},
			{
				field: 'endDate',
				headerName: 'End',
				description: 'End Date',
				renderCell: (cellValues) => {
					return (
						<BasicCellText
							isDate
							cellValues={cellValues}
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
						/>
					);
				},
				minWidth: 150,
				flex: !columnWidths.endDate && 1
			},
			{
				field: 'action',
				headerName: hasEditPermissions ? 'Usage & Actions' : 'Usage',
				description: hasEditPermissions ? 'Usage & Actions' : 'Usage',
				renderCell: (cellValues) => {
					let hasSubmissionStage = false;
					let hasAppProcess = false;

					let last_stage = null;
					cellValues?.api?.getRowModels().forEach((value) => {
						if (value?.type === 'Submission Stage') {
							hasSubmissionStage = true;
						} else if (value?.type === 'APPLICATION PROCESS' && value?.appId) {
							hasAppProcess = true;
						}

						if (['Submission Stage', 'Evaluation Stage'].includes(value?.type)) {
							last_stage = value;
						}
					});

					const isCoreInfoForm = cellValues?.row?.purpose === 'Core Information';
					const formIsInUse = cellValues?.row?.in_use;
					const hasNextSubmissionStage = cellValues?.row?.hasSubsequentSubmissionStage;
					const isDisabled = formIsInUse || isCoreInfoForm || hasNextSubmissionStage;

					return (
						hasAppProcess && (
							<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
								{cellValues?.row?.in_use && (
									<Tooltip arrow placement="top" title={'This Form is in use'}>
										<PersonLockIcon isActive={true} />
									</Tooltip>
								)}

								{hasEditPermissions && (
									<Tooltip
										arrow
										placement="top"
										title={
											isCoreInfoForm
												? 'Core Information Form is mandatory and cannot be deleted.'
												: hasNextSubmissionStage
												? 'Block is closed, deletion of evaluation stage not permitted.'
												: 'Delete'
										}
									>
										<IconButton
											aria-label="delete"
											disabled={isDisabled}
											onClick={() => {
												setSearchParams({
													...searchParams,
													deleteModal: cellValues?.row?.appId,
													hasCoreInfo: cellValues?.row?.hasCoreInfo,
													type: cellValues?.row?.type?.includes('APPLICATION PROCESS')
														? 'process'
														: cellValues?.row?.type?.includes('Stage')
														? 'stage'
														: 'form'
												});
											}}
										>
											<DeleteForeverIcon color={isDisabled ? 'action' : 'error'} />
										</IconButton>
									</Tooltip>
								)}
								{hasEditPermissions && (
									<ActionButton
										type={cellValues?.row?.type}
										clickedRow={cellValues?.row}
										hasSubmissionStage={hasSubmissionStage}
										last_stage={last_stage}
									/>
								)}
							</div>
						)
					);
				},
				minWidth: 300,

				align: 'center'
			}
		];
		setFinalColumns(columns);
	}, [hasEditPermissions]);

	return finalColumns;
};

export const groupingColDef = (columnWidths) => {
	return {
		resizable: false,
		minWidth: 0,
		width: 0
	};
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<Tooltip arrow placement="top" title={cellValues?.formattedValue ? formattedText : '--'}>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : '--'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : '--'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};

export const ActionButton = ({ type, clickedRow, hasSubmissionStage, last_stage }) => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const last_stage_is_submission = last_stage?.type === 'Submission Stage';

	const rowType = type?.toLowerCase();
	const addStageButton = {
		title: 'ADD STAGE',
		id: 198567,
		tipPlacement: 'left',
		variant: 'contained',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		tipTitle: last_stage_is_submission
			? 'Evaluation Stage must be added to close block.'
			: 'In order to add a Evaluation Stage, you must first add a Submission Stage.',
		options: [
			{
				disabled: last_stage_is_submission,
				title: 'Submission Stage - From Scratch',
				onClick: () => {
					dispatch(clearMetaInfo());
					setSearchParams({ ...searchParams, stageCreation: 'application' });
				}
			},
			{
				disabled: last_stage_is_submission,
				title: 'Submission Stage - Using Template',
				individiualTooltip: 'Templates currently unavailable.',
				onClick: (e) => {
					setSearchParams({
						...searchParams,
						templatesModal: 'submission-stage',
						stageId: clickedRow?.appId
					});
				}
			},
			{
				disabled: !hasSubmissionStage,
				title: 'Evaluation Stage - From Scratch',
				onClick: () => {
					dispatch(clearMetaInfo());
					setSearchParams({ ...searchParams, stageCreation: 'evaluation' });
				}
			},
			{
				disabled: !hasSubmissionStage,
				title: 'Evaluation Stage - Using Template',
				individiualTooltip: 'Templates currently unavailable.',
				onClick: (e) => {
					setSearchParams({
						...searchParams,
						templatesModal: 'evaluation-stage',
						stageId: clickedRow?.appId
					});
				}
			}
		]
	};

	const addFormButton = {
		title: 'ADD Form',
		id: 198568,
		tipPlacement: 'left',
		variant: 'outlined',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		tipTitle: '',
		options:
			rowType === 'submission stage'
				? [
						{
							title: 'Application Form - From Scratch',
							onClick: () => {
								dispatch(
									setFormMetaInfo({
										stage_title: clickedRow?.title
									})
								);
								setSearchParams({
									...searchParams,
									formBuilder: 'application',
									formId: clickedRow?.form_id,
									stageId: clickedRow?.appId
								});
							}
						},
						{
							title: 'Application Form - Using Template',
							individiualTooltip: 'Templates currently unavailable.',
							onClick: (e) => {
								setSearchParams({
									...searchParams,
									templatesModal: 'application-form',
									stageId: clickedRow?.appId
								});
							}
						}
				  ]
				: [
						{
							title: 'Review Form - From Scratch',
							onClick: () => {
								dispatch(
									setFormMetaInfo({
										stage_title: clickedRow?.title
									})
								);
								dispatch(setToReviewForms(clickedRow?.review_info || []));
								setSearchParams({
									...searchParams,
									formBuilder: 'review',
									stageId: clickedRow?.appId
								});
							}
						},
						{
							title: 'Review Form - Using Template',
							onClick: (e) => {
								setSearchParams({
									...searchParams,
									templatesModal: 'review-form',
									stageId: clickedRow?.appId
								});
							}
						}
				  ]
	};

	return !rowType?.includes('form') ? (
		<ButtonDropdown data={rowType?.includes('stage') ? addFormButton : addStageButton} />
	) : (
		<ButtonDropdown data={addFormButton} hidden />
	);
};
