import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { getElementIcon } from '../utils';

export const MUIPhoneNumber = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			<TextField
				error={props?.data?.error}
				required={props?.data?.required}
				id="outlined-error-helper-text"
				name={name}
				inputRef={ref}
				disabled={disabled}
				defaultValue={defaultValue}
				style={{ width: '100%' }}
				label={
					<>
						{isFormBuilder && getElementIcon(props.data?.key)}
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						{isFormBuilder && (
							<div>
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</div>
						)}
					</>
				}
				type="string"
				InputLabelProps={{ shrink: isFormBuilder || undefined }}
			/>
			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});
MUIPhoneNumber.displayName = 'MUI_PhoneNumber';
Registry.register('MUI_PhoneNumber', MUIPhoneNumber);
