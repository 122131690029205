import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import StyledWrapper from '../IndividualProgram/style';
import { checkOverflow } from '../../utils/utilFunctions';
import AppProcessIcon from '../../assets/customIcons/applicantProcess.jsx';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewForm';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import AppFormIcon from '../../assets/customIcons/appFormIcon';
import PersonLockIcon from '../../assets/customIcons/personLockIcon';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Tooltip from '@mui/material/Tooltip';
import { GRID_REORDER_COL_DEF } from '@mui/x-data-grid-premium';
import IconButton from '@mui/material/IconButton';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InfoOutlineIcon from '../../assets/customIcons/infoOutlineIcon';
import { getEntityPrefix } from '../../utils/utilFunctions';
import { formatTimestamp } from '../../utils/dateUtils';

// openTooltip, setOpenTooltip
export const StageColumns = (columnWidths, isAppProcess) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [openTooltip, setOpenTooltip] = useState(0);

	const columns = [
		{
			field: 'order',
			headerName: '#',
			headerAlign: 'center',
			align: 'center',
			renderCell: (cellValues) => {
				return cellValues?.row?.id;
			},
			width: 70,
			resizable: false
		},

		{
			field: 'form_type',
			headerName: 'Type',
			description: 'Type',
			renderCell: (cellValues) => {
				const title = cellValues?.formattedValue?.toLowerCase();
				const currentCell = {
					...cellValues,
					formattedValue: title?.includes('application')
						? 'Application Form'
						: title?.includes('review form')
						? 'Review Form'
						: cellValues?.formattedValue
				};

				return (
					<BasicCellText
						cellValues={currentCell}
						StartIcon={
							title?.includes('application')
								? AppFormIcon
								: title?.includes('review form')
								? ReviewFormIcon
								: title?.includes('submission stage')
								? SubmissionStageIcon
								: ReviewStageIcon
						}
					/>
				);
			},
			flex: !columnWidths.title && 1
		},

		{
			field: 'label',
			headerName: 'Name',
			description: 'Name',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: !columnWidths.title && 1
		},
		{
			field: 'unique_identifier',
			headerName: 'Entity ID',
			description: 'Entity ID',
			renderCell: (cellValues) => {
				const FormPrefix = getEntityPrefix({
					type:
						cellValues?.row?.form_type === 'application' ? 'application-form' : 'application-form',
					isMaters: true
				});
				cellValues.formattedValue = isAppProcess
					? cellValues?.row?.uniqueIdentifier
					: `${FormPrefix}${cellValues.formattedValue}`;
				return (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			flex: !columnWidths.appId && 1
		},
		{
			field: 'businessLogic',
			headerName: 'BL',
			description: 'Business Logic',
			renderCell: (cellValues) => {
				return cellValues?.formattedValue ? <SettingsSuggestIcon color="action" /> : '--';
			},
			width: 70,
			resizable: false
		},
		{
			field: 'instructions',
			headerName: 'Instructions',
			description: 'Instructions',
			renderCell: (cellValues) => {
				return cellValues?.formattedValue && cellValues?.formattedValue?.length > 0 ? (
					<InfoOutlineIcon width={'20px'} height={'20px'} />
				) : (
					'--'
				);
			},
			width: 130,
			resizable: false
		},
		{
			field: 'startDate',
			headerName: 'Start',
			description: 'Start',
			renderCell: (cellValues) => {
				return cellValues?.formattedValue ? (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDate
					/>
				) : (
					'n/a'
				);
			},
			flex: !columnWidths.startDate && 1
		},
		{
			field: 'endDate',
			headerName: 'End',
			description: 'End',
			renderCell: (cellValues) => {
				return cellValues?.formattedValue ? (
					<BasicCellText
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						isDate
					/>
				) : (
					'n/a'
				);
			},
			flex: !columnWidths.endDate && 1
		},
		{
			field: 'action',
			headerName: 'Usage & Actions',
			description: 'Usage & Actions',
			renderCell: (cellValues) => {
				const in_use = cellValues?.row?.in_use;
				const isDisabled = in_use || cellValues?.row?.hasSubsequentSubmissionStage;
				return (
					<div
						style={{
							display: 'flex',
							gap: '5px',
							alignItems: 'center'
						}}
					>
						{in_use && <PersonLockIcon isActive={true} />}
						<IconButton
							aria-label="delete"
							disabled={isDisabled}
							onClick={() => {
								searchParams.set('deleteModal', cellValues?.row?.form_id);
								searchParams.set('type', 'form');
								setSearchParams(searchParams);
							}}
						>
							<DeleteForeverIcon color={isDisabled ? 'action' : 'error'} />
						</IconButton>
					</div>
				);
			},
			disableColumnMenu: true,
			disableReorder: true,
			sortable: false,
			resizable: false,
			width: 130,
			headerAlign: 'right',
			align: 'right'
		},
		{
			...GRID_REORDER_COL_DEF,
			width: 40
		}
	];

	return columns;
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate
}) => {
	const formattedText = isDate
		? formatTimestamp(cellValues?.formattedValue)
		: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : 'n/a'}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && <StartIcon />}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : 'n/a'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : 'n/a'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
