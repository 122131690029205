import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';
export const SubmissionStageIcon = ({
	isActive,
	width,
	isBlueprint,
	isTemplate,
	isWarning,
	isArchived,
	isCurrentStage,
	customColor,
	status
}) => {
	const color = getIconColor(status);

	return isBlueprint ? (
		<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22.4317C17.7614 22.4317 22.4319 17.7612 22.4319 11.9998C22.4319 6.2384 17.7614 1.56787 12 1.56787C6.23864 1.56787 1.56812 6.2384 1.56812 11.9998C1.56812 17.7612 6.23864 22.4317 12 22.4317ZM10.8746 6.37751C10.5889 6.09188 10.2056 5.93404 9.80722 5.93404H5.92116C5.09434 5.93404 4.42536 6.61053 4.42536 7.43735L4.41785 16.4572C4.41785 17.284 5.09434 17.9605 5.92116 17.9605H17.9476C18.7745 17.9605 19.451 17.284 19.451 16.4572V8.94066C19.451 8.11384 18.7745 7.43735 17.9476 7.43735H11.9344L10.8746 6.37751ZM13.4053 8.45509C13.2446 8.28371 13.029 8.189 12.8049 8.189H9.77339C9.30831 8.189 8.92778 8.5949 8.92778 9.09099V16.3069C8.92778 16.803 9.30408 17.2089 9.76916 17.2089H14.8471C15.3122 17.2089 15.6927 16.803 15.6927 16.3069V11.2693C15.6927 11.0303 15.6039 10.8003 15.4432 10.6334L13.4053 8.45509ZM12.733 8.86549V10.895C12.733 11.143 12.9233 11.346 13.1558 11.346H15.0585L12.733 8.86549ZM13.5786 13.6009H11.0418C10.8093 13.6009 10.619 13.398 10.619 13.1499C10.619 12.9019 10.8093 12.6989 11.0418 12.6989H13.5786C13.8112 12.6989 14.0015 12.9019 14.0015 13.1499C14.0015 13.398 13.8112 13.6009 13.5786 13.6009ZM13.5786 15.4049H11.0418C10.8093 15.4049 10.619 15.2019 10.619 14.9539C10.619 14.7059 10.8093 14.5029 11.0418 14.5029H13.5786C13.8112 14.5029 14.0015 14.7059 14.0015 14.9539C14.0015 15.2019 13.8112 15.4049 13.5786 15.4049Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	) : isTemplate ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9983 22.9947C17.7597 22.9947 22.4302 18.3242 22.4302 12.5628C22.4302 6.80139 17.7597 2.13086 11.9983 2.13086C6.23693 2.13086 1.56641 6.80139 1.56641 12.5628C1.56641 18.3242 6.23693 22.9947 11.9983 22.9947ZM10.8729 6.9405C10.5872 6.65487 10.2039 6.49702 9.80551 6.49702H5.91945C5.09263 6.49702 4.42365 7.17351 4.42365 8.00034L4.41614 17.0202C4.41614 17.847 5.09263 18.5235 5.91945 18.5235H17.9459C18.7728 18.5235 19.4492 17.847 19.4492 17.0202V9.50365C19.4492 8.67683 18.7728 8.00034 17.9459 8.00034H11.9327L10.8729 6.9405ZM13.4036 9.01808C13.2429 8.8467 13.0273 8.75199 12.8032 8.75199H9.77168C9.3066 8.75199 8.92607 9.15789 8.92607 9.65398V16.8699C8.92607 17.366 9.30237 17.7719 9.76746 17.7719H14.8454C15.3104 17.7719 15.691 17.366 15.691 16.8699V11.8323C15.691 11.5932 15.6022 11.3632 15.4415 11.1964L13.4036 9.01808ZM12.7313 9.42848V11.458C12.7313 11.706 12.9216 11.9089 13.1541 11.9089H15.0568L12.7313 9.42848ZM13.5769 14.1639H11.0401C10.8076 14.1639 10.6173 13.961 10.6173 13.7129C10.6173 13.4649 10.8076 13.2619 11.0401 13.2619H13.5769C13.8095 13.2619 13.9997 13.4649 13.9997 13.7129C13.9997 13.961 13.8095 14.1639 13.5769 14.1639ZM13.5769 15.9679H11.0401C10.8076 15.9679 10.6173 15.7649 10.6173 15.5169C10.6173 15.2688 10.8076 15.0659 11.0401 15.0659H13.5769C13.8095 15.0659 13.9997 15.2688 13.9997 15.5169C13.9997 15.7649 13.8095 15.9679 13.5769 15.9679Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: isWarning
						? '#ED6C02'
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M0.317037 2.12839C0.077019 1.36669 0.782692 0.644831 1.54964 0.867514L3.95423 1.56569C4.94153 1.85235 4.9056 3.26327 3.90499 3.49931L3.77741 3.52941C3.40068 3.61828 3.10907 3.91658 3.02876 4.29523L3.00157 4.42345C2.78828 5.42915 1.37853 5.49707 1.06956 4.51653L0.317037 2.12839Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: isWarning
						? '#ED6C02'
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M0.430318 23.0171C0.1903 23.7788 0.895974 24.5007 1.66292 24.278L4.06751 23.5798C5.05481 23.2932 5.01888 21.8822 4.01828 21.6462L3.89069 21.6161C3.51396 21.5272 3.22235 21.2289 3.14204 20.8503L3.11485 20.7221C2.90156 19.7164 1.49182 19.6484 1.18284 20.629L0.430318 23.0171Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: isWarning
						? '#ED6C02'
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M23.6869 2.10789C23.9269 1.34619 23.2212 0.624323 22.4543 0.847006L20.0497 1.54518C19.0624 1.83184 19.0983 3.24276 20.0989 3.4788L20.2265 3.5089C20.6032 3.59777 20.8948 3.89607 20.9751 4.27472L21.0023 4.40294C21.2156 5.40864 22.6254 5.47656 22.9343 4.49602L23.6869 2.10789Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: isWarning
						? '#ED6C02'
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M23.6869 23.0171C23.9269 23.7788 23.2212 24.5007 22.4543 24.278L20.0497 23.5798C19.0624 23.2932 19.0983 21.8822 20.0989 21.6462L20.2265 21.6161C20.6032 21.5272 20.8948 21.2289 20.9751 20.8503L21.0023 20.7221C21.2156 19.7164 22.6254 19.6484 22.9343 20.629L23.6869 23.0171Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: isWarning
						? '#ED6C02'
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
		</svg>
	) : isCurrentStage ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.17 6C7.7 6 8.21 6.21 8.59 6.59L10 8H18C19.1 8 20 8.9 20 10V20C20 21.1 19.1 22 18 22H2C0.9 22 0 21.1 0 20L0.00999999 8C0.00999999 6.9 0.9 6 2 6H7.17ZM11.1581 9C11.4563 9 11.7431 9.126 11.9569 9.354L14.6681 12.252C14.8819 12.474 15 12.78 15 13.098V19.8C15 20.46 14.4938 21 13.875 21H7.11937C6.50062 21 6 20.46 6 19.8V10.2C6 9.54 6.50625 9 7.125 9H11.1581ZM8.8125 18.6H12.1875C12.4969 18.6 12.75 18.33 12.75 18C12.75 17.67 12.4969 17.4 12.1875 17.4H8.8125C8.50312 17.4 8.25 17.67 8.25 18C8.25 18.33 8.50312 18.6 8.8125 18.6ZM8.8125 16.2H12.1875C12.4969 16.2 12.75 15.93 12.75 15.6C12.75 15.27 12.4969 15 12.1875 15H8.8125C8.50312 15 8.25 15.27 8.25 15.6C8.25 15.93 8.50312 16.2 8.8125 16.2ZM11.0625 9.9V12.6C11.0625 12.93 11.3156 13.2 11.625 13.2H14.1562L11.0625 9.9Z"
				fill={customColor || getOldBlueColor()}
			/>
			<circle cx="18" cy="6" r="6" fill="white" />
			<path
				d="M17.9992 8.35442L20.0742 9.714C20.4542 9.96316 20.9192 9.59483 20.8192 9.129L20.2692 6.57233L22.1042 4.84983C22.4392 4.53567 22.2592 3.93983 21.8192 3.90192L19.4042 3.67983L18.4592 1.264C18.2892 0.825249 17.7092 0.825249 17.5392 1.264L16.5942 3.67442L14.1792 3.8965C13.7392 3.93442 13.5592 4.53025 13.8942 4.84442L15.7292 6.56692L15.1792 9.12358C15.0792 9.58941 15.5442 9.95775 15.9242 9.70858L17.9992 8.35442Z"
				fill={customColor ? '#F57F17' : '#F9A825'}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.17 4C9.7 4 10.21 4.21 10.59 4.59L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17ZM13.1581 7C13.4563 7 13.7431 7.126 13.9569 7.354L16.6681 10.252C16.8819 10.474 17 10.78 17 11.098V17.8C17 18.46 16.4938 19 15.875 19H9.11937C8.50062 19 8 18.46 8 17.8V8.2C8 7.54 8.50625 7 9.125 7H13.1581ZM10.8125 16.6H14.1875C14.4969 16.6 14.75 16.33 14.75 16C14.75 15.67 14.4969 15.4 14.1875 15.4H10.8125C10.5031 15.4 10.25 15.67 10.25 16C10.25 16.33 10.5031 16.6 10.8125 16.6ZM10.8125 14.2H14.1875C14.4969 14.2 14.75 13.93 14.75 13.6C14.75 13.27 14.4969 13 14.1875 13H10.8125C10.5031 13 10.25 13.27 10.25 13.6C10.25 13.93 10.5031 14.2 10.8125 14.2ZM13.0625 7.9V10.6C13.0625 10.93 13.3156 11.2 13.625 11.2H16.1562L13.0625 7.9Z"
				fill={isActive ? customColor || getOldBlueColor() : color}
			/>
		</svg>
	);
};

export default SubmissionStageIcon;
