import styled from 'styled-components';

const LastEdited = styled.h6`
	margin: 0px;
	margin-bottom: ${(props) => props?.marginBottom};
`;

export default {
	LastEdited
};
