import React from 'react';

import { getChipColor, removeHttpVariations } from '../../../utils/utilFunctions';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import StyledWrapper from './style';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import LaunchIcon from '@mui/icons-material/Launch';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { getElementIcon } from '../utils';

export const MUILinkInput = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;

	function areAllEmptyStrings(array) {
		return array?.every((entry) => entry === '');
	}

	const allEmpty = defaultValue ? areAllEmptyStrings(defaultValue) : true;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			{disabled ? (
				<FormControl sx={{ width: '100%' }} variant="outlined">
					<StyledWrapper.CustomLabel
						error={props?.data?.error}
						value={defaultValue && !allEmpty}
						disabled={disabled ? true : false}
						required={props?.data?.required}
						htmlFor="outlined-adornment-password"
						shrink={isFormBuilder || undefined}
					>
						{isFormBuilder && getElementIcon(props.data?.key)}
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						{isFormBuilder && (
							<div>
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</div>
						)}
					</StyledWrapper.CustomLabel>
					<StyledWrapper.OutlinedInputReadOnly
						id="outlined-adornment-password"
						type={'text'}
						disabled={disabled}
						style={{ width: '100%', color: 'red!important' }}
						inputComponent={() => {
							return defaultValue?.[0] ? (
								<CustomInputLink
									hideLinkIcon={props?.data?.custom_options?.includes('hideIcon')}
									chip={props?.data?.chip}
									text={
										!props?.data?.custom_options?.includes('editOnlyLink')
											? defaultValue?.[0]
											: defaultValue[1]
									}
									link={defaultValue?.[1]}
								/>
							) : (
								<TextField required={props?.data?.required} isLink disabled />
							);
						}}
						value={defaultValue?.[0] || ''}
						label={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					/>
				</FormControl>
			) : (
				<div style={{ display: 'flex', gap: '10px' }}>
					{!props?.data?.custom_options?.includes('editOnlyLink') && (
						<TextField
							error={props?.data?.error}
							required={props?.data?.required}
							id="outlined-error-helper-text"
							name={`${name}-title`}
							inputRef={ref}
							defaultValue={defaultValue?.[0] || ''}
							style={{ width: '100%' }}
							label={
								<>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<div>
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</div>
									)}
								</>
							}
							multiline
							InputLabelProps={{ shrink: isFormBuilder || undefined }}
						/>
					)}

					<TextField
						error={props?.data?.error}
						required={props?.data?.required}
						id="outlined-error-helper-text"
						name={`${name}-link`}
						inputRef={ref}
						data-testvalue={'test'}
						defaultValue={defaultValue?.[1] || ''}
						style={{ width: '100%' }}
						label={
							<>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{`${stringToHTML(props?.data?.second_label || props?.data?.label)} ${
									!props?.data?.second_label ? '(External Link)' : ''
								}`}
								{isFormBuilder && (
									<div>
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</div>
								)}
							</>
						}
						multiline
						InputLabelProps={{ shrink: isFormBuilder || undefined }}
					/>
				</div>
			)}

			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});

MUILinkInput.displayName = 'MUI_LinkInput';
Registry.register('MUI_LinkInput', MUILinkInput);

const CustomInputLink = React.forwardRef((props, _ref) => {
	const { text, link, chip, hideLinkIcon } = props;
	const url = link ? `https://${removeHttpVariations(link)}` : '';
	return (
		<div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '16.5px 14px' }}>
			{chip && (
				<Chip
					id={'programDetails-statusChip'}
					data-testid={`programDetails-statusChip`}
					label={chip}
					sx={{
						backgroundColor: getChipColor(chip),
						color: chip === 'Unassigned' ? 'black' : 'white!important',
						WebkitTextFillColor: chip === 'Unassigned' ? 'black' : 'white!important'
					}}
					size="small"
				/>
			)}
			<a
				onClick={() => {
					link?.[0] === '/' ? (window.location.href = link) : window.open(url);
				}}
			>
				{text}
			</a>

			{text && (
				<IconButton
					aria-label="toggle password visibility"
					onClick={() => {
						link?.[0] === '/' ? (window.location.href = link) : window.open(url);
					}}
					edge="end"
					style={{ padding: '0px' }}
				>
					{!hideLinkIcon && <LaunchIcon />}
				</IconButton>
			)}
		</div>
	);
});

CustomInputLink.displayName = 'CustomInputLink';
