import Tooltip from '@mui/material/Tooltip';

export default function HelpIcon({ style, id, hint, iconStyle }) {
	return (
		<Tooltip
			title={hint || 'Temp Help Info'}
			placement="top"
			arrow
			data-testid={`helpIcon-tooltip-${id}`}
		>
			<svg
				data-testid={`helpIcon`}
				role="image"
				style={{
					...style,
					position: style?.position || 'absolute',
					right: style?.right || '-22px'
				}}
				className="label-help-icon"
				width="18"
				height="17"
				viewBox="0 0 18 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8.16663 4.33342H9.83329V6.00008H8.16663V4.33342ZM8.16663 7.66675H9.83329V12.6667H8.16663V7.66675ZM8.99996 0.166748C4.39996 0.166748 0.666626 3.90008 0.666626 8.50008C0.666626 13.1001 4.39996 16.8334 8.99996 16.8334C13.6 16.8334 17.3333 13.1001 17.3333 8.50008C17.3333 3.90008 13.6 0.166748 8.99996 0.166748ZM8.99996 15.1667C5.32496 15.1667 2.33329 12.1751 2.33329 8.50008C2.33329 4.82508 5.32496 1.83341 8.99996 1.83341C12.675 1.83341 15.6666 4.82508 15.6666 8.50008C15.6666 12.1751 12.675 15.1667 8.99996 15.1667Z"
					fill={iconStyle?.fill || 'black'}
					fillOpacity={iconStyle?.opacity || '0.54'}
				/>
			</svg>
		</Tooltip>
	);
}
