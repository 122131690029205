import styled from 'styled-components';
import { Link } from 'react-router-dom';

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-items: center;
	gap: 26px;

	@media (max-width: 768px) {
		justify-content: flex-start;
		width: fit-content;
	}
`;

const CardTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 0px 0px 14px 0px;
`;

const CardTitle = styled(Link)`
	font-weight: 500;
`;

const CardTitleNoLink = styled.p`
	font-weight: 500;
	margin: 0px;
`;

const NothingToShow = styled.h6`
	text-align: center;
`;

export default {
	OverviewContainer,
	CardTitleContainer,
	CardTitle,
	CardTitleNoLink,
	NothingToShow
};
