import { GPM } from '../gpmAPI';

const stagesApi = GPM.injectEndpoints({
	tagTypes: ['Stages', 'IndividualForms', 'Reviewers'],
	endpoints: (builder) => ({
		getAllStages: builder.query({
			query: (props) => ({
				url: `form/stage/program/${props.programID}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		getIndividualStage: builder.query({
			query: (props) => ({
				url: `form/stage/${props.stageID}/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		createStage: builder.mutation({
			query: (props) => ({
				url: `form/stage/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Stages']
			})
		}),
		updateStage: builder.mutation({
			query: (props) => ({
				url: `form/stage/${props.stageID}/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Stages', 'IndividualForms', 'Reviewers']
			}),
			invalidatesTags: ['Stages', 'IndividualForms', 'Reviewers']
		}),
		deleteForm: builder.mutation({
			query: (options) => ({
				url: `form/forms/${options?.id}/`,
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages', 'Reviewers']
		}),
		deleteStage: builder.mutation({
			query: (options) => ({
				url: `form/stage/${options?.id}/`,
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				},
			}),
			invalidatesTags: ['IndividualForms', 'Stages', 'Reviewers']
		})
	}),
	overrideExisting: false
});

export const {
	useGetAllStagesQuery,
	useGetIndividualStageQuery,
	useCreateStageMutation,
	useDeleteFormMutation,
	useUpdateStageMutation,
	useDeleteStageMutation
} = stagesApi;
