import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeft';
import MuiDrawer from '@mui/material/Drawer';

import StyledWrapper from './style';

import { LegalFooter } from '../LegalFooter';
import { drawerWidth } from '../../constants/Layout';
import ListItem from '../ListItem';
import { PrimaryLinks } from '../../constants/routes';

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		backgroundColor: 'rgba(144, 202, 249, 0.08)',
		position: 'relative',
		whiteSpace: 'nowrap',
		border: '0px',
		width: drawerWidth,
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		boxSizing: 'border-box',
		...(!open && {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9)
			}
		})
	}
}));

export default function SideNav({ open, toggleDrawer }) {
	return (
		<StyledWrapper.MainContainer data-testid={`sidenav-container`}>
			<StyledWrapper.ToggleButton
				open={open}
				data-testid={`sidenav-toggle-button`}
				onClick={toggleDrawer}
				style={{
					marginTop: '10px'
				}}
			>
				<StyledWrapper.SideBarChevron open={open}>
					<ChevronLeftRoundedIcon data-testid={`sidenav-toggle-icon`} />
				</StyledWrapper.SideBarChevron>
			</StyledWrapper.ToggleButton>
			<Drawer
				data-testid={`sidenav-drawer`}
				variant="permanent"
				open={open}
				style={{
					height: 'calc(100vh - 74px)'
				}}
			>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						px: [1]
					}}
				></Toolbar>
				<List data-testid={`sidenav-list-container`} component="nav">
					{PrimaryLinks.map((item, index) => (
						<ListItem index={index} key={`${item?.title}-listItem`} item={item} />
					))}
				</List>
				<LegalFooter data-testid={`sidenav-legal-footer`} open={open} />
			</Drawer>
		</StyledWrapper.MainContainer>
	);
}
