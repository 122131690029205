import { useLocation } from 'react-router-dom';

import StyledWrapper from './style';

import ProgramDetails from './subPages/ProgramDetails/ProgramDetails';
import TeamsAndRoles from './subPages/TeamsAndRoles/TeamsAndRoles';
import Budget from './subPages/Budget/Budget';
import GoalsAndMetrics from './subPages/GoalsAndMetrics/GoalsAndMetrics';
import RolesAndPermissions from './subPages/RolesAndPermissions/RolesAndPermissions';

import { ItemPageTitle } from '../../components/ItemPageTitle';
import { VerticalNavigation } from '../../components/VerticalNavigation';
import { testTabs } from './mockData';
import ApplicationProcess from './subPages/StagesAndForms/ApplicationProcess';

const ProgramSetup = () => {
	const location = useLocation();
	const pathArray = location?.pathname?.split('/');
	const subPage = pathArray[pathArray.length - 1];

	const chosenView = () => {
		const formattedSubPage = subPage?.replaceAll('%20', ' ');
		switch (formattedSubPage) {
			case 'program details':
				return <ProgramDetails />;
			case 'application':
				return <ApplicationProcess />;
			// case 'team & roles':
			// 	return <TeamsAndRoles />;
			// case 'roles permissions':
			// 	return <RolesAndPermissions />;
			case 'budget':
				return <Budget />;
			case 'goals & metrics':
				return <GoalsAndMetrics />;
			default:
				return <></>;
		}
	};

	return (
		<StyledWrapper.OutermostContainer>
			<ItemPageTitle />
			<StyledWrapper.InnerContainer>
				<VerticalNavigation customInnerWidth={'180px'} data={testTabs} />
				<StyledWrapper.MainContent>{chosenView()}</StyledWrapper.MainContent>
			</StyledWrapper.InnerContainer>
		</StyledWrapper.OutermostContainer>
	);
};

export default ProgramSetup;
