// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ResponsivePie } from '@nivo/pie';
import { patternDotsDef } from '@nivo/core';
import useDefaultFilterChange from '../../../hooks/useFilterChange';
import { getOldBlueColor } from '../../../utils/utilFunctions';

import StyledWrapper from '../NestedPieChart/style';

const PieChart = ({
	data,
	testTitle,
	innerRadius,
	sameColorLabels,
	noLinkedLabels,
	customMargin,
	arcLinkLabelsStraightLength
}) => {
	const [total] = useState(data.reduce((a, b) => a + b.value, 0));
	const finalInnerRadius = innerRadius >= 0 && innerRadius <= 1 ? innerRadius : 0.5;
	const { programID } = useParams();
	const handleDefaultFilterChange = useDefaultFilterChange('PROGRAM_APPLICATIONS');

	return (
		<ResponsivePie
			data-testid={`piechart-${testTitle}`}
			data={data}
			margin={customMargin || { top: 70, right: 70, bottom: 70, left: 90 }}
			innerRadius={finalInnerRadius}
			padAngle={0.7}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={0}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 0.2]]
			}}
			defs={[
				patternDotsDef('dots', {
					size: 3,
					padding: 5,
					stagger: true,
					background: '#ffffff',
					color: '#D9D9D9'
				}),
				patternDotsDef('dots2', {
					size: 3,
					padding: 5,
					stagger: true,
					background: '#ffffff',
					color: `${getOldBlueColor()}`
				})
			]}
			// 2. defining rules to apply those patterns
			fill={[
				{ match: (d) => d.id === 'Unassigned', id: 'dots' },
				{ match: (d) => d.id === 'Optional Unfilled', id: 'dots' },
				{ match: (d) => d.id === 'Mandatory Unfilled', id: 'dots2' }
			]}
			colors={{ datum: 'data.color' }}
			enableArcLabels={false}
			arcLinkLabel={(d) =>
				noLinkedLabels ? (
					<StyledWrapper.PieChartLabel>
						{d?.id} ({d?.value})
					</StyledWrapper.PieChartLabel>
				) : (
					<StyledWrapper.PieChartLink
						onClick={() => {
							handleDefaultFilterChange({
								columnField: 'status',
								operatorValue: 'contains',
								value: d?.id
							});
						}}
						to={`/programs/${programID}/applications`}
						state={{
							filtering: [
								{
									columnField: 'status',
									operatorValue: 'contains',
									value: d?.id
								}
							]
						}}
						alt={'pieChart-link'}
					>
						{d?.id} ({d?.value})
					</StyledWrapper.PieChartLink>
				)
			}
			arcLinkLabelsSkipAngle={10}
			arcLinkLabelsTextColor="#333333"
			arcLinkLabelsThickness={2}
			arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
			arcLinkLabelsColor={sameColorLabels || { from: 'color' }}
			arcLabelsSkipAngle={10}
			arcLabelsTextColor={{
				from: 'color',
				modifiers: [['darker', 2]]
			}}
			legends={[]}
			tooltip={({ datum: { id, value, color, data } }) => (
				<StyledWrapper.tooltip>
					<StyledWrapper.TTColorBox
						color={color}
						pattern={data?.pattern}
					></StyledWrapper.TTColorBox>
					{id}: <strong>{parseInt((value / total) * 100)}%</strong>
				</StyledWrapper.tooltip>
			)}
		/>
	);
};

export default PieChart;
