import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';
export const ReviewFormIcon = ({ isActive, width, isTemplate, isWarning, customColor, status }) => {
	const color = getIconColor(status);
	return isTemplate ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9983 22.9947C17.7597 22.9947 22.4302 18.3242 22.4302 12.5628C22.4302 6.80139 17.7597 2.13086 11.9983 2.13086C6.23693 2.13086 1.56641 6.80139 1.56641 12.5628C1.56641 18.3242 6.23693 22.9947 11.9983 22.9947ZM14.5487 4.70979C14.2479 4.40896 13.8441 4.24271 13.4246 4.24271H7.7483C6.87747 4.24271 6.16497 4.95521 6.16497 5.82604V13.3681C7.72683 13.2055 9.71882 13.7799 11.5356 15.053C13.5741 16.4815 14.8612 18.4177 15.0539 20.076H17.2483C18.1191 20.076 18.8316 19.3635 18.8316 18.4927V9.64979C18.8316 9.23021 18.6654 8.82646 18.3646 8.53354L14.5487 4.70979ZM13.29 8.99271V5.43021L17.6441 9.78437H14.0816C13.6462 9.78437 13.29 9.42812 13.29 8.99271ZM7.90838 18.079C7.90838 17.4858 8.37484 17.0069 8.95269 17.0069C9.53053 17.0069 9.99699 17.4858 9.99699 18.079C9.99699 18.6723 9.53053 19.1512 8.95269 19.1512C8.37484 19.1512 7.90838 18.6723 7.90838 18.079ZM8.95269 15.3986C7.21218 15.3986 5.72578 16.5101 5.12357 18.079C5.72578 19.648 7.21218 20.7594 8.95269 20.7594C10.6932 20.7594 12.1796 19.648 12.7818 18.079C12.1796 16.5101 10.6932 15.3986 8.95269 15.3986ZM8.95269 19.866C7.99192 19.866 7.21218 19.0654 7.21218 18.079C7.21218 17.0927 7.99192 16.2921 8.95269 16.2921C9.91345 16.2921 10.6932 17.0927 10.6932 18.079C10.6932 19.0654 9.91345 19.866 8.95269 19.866Z"
				fill={
					isWarning
						? '#ED6C02'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M0.317037 2.12839C0.077019 1.36669 0.782692 0.644831 1.54964 0.867514L3.95423 1.56569C4.94153 1.85235 4.9056 3.26327 3.90499 3.49931L3.77741 3.52941C3.40068 3.61828 3.10907 3.91658 3.02876 4.29523L3.00157 4.42345C2.78828 5.42915 1.37853 5.49707 1.06956 4.51653L0.317037 2.12839Z"
				fill={
					isWarning
						? '#ED6C02'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M0.430318 23.0171C0.1903 23.7788 0.895974 24.5007 1.66292 24.278L4.06751 23.5798C5.05481 23.2932 5.01888 21.8822 4.01828 21.6462L3.89069 21.6161C3.51396 21.5272 3.22235 21.2289 3.14204 20.8503L3.11485 20.7221C2.90156 19.7164 1.49182 19.6484 1.18284 20.629L0.430318 23.0171Z"
				fill={
					isWarning
						? '#ED6C02'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M23.6869 2.10789C23.9269 1.34619 23.2212 0.624323 22.4543 0.847006L20.0497 1.54518C19.0624 1.83184 19.0983 3.24276 20.0989 3.4788L20.2265 3.5089C20.6032 3.59777 20.8948 3.89607 20.9751 4.27472L21.0023 4.40294C21.2156 5.40864 22.6254 5.47656 22.9343 4.49602L23.6869 2.10789Z"
				fill={
					isWarning
						? '#ED6C02'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M23.6869 23.0171C23.9269 23.7788 23.2212 24.5007 22.4543 24.278L20.0497 23.5798C19.0624 23.2932 19.0983 21.8822 20.0989 21.6462L20.2265 21.6161C20.6032 21.5272 20.8948 21.2289 20.9751 20.8503L21.0023 20.7221C21.2156 19.7164 22.6254 19.6484 22.9343 20.629L23.6869 23.0171Z"
				fill={
					isWarning
						? '#ED6C02'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5504 4.98796C14.2496 4.68712 13.8459 4.52087 13.4263 4.52087H7.75002C6.87919 4.52087 6.16669 5.23337 6.16669 6.10421V13.6462C7.72854 13.4837 9.72054 14.0581 11.5373 15.3312C13.5759 16.7597 14.8629 18.6958 15.0556 20.3542H17.25C18.1209 20.3542 18.8334 19.6417 18.8334 18.7709V9.92796C18.8334 9.50837 18.6671 9.10462 18.3663 8.81171L14.5504 4.98796ZM13.2917 9.27087V5.70837L17.6459 10.0625H14.0834C13.6479 10.0625 13.2917 9.70629 13.2917 9.27087ZM2.5 18.9375C3.28636 16.8889 5.22727 15.4375 7.5 15.4375C9.77273 15.4375 11.7136 16.8889 12.5 18.9375C11.7136 20.9862 9.77273 22.4375 7.5 22.4375C5.22727 22.4375 3.28636 20.9862 2.5 18.9375ZM5.22727 18.9375C5.22727 20.2255 6.24545 21.2709 7.5 21.2709C8.75455 21.2709 9.77273 20.2255 9.77273 18.9375C9.77273 17.6495 8.75455 16.6042 7.5 16.6042C6.24545 16.6042 5.22727 17.6495 5.22727 18.9375ZM6.13636 18.9375C6.13636 18.1629 6.74545 17.5375 7.5 17.5375C8.25455 17.5375 8.86364 18.1629 8.86364 18.9375C8.86364 19.7122 8.25455 20.3375 7.5 20.3375C6.74545 20.3375 6.13636 19.7122 6.13636 18.9375Z"
				fill={isActive ? customColor || getOldBlueColor() : color}
			/>
		</svg>
	);
};

export default ReviewFormIcon;
