import { useRef, useEffect, forwardRef } from 'react';
import { Registry } from '../../FormBuilderLibrary';

import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import StyledWrapper from './style';

export const Instructions = forwardRef((props, _ref) => {
	const detailsContainer = useRef(null);
	const { data, width, maxWidth, name } = props;
	const instructionDetails = data?.content;

	useEffect(() => {
		detailsContainer &&
			instructionDetails &&
			(detailsContainer.current.innerHTML = instructionDetails);
	}, [data, detailsContainer]);

	return (
		<>
			<StyledWrapper.CustomAccordion width={width} maxWidth={maxWidth} name={name}>
				<StyledWrapper.CustomAccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					data-testid="appForm-coreInfo"
				>
					<ErrorOutlineIcon color={'action'} />
					<StyledWrapper.DetailTitle margin={'19px 0px'}>Instructions</StyledWrapper.DetailTitle>
				</StyledWrapper.CustomAccordionSummary>
				<AccordionDetails ref={detailsContainer}></AccordionDetails>
			</StyledWrapper.CustomAccordion>
		</>
	);
});

Instructions.displayName = 'Instructions';
Registry.register('Instructions', Instructions);
