import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { generateUUID, getEntityPrefix } from '../utils/utilFunctions';
import {
	useGetAllEvalStagesTemplatesQuery,
	useGetAllProcessTemplatesQuery,
	useGetAllSubmissionStagesTemplatesQuery
} from '../services/endpoints/templatesEndpoints';
import usePaginatedCall from './usePaginatedCall';

export const useTemplates = (overallFilter) => {
	const [searchParams] = useSearchParams();

	const [rowData, setRowData] = useState([]);
	const [loading, setLoading] = useState(false);

	const currentPath = window.location.pathname;
	const isApplications = currentPath.includes('templates/applications');
	const isSubmissionStage = currentPath.includes('templates/submission-stages');
	const isEvalStage = currentPath.includes('templates/evaluation-stages');
	const queryHook = isEvalStage
		? useGetAllEvalStagesTemplatesQuery
		: isSubmissionStage
		? useGetAllSubmissionStagesTemplatesQuery
		: useGetAllProcessTemplatesQuery;

	const baseName = isEvalStage
		? 'EVAL_TEMPLATES'
		: isSubmissionStage
		? 'SUBMISSION_TEMPLATES'
		: 'APPLICATION_PROCESS_TEMPLATES';

	const pagination = usePaginatedCall(queryHook, {
		overallFilter: overallFilter,
		filterBaseName: baseName
	});

	const formatAppProcess = (process) => {
		const ApplicationPrefix = getEntityPrefix({
			type: 'application-process',
			isBaseTemplate: true
		});

		return {
			hierarchy: [process?.process_id],
			type: 'Application Process',
			id: generateUUID(),
			table_id: `${ApplicationPrefix}${process?.unique_identifier}`,
			title: process?.process_name,
			published: process?.created_on,
			description: process?.description,
			updated: process?.last_updated_on,
			times_shared: process?.times_shared,
			process_id: process?.process_id,
			is_archived: process?.is_archive,
			isDeletable: true,
			stageBlockClosed: process?.stages[process?.stages.length - 1]?.stage_type === 'evaluation',
			hasStages: process?.stages?.length > 0
		};
	};

	const formatStage = (stage, process) => {
		const stageType = stage?.stage_type === 'application' ? 'submission-stage' : 'evaluation-stage';
		const StagePrefix = getEntityPrefix({ type: stageType, isBaseTemplate: true });

		const isDeletable = process ? false : true;

		const hierarchy = [];
		process && hierarchy.push(process?.process_id);
		hierarchy.push(stage?.stage_id);

		const baseFormCount = stage?.forms?.filter((e) => !e?.is_adhoc)?.length;
		const adHocFormCount = stage?.forms?.filter((e) => e?.is_adhoc)?.length;

		return {
			hierarchy: hierarchy,
			type: stage?.stage_type === 'application' ? 'Submission Stage' : 'Evaluation Stage',
			table_id: `${StagePrefix}${stage?.unique_identifier}`,
			id: generateUUID(),
			title: stage?.stage_name,
			description: stage?.description,
			stage_id: stage?.stage_id,
			published: stage?.created_on,
			updated: stage?.last_updated_on,
			times_shared: stage?.times_shared,
			process_id: process?.process_id,
			is_archived: process?.is_archive || stage?.is_archive,
			isDeletable: isDeletable,
			base_form_count: baseFormCount,
			adhoc_form_count: adHocFormCount,
			linked_count: stage?.linked_count
		};
	};

	const formatForm = (form, stage, process) => {
		const formType = form?.form_type === 'application' ? 'application-form' : 'review-form';
		const FormPrefix = getEntityPrefix({
			type: formType,
			isBaseTemplate: true,
			isAdHoc: form?.is_adhoc
		});

		const isDeletable = form?.isAdHoc ? true : stage ? false : true;

		const hierarchy = [];
		process && hierarchy.push(process?.process_id);
		stage && hierarchy.push(stage?.stage_id);
		hierarchy.push(form?.form_id);

		return {
			hierarchy: hierarchy,
			type: form?.form_type === 'application' ? 'Application Form' : 'Review Form',
			id: generateUUID(),
			table_id: `${FormPrefix}${form?.unique_identifier}`,
			title: form?.label,
			published: form?.created_on,
			description: form?.description,
			updated: form?.last_updated_on,
			times_shared: form?.times_shared,
			form_id: form?.form_id,
			stage_id: stage?.stage_id,
			process_id: process?.process_id,
			is_archived: stage?.is_archive || process?.is_archived,
			isDeletable: isDeletable,
			is_adhoc: form?.is_adhoc
		};
	};

	useEffect(() => {
		setLoading(true);
		const finalData = pagination?.error?.status === 404 ? {} : pagination?.rows?.results;
		let copiedRowData = [];

		if (isApplications) {
			finalData?.forEach((process) => {
				copiedRowData?.push(formatAppProcess(process));

				//ADD Stages
				process?.stages?.forEach((stage) => {
					copiedRowData?.push(formatStage(stage, process));
				});
			});
		}

		if (isSubmissionStage || isEvalStage) {
			finalData?.forEach((stage) => {
				copiedRowData?.push(formatStage(stage));

				// ADD Forms
				stage?.forms?.forEach((form) => {
					copiedRowData?.push(formatForm(form, stage));
				});
			});
		}
		setRowData(copiedRowData);
		setLoading(false);
	}, [pagination?.rows, pagination?.error]);

	useEffect(() => {
		pagination?.refreshCall({ force: true, skip: false });
	}, [searchParams]);

	return {
		data: rowData,
		isLoading: loading,
		refresh: pagination?.refreshCall,
		pagination
	};
};
