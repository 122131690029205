import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import StyledWrapper from '../../../IndividualProgram/style';
import { checkOverflow } from '../../../../utils/utilFunctions';
import { formatTimestamp } from '../../../../utils/dateUtils';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '../../../../assets/customIcons/personIcon';
import { getEntityPrefix } from '../../../../utils/utilFunctions';
import { TableTitle } from '../../../../components/TableTitle/TableTitle';
// openTooltip, setOpenTooltip
export const CompareReviewColumns = (
	columnWidths,
	title,
	allRows,
	setAssignedReviewModal,
	setReassignReviewModal
) => {
	const [openTooltip, setOpenTooltip] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentPath = window.location.pathname;
	const navigate = useNavigate();

	const columns = [
		{
			field: 'reviewer',
			headerName: 'Reviewer',
			description: 'Reviewer',

			renderCell: (cellValues) => {
				const name = `${cellValues?.formattedValue?.first_name} ${cellValues?.formattedValue?.last_name}`;
				return (
					<BasicCellText
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={{ formattedValue: name }}
						StartIcon={PersonIcon}
						isActive
					/>
				);
			},

			flex: columnWidths.reviewer || 2,
			minWidth: 100,
			resizable: true
		},
		{
			field: 'reviewId',
			headerName: 'Review',
			description: 'Review ID',
			renderCell: (cellValues) => {
				const formID = cellValues.row?.unique_identifier;
				const status = cellValues.row?.status;
				const displayId = `${getEntityPrefix({
					type: 'review-form',
					isInstance: true
				})}${formID}`;
				const titleData = {
					displayId,
					title,
					status,
					entityType: 'Review Form'
				};
				const handleCardClick = () => {
					const newPath = currentPath.replace('compare-reviews', displayId);
					navigate(newPath);
				};
				return <TableTitle titleData={titleData} handleCardClick={handleCardClick} />;
			},

			flex: columnWidths.reviewId || 1,
			minWidth: 100,
			resizable: true
		},
		{
			field: 'completion',
			headerName: 'Completion',
			description: 'Completion',
			renderCell: (cellValues) => {
				const percentage = `${cellValues?.formattedValue}${
					cellValues?.formattedValue?.toString() && '%'
				}`;
				return (
					<BasicCellText
						isDisabled={
							cellValues?.row?.status === 'Submitted' || cellValues?.row?.status === 'Completed'
						}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={{ formattedValue: percentage }}
						disableIcon={true}
					/>
				);
			},

			flex: columnWidths.completion || 1,
			resizable: true
		},
		{
			field: 'score',
			headerName: 'Score',
			description: 'Score',
			renderCell: (cellValues) => {
				const score = `${cellValues?.formattedValue?.toString() || '0/0'}`;
				return (
					<BasicCellText
						isDisabled={cellValues?.row?.status === 'Submitted'}
						cellValues={{ formattedValue: score }}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},
			minWidth: 1,
			flex: columnWidths.score || 1,
			resizable: true
		},
		{
			field: 'comments',
			headerName: 'Overall Comments',
			description: 'Overall Comments',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isDisabled={cellValues?.row?.status === 'Submitted'}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
					/>
				);
			},

			flex: columnWidths.comments || 2,
			resizable: true
		},
		{
			field: 'specifics',
			headerName: 'Specifics',
			description: 'Specifics',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isDisabled={cellValues?.row?.status === 'Submitted'}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
					/>
				);
			},

			flex: columnWidths.specifics || 2,
			resizable: true
		}
	];

	return columns;
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	link,
	isDate,
	isActive,
	emptyCell,
	isBlueprint,
	isDisabled
}) => {
	const navigate = useNavigate();
	const formattedText =
		isDate && cellValues?.formattedValue !== '--'
			? formatTimestamp(cellValues?.formattedValue)
			: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : 'n/a'}
		>
			<StyledWrapper.CellContainer
				onClick={() => navigate(link)}
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && (
					<StartIcon isActive={isActive} isBlueprint={isBlueprint} />
				)}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						status={isDisabled}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : emptyCell ? '' : '--'}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						status={isDisabled}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : emptyCell ? '' : '--'}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
