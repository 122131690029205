import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const usePermissions = () => {
	const userAccountTypeID = useSelector((state) => state.login?.user?.userAccountTypeID);
	const [hasEditPermissions, setEditPermissions] = useState(false);

	useEffect(() => {
		if (userAccountTypeID) {
			const hasPermissions = [1, 2, 3, 4]?.includes(userAccountTypeID);
			setEditPermissions(hasPermissions);
		}
	}, [userAccountTypeID]);

	return {
		hasEditPermissions,
		userAccountTypeID
	};
};
