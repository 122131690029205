export const PersonLockIcon = ({ isActive, width }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.99989 3.94141C7.08322 3.94141 4.44434 6.17752 4.44434 9.63585C4.44434 11.8442 6.14572 14.4414 9.54156 17.4275C9.79156 17.6497 10.2152 17.6497 10.4652 17.4275C13.8541 14.4414 15.5554 11.8442 15.5554 9.63585C15.5554 6.17752 12.9166 3.94141 9.99989 3.94141ZM7.00684 11.2261C7.83322 10.5803 8.86795 10.1914 9.99989 10.1914C11.1318 10.1914 12.1666 10.5803 12.9929 11.2261C12.3957 12.2609 11.2846 12.9692 9.99989 12.9692C8.71517 12.9692 7.60406 12.2609 7.00684 11.2261ZM9.99989 6.71918C10.7638 6.71918 11.3888 7.34418 11.3888 8.10807C11.3888 8.87196 10.7638 9.49696 9.99989 9.49696C9.236 9.49696 8.611 8.87196 8.611 8.10807C8.611 7.34418 9.236 6.71918 9.99989 6.71918Z"
				fill={isActive ? '#FFA726' : 'rgba(0, 0, 0, 0.54)'}
			/>
			<path
				d="M17.7779 14.8138V14.1194C17.7779 13.3555 17.1529 12.7305 16.389 12.7305C15.6251 12.7305 15.0001 13.3555 15.0001 14.1194V14.8138C14.6182 14.8138 14.3057 15.1263 14.3057 15.5082V17.5916C14.3057 17.9735 14.6182 18.286 15.0001 18.286H17.7779C18.1598 18.286 18.4723 17.9735 18.4723 17.5916V15.5082C18.4723 15.1263 18.1598 14.8138 17.7779 14.8138ZM17.0834 14.8138H15.6946V14.1194C15.6946 13.7374 16.0071 13.4249 16.389 13.4249C16.7709 13.4249 17.0834 13.7374 17.0834 14.1194V14.8138Z"
				fill={isActive ? '#FFA726' : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default PersonLockIcon;
