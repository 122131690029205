import AllNotifications from '../IndividualProgram/AllNotifications/AllNotifications';
import { rows } from '../IndividualProgram/AllNotifications/MockData';

const SystemNotifications = () => {
	return (
		<div>
			<h4>Systemwide Notifications</h4>
			<AllNotifications rows={rows} />
		</div>
	);
};

export default SystemNotifications;
