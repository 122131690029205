import { getOldBlueColor } from '../../utils/utilFunctions';
export const UnlinkIcon = ({ isActive, width }) => {
	return (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.0002 13.5H17.0002C17.5502 13.5 18.0002 13.95 18.0002 14.5C18.0002 15.05 17.5502 15.5 17.0002 15.5H11.0002C10.4502 15.5 10.0002 15.05 10.0002 14.5C10.0002 13.95 10.4502 13.5 11.0002 13.5ZM22.9302 14.5C23.5502 14.5 24.0002 13.91 23.8602 13.31C23.3202 11.12 21.3502 9.5 19.0002 9.5H15.9502C15.4302 9.5 15.0002 9.93 15.0002 10.45C15.0002 10.97 15.4302 11.4 15.9502 11.4H19.0002C20.4502 11.4 21.6702 12.4 22.0102 13.74C22.1202 14.18 22.4802 14.5 22.9302 14.5ZM5.9602 13.88C6.2402 12.41 7.6202 11.4 9.1202 11.4H12.0502C12.5702 11.4 13.0002 10.97 13.0002 10.45C13.0002 9.93 12.5702 9.5 12.0502 9.5H9.2202C6.6102 9.5 4.2802 11.41 4.0302 14.01C3.7402 16.99 6.0802 19.5 9.0002 19.5H12.0502C12.5702 19.5 13.0002 19.07 13.0002 18.55C13.0002 18.03 12.5702 17.6 12.0502 17.6H9.0002C7.0902 17.6 5.5802 15.86 5.9602 13.88Z"
				fill={isActive ? getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default UnlinkIcon;
