import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLocalAuth } from '../../utils/environmentUtils';
import ID from './UUID';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DownloadIcon from '@mui/icons-material/Download';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import LinkIcon from '@mui/icons-material/Link';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TitleIcon from '@mui/icons-material/Title';
import SegmentIcon from '@mui/icons-material/Segment';
import LabelIcon from '@mui/icons-material/Label';
import StyleIcon from '@mui/icons-material/Style';
import LanguageIcon from '@mui/icons-material/Language';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DataArrayIcon from '@mui/icons-material/DataArray';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

export const makeFieldCopy = (field) => {
	let fieldCopy = _.cloneDeep(field);
	let fieldName = fieldCopy?.field_name.split('_');
	fieldName.pop();
	fieldName.push(ID.uuid());
	let newFieldName = fieldName.join('_');
	if (
		fieldCopy.key == 'MUI_RadioButton' ||
		fieldCopy.key == 'MUI_Dropdown' ||
		fieldCopy.key == 'MUI_Checkbox'
	) {
		fieldCopy.options = fieldCopy.options.map((item) => ({
			...item,
			key: `option_${ID.uuid()}`
		}));
	}
	const newID = ID.uuid();
	let FieldCopy = {
		...fieldCopy,
		field_name: newFieldName,
		field_id: newID,
		id: newID
	};
	return FieldCopy;
};

export const makeGroupCopy = (field, entireForm) => {
	const mixedFormSteps = entireForm?.flat(1);
	const startID = field?.id;
	const startIndex = mixedFormSteps?.findIndex((e) => e.id === startID);
	const endIndex = mixedFormSteps?.findIndex(
		(e, index) => e.key === 'MUI_GroupingEnd' && index > startIndex
	);

	const slicedElements = mixedFormSteps.slice(startIndex, endIndex + 1);
	const groupCopy = slicedElements?.map((e) => makeFieldCopy(e));

	return groupCopy?.length > 0 ? groupCopy : null;
};

export const stripTextHTML = (text) => {
	let tmp = document.createElement('DIV');
	tmp.innerHTML = text;
	const label = tmp.textContent || tmp.innerText || '';
	return label;
};

export function stringToHTML(htmlString) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	const elements = Array.from(doc.body.childNodes);

	return elements.map((element, index) => {
		if (element.nodeType === Node.ELEMENT_NODE) {
			const TagName = element.tagName.toLowerCase();
			return React.createElement(TagName, {
				key: index,
				dangerouslySetInnerHTML: { __html: element.innerHTML }
			});
		}
		return null;
	});
}

export const checkForEmptyForms = (form) => {
	let isNull = false;
	form?.forEach((e) => {
		isNull = e?.length === 0 ? true : isNull;
	});
	return isNull;
};

export const checkMissingGroupings = (form) => {
	let numGroupingStarts = 0;
	let numGroupingEnds = 0;
	form?.forEach((e) => {
		numGroupingStarts += e?.filter((e) => e?.key === 'MUI_GroupingStart').length || 0;
		numGroupingEnds += e?.filter((e) => e?.key === 'MUI_GroupingEnd').length || 0;
	});

	return numGroupingStarts === numGroupingEnds ? false : true;
};

export const elementIcons = {
	LineBreak: <HorizontalRuleIcon />,
	MUI_GroupingStart: <DataArrayIcon />,
	MUI_GroupingEnd: <DataArrayIcon />,
	TwoColumnRow: <ViewComfyIcon />,
	ThreeColumnRow: <ViewComfyIcon />,
	MultiColumnRow: <ViewComfyIcon />,
	Instructions: <ErrorOutlineIcon />,
	Header: <TitleIcon />,
	Paragraph: <SegmentIcon />,
	Label: <LabelIcon />,
	MUI_Tags: <StyleIcon />,
	HyperLink: <LanguageIcon />,
	MUI_Input: <ExitToAppIcon />,
	MUI_TextArea: <ExitToAppIcon />,
	MUI_Email: <AlternateEmailIcon />,
	MUI_PhoneNumber: <LocalPhoneIcon />,
	MUI_NumberInput: <NumbersIcon />,
	MUI_Dropdown: <ExpandCircleDownOutlinedIcon />,
	MUI_Checkbox: <CheckBoxOutlinedIcon />,
	MUI_RadioButton: <RadioButtonCheckedIcon />,
	StatePicker: <LocationCityIcon />,
	MUI_Calculator: <CalculateIcon />,
	MUI_DatePicker: <TodayIcon />,
	MUI_StartEndDate: <CalendarMonthIcon />,
	MUI_Rating: <StarHalfIcon />,
	MUI_LinkInput: <LinkIcon />,
	Image: <ImageOutlinedIcon />,
	MUI_Camera: <CameraAltIcon />,
	MUI_FileUpload: <CloudUploadIcon />,
	MUI_FileAttachment: <DownloadIcon />,
	Signature: <LinkedCameraIcon />
};

export const getElementIcon = (key) => {
	return elementIcons[key];
};

const sendImageCaptureToServer = (apiToken) => async (filename, fileData) => {
	const apiUrl = process.env.REACT_APP_BASE_URL;
	const formData = new FormData();
	formData.append('file', fileData, filename);

	try {
		const response = await fetch(`${apiUrl}form/fields/upload_file/`, {
			method: 'POST',
			body: fileData,
			headers: {
				Authorization: `Bearer ${apiToken}`,
				'Content-Disposition': 'attachment; filename=file',
				filename: filename
			}
		});

		const data = await response.text();
		return data;
	} catch (error) {
		console.error('Error:', error);
		throw error;
	}
};

const sendFileToServer = (apiToken) => async (filename, fileData) => {
	const apiUrl = process.env.REACT_APP_BASE_URL;

	let path = null;

	try {
		const response = await fetch(`${apiUrl}form/fields/upload_file/`, {
			method: 'POST',
			body: new Blob([fileData]),
			headers: {
				Authorization: `Bearer ${apiToken}`,
				'Content-Disposition': 'attachment; filename=file',
				filename: filename
			}
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const contentType = response.headers.get('content-type');

		if (contentType.includes('json')) {
			path = await response.json();
		}
		if (contentType.includes('text')) {
			path = await response.text();
		}

		return path;
	} catch (error) {
		console.error('Error uploading file:', error);
	}
};

const downloadFile = (apiToken) => (filePath) => {
	const apiUrl = process.env.REACT_APP_BASE_URL;
	fetch(`${apiUrl}form/fields/download_file/`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${apiToken}`,
			filename: filePath?.replace(/^["']|["']$/g, '')
		}
	})
		.then((response) => {
			return response.blob(); // Convert response to Blob
		})
		.then((blob) => {
			// Create a temporary URL for the Blob
			const blobUrl = URL.createObjectURL(blob);
			// Create a download link and click it programmatically
			const a = document.createElement('a');
			a.href = blobUrl;

			a.download = filePath?.replace(/^["']|["']$/g, ''); // Set the desired filename
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);

			// Clean up the temporary URL
			URL.revokeObjectURL(blobUrl);
		})
		.catch((error) => {
			console.error('Error:', error);
		});
};

const displayImage = (apiToken) => async (filePath) => {
	const apiUrl = process.env.REACT_APP_BASE_URL;
	const filename = typeof filePath === 'object' ? JSON.stringify(filePath) : filePath;
	let photoURL = '';

	await fetch(`${apiUrl}form/fields/download_file/`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${apiToken}`,
			filename
		}
	})
		.then((response) => {
			return response.blob(); // Convert response to Blob
		})
		.then((blob) => {
			// Create a temporary URL for the Blob
			const blobUrl = URL.createObjectURL(blob);
			photoURL = blobUrl;
		})
		.catch((error) => {
			console.error('Error:', error);
		});

	return photoURL;
};

export function useFileUtils() {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);

	const memoFns = useMemo(
		() => ({
			sendFileToServer: sendFileToServer(apiToken),
			displayImage: displayImage(apiToken),
			downloadFile: downloadFile(apiToken),
			sendImageCaptureToServer: sendImageCaptureToServer(apiToken)
		}),
		[apiToken]
	);

	return memoFns;
}

export const capitalizeFirstLetter = (string) => {
	//capitalize the first letter
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
