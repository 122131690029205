import { GPM } from '../gpmAPI';

const reviewersApi = GPM.injectEndpoints({
	tagTypes: ['Reviewers'],
	endpoints: (builder) => ({
		getAvailableReviewers: builder.query({
			query: (props) => ({
				url: `reviewers/${props?.programID}/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers']
			}),
			providesTags: ['Reviewers']
		}),
		getReviewerPool: builder.query({
			query: (props) => ({
				url: `reviewers/${props?.programID}/pool/${props?.stageID}?page=${props?.page}&page_size=${
					props?.pageSize
				}${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers']
			}),
			providesTags: ['Reviewers']
		}),
		getAssignedReviewers: builder.query({
			query: (props) => ({
				url: `reviewers/${props?.programID}/assigned/${props?.stageID}?page=${
					props?.page
				}&page_size=${props?.pageSize}${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers']
			}),
			providesTags: ['Reviewers']
		}),
		getEvalStages: builder.query({
			query: (props) => ({
				url: `applications/eval-stages/${props?.programID}/?page=${props?.page}&page_size=${
					props?.pageSize
				}${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Reviewers', 'Stages', 'IndividualForms']
		}),
		getBulkAssignReviewers: builder.query({
			query: (props) => ({
				url: `reviewers/eval-stages/${props?.programID}/?page=${props?.page}&page_size=${
					props?.pageSize
				}${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Reviewers', 'Stages', 'IndividualForms']
		}),
		getCompareReviews: builder.query({
			query: (props) => ({
				url: `applications/reviewers/${props?.programID}/compare-reviews/${
					props?.masterFormID
				}?page=${props?.page}&page_size=${props?.pageSize}${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers']
			}),
			providesTags: ['Reviewers']
		}),
		assignReview: builder.mutation({
			query: (props) => ({
				url: `applications/stages/${props.uniqueID}/assign-reviewer/${props?.reviewerID}`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms'] //Seems to be the working one
		}),
		bulkAssignReview: builder.mutation({
			query: (props) => ({
				url: `applications/reviewers/bulk-assign/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms'] //Seems to be the working one
		}),
		unassignDelete: builder.mutation({
			query: (options) => ({
				url: `reviewers/${options?.current_reviewer}/unassign-delete/${options?.stageID}/`,
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
		}),
		bulkUnassignDelete: builder.mutation({
			query: (options) => ({
				url: `reviewers/bulk/unassign-delete/`,
				method: 'DELETE',
				body: options?.payload,
				headers: {
					Authorization: `Bearer ${options?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
		}),
		reassignDelete: builder.mutation({
			query: (props) => ({
				url: `reviewers/${props?.current_reviewer}/reassign-delete/${props?.new_reviewer}/${props?.stageID}/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms'] //Seems to be the working one
		}),
		bulkReassignDelete: builder.mutation({
			query: (props) => ({
				url: `reviewers/bulk/reassign-delete/${props?.new_reviewer}/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms'] //Seems to be the working one
		}),
		reassignKeep: builder.mutation({
			query: (props) => ({
				url: `reviewers/${props?.current_reviewer}/reassign-keep/${props?.new_reviewer}/${props?.stageID}/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms'] //Seems to be the working one
		}),
		bulkReassignKeep: builder.mutation({
			query: (props) => ({
				url: `reviewers/bulk/reassign-keep/${props?.new_reviewer}/`,
				method: 'PATCH',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			invalidatesTags: ['Reviewers', 'Stages', 'IndividualForms'] //Seems to be the working one
		}),
		getRevToAppEmails: builder.query({
			query: (props) => ({
				url: `reviewers/${props?.id}/email_list?${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getManageAssignedRevEmails: builder.query({
			query: (props) => ({
				url: `reviewers/eval-stages/${props?.id}/email_list?${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		getAppToRevEmails: builder.query({
			query: (props) => ({
				url: `applications/eval-stages/${props?.id}/email_list?${props?.finalQuery || ''}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Reviewers', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Reviewers', 'Stages', 'IndividualForms']
		})
	}),
	overrideExisting: false
});

export const {
	useGetAvailableReviewersQuery,
	useGetReviewerPoolQuery,
	useGetAssignedReviewersQuery,
	useGetEvalStagesQuery,
	useGetBulkAssignReviewersQuery,
	useGetCompareReviewsQuery,
	useAssignReviewMutation,
	useBulkAssignReviewMutation,
	useUnassignDeleteMutation,
	useBulkUnassignDeleteMutation,
	useReassignDeleteMutation,
	useBulkReassignDeleteMutation,
	useReassignKeepMutation,
	useBulkReassignKeepMutation,
	useGetRevToAppEmailsQuery,
	useGetManageAssignedRevEmailsQuery,
	useGetAppToRevEmailsQuery
} = reviewersApi;
