import StyledWrapper from '../style';
import CompareReviewsTable from '../components/CompareReviewTable/CompareReviewsTable';

const CompareReviews = ({ title }) => {
	return (
		<>
			<StyledWrapper.OutermostContainer>
				<StyledWrapper.InnerContainer>
					<CompareReviewsTable title={title} />
				</StyledWrapper.InnerContainer>
			</StyledWrapper.OutermostContainer>
		</>
	);
};

export default CompareReviews;
