import styled from 'styled-components';

interface ToggleButtonProps {
	open?: boolean;
}

const LogoWrapper = styled.div`
	display: flex;
	& img {
		width: ${(p: ToggleButtonProps) => (!p.open ? '25px' : '100%')};
	}
`;

export default {
	LogoWrapper
};
