import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { HelpIcon } from '../../../components/core/HelpIcon';
import { SectionCard } from '../../../components/SectionCard';
import BarGraph from '../../../components/Graphs/BarGraph/BarGraph';
import ProgressBar from '../../../components/Graphs/ProgressBar/ProgressBar';
import { checkOverflow, getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

import StyledWrapper from '../style';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import LaunchIcon from '@mui/icons-material/Launch';
import { getGNURL, getPortalURL } from '../../../utils/environmentUtils';
import { numberToMoney } from '../../../utils/numberUtils';

const InfoCard = ({ data, width, isFetching }) => {
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const [fundedDropdown, setFundedDropdown] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [progressBarData, setProgressBarData] = useState();
	const [BarGraphData, setBarGraphData] = useState();
	const BarGraphKeys = ['value'];

	useEffect(() => {
		if (data?.start_date && data?.end_date) {
			setProgressBarData({
				startDate: new Date(data?.start_date),
				endDate: new Date(data?.end_date),
				isCountdown: false
			});
		}

		setBarGraphData([
			{
				item: 'Requested',
				value: data?.requested_funds || 1,
				color: '#FFA726'
			},
			{
				item: 'Available',
				value: data?.available_funds || 1,
				color: '#8C9EFF'
			}
		]);
	}, [data]);

	const solicitationURL = data?.solicitationId
		? `${getGNURL()}/GO/srmng_solicitationEdit/SOLICITATIONID/${data?.solicitationId}`
		: '-';
	const budgetURL = data?.budget_template?.budgetTemplateID
		? `${getPortalURL()}/#/peerBudget/${data?.budget_template?.budgetTemplateID}`
		: '';

	return (
		<SectionCard width={width} height={'fit-content'}>
			{isFetching ? (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					<StyledWrapper.CardTitleContainer>
						{data?.status && (
							<Chip
								sx={{
									backgroundColor: getChipColor(data?.status),
									color: getChipTextColor(data?.status)
								}}
								label={data?.status}
								color="primary"
								size="small"
							/>
						)}
						<Tooltip
							title={
								<StyledWrapper.CenteredText
									isLink={true}
									onClick={() => window.location.replace(getGNURL())}
								>
									Edit Program in Grants Network <LaunchIcon />
								</StyledWrapper.CenteredText>
							}
							placement="top"
							arrow
						>
							<StyledWrapper.CardTitle
								to={`/programs/p-${programID}/application-setup/program%20details`}
								data-testid={'cardinfo-title'}
							>
								{data?.title || '-'}
							</StyledWrapper.CardTitle>
						</Tooltip>
					</StyledWrapper.CardTitleContainer>

					<StyledWrapper.FullContainer>
						<StyledWrapper.HalfContainer>
							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'department-title'}>
									Department{' '}
									<HelpIcon
										hint={'The Grants Network department to which this program is assigned.'}
									/>
								</StyledWrapper.CardRowTitle>
								<Tooltip
									open={openTooltip === 1}
									onOpen={(e) => checkOverflow(e) && setOpenTooltip(1)}
									onClose={() => setOpenTooltip(0)}
									arrow
									placement="top"
									title={data?.department || '-'}
								>
									<StyledWrapper.CardRowInfo data-testid={'department-value'}>
										{data?.department || '-'}
									</StyledWrapper.CardRowInfo>
								</Tooltip>
							</StyledWrapper.CardRowContainer>

							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'requested-title'}>
									Requested vs Available{' '}
									<HelpIcon
										hint={
											'The amount of funding currently included in submitted applications versus the total amount of funding available for this program.'
										}
									/>
								</StyledWrapper.CardRowTitle>

								<StyledWrapper.CardRowDataSet data-testid={'requested-value-container'}>
									{data?.require_budget && (data?.available_funds || data?.requested_funds) ? (
										<>
											{`${numberToMoney(data?.requested_funds || '$0.00')}`}
											<div data-testid={'nivo-bar-graph'} style={{ width: '80px', height: '30px' }}>
												<BarGraph data={BarGraphData} keys={BarGraphKeys} />
											</div>
											{`${numberToMoney(data?.available_funds || '$0.00')}`}
										</>
									) : (
										'-'
									)}
								</StyledWrapper.CardRowDataSet>
							</StyledWrapper.CardRowContainer>

							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'app-period-title'}>
									Application Period{' '}
									<HelpIcon hint={'The time frame when applications can be submitted.'} />
								</StyledWrapper.CardRowTitle>
								<StyledWrapper.CardRowInfo data-testid={'app-period-progress'}>
									{progressBarData ? <ProgressBar data={progressBarData} /> : '-'}
								</StyledWrapper.CardRowInfo>
							</StyledWrapper.CardRowContainer>

							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'solicitation-title'}>
									Solicitation Listing{' '}
									<HelpIcon
										hint={'Link to the Public Solicitation Listing page for this program.'}
									/>
								</StyledWrapper.CardRowTitle>
								<StyledWrapper.GroupTextAndIcon width={'16rem'}>
									<Tooltip
										open={openTooltip === 2}
										onOpen={(e) => checkOverflow(e) && setOpenTooltip(2)}
										onClose={() => setOpenTooltip(0)}
										arrow
										placement="top"
										title={solicitationURL}
									>
										<StyledWrapper.CardRowInfoLink
											onClick={(e) => {
												e.preventDefault();
												window.open(solicitationURL, '_blank');
											}}
											width={'fit-content'}
											maxWidth={'80%'}
											to={solicitationURL}
											data-testid={'solicitation-value'}
										>
											{solicitationURL}
										</StyledWrapper.CardRowInfoLink>
									</Tooltip>
									{data?.solicitationId && (
										<StyledWrapper.CopyButton
											position={'relative'}
											aria-label="copy"
											onClick={() => {
												navigator.clipboard.writeText(solicitationURL);
											}}
										>
											<ContentCopyIcon />
										</StyledWrapper.CopyButton>
									)}
								</StyledWrapper.GroupTextAndIcon>
							</StyledWrapper.CardRowContainer>
						</StyledWrapper.HalfContainer>

						<StyledWrapper.HalfContainer>
							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'budget-title'}>
									Budget Template{' '}
									<HelpIcon hint={'The budget template included for this program.'} />
								</StyledWrapper.CardRowTitle>
								<Tooltip
									open={openTooltip === 3}
									onOpen={(e) => checkOverflow(e) && setOpenTooltip(3)}
									onClose={() => setOpenTooltip(0)}
									arrow
									placement="top"
									title={data?.budget_template?.title || '-'}
								>
									<StyledWrapper.CardRowInfo data-testid={'budget-value'}>
										{data?.budget_template?.title || '-'}
									</StyledWrapper.CardRowInfo>
								</Tooltip>
							</StyledWrapper.CardRowContainer>

							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'ancestry-title'}>
									Program Type{' '}
									<HelpIcon
										hint={
											'Program type is selected when creating your program. A subprogram is a program whose funding source(s), budget template, and goals are inherited by the parent program selected in the dropdown menu. Once created, you can connect awards between the Parent Program and subrecipient awards in Subprogram.'
										}
									/>
								</StyledWrapper.CardRowTitle>
								<Tooltip
									open={openTooltip === 4}
									onOpen={(e) => checkOverflow(e) && setOpenTooltip(4)}
									onClose={() => setOpenTooltip(0)}
									arrow
									placement="top"
									title={data?.ancestory?.title || '-'}
								>
									<StyledWrapper.CardRowInfo data-testid={'ancestry-value'}>
										{data?.ancestry?.title || data?.ancestory?.title
											? 'Child Program'
											: data?.is_parent
											? 'Parent Program'
											: 'N/A'}
									</StyledWrapper.CardRowInfo>
								</Tooltip>
							</StyledWrapper.CardRowContainer>

							<StyledWrapper.CardRowContainer dropdownstate={fundedDropdown}>
								<StyledWrapper.CardRowTitle data-testid={'fundedby-title'}>
									{`Funded by ${data?.funded_by?.length || 0} Projects`}{' '}
									<HelpIcon hint={'Funding sources supporting this program.'} />
								</StyledWrapper.CardRowTitle>
								{data?.funded_by?.length !== 0 ? (
									<>
										<StyledWrapper.GroupTextAndIcon width={'16rem'}>
											<Tooltip
												open={openTooltip === 5}
												onOpen={(e) => checkOverflow(e) && setOpenTooltip(5)}
												onClose={() => setOpenTooltip(0)}
												arrow
												placement="top"
												title={data?.funded_by?.map((e, index) => (
													<>
														{e?.accountTitle || e?.extGrantName || ' Unspecified '}{' '}
														{data?.funded_by?.length - 1 !== index && ', '}
													</>
												))}
											>
												<StyledWrapper.CardRowMultiLinks
													width={'fit-content'}
													maxWidth={'80%'}
													dropdownstate={fundedDropdown}
												>
													{data?.funded_by?.map((e, index) => {
														return (
															<span key={`fundedBy-link-${index}`}>
																{e?.accountTitle || e?.extGrantName || ' Unspecified '}
																{data?.funded_by?.length - 1 !== index && ', '}
															</span>
														);
													})}
												</StyledWrapper.CardRowMultiLinks>
											</Tooltip>
											<StyledWrapper.CopyButton
												position={'relative'}
												aria-label="copy"
												onClick={() => {
													setFundedDropdown(!fundedDropdown);
												}}
											>
												{fundedDropdown ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
											</StyledWrapper.CopyButton>
										</StyledWrapper.GroupTextAndIcon>
									</>
								) : (
									<StyledWrapper.CardRowInfo data-testid={'fundedBy-value'}>
										{'-'}
									</StyledWrapper.CardRowInfo>
								)}
							</StyledWrapper.CardRowContainer>

							<StyledWrapper.CardRowContainer>
								<StyledWrapper.CardRowTitle data-testid={'access-reports-title'}>
									Access Reports{' '}
									<HelpIcon
										hint={'To access reporting for your programs, visit Enterprise Reporting. '}
									/>
								</StyledWrapper.CardRowTitle>
								<Tooltip
									open={openTooltip === 6}
									onOpen={(e) => checkOverflow(e) && setOpenTooltip(6)}
									onClose={() => setOpenTooltip(0)}
									arrow
									placement="top"
									title={'Grants Network Enterprise Reports'}
								>
									<StyledWrapper.CardRowInfoLink
										onClick={(e) => {
											e.preventDefault();
											window.open(`${getGNURL()}/GO/rpt_enterpriseReporting`, '_blank');
										}}
										data-testid={'access-reports-value'}
									>
										{'Grants Network Enterprise Reports'}
									</StyledWrapper.CardRowInfoLink>
								</Tooltip>
							</StyledWrapper.CardRowContainer>
						</StyledWrapper.HalfContainer>
					</StyledWrapper.FullContainer>
				</>
			)}
		</SectionCard>
	);
};

export default InfoCard;
