import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import StyledWrapper from './style';
import { mockFormSchema, mockFormAnswers } from './MockData';
import ApplicationFormContent from './subPages/ApplicationFormContent';
import ReviewStageOverview from '../IndividualReview/subPages/ReviewStageOverview';
import ApplicationOverview from './subPages/ApplicationOverview';
import ReviewOverview from '../IndividualReview/subPages/ReviewOverview';
import ReviewDetails from '../IndividualReview/subPages/ReviewDetails';
import SubmissionStageOverview from './subPages/SubmissionStageOverview';
import SingleApplicationOverview from './subPages/SingleApplicationOverview';

import { setFormSchema, setFormData } from '../../components/FormBuilderLibrary/slice';
import { ItemPageTitle } from '../../components/ItemPageTitle';
import { VerticalNavigation } from '../../components/VerticalNavigation';
import { testTabs } from '../../components/VerticalNavigation/mockData';
import { TabPanel, a11yProps } from '../../components/TabPanel';
import ReviewFormContainer from '../IndividualReview/subPages/ReviewFormContainer';
import { getApplicationPageTitle } from '../../utils/utilFunctions';
import { useGetAppProcessNav } from '../../hooks/useGetAppProcessNav';
import { saveVariable } from '../../components/BaseLayout/slice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ProjectTeam from './subPages/ProjectTeam';
import NoApplicantProtection from '../../components/ProtectedRoute/NoApplicantProtection';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const IndividualApplication = ({ appId }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	let { programID } = useParams();
	const userAccountTypeID = useSelector((state) => state.login?.user?.userAccountTypeID);
	const isReviewer = useSelector((state) => state.login?.user?.isReviewer);
	programID = programID?.replace('p-', '');
	const pathArray = location?.pathname?.split('/');
	const tabs =
		appId?.includes('arr-') || appId?.includes('rvf-')
			? ['REVIEW OVERVIEW', 'REVIEW CONTENT']
			: ['FORM DASHBOARD', 'FORM CONTENT'];

	const [value, setValue] = useState(0);
	const [chosenView, setChosenView] = useState(<></>);
	const [alertBalloon, setAlertBalloon] = useState(false);

	const { data: navData, isLoading } = useGetAppProcessNav(appId);

	const innerTabs = ['compare-reviews', 'layout', 'details', 'overview', 'content'];
	const currentPage = pathArray[pathArray.length - 1];
	let subPage = innerTabs.includes(currentPage) ? pathArray[pathArray.length - 2] : currentPage;

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 2000);
	};

	useEffect(() => {
		// dispatch(setFormSchema([-1, [[...mockFormSchema]]]));
		// dispatch(setFormData([1, [...mockFormAnswers]]));
	}, [pathArray]);

	// useEffect(() => {
	// 	subPage = subPage?.replaceAll('%20', ' ');
	// 	if (JSON.stringify(testTabs).toLowerCase().indexOf(subPage) <= -1) {
	// 		const goToApplications = pathArray?.slice(0, pathArray.length - 1);
	// 		navigate(goToApplications?.join('/'));
	// 	}
	// }, [subPage]);

	const overviewPageSelect = () => {
		if (subPage?.includes('esi-')) {
			return userAccountTypeID ? (
				<ReviewStageOverview appId={appId} subPage={subPage} handleAlert={handleAlert} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('rfi-')) {
			return isReviewer || userAccountTypeID ? (
				<ReviewDetails formID={subPage} navData={navData} />
			) : (
				<NoApplicantProtection />
			);
			// return <ReviewOverview />; ENABLE IN PHASE 2 IF NEEDED
		}
		if (subPage?.includes('afi-')) {
			return <ApplicationFormContent formID={subPage} />;
			// return <SingleApplicationOverview />; ENABLE WHEN NEEDED IN PHASE 2
		}
		if (subPage?.includes('rfm-')) {
			return userAccountTypeID ? (
				<ReviewFormContainer appId={appId} handleAlert={handleAlert} />
			) : (
				<NoApplicantProtection />
			);
		}
		if (subPage?.includes('ssi-')) {
			return <SubmissionStageOverview appId={appId} subPage={subPage} handleAlert={handleAlert} />;
		}
		if (subPage?.includes('api-')) {
			return <ApplicationOverview appId={appId} handleAlert={handleAlert} />;
		}
		if (subPage?.includes('project%20team')) {
			return <ProjectTeam />;
		} else {
			return <></>;
		}
	};

	const selectView = (view) => {
		switch (view) {
			case 0:
				return overviewPageSelect();
			case 1:
				if (appId?.includes('arr-') || appId?.includes('rvf-')) {
					return <ReviewDetails />;
				} else {
					return <ApplicationFormContent />;
				}
			default:
				return <></>;
		}
	};

	useEffect(() => {
		setChosenView(selectView(value));
	}, [value, location]);

	return (
		<StyledWrapper.OutermostContainer id={'IndividualApplication-outermostContainer'}>
			<ItemPageTitle
				text={getApplicationPageTitle({
					appId,
					title: 'Test Title',
					applicationStageID: appId,
					reviewer: 'test reviewer'
				})}
			/>
			<StyledWrapper.InnerContainer>
				{navData && !isLoading && (
					<VerticalNavigation
						customInnerWidth={'290px'}
						data={navData}
						baseURL={`/programs/p-${programID}/applications`}
					/>
				)}
				<StyledWrapper.FormContainer>
					<Box sx={{ width: '100%' }}>
						<Collapse in={alertBalloon}>
							<Alert
								severity={alertBalloon?.status}
								action={
									<IconButton
										aria-label="close"
										color={alertBalloon?.status}
										size="small"
										onClick={() => {
											setAlertBalloon(false);
										}}
									>
										<CloseIcon fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2, mt: 2 }}
							>
								{alertBalloon?.message}
							</Alert>
						</Collapse>
						{/* ENABLE IN PHASE 2 IF NEEDED */}
						{/* {(appId?.includes('arr-') || appId?.includes('afa-') || appId?.includes('rvf-')) && (
							<Box data-testid={`individualReview-box`} sx={{ padding: '0px 24px' }}>
								<Tabs
									value={value}
									onChange={(e, value) => {
										setValue(value);
									}}
									aria-label="basic tabs example"
									style={{ width: 'fit-content' }}
								>
									{tabs.map((e, index) => {
										return (
											<Tab
												iconPosition="start"
												data-testid={`individualReview-tab-${index}`}
												key={`FormTab-${index}`}
												label={`${e}`}
												{...a11yProps(e + 1)}
											/>
										);
									})}
								</Tabs>
							</Box>
						)} */}
						<TabPanel data-testid={`individualReview-tab-panel`} value={value} index={value}>
							{isLoading ? (
								<Stack justifyContent="center" alignItems="center" sx={{ width: 1, height: 1 }}>
									<CircularProgress />
								</Stack>
							) : (
								chosenView
							)}
						</TabPanel>
					</Box>
				</StyledWrapper.FormContainer>
			</StyledWrapper.InnerContainer>
		</StyledWrapper.OutermostContainer>
	);
};

export default IndividualApplication;
