import { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { encryptQueryFilters } from '../utils/encryptionUtils';
import { getLocalAuth } from '../utils/environmentUtils';
import { saveVariable } from '../components/BaseLayout/slice';
import { DEFAULT_FILTER_BASE_NAME } from '../constants/Layout';

const usePaginatedCall = (call, options = null) => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const pathArray = location?.pathname?.split('/');

	const masterFormID = pathArray
		?.filter((e) => e?.includes('rfm-'))?.[0]
		?.replace('-group', '')
		?.replace('rfm-', '');
	const appID = pathArray?.filter((e) => e?.includes('api-'))?.[0]?.replace('api-', '');

	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const dispatch = useDispatch();
	const filters = useSelector((state) => state.globalProgramVariables?.filters);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState();
	const [pageSizeSet, setPageSizeSet] = useState(false);

	const filterBaseName = useMemo(
		() => options?.filterBaseName ?? DEFAULT_FILTER_BASE_NAME,
		[options?.filterBaseName]
	);

	const handleSortModelChange = useCallback(
		(orderModel) => {
			if (filters) {
				dispatch(
					saveVariable([
						'filters',
						{
							...filters,
							[filterBaseName]: {
								...filters[filterBaseName],
								orderModel
							}
						}
					])
				);
			}
		},
		[filters, filterBaseName]
	);

	const handleFilterModelChange = useCallback(
		(filterModel) => {
			if (filters) {
				dispatch(
					saveVariable([
						'filters',
						{
							...filters,
							[filterBaseName]: {
								...filters[filterBaseName],
								filterModel
							}
						}
					])
				);
			}
		},
		[filters, filterBaseName]
	);

	const finalQuery = useMemo(() => {
		const currentElement = filters?.[filterBaseName]?.orderModel?.[0];
		const sortQuery = currentElement
			? `&ordering=${currentElement?.sort === 'desc' ? '-' : ''}${currentElement?.field}`
			: '';
		let query = encryptQueryFilters({ filters: filters?.[filterBaseName]?.filterModel ?? [] });
		// The problem is that many of the columns have different names in the database.
		if (filterBaseName.includes('_TEMPLATES')) {
			query = query.replace(/table_id/g, 'unique_identifier');
			query = query.replace(/updated/g, 'last_updated_on');
			query = query.replace(/published/g, 'created_on');
			switch (filterBaseName) {
				case 'REVIEW_TEMPLATES':
				case 'APPLICATION_TEMPLATES':
					query = query.replace(/title/g, 'label');
					break;
				case 'EVAL_TEMPLATES':
				case 'SUBMISSION_TEMPLATES':
					query = query.replace(/title/g, 'stage_name');
					break;
				case 'APPLICATION_PROCESS_TEMPLATES':
					query = query.replace(/title/g, 'process_name');
					break;
			}
		}

		return `${sortQuery || ''}&${query || ''}`;
	}, [filters?.[filterBaseName]?.orderModel, filters?.[filterBaseName]?.filterModel]);

	useEffect(() => {
		setPage(1);
	}, [filters?.[filterBaseName]?.orderModel, filters?.[filterBaseName]?.filterModel]);

	//Query options not handled in call
	const { data, isFetching, refetch, isUninitialized } = call(
		{
			apiToken,
			page,
			pageSize,
			finalQuery: finalQuery,
			programID,
			appID: appID,
			stageID: options?.stageID,
			masterFormID: masterFormID,
			templateGeneralFilter: options?.overallFilter,
			type: options?.type,
			templateID: options?.templateID
		},
		{ refetchOnMountOrArgChange: true, skip: pageSize == null }
	);
	const goToPage = (page) => {
		setPage(page + 1);
	};

	const handlePageSize = (size) => {
		// Only do this on component load. Ignore the other page resizes
		if (options.iFrame) {
			setPageSize(size);
		} else if (!pageSizeSet) {
			setPageSize(size);
			setPageSizeSet(true);
		}
	};

	return {
		rows: data,
		pagination: true,
		pageSize: pageSize,
		rowCount: data?.count ?? 0,
		paginationMode: 'server',
		onPageChange: (e) => goToPage(e),
		onPageSizeChange: (e) => handlePageSize(e),
		page: page - 1,
		loading: isFetching,
		isUninitialized: isUninitialized,
		refreshCall: pageSize != null ? refetch : () => {},
		sortingMode: 'server',
		filterModel: filters?.[filterBaseName]?.filterModel ?? [],
		sortModel: filters?.[filterBaseName]?.orderModel ?? [],
		onSortModelChange: (e) => handleSortModelChange(e),
		onFilterModelChange: (e) => handleFilterModelChange(e)
	};
};

export default usePaginatedCall;
