import React from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon, useFileUtils } from '../utils';
import StyledWrapper from './style';
import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';

export const MUIFileAttachment = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, data, name } = props;
	const [loading, setLoading] = React.useState(false);
	const { downloadFile } = useFileUtils();

	const parsedLabel = React.useMemo(() => stringToHTML(props.data?.label), [props.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props.data?.help_message),
		[props.data?.help_message]
	);

	return (
		<>
			<div className="inner-form-group" name={name}>
				<StyledWrapper.CustomLabel
					value={defaultValue?.length > 0}
					required={false}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={!data?.src ? true : false}
				>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{data.src && data.src.length > 0 && (
							<>
								{props.data.src.map((file) => {
									let parsedFile = null;
									try {
										if (file != null) {
											if (typeof file === 'string') {
												parsedFile = JSON.parse(file);
											}
											if (typeof file === 'object') {
												parsedFile = file;
											}
										}
									} catch (error) {
										console.error('Error parsing file: ', file);
									}
									if (!parsedFile) {
										return null;
									}
									return (
										<div
											onClick={() => {
												downloadFile(`${parsedFile.uuid}-${parsedFile.filename}`);
											}}
											key={parsedFile.uuid}
											style={{ cursor: 'pointer' }}
										>
											<Tooltip title="Download File" placement="top">
												<IconButton color="error">
													<DownloadIcon color="primary" />
												</IconButton>
											</Tooltip>{' '}
											<span style={{ color: 'rgb(21, 101, 192)' }}>{parsedFile.filename}</span>
										</div>
									);
								})}
							</>
						)}
					</div>
				</StyledWrapper.BorderOutline>
			</div>

			<FormHelperText error={props.data?.error}>
				{props.data?.error
					? props.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props.data?.help_message}
			</FormHelperText>
		</>
	);
});

MUIFileAttachment.displayName = 'MUI_FileAttachment';
Registry.register('MUI_FileAttachment', MUIFileAttachment);
