import { useState, useEffect, useRef } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { TemplateColumns, groupingColDef } from './templateColumns';
import { Table } from '../Table';
import {
	getTemplatesIcon,
	getTemplatesMessage,
	getTemplatesName,
	getTemplatesApiCall,
	formatApiCall
} from './utils';
import { getLocalAuth } from '../../utils/environmentUtils';
import usePaginatedCall from '../../hooks/usePaginatedCall';
import {
	usePostLinkTemplateMutation,
	usePostCopyTemplateMutation
} from '../../services/endpoints/templatesEndpoints';
import CoreInfoWarning from '../CoreInfoWarning/CoreInfoWarning';
import useCreateCoreInfo from '../../hooks/createCoreInfo';

export default function ChooseTemplateDialog({
	isOpen,
	handleClose,
	handleSave,
	type,
	isLinking,
	handleAlert,
	currentAppProcess
}) {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState(isOpen);
	const [columnWidth, setColumnWidth] = useState({});
	const [selectedRows, setSelectedRows] = useState([]);
	const [warningModal, setWarningModal] = useState(false);
	const stateRef = useRef();
	stateRef.current = selectedRows;
	const formattedType =
		type === 'application' ? 'process' : type?.includes('form') ? 'form' : 'stage';

	const handleRowSelection = (e) => {
		setSelectedRows(e);
	};

	const templateColumns = TemplateColumns(columnWidth);
	const getTreeDataPath = (row) => row?.hierarchy;

	const pagination = usePaginatedCall(getTemplatesApiCall(type), {
		overallFilter: 'active',
		filterBaseName: 'CHOOSE_TEMPLATE'
	});
	const [linkTemplate] = usePostLinkTemplateMutation();
	const [copyTemplate] = usePostCopyTemplateMutation();
	const { createCoreInfo } = useCreateCoreInfo();

	// function createHierarchyObject(arr) {
	// 	const hierarchyObject = {};

	// 	arr.forEach((element) => {
	// 		const [hierarchyKey] = element.hierarchy;
	// 		const { stageId } = element;

	// 		if (!hierarchyObject[hierarchyKey]) {
	// 			hierarchyObject[hierarchyKey] = [stageId];
	// 		} else if (!hierarchyObject[hierarchyKey].includes(stageId)) {
	// 			hierarchyObject[hierarchyKey].push(stageId);
	// 		}
	// 	});

	// 	setChosenRows(hierarchyObject);
	// }

	const handleSubmit = () => {
		isLinking ? handleLinking() : handleTemplateAssignment();
	};

	const checkForCoreInfo = () => {
		let hasCoreInfo = false;
		if (formattedType === 'stage') {
			const coreInfoForm = currentAppProcess.find((e) => e?.purpose === 'Core Information');
			hasCoreInfo = coreInfoForm ? true : false;
		}
		return hasCoreInfo;
	};

	const handleTemplateAssignment = async () => {
		const current_row = selectedRows?.api?.getRow(selectedRows?.id);
		const firstSubmissionStage = current_row?.stages?.find((e) => e?.stage_type === 'application');
		const hasAppForms =
			formattedType === 'stage'
				? current_row?.forms?.length > 0
				: firstSubmissionStage?.forms?.length > 0;
		const hasCoreInfo = await checkForCoreInfo();
		hasAppForms && !hasCoreInfo ? setWarningModal({ isOpen: true }) : handleTemplateCopying();
	};

	const handleTemplateCopying = () => {
		let unlinkTemplateOptions = {
			apiToken: apiToken,
			payload: {
				element: selectedRows?.id,
				type: formattedType,
				program_id: programID,
				stage_id: searchParams.get('stageId')
			}
		};

		copyTemplate(unlinkTemplateOptions)
			.unwrap()
			.then(async (res) => {
				if (res?.stage_id && !checkForCoreInfo()) {
					await createCoreInfo(res);
					handleClose();
					handleAlert('Template Copied Successfully!', 'success');
				} else {
					handleClose();
					handleAlert('Template Copied Successfully!', 'success');
				}
			})
			.catch(() => {
				handleClose();
				handleAlert('Template Copying Failed!', 'error');
			});
	};

	const handleLinking = () => {
		let linkTemplateOptions = {
			apiToken: apiToken,
			payload: {
				parent: type?.includes('form')
					? searchParams.get('stageId')
					: searchParams.get('processId'),
				child: stateRef?.current?.id,
				type: type?.includes('form') ? 'form' : 'stage',
				adHoc: searchParams.get('isAdHoc') ? true : false
			}
		};

		linkTemplate(linkTemplateOptions)
			.unwrap()
			.then(async () => {
				handleClose();
				handleAlert('Template Linked Successfully!', 'success');
			})
			.catch(() => {
				handleAlert('Template Linking Failed!', 'error');
			});
	};

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<div>
			<StyledWrapper.CustomDialog
				disableEscapeKeyDown
				fullWidth={true}
				maxWidth={'xl'}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				{pagination?.loading ? (
					<Box sx={{ display: 'flex' }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							{getTemplatesIcon(type)}
							{getTemplatesName(type)}
						</StyledWrapper.CustomDialogTitle>
						<StyledWrapper.CustomDialogContent>
							<DialogContentText sx={{ fontWeight: '500' }}>
								{getTemplatesMessage(type)}
							</DialogContentText>
							<DialogContentText sx={{ fontWeight: '500' }}>
								{
									'By applying the template the entire structure will be pre-populated. Once applied you can edit and customize the way you need it.'
								}
							</DialogContentText>
						</StyledWrapper.CustomDialogContent>
						<Table
							columns={templateColumns}
							rowHeight={52}
							enableAutoPageSize
							rows={formatApiCall(type, pagination?.rows?.results || [])}
							rowCount={pagination?.rowCount}
							pagination={pagination?.pagination}
							pageSize={pagination?.pageSize}
							paginationMode={pagination?.paginationMode}
							onPageChange={(e) => pagination?.onPageChange(e)}
							onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
							page={pagination?.page}
							loading={pagination?.loading}
							sortingMode={pagination?.sortingMode}
							onSortModelChange={(e) => pagination?.onSortModelChange(e)}
							onFilterModelChange={(e) => console.log(e)}
							hideFilters
							disableSelectionOnClick
							key={'manageRevs-table'}
							treeData={true}
							getTreeDataPath={getTreeDataPath}
							groupingColDef={() => groupingColDef(columnWidth, handleRowSelection, stateRef)}
							onColumnResize={(e) => {
								const newColumnSizes = e?.colDef?.field;
								const width = e?.width;
								setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
							}}
							filterModel={pagination?.filterModel}
							sortModel={pagination?.sortModel}
						/>
						<DialogActions style={{ justifyContent: 'center' }}>
							<Button size="large" variant="outlined" onClick={handleClose} autoFocus>
								Cancel
							</Button>
							<Button size="large" variant="contained" autoFocus onClick={handleSubmit}>
								{isLinking ? 'CHOOSE TEMPLATE' : 'APPLY TEMPLATE'}
							</Button>
						</DialogActions>
					</>
				)}

				{warningModal && (
					<CoreInfoWarning
						disableBackdropClick
						isOpen={warningModal?.isOpen}
						handleClose={() => setWarningModal({ isOpen: false })}
						handleStay={handleTemplateCopying}
					/>
				)}
			</StyledWrapper.CustomDialog>
		</div>
	);
}
