import { useState } from 'react';

const useDisabledButton = () => {
	const [disabled, setDisabled] = useState(false);

	const buttonStyle = `bg-red-${disabled ? '400' : '500'} font-bold text-white`;

	return { disabled, setDisabled, buttonStyle };
};

export default useDisabledButton;
