import { getIconColor, getEcivisBlueColor } from '../../utils/utilFunctions';
export const ProgramIcon = ({ width, isMulti, status }) => {
	const color = getIconColor(status);
	return !isMulti ? (
		<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.3 2.51007L8.3 0.260066C8.75 0.0900659 9.25 0.0900659 9.7 0.270066L15.7 2.52007C16.48 2.81007 17 3.56007 17 4.39007V9.10007C17 14.1501 13.59 18.8601 9 20.0101C4.41 18.8601 1 14.1501 1 9.10007V4.39007C1 3.55007 1.52 2.81007 2.3 2.51007ZM12.9831 5.96222H5.01685C4.46685 5.96222 4.01685 5.51222 4.01685 4.96222C4.01685 4.41222 4.46685 3.96222 5.01685 3.96222H12.9831C13.5331 3.96222 13.9831 4.41222 13.9831 4.96222C13.9831 5.51222 13.5331 5.96222 12.9831 5.96222ZM14.961 11.3428L15.0262 11.408C15.3087 11.6904 15.3087 12.1467 15.0262 12.4292L12.1934 15.5966C11.9109 15.8791 11.4546 15.8791 11.1721 15.5966L9.45165 13.7555C9.16917 13.473 9.16917 13.0167 9.45165 12.7342L9.51684 12.669C9.79931 12.3866 10.2556 12.3866 10.5381 12.669L11.6864 13.938L13.9398 11.35C14.215 11.0603 14.6786 11.0603 14.961 11.3428ZM5.01685 9.96222H12.9831C13.5331 9.96222 13.9831 9.51222 13.9831 8.96222C13.9831 8.41222 13.5331 7.96222 12.9831 7.96222H5.01685C4.46685 7.96222 4.01685 8.41222 4.01685 8.96222C4.01685 9.51222 4.46685 9.96222 5.01685 9.96222ZM6.85145 13.9561H5.01685C4.46685 13.9561 4.01685 13.5061 4.01685 12.9561C4.01685 12.4061 4.46685 11.9561 5.01685 11.9561H6.85145C7.40145 11.9561 7.85145 12.4061 7.85145 12.9561C7.85145 13.5061 7.40145 13.9561 6.85145 13.9561Z"
				fill={color}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.01367 3.0962L6.71016 1.11139C7.06239 0.96143 7.45377 0.96143 7.806 1.12021L11.2348 2.56928L7.02464 4.34856C6.4141 4.6132 6.00707 5.26598 6.00707 6.00697V10.1618C6.00707 12.8128 6.50877 16.0227 7.67216 18.4014C7.5356 18.4501 7.39753 18.4942 7.25808 18.5336C3.66527 17.5191 0.996094 13.3643 0.996094 8.90947V4.75461C0.996094 4.01362 1.40312 3.36084 2.01367 3.0962Z"
				fill={getEcivisBlueColor(1)}
			/>
			<path
				d="M14.8862 3.08892L9.25038 5.35726C8.51773 5.65971 8.0293 6.40574 8.0293 7.25259V12.001C8.0293 17.0922 11.2323 21.8406 15.5437 23C19.8551 21.8406 23.0581 17.0922 23.0581 12.001V7.25259C23.0581 6.41583 22.5696 5.65971 21.837 5.36734L16.2012 3.099C15.7785 2.91753 15.3089 2.91753 14.8862 3.08892Z"
				fill={getEcivisBlueColor(1)}
			/>
		</svg>
	);
};

export default ProgramIcon;
