import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { encryptQueryFilters } from '../../utils/encryptionUtils';
import { getLocalAuth } from '../../utils/environmentUtils';

export default function GenerateEmailModal({
	emailEndpoint,
	activeFilters,
	emailType,
	isOpen,
	handleClose
}) {
	const [open, setOpen] = useState(isOpen);
	const [emailList, setEmailList] = useState();
	const [alertBalloon, setAlertBalloon] = useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	let endpointQuery = encryptQueryFilters({ filters: activeFilters ?? [] });

	const { data: EndpointEmails, isLoading } = emailEndpoint({
		id: programID,
		apiToken,
		finalQuery: endpointQuery
	});

	const getUniqueEmails = (emails) => {
		let uniqueEmails = [];
		emails.forEach((entry) => {
			if (Array.isArray(entry)) {
				const emailSet = [...new Set(entry)];
				uniqueEmails = [...uniqueEmails, ...emailSet];
			} else {
				uniqueEmails.push(entry);
			}
		});
		return [...new Set(uniqueEmails)];
	};

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		if (EndpointEmails) {
			const uniqueEmails = getUniqueEmails(EndpointEmails);
			let all_email_string = '';
			uniqueEmails?.forEach((e) => {
				all_email_string = all_email_string + e + ';';
			});
			setEmailList(all_email_string);
		}
	}, [EndpointEmails]);

	const onSubmit = () => {
		navigator.clipboard.writeText(emailList);
		handleAlert('Emails successfully copied to clipboard.', 'success');
	};

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 2000);
	};

	return (
		<>
			<StyledWrapper.CustomDialog
				disableEscapeKeyDown
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
					Collect Email Addresses
				</StyledWrapper.CustomDialogTitle>
				<Collapse in={alertBalloon}>
					<Alert
						severity={alertBalloon?.status}
						action={
							<IconButton
								aria-label="close"
								color={alertBalloon?.status}
								size="small"
								onClick={() => {
									setAlertBalloon(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2, mt: 2 }}
					>
						{alertBalloon?.message}
					</Alert>
				</Collapse>

				<StyledWrapper.CustomDialogContent>
					<DialogContentText sx={{ fontWeight: '500' }}>
						{`The following email addresses are associated with the ${emailType}(s) currently filtered on the ${emailType} table. Copy these emails to your email client to send a client notification.`}
					</DialogContentText>
				</StyledWrapper.CustomDialogContent>

				{isLoading ? (
					<CircularProgress sx={{ margin: '50px auto' }} />
				) : (
					<StyledWrapper.EmailContainer>{emailList}</StyledWrapper.EmailContainer>
				)}

				<DialogActions style={{ justifyContent: 'center' }}>
					<Button
						disabled={isLoading}
						size="large"
						variant="outlined"
						onClick={handleClose}
						autoFocus
					>
						CLOSE
					</Button>
					<Button
						disabled={isLoading}
						size="large"
						variant="contained"
						autoFocus
						onClick={onSubmit}
					>
						CLICK TO COPY EMAILS
					</Button>
				</DialogActions>
			</StyledWrapper.CustomDialog>
		</>
	);
}
