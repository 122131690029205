import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import StyledWrapper from '../../style';
import { Table } from '../../../../components/Table';
import { ProcessTemplateColumns, groupingColDef } from '../../components/processTemplateColumns';
import { ButtonDropdown } from '../../../../components/core/ButtonDropdown/ButtonDropdown';
import { DeleteDialog } from '../../../../components/DeleteDialog';
import StageTemplateCreation from '../../../StageTemplateCreationModal/StageTemplateCreation';
import { clearMetaInfo } from '../../../../components/FormBuilderLibrary/slice';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useTemplates } from '../../../../hooks/useTemplates';
import useBulkTemplateActions from '../../../../hooks/useBulkTemplateActions';
import ChooseTemplateDialog from '../../../../components/ChooseTemplateDialog/ChooseTemplateDialog';

const ApplicationProcessTemplates = () => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [columnWidth, setColumnWidth] = useState({});
	const [alertBalloon, setAlertBalloon] = useState(false);

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 3000);
	};

	const { handleBulkAction } = useBulkTemplateActions('application-process');
	const initialColumns = ProcessTemplateColumns(columnWidth, handleAlert, true);
	const getTreeDataPath = (row) => row.hierarchy;

	const [openStageCreation, setOpenStageCreation] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [showValue, setShowValue] = useState('all');
	const [startFromTemplate, setStartFromTemplate] = useState();

	const { data, pagination } = useTemplates(showValue);

	const handleChange = (event) => {
		setShowValue(event.target.value);
	};

	useEffect(() => {
		const isStageCreation = searchParams.get('stageCreation');
		isStageCreation && setOpenStageCreation(isStageCreation);

		const isTemplateModal = searchParams.get('templateSelection');
		isTemplateModal && setStartFromTemplate(isTemplateModal);
	}, [searchParams]);

	const bulkActionButton = {
		title: 'BULK ACTION',
		id: 1985921,
		tipPlacement: 'left',
		variant: 'outlined',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		tipTitle: 'Please Select an Item',
		height: '28px',
		options: [
			{
				disabled: selectedRows?.length === 0,
				title: 'Archive Selected',
				onClick: () => {
					handleBulkAction(selectedRows, handleAlert, 'archive');
				}
			},
			{
				disabled: selectedRows?.length === 0,
				title: 'Unarchive Selected',
				onClick: () => {
					handleBulkAction(selectedRows, handleAlert, 'unarchive');
				}
			},
			{
				disabled: selectedRows?.length === 0,
				title: 'Delete Selected',
				onClick: () => {
					handleBulkAction(selectedRows, handleAlert, 'delete');
				}
			}
		]
	};

	const filteredColumns = initialColumns?.filter(
		(e) => e?.field !== 'linked_count' && e?.field !== 'computed_id'
	);

	return (
		<>
			<StyledWrapper.FormHeader>
				<StyledWrapper.LastEditedActions>
					<>
						<StyledWrapper.ShowDropdownContainer>
							Show
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<Select
									id="show-templates-dropdown"
									value={showValue}
									onChange={handleChange}
									sx={{ border: 'red' }}
								>
									<MenuItem value={'all'}>All Templates</MenuItem>
									<MenuItem value={'active'}>Active Only</MenuItem>
									<MenuItem value={'archived'}>Archived Only</MenuItem>
								</Select>
							</FormControl>
						</StyledWrapper.ShowDropdownContainer>
						<StyledWrapper.ActionButtonContainer>
							<ButtonDropdown data={bulkActionButton} />
							<Button
								onClick={() => {
									dispatch(clearMetaInfo());
									setSearchParams({ ...searchParams, stageCreation: 'process' });
								}}
								size="small"
								data-testid={'templates-newTemplate-button'}
								variant="contained"
							>
								NEW APPLICATION PROCESS TEMPLATE
							</Button>
						</StyledWrapper.ActionButtonContainer>
					</>
				</StyledWrapper.LastEditedActions>
			</StyledWrapper.FormHeader>
			<Collapse in={alertBalloon}>
				<Alert
					severity={alertBalloon?.status}
					action={
						<IconButton
							aria-label="close"
							color={alertBalloon?.status}
							size="small"
							onClick={() => {
								setAlertBalloon(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2, mt: 2 }}
				>
					{alertBalloon?.message}
				</Alert>
			</Collapse>

			<Table
				customHeight={'64vh'}
				columns={filteredColumns}
				rowHeight={true}
				enableAutoPageSize
				rows={data || []}
				rowCount={pagination?.rowCount}
				pagination={pagination?.pagination}
				pageSize={pagination?.pageSize}
				paginationMode={pagination?.paginationMode}
				onPageChange={(e) => pagination?.onPageChange(e)}
				onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
				page={pagination?.page}
				loading={pagination?.loading}
				sortingMode={pagination?.sortingMode}
				onSortModelChange={(e) => pagination?.onSortModelChange(e)}
				onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
				onSelectChange={(e) => setSelectedRows(e)}
				disableSelectionOnClick
				key={'BulkRevToEvalModal'}
				treeData
				isRowSelectable={(params) => !params?.row?.type?.includes('Stage')}
				getTreeDataPath={getTreeDataPath}
				groupingColDef={() => groupingColDef(columnWidth)}
				checkboxSelection
				onColumnResize={(e) => {
					const newColumnSizes = e?.colDef?.field;
					const width = e?.width;
					setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
				}}
				hideDisabledCheckboxes
				addGroupDividers
				filterModel={pagination?.filterModel}
				sortModel={pagination?.sortModel}
				excludeColumns={['action', 'baseForms', 'adHocForms', 'linked_count']}
			/>

			{openStageCreation && (
				<StageTemplateCreation
					openModal={openStageCreation}
					isTemplate
					closeModal={() => setOpenStageCreation(false)}
				/>
			)}

			<DeleteDialog
				refetch={pagination?.refreshCall}
				selectedRows={selectedRows}
				handleAlert={handleAlert}
				props={{ type: 'Application process', is_template: true }}
			/>

			{startFromTemplate && (
				<ChooseTemplateDialog
					isOpen={startFromTemplate}
					type={startFromTemplate}
					isLinking
					handleClose={() => {
						setSearchParams({});
						setStartFromTemplate(false);
					}}
					handleAlert={handleAlert}
				/>
			)}
		</>
	);
};

export default ApplicationProcessTemplates;
