import { useEffect, useState } from 'react';

import StyledWrapper from '../style';

import { Table } from '../../../../components/Table';
import { checkOverflow } from '../../../../utils/utilFunctions';
import { TeamAndRolesData } from '../../mockData';
import { SelectInput } from '../../../../components/core/SelectInput';
import { setPencilIcon } from '../../../../utils/utilFunctions';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import WarningIcon from '@mui/icons-material/Warning';

const TeamsAndRoles = () => {
	const [readOnly, setReadOnly] = useState(true);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [rowHovered, setRowHovered] = useState(null);
	const [changedRoles, setChangedRoles] = useState([]);

	const roles = ['Administrator', 'Editor', 'Full Member', 'Unassigned'];

	const handleChosenRole = (role) => {
		const exists = changedRoles?.findIndex((e) => e?.id === role?.id);
		exists !== -1 && setChangedRoles(changedRoles.splice(exists, 1));
		setChangedRoles([...changedRoles, role]);
	};

	const checkWarning = (id) => {
		const exists = changedRoles?.find((e) => e?.id === id);
		return exists?.role === 'Unassigned' ? true : false;
	};

	useEffect(() => {
		setPencilIcon(readOnly);
	}, [readOnly]);

	const columns = [
		{
			field: 'role',
			headerName: 'Role',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `role-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`role-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							{readOnly ? (
								<StyledWrapper.CardRowInfo data-testid={`${cellValues?.field}-${cellValues?.id}`}>
									{cellValues?.formattedValue}
									{cellValues?.formattedValue === 'Unassigned' && (
										<WarningIcon sx={{ fill: '#FF9800' }} />
									)}
								</StyledWrapper.CardRowInfo>
							) : (
								<>
									<SelectInput
										label={'Roles'}
										items={roles}
										defaultValue={cellValues?.formattedValue}
										onChange={(e) => handleChosenRole({ id: cellValues?.id, role: e })}
									/>
									{(cellValues?.formattedValue === 'Unassigned' ||
										checkWarning(cellValues?.id)) && <WarningIcon sx={{ fill: '#FF9800' }} />}
								</>
							)}
						</Tooltip>
					</>
				);
			},
			flex: 1
		},
		{
			field: 'name',
			headerName: 'Name',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `name-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`name-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfo data-testid={`${cellValues?.field}-${cellValues?.id}`}>
								{cellValues?.formattedValue}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1
		},
		{
			field: 'department',
			headerName: 'Department',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `department-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`department-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfo data-testid={`${cellValues?.field}-${cellValues?.id}`}>
								{cellValues?.formattedValue}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1
		},
		{
			field: 'email',
			headerName: 'Email',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `email-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`email-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfo data-testid={`${cellValues?.field}-${cellValues?.id}`}>
								{cellValues?.formattedValue}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1
		},
		{
			field: 'addedDate',
			headerName: 'Added At',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							open={openTooltip === `addedDate-${cellValues?.id}`}
							onOpen={(e) => checkOverflow(e) && setOpenTooltip(`addedDate-${cellValues?.id}`)}
							onClose={() => setOpenTooltip(0)}
							arrow
							placement="top"
							title={cellValues?.formattedValue}
						>
							<StyledWrapper.CardRowInfo
								rowHovered={rowHovered === cellValues?.id}
								data-testid={`${cellValues?.field}-${cellValues?.id}`}
							>
								{cellValues?.formattedValue}
								{rowHovered === cellValues?.id && (
									<IconButton color="primary" aria-label="search-row" component="label">
										<ContentPasteSearchIcon />
									</IconButton>
								)}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1
		}
	];
	return (
		<>
			<StyledWrapper.FormHeader>
				<StyledWrapper.LastEdited data-testid={'teamandroles-lastedited'}>
					Last edited by: Antonio Banderas, 03/12/2022 4:22AM
				</StyledWrapper.LastEdited>
				<StyledWrapper.LastEditedActions>
					{readOnly ? (
						<>
							{' '}
							<IconButton aria-label="moreOptions">
								<MoreHorizIcon />
							</IconButton>
							<Button
								data-testid={'teamandroles-editroleassignment'}
								variant="outlined"
								onClick={() => setReadOnly(false)}
								startIcon={<EditIcon />}
							>
								Edit Role Assignments
							</Button>
						</>
					) : (
						<>
							<Button
								variant="contained"
								component="label"
								data-testid={'teamandroles-saveroles-button'}
							>
								Save Roles
							</Button>
							<Button
								variant="outlined"
								onClick={() => setReadOnly(true)}
								data-testid={'teamandroles-cancel-button'}
							>
								Cancel
							</Button>
						</>
					)}
				</StyledWrapper.LastEditedActions>
			</StyledWrapper.FormHeader>
			<Table
				columns={columns}
				rows={TeamAndRolesData}
				// rowHeight={true}
				rowHover={(e) => setRowHovered(e)}
			/>
		</>
	);
};

export default TeamsAndRoles;
