import React from 'react';
import { format, parse } from 'date-fns';
import ComponentHeader from './component-header';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import StyledWrapper from './style';
import { getElementIcon } from '../../FormBuilder/utils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

class DatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.inputField = React.createRef();

		const { formatMask } = DatePicker.updateFormat(props, null);
		this.state = DatePicker.updateDateTime(props, { formatMask }, formatMask);
	}

	// formatMask = '';

	handleChange = (dt) => {
		let placeholder;
		const { formatMask } = this.state;
		if (dt && dt.target) {
			placeholder = dt && dt.target && dt.target.value === '' ? formatMask.toLowerCase() : '';
			const formattedDate = dt.target.value ? format(dt.target.value, formatMask) : '';
			this.setState({
				value: formattedDate,
				internalValue: formattedDate,
				placeholder
			});
		} else {
			this.setState({
				value: dt ? format(dt, formatMask) : '',
				internalValue: dt,
				placeholder
			});
		}
	};

	static updateFormat(props, oldFormatMask) {
		const { showTimeSelect, showTimeSelectOnly, showTimeInput } = props.data;
		const dateFormat = showTimeSelect && showTimeSelectOnly ? '' : props.data.dateFormat;
		const timeFormat = showTimeSelect || showTimeInput ? props.data.timeFormat : '';
		const formatMask = `${dateFormat} ${timeFormat}`.trim();
		const updated = formatMask !== oldFormatMask;

		return { updated, formatMask };
	}

	static updateDateTime(props, state, formatMask) {
		let value;
		let internalValue;
		const { defaultToday } = props.data;
		if (defaultToday && (props.defaultValue === '' || props.defaultValue === undefined)) {
			value = format(new Date(), formatMask);
			internalValue = new Date();
		} else {
			value = props.defaultValue;

			if (value === '' || value === undefined) {
				internalValue = undefined;
			} else {
				internalValue = parse(value, state.formatMask, new Date());
			}
		}
		return {
			value,
			internalValue,
			placeholder: formatMask.toLowerCase(),
			defaultToday,
			formatMask: state.formatMask
		};
	}

	static getDerivedStateFromProps(props, state) {
		const { updated, formatMask } = DatePicker.updateFormat(props, state.formatMask);
		if (props.data.defaultToday !== state.defaultToday || updated) {
			const newState = DatePicker.updateDateTime(props, state, formatMask);
			return newState;
		}
		return null;
	}

	render() {
		const props = {};
		props.type = 'date';
		props.className = 'form-control';
		props.name = this.props.data.field_name;
		const read_only = this.props.data.read_only || this.props.read_only;
		const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

		if (this.props.mutable) {
			props.defaultValue = this.props.defaultValue;
			props.ref = this.inputField;
		}

		let baseClasses = 'SortableItem rfb-item';
		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		const label =
			this.props?.data?.label && this.props?.data?.label !== ' '
				? this.props?.data?.label
				: this.props?.data?.text;
		const isFormBuilder = true;

		return (
			<div style={{ ...this.props.style }} className={baseClasses}>
				<LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
					<ComponentHeader {...this.props} />
					<div
						className="form-group"
						style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
					>
						<div style={{ display: 'flex', gap: '10px' }}>
							<StyledWrapper.CustomDatePicker
								minDate={props?.data?.min_date}
								maxDate={props?.data?.max_date}
								label={
									<>
										{isFormBuilder && getElementIcon(this.props.data?.key)}
										{`${label ? label + ' ' : ''}Start Date`}
										{isFormBuilder && (
											<div>
												{this.props.data?.unique_identifier
													? `(ff-${this.props.data?.unique_identifier})`
													: `(tmp-${this.props.data?.temp_order})`}
											</div>
										)}
									</>
								}
								disabled={this.props?.disabled}
								renderInput={(params) => (
									<TextField
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={this.props?.required}
										data-testid={`datepicker-textfield`}
										style={{ width: '100%' }}
										{...params}
									/>
								)}
							/>
							<StyledWrapper.CustomDatePicker
								minDate={props?.data?.min_date}
								maxDate={props?.data?.max_date}
								label={
									<>
										{isFormBuilder && getElementIcon(this.props.data?.key)}
										{`${label ? label + ' ' : ''}End Date`}
										{isFormBuilder && (
											<div>
												{this.props.data?.unique_identifier
													? `(ff-${this.props.data?.unique_identifier})`
													: `(tmp-${this.props.data?.temp_order})`}
											</div>
										)}
									</>
								}
								disabled={this.props?.disabled}
								renderInput={(params) => (
									<TextField
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={this.props?.required}
										data-testid={`datepicker-textfield`}
										style={{ width: '100%' }}
										{...params}
									/>
								)}
							/>
						</div>

						{!this.props?.data?.custom_options?.includes('hideTimePeriod') && (
							<div style={{ display: 'flex', gap: '10px' }}>
								<TextField
									id={`${this.props?.data?.name}-totalTime`}
									label={'Total Time'}
									value={'(not defined)'}
									sx={{ width: '100%' }}
									disabled
									InputLabelProps={{ shrink: isFormBuilder || undefined }}
								/>
								<FormControl sx={{ width: '100%' }} variant="outlined">
									<StyledWrapper.CustomLabel
										value={'(not defined)'}
										disabled
										htmlFor={`${this.props?.data?.name}-timeRemaining`}
										shrink={isFormBuilder || undefined}
									>
										{isFormBuilder ? 'Time Remaining' : ''}
									</StyledWrapper.CustomLabel>
									<StyledWrapper.OutlinedInputReadOnly
										type={'text'}
										disabled={true}
										style={{ width: '100%' }}
										inputComponent={() => {
											return (
												<>
													<TextField
														error={props?.data?.error}
														InputLabelProps={{ shrink: isFormBuilder || undefined }}
														id={`${this.props?.data?.name}-timeRemaining`}
														label={'Time Remaining'}
														sx={{ width: 'fit-content' }}
														value={'(not defined)'}
														disabled
													/>
												</>
											);
										}}
										label={'Time Remaining'}
									/>
								</FormControl>
							</div>
						)}
					</div>
				</LocalizationProvider>
			</div>
		);
	}
}

export default DatePicker;
