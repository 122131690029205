import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
const PLACE_HOLDER = 'card-form-place-holder';

class CardPlaceHolder extends React.Component {
	render() {
		const placeHolderClass = PLACE_HOLDER;

		return (
			<div className={placeHolderClass}>
				<div>Insert Element Here</div>
			</div>
		);
	}
}

export default injectIntl(CardPlaceHolder);
CardPlaceHolder.propTypes = {
	text: PropTypes.string,
	show: PropTypes.bool
};

CardPlaceHolder.defaultProps = {
	text: 'Dropzone',
	show: true
};
