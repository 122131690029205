import styled from 'styled-components';

const ToolbarContainer = styled.div`
	display: ${(props) => (props?.disableToolbar ? 'hidden' : 'flex')};
	margin-bottom: 20px;
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
`;

const CustomToolbarContainer = styled.div`
	display: ${(props) => (props?.disableToolbar ? 'hidden' : 'flex')};
	border-radius: 5px;
	align-items: center;
	gap: 10px;
	margin-right: 20px;
`;

const ToolbarButton = styled.div`
	display: ${(props) => (props?.show ? 'flex' : 'none')};
	border-radius: 5px;
	cursor: pointer;
`;

const ActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	height: 100%;
	position: relative;
	overflow: visible;
`;

export default {
	ToolbarContainer,
	CustomToolbarContainer,
	ToolbarButton,
	ActionsContainer
};
