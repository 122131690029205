import styled from 'styled-components';

const MainContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 240px;
	padding: 16px 16px 16px 24px;
	margin-top: auto;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ListContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

const ListItem = styled.p`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 9px;
	color: rgba(0, 0, 0, 0.6);
	margin: 0px;
	letter-spacing: 1.2px;
`;

const Bullet = styled.span`
	width: 4px;
	height: 4px;
	background-color: rgba(0, 0, 0, 0.54);
	border-radius: 50px;
`;

export default {
	MainContainer,
	InnerContainer,
	ListContainer,
	ListItem,
	Bullet
};
