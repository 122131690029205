import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';

const OuterMostContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 8px;
	width: ${(p) => (p.open ? '220px' : '4px')};
	height: 100%;
	padding: ${(p) => (p.open ? '12px 0px 12px 0px' : '12px 0px 12px 0px')};
	background-color: #f6f6f6;
	border-left: 1px solid #d9d9d9;
	margin-left: 20px;
	overflow: visible;
	margin-left: 20px;
	// margin-top: -40px;
	transition: width 0.5s ease, margin 0.5s ease, padding-left 0.5s ease;
`;

const InnerContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	margin-left: 12px;
	height: 100%;
	gap: 10px;
	overflow-y: ${(p) => (p.open ? 'scroll' : 'hidden')};
	padding-right: ${(p) => (p.open ? '7px' : '0px')};

	&::-webkit-scrollbar-thumb {
		border: 3px solid #f6f6f6;
		right: -6px;
	}

	&::-webkit-scrollbar-track {
		background-color: #f6f6f6;
	}
`;

const CustomAccordion = styled(Accordion)`
	border-radius: 8px !important;

	& .MuiAccordionSummary-root {
		padding: 0px;
		min-height: fit-content !important;
		background-color: transparent !important;
		border-radius: 8px;
	}

	& .MuiAccordionSummary-content {
		margin: 0px !important;
		padding: 0px;
		border-radius: 8px;
	}

	& .MuiAccordionDetails-root {
		padding: 0px !important;
	}
`;

const FilterIndicator = styled.div`
	position: absolute;
	top: -25px;
	left: 0px;
	transform: translate(-50%, 0);
`;

const FilterCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	border-radius: 8px;
	overflow: hidden;

	& label {
		font-size: 14px;

		&.MuiInputBase-inputAdornedEnd {
			padding: 2px;
		}
	}

	& input {
		font-size: 14px;
	}

	& .MuiNativeSelect-root {
		width: 142px;
	}

	& .MuiNativeSelect-icon {
		right: 0px;
	}
`;

const CardHeader = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 10px;
	background-color: ${(p) => (p.active ? '#FFE0B2' : '#e1e1e1')};
	border-radius: 8px 8px 0px 0px;
	padding: 7px;
	font-weight: 500;
	font-size: 10px;
	cursor: default;

	& .dragIndicator {
		cursor: grab;
	}

	& .dragIndicator:active {
		cursor: grabbing;
	}
`;

const FiltersTitle = styled.p`
	font-weight: 500;
	font-size: 11px;
	margin-top: 0px;
	margin-bottom: 0px;
`;

const FiltersTitleContainer = styled.div`
	display: flex;
	text-wrap: nowrap;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-left: 12px;
	margin-right: 12px;
`;

const FiltersClearContainer = styled.div`
	display: flex;
	text-wrap: nowrap;
	align-items: center;
	justify-content: end;
	flex: 1;
	gap: 4px;
`;

const CardBody = styled.div`
	display: flex;
	background-color: white;
	border-radius: 0px 0px 8px 8px;
`;

const OrderIndicator = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 20px;
	color: white;
	background-color: ${(p) => (p.active ? '#FFE0B2' : '#e1e1e1')};
	padding: 8px;
	min-width: 35px;
	border-radius: 0px 0px 0px 8px;
`;

const CardLogicContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 6px;
	font-size: 14px;
`;

const ToggleButton = styled.button`
	background-color: #f6f6f6;
	position: absolute;
	top: 10px;
	left: -20px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	z-index: 1201;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	border-right: 0px;
	padding: 0px;
	width: 20px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const SideBarChevron = styled.div`
	position: relative;
	width: 24px;
	height: 24px;
	margin-left: 5px;
	transform: ${(p) => p.open && 'scaleX(-1)'};
`;

export default {
	OuterMostContainer,
	InnerContainer,
	CustomAccordion,
	FilterIndicator,
	FilterCardContainer,
	CardHeader,
	FiltersTitle,
	FiltersTitleContainer,
	FiltersClearContainer,
	CardBody,
	OrderIndicator,
	CardLogicContainer,
	ToggleButton,
	SideBarChevron
};
