import * as React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
	const { onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			valueIsNumericString
			prefix="$"
		/>
	);
});
export default function NumericInputField({ currentFilter, setCurrentFilter }) {
	return (
		<TextField
			sx={{ fontSize: '14px' }}
			label="Value"
			value={currentFilter.value ?? ''}
			onChange={(e) => {
				setCurrentFilter({ ...currentFilter, value: e.target.value });
			}}
			name="numberformat"
			id="formatted-numberformat-input"
			InputProps={{
				inputComponent: NumericFormatCustom
			}}
			variant="standard"
		/>
	);
}
