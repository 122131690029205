import { useState, useEffect } from 'react';

import StyledWrapper from './style';

import { SectionCard } from '../../../../components/SectionCard';
import { useApplicationSetup } from '../../../../hooks/useApplicationSetup';
import { IndividualMasterFormSelection } from './IndividualMasterFormSelection';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export const StageAppSelection = ({ isReviewForm, readOnly, customWidth }) => {
	const [refresh, setRefresh] = useState(false);
	const [expanded, setExpanded] = useState([]);
	const [allExpanded, setAllExpanded] = useState(true);
	const [allForms, setAllForms] = useState([]);
	const { data: allStagesAndForms, isLoading, refetch } = useApplicationSetup();

	const initializeFormsUniqueIdentifiers = (forms) =>
		forms.map((item) => 'panel-' + item.uniqueIdentifier);

	const handleExpansion = (expandedAccordion) => {
		setAllExpanded(false);

		const expandedFormatted = expanded;
		const existsIndex = expandedFormatted?.indexOf(expandedAccordion);
		existsIndex !== -1
			? expandedFormatted.splice(existsIndex, 1)
			: expandedFormatted.push(expandedAccordion);

		setExpanded(expandedAccordion === '' ? [] : expandedFormatted);
		setRefresh(!refresh);
	};

	const handleAllExpand = (isAllExpanded) => {
		if (isAllExpanded) {
			setAllExpanded(true);
			setExpanded(initializeFormsUniqueIdentifiers(allForms));
		} else {
			setAllExpanded(false);
			setExpanded([]);
		}
	};
	useEffect(() => {
		setAllForms();
		refetch();
	}, [location.pathname]);

	useEffect(() => {
		const onlyAppForms = allStagesAndForms?.filter((e) => e?.type === 'Application Form');
		onlyAppForms && setAllForms(onlyAppForms);

		setAllForms((prevForms) => {
			const formsUniqueIdentifiers = initializeFormsUniqueIdentifiers(prevForms);
			setExpanded(formsUniqueIdentifiers);
			return prevForms;
		});
	}, [allStagesAndForms]);

	return isLoading ? (
		<>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<CircularProgress />
			</Box>
		</>
	) : (
		<SectionCard
			width={customWidth || '100%'}
			height={'fit-content'}
			noscroll={true}
			padding={'0px'}
		>
			<StyledWrapper.DetailTitleContainer data-testid={`appForm-title-container`}>
				<StyledWrapper.DetailTitle>{`Application Forms to Review`}</StyledWrapper.DetailTitle>
				<StyledWrapper.ActionButtonContainer>
					<StyledWrapper.CustomIconButton onClick={() => handleAllExpand(true)} aria-label="unfold">
						<UnfoldMoreIcon />
					</StyledWrapper.CustomIconButton>
					<StyledWrapper.CustomIconButton onClick={() => handleAllExpand(false)} aria-label="fold">
						<UnfoldLessIcon />
					</StyledWrapper.CustomIconButton>
				</StyledWrapper.ActionButtonContainer>
			</StyledWrapper.DetailTitleContainer>
			{!isReviewForm && (
				<StyledWrapper.SubTitle>{`Select what to review (only selected will be displayed in the Review)`}</StyledWrapper.SubTitle>
			)}

			{allForms?.map((form, index) => (
				<IndividualMasterFormSelection
					key={`individual-form-selection-${index}`}
					readOnly={readOnly}
					allExpanded={allExpanded}
					appId={form?.uniqueIdentifier}
					title={form?.title}
					expanded={expanded}
					isReviewing={isReviewForm}
					handleExpansion={handleExpansion}
					style={index == 0 ? { borderTop: '1px solid rgba(0, 0, 0, 0.12)' } : {}}
				/>
			))}
		</SectionCard>
	);
};
