import styled from 'styled-components';
import { IconButton } from '@mui/material';

const TableActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	position: absolute;
	top: -2rem;
	left: -2.5rem;
`;

const EllipsisButton = styled(IconButton)`
	width: 2rem;
	height: 2rem;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export default {
	TableActionsContainer,
	EllipsisButton
};
