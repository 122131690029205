import { getGNURL } from '../../../../utils/environmentUtils';

export const goalsFormSchema = [
	{
		id: 'A59AD300-B927-482B-8992-CA6DAE430B40',
		element: 'TwoColumnRow',
		text: 'Two Column Row',
		group_name: 'Layout',
		required: false,
		can_have_page_break_before: true,
		can_have_alternate_form: true,
		can_have_display_horizontal: true,
		can_have_option_correct: true,
		can_have_option_value: true,
		can_populate_from_api: true,
		field_name: 'two_col_row_2DC9D548-3FAE-48A4-A37F-47FABD2414E9',
		child_items: ['4DB77E01-5631-47D6-A97C-2ED9F035DB6A', 'CC8BE050-8776-4E75-AB91-3962A21AA260'],
		is_container: true
	},
	{
		id: '4DB77E01-5631-47D6-A97C-2ED9F035DB6A',
		element: 'CustomElement',
		text: 'Link Input',
		group_name: 'Controls',
		required: false,
		key: 'MUI_LinkInput',
		custom: true,
		forward_ref: true,
		custom_options: ['hideLabel'],
		can_have_page_break_before: true,
		can_have_alternate_form: true,
		can_have_display_horizontal: true,
		can_have_option_correct: true,
		can_have_option_value: true,
		can_populate_from_api: true,
		field_name: 'linkInput_F23DDD24-ED40-40B3-BD36-4719B121EB93',
		label: '<p>Access & Edit</p>\n',
		col: 0,
		parent_id: 'A59AD300-B927-482B-8992-CA6DAE430B40',
		parent_index: 1,
		dirty: false
	},
	{
		id: 'CC8BE050-8776-4E75-AB91-3962A21AA260',
		element: 'CustomElement',
		text: 'Text Input',
		group_name: 'Controls',
		required: false,
		key: 'MUI_Input',
		custom: true,
		forward_ref: true,
		custom_options: ['hideLabel'],
		can_have_page_break_before: true,
		can_have_alternate_form: true,
		can_have_display_horizontal: true,
		can_have_option_correct: true,
		can_have_option_value: true,
		can_populate_from_api: true,
		field_name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
		label: '<p>Program Goals URL</p>\n',
		col: 1,
		parent_id: 'A59AD300-B927-482B-8992-CA6DAE430B40',
		parent_index: 0,
		dirty: false,
		isLink: true,
		customWidth: '67%'
	}
];

export const mockFormAnswers = [
	{
		name: 'linkInput_F23DDD24-ED40-40B3-BD36-4719B121EB93',
		custom_name: 'linkInput_F23DDD24-ED40-40B3-BD36-4719B121EB93',
		value: ['Program Goals', 'www.google.com']
	},
	{
		name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
		custom_name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
		value: `${getGNURL()}/GO/srmng_solicitationEdit/SOLICITAT...`
	}
];

export const rows = [
	{
		goal: 'Businesses Revitalized',
		subgoals: [
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.'
		],
		target: 10000000.0,
		id: 1
	},
	{
		goal: 'Persons Assisted',
		subgoals: [
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.'
		],
		target: 5000000.0,
		id: 2
	},
	{
		goal: 'Homes - New Construction',
		subgoals: [
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id',
			'tempor a sapien. Curabitur sit amet placerat sapien.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius.',
			'Ut vitae egestas leo nivamus nec arcu blandit auctor sapien eget varius. Donec nunc ante semper vel consectetur id'
		],
		target: 100000.0,
		id: 3
	},
	{
		goal: 'Citizen Access',
		subgoals: [],
		target: 50000.0,
		id: 4
	},
	{
		goal: 'Fiber Optics Miles',
		subgoals: [
			'tempor a sapien. Curabitur sit amet placerat sapien.',
			'tempor a sapien. Curabitur sit amet placerat sapien.'
		],
		target: 1000.0,
		id: 5
	}
];
