import styled from 'styled-components';

const ResizePanelContainer = styled.div`
	& .customVerticalResizeBar {
		position: relative;
		width: 20px;
		border-radius: 10px;
		height: 100%;
		/* background: rgba(0, 0, 0, 0.6); */
		opacity: 0;
		/* padding: 0px 10px; */
		cursor: ew-resize;
		--carat-before-top: 0%;
		--carat-after-top: 0%;
	}

	& .customResizeBorder {
		position: relative;
		right: ${(p) => p.right || ''};
	}

	& .customResizeBorder::after {
		content: '';
		height: 100%;
		width: 2px;
		top: 0px;
		left: 10px;
		font-size: 30px;
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.6);
		position: absolute;
		display: block;
	}

	&:hover .customVerticalResizeBar,
	&:hover .customResizeBorder::after {
		opacity: 0.8 !important;
	}

	& .customVerticalResizeBar::before {
		content: '\\2039';
		transform: translateY(-50%);
		font-size: 30px;
		color: rgba(0, 0, 0, 0.6);
		opacity: 0;
		margin-right: 5px;
		transition: opacity 0.2s ease-in-out;
		position: absolute;
		top: var(--carat-before-top);
	}

	& .customVerticalResizeBar::after {
		content: '\\203A';
		position: absolute;
		top: var(--carat-after-top);
		transform: translateY(-50%);
		font-size: 30px;
		color: rgba(0, 0, 0, 0.6);
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	&:hover .customVerticalResizeBar::before {
		left: -10px;
	}

	&:hover .customVerticalResizeBar::after {
		right: -10px;
	}

	&:hover .customVerticalResizeBar::before,
	&:hover .customVerticalResizeBar::after {
		opacity: 0.8;
	}
`;

export default {
	ResizePanelContainer
};
