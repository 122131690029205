import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from '../style';
import { budgetFormSchema } from './utils';

import { MultiFormViewer } from '../../../../components/MultiFormViewer';
import { useGetIndividualProgramsQuery } from '../../../../services/endpoints/programsEndpoints';
import { getPortalURL, getLocalAuth } from '../../../../utils/environmentUtils';

const Budget = () => {
	const FormContainer = useRef(null);
	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);

	const [formAnswers, setFormAnswers] = useState([]);

	const { data: individualProgram, isLoading: isLoadingProgram } = useGetIndividualProgramsQuery(
		{
			id: programID,
			apiToken
		},
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		const finalBudget =
			individualProgram?.budget_template?.[0] || individualProgram?.budget_template;

		const budgetURL = finalBudget?.budgetTemplateID
			? `${getPortalURL()}/#/peerBudget/${finalBudget?.budgetTemplateID}`
			: '';

		const formAnswers = [
			{
				name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
				custom_name: 'mui_input_909B07FE-2254-4ABD-B7DB-B107215ECF00',
				value: finalBudget?.title || ' '
			}
		];
		setFormAnswers(formAnswers);
	}, [individualProgram]);

	return (
		<>
			{!isLoadingProgram && (
				<StyledWrapper.FormContainer ref={FormContainer}>
					<StyledWrapper.FormHeader>
						<StyledWrapper.LastEdited data-testid={'budget-description'}>
							The purpose of the budget is to capture the financial request of your applicants in a
							format that aligns to your program objectives, allowing grant managers to monitor
							eligible and ineligible costs. The final amounts approved in the budget will become
							the categories used by subrecipients to report spending and request reimbursement.
						</StyledWrapper.LastEdited>
					</StyledWrapper.FormHeader>

					{individualProgram?.budget_template ? (
						<MultiFormViewer
							hideInstructions
							formAnswers={[[], formAnswers]}
							form={[budgetFormSchema]}
							readOnly={true}
							disableFooterButtons
							disableActionButtons
							hideActionButtons
						/>
					) : (
						<p style={{ width: '100%', textAlign: 'center' }}>Budget Not Found</p>
					)}
				</StyledWrapper.FormContainer>
			)}
		</>
	);
};

export default Budget;
