import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';

const Container = styled.div`
	position: absolute;
	top: 50px;
	right: 0;
	z-index: 1250;
	width: 450px;
	padding: 16px;
	background-color: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
		0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	@media (max-width: 650px) {
		position: fixed;
		top: 68px;
		right: 0px;
		left: 0px;
		margin: auto;
	}
	@media (max-width: 500px) {
		width: 320px;
		top: 60px;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0px 16px 0px;
`;

const Title = styled.h6`
	color: rgba(0, 0, 0, 0.87);
	margin: 0px;
`;

const NotificationButton = styled(IconButton)`
	cursor: default !important;
	background-color: rgba(0, 0, 0, 0.04) !important;
	pointer-events: none !important;
`;

const AllRead = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const AllReadLink = styled.a`
	font-size: 12px;
`;

const Divider = styled.div`
	height: 1px;
	width: 450px;
	background-color: rgba(0, 0, 0, 0.12);
	margin: 16px -16px;
	@media (max-width: 500px) {
		width: 320px;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const SeeAll = styled.a`
	margin-top: 10px;
`;

export default {
	Container,
	Header,
	Title,
	NotificationButton,
	AllRead,
	AllReadLink,
	Divider,
	Footer,
	SeeAll
};
