import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useGetBulkAssignReviewersQuery } from '../services/endpoints/reviewEndpoints';
import { getEntityPrefix } from '../utils/utilFunctions';
import usePaginatedCall from './usePaginatedCall';

export const useGetBulkReviewerTable = () => {
	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState(true);

	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const pagination = usePaginatedCall(useGetBulkAssignReviewersQuery, {
		filterBaseName: 'BULK_REVIEWS'
	});
	const allReviewers = pagination?.rows;

	const [rowData, setRowData] = useState([]);
	useEffect(() => {
		let copiedRowData = [];

		if (allReviewers?.results) {
			allReviewers?.results?.forEach((rev) => {
				copiedRowData.push({
					hierarchy: [rev?.reviewer_id],
					reviewer: `${rev?.first_name} ${rev?.last_name}`,
					id: copiedRowData?.length + 1
				});

				const ApplicationPrefix = getEntityPrefix({
					type: 'application-process',
					isInstance: true
				});

				const StagePrefix = getEntityPrefix({
					type: 'evaluation-stage',
					isInstance: true
				});

				const MasterFormPrefix = getEntityPrefix({
					type: 'review-form',
					isMaters: true
				});

				const FormPrefix = getEntityPrefix({
					type: 'review-form',
					isInstance: true
				});

				//Handle EvalStages Hierarchy Rows
				let uniqueStageIDs = [];
				rev?.forms?.forEach((x) => {
					const app = x?.stage?.application;
					if (!uniqueStageIDs?.includes(x?.stage?.unique_identifier)) {
						copiedRowData.push({
							hierarchy: [rev?.reviewer_id, x?.stage?.unique_identifier],
							appId: `${ApplicationPrefix}${app?.unique_identifier}`,
							submitter: app?.submitter,
							stageName: x?.stage?.stage_name,
							stageId: `${StagePrefix}${x?.stage?.unique_identifier}`,
							status: x?.stage?.status,
							projectTitle: x?.stage?.application?.project_title || '--',
							id: copiedRowData?.length + 1
						});
						uniqueStageIDs?.push(x?.stage?.unique_identifier);
					}

					//Handle Forms Hierarchy Rows
					copiedRowData.push({
						hierarchy: [rev?.reviewer_id, x?.stage?.unique_identifier, x?.unique_identifier],
						reviewFormName: x?.master_form?.label,
						formID: `${MasterFormPrefix}${x?.master_form?.unique_identifier}`,
						reviewId: `${FormPrefix}${x?.unique_identifier}`,
						id: copiedRowData?.length + 1
					});
				});
			});
		}

		setRowData(copiedRowData);
		setIsLoading(false);
	}, [allReviewers, searchParams]);

	useEffect(() => {
		pagination?.refreshCall();
	}, [searchParams]);

	return {
		data: rowData,
		isLoading,
		refetch: pagination?.refreshCall,
		pagination
	};
};
