import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Title = styled.h4`
	width: 100%;
	text-align: left;
	margin-bottom: 30px !important;
	display: flex;
	align-items: left;
	justify-content: left;
	gap: 18px;
`;

const Description = styled.p`
	text-align: ${(props) => props?.center && 'center'};
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: ${(props) => props?.center && 'center'};
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 22px;
	margin-top: ${(props) => props?.marginTop && '40px'};
`;

const OutermostContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 30px;
`;

const BackIconContainer = styled.div`
	width: 100%;
	position: absolute;
	top: -16px;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex-wrap: no-wrap;
	gap: 15px;
	align-items: flex-start;
	padding: ${(props) => props?.padding};
	margin: ${(props) => props?.margin};
`;

const CustomDialog = styled(Dialog)`
	& .MuiDialog-paper {
		padding: 30px;
	}
`;

const CustomDialogTitle = styled(DialogTitle)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const CustomDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
`;

const TableContainer = styled.div`
	& .MuiDataGrid-treeDataGroupingCellToggle {
		display: ${(props) => props?.hideDropdownCarot && 'none!important'};
	}
`;

export default {
	Title,
	Description,
	ButtonContainer,
	OutermostContainer,
	BackIconContainer,
	InnerContainer,
	CustomDialog,
	CustomDialogTitle,
	CustomDialogContent,
	TableContainer
};
