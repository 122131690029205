import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';
export const ApplicantProcessIcon = ({
	isActive,
	width,
	status,
	isBlueprint,
	isCurrentStage,
	isTemplate,
	isArchived,
	isWarning,
	customColor
}) => {
	const color = getIconColor(status);
	return isBlueprint ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0003 22.2745C17.7617 22.2745 22.4322 17.6039 22.4322 11.8426C22.4322 6.08117 17.7617 1.41064 12.0003 1.41064C6.23889 1.41064 1.56836 6.08117 1.56836 11.8426C1.56836 17.6039 6.23889 22.2745 12.0003 22.2745ZM11.2588 4.61948C11.0568 4.41747 10.7857 4.30584 10.504 4.30584H6.69241C6.10765 4.30584 5.62921 4.78428 5.62921 5.36904V13.8746C5.62921 14.4594 6.10233 14.9378 6.68709 14.9378H6.9722V6.71202C6.9722 6.12726 7.45063 5.64882 8.03539 5.64882H11.8469H12.5551L11.2588 4.61948ZM12.5184 6.32032C12.8002 6.32032 13.0713 6.43195 13.2733 6.63396L14.5696 7.6633H13.8614H10.0499C9.46511 7.6633 8.98667 8.14174 8.98667 8.72649V16.9523H8.70157C8.11681 16.9523 7.64369 16.4738 7.64369 15.8891V7.38351C7.64369 6.79875 8.12213 6.32032 8.70688 6.32032H12.5184ZM15.2878 8.64843C15.0858 8.44643 14.8147 8.33479 14.5329 8.33479H10.7214C10.1366 8.33479 9.65816 8.81323 9.65816 9.39799V17.9036C9.65816 18.4883 10.1313 18.9667 10.716 18.9667H17.1005C17.6853 18.9667 18.1637 18.4883 18.1637 17.9036V11.9656C18.1637 11.6839 18.0521 11.4127 17.8501 11.2161L15.2878 8.64843ZM15.5057 16.8404H12.3162C12.0238 16.8404 11.7846 16.6011 11.7846 16.3088C11.7846 16.0164 12.0238 15.7772 12.3162 15.7772H15.5057C15.7981 15.7772 16.0373 16.0164 16.0373 16.3088C16.0373 16.6011 15.7981 16.8404 15.5057 16.8404ZM15.5057 14.714H12.3162C12.0238 14.714 11.7846 14.4747 11.7846 14.1824C11.7846 13.89 12.0238 13.6508 12.3162 13.6508H15.5057C15.7981 13.6508 16.0373 13.89 16.0373 14.1824C16.0373 14.4747 15.7981 14.714 15.5057 14.714ZM14.4425 11.5244V9.13219L17.3663 12.056H14.9741C14.6818 12.056 14.4425 11.8168 14.4425 11.5244Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	) : isCurrentStage ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.0498 9.55058C14.7489 9.24975 14.3452 9.0835 13.9256 9.0835H8.24935C7.37852 9.0835 6.66602 9.796 6.66602 10.6668V23.3335C6.66602 24.2043 7.3706 24.9168 8.24143 24.9168H17.7493C18.6202 24.9168 19.3327 24.2043 19.3327 23.3335V14.4906C19.3327 14.071 19.1664 13.6672 18.8656 13.3743L15.0498 9.55058ZM15.3743 21.7502H10.6243C10.1889 21.7502 9.83268 21.3939 9.83268 20.9585C9.83268 20.5231 10.1889 20.1668 10.6243 20.1668H15.3743C15.8098 20.1668 16.166 20.5231 16.166 20.9585C16.166 21.3939 15.8098 21.7502 15.3743 21.7502ZM15.3743 18.5835H10.6243C10.1889 18.5835 9.83268 18.2272 9.83268 17.7918C9.83268 17.3564 10.1889 17.0002 10.6243 17.0002H15.3743C15.8098 17.0002 16.166 17.3564 16.166 17.7918C16.166 18.2272 15.8098 18.5835 15.3743 18.5835ZM13.791 13.8335V10.271L18.1452 14.6252H14.5827C14.1473 14.6252 13.791 14.2689 13.791 13.8335Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
			<path
				d="M10.9256 6.0835C11.3452 6.0835 11.7489 6.24975 12.0498 6.55058L13.9802 8.0835C13.3063 8.0835 13.3136 8.0835 12.9256 8.0835H11.6035H10.791H7.24935C6.37852 8.0835 5.66602 8.796 5.66602 9.66683V21.9168H5.24143C4.3706 21.9168 3.66602 21.2043 3.66602 20.3335V7.66683C3.66602 6.796 4.37852 6.0835 5.24935 6.0835H10.9256Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
			<path
				d="M7.9256 3.0835C8.34518 3.0835 8.74893 3.24975 9.04977 3.55058L10.9802 5.0835C10.3063 5.0835 10.3136 5.0835 9.9256 5.0835H8.60352H7.79102H4.24935C3.37852 5.0835 2.66602 5.796 2.66602 6.66683V18.9168H2.24143C1.3706 18.9168 0.666016 18.2043 0.666016 17.3335V4.66683C0.666016 3.796 1.37852 3.0835 2.24935 3.0835H7.9256Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
			<circle cx="18" cy="6" r="6" fill="white" />
			<path
				d="M17.9992 8.35454L20.0742 9.71412C20.4542 9.96329 20.9192 9.59495 20.8192 9.12912L20.2692 6.57245L22.1042 4.84995C22.4392 4.53579 22.2592 3.93995 21.8192 3.90204L19.4042 3.67995L18.4592 1.26412C18.2892 0.825371 17.7092 0.825371 17.5392 1.26412L16.5942 3.67454L14.1792 3.89662C13.7392 3.93454 13.5592 4.53037 13.8942 4.84454L15.7292 6.56704L15.1792 9.1237C15.0792 9.58954 15.5442 9.95787 15.9242 9.7087L17.9992 8.35454Z"
				fill={customColor ? '#F57F17' : '#F9A825'}
			/>
		</svg>
	) : isTemplate ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9983 22.9947C17.7597 22.9947 22.4302 18.3242 22.4302 12.5628C22.4302 6.80139 17.7597 2.13086 11.9983 2.13086C6.23693 2.13086 1.56641 6.80139 1.56641 12.5628C1.56641 18.3242 6.23693 22.9947 11.9983 22.9947ZM11.2569 5.3397C11.0549 5.13769 10.7838 5.02605 10.502 5.02605H6.69045C6.1057 5.02605 5.62726 5.50449 5.62726 6.08925V14.5948C5.62726 15.1796 6.10038 15.658 6.68514 15.658H6.97024V7.43223C6.97024 6.84748 7.44868 6.36904 8.03344 6.36904H11.845H12.5531L11.2569 5.3397ZM12.5165 7.04053C12.7982 7.04053 13.0693 7.15217 13.2714 7.35417L14.5676 8.38351H13.8595H10.0479C9.46316 8.38351 8.98472 8.86195 8.98472 9.44671V17.6725H8.69961C8.11486 17.6725 7.64173 17.194 7.64173 16.6093V8.10373C7.64173 7.51897 8.12017 7.04053 8.70493 7.04053H12.5165ZM15.2858 9.36865C15.0838 9.16664 14.8127 9.05501 14.531 9.05501H10.7194C10.1346 9.05501 9.65621 9.53344 9.65621 10.1182V18.6238C9.65621 19.2085 10.1293 19.687 10.7141 19.687H17.0986C17.6833 19.687 18.1618 19.2085 18.1618 18.6238V12.6858C18.1618 12.4041 18.0501 12.133 17.8481 11.9363L15.2858 9.36865ZM15.5038 17.5606H12.3142C12.0218 17.5606 11.7826 17.3214 11.7826 17.029C11.7826 16.7366 12.0218 16.4974 12.3142 16.4974H15.5038C15.7962 16.4974 16.0354 16.7366 16.0354 17.029C16.0354 17.3214 15.7962 17.5606 15.5038 17.5606ZM15.5038 15.4342H12.3142C12.0218 15.4342 11.7826 15.195 11.7826 14.9026C11.7826 14.6102 12.0218 14.371 12.3142 14.371H15.5038C15.7962 14.371 16.0354 14.6102 16.0354 14.9026C16.0354 15.195 15.7962 15.4342 15.5038 15.4342ZM14.4406 12.2446V9.8524L17.3644 12.7762H14.9722C14.6798 12.7762 14.4406 12.537 14.4406 12.2446Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isWarning
						? '#ED6C02'
						: !isActive
						? 'rgba(0, 0, 0, 0.54)'
						: customColor || getOldBlueColor()
				}
			/>
			<path
				d="M0.317037 2.12839C0.077019 1.36669 0.782692 0.644831 1.54964 0.867514L3.95423 1.56569C4.94153 1.85235 4.9056 3.26327 3.90499 3.49931L3.77741 3.52941C3.40068 3.61828 3.10907 3.91658 3.02876 4.29523L3.00157 4.42345C2.78828 5.42915 1.37853 5.49707 1.06956 4.51653L0.317037 2.12839Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isWarning
						? '#ED6C02'
						: !isActive
						? 'rgba(0, 0, 0, 0.54)'
						: customColor || getOldBlueColor()
				}
			/>
			<path
				d="M0.430318 23.0171C0.1903 23.7788 0.895974 24.5007 1.66292 24.278L4.06751 23.5798C5.05481 23.2932 5.01888 21.8822 4.01828 21.6462L3.89069 21.6161C3.51396 21.5272 3.22235 21.2289 3.14204 20.8503L3.11485 20.7221C2.90156 19.7164 1.49182 19.6484 1.18284 20.629L0.430318 23.0171Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isWarning
						? '#ED6C02'
						: !isActive
						? 'rgba(0, 0, 0, 0.54)'
						: customColor || getOldBlueColor()
				}
			/>
			<path
				d="M23.6869 2.10789C23.9269 1.34619 23.2212 0.624323 22.4543 0.847006L20.0497 1.54518C19.0624 1.83184 19.0983 3.24276 20.0989 3.4788L20.2265 3.5089C20.6032 3.59777 20.8948 3.89607 20.9751 4.27472L21.0023 4.40294C21.2156 5.40864 22.6254 5.47656 22.9343 4.49602L23.6869 2.10789Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isWarning
						? '#ED6C02'
						: !isActive
						? 'rgba(0, 0, 0, 0.54)'
						: customColor || getOldBlueColor()
				}
			/>
			<path
				d="M23.6869 23.0171C23.9269 23.7788 23.2212 24.5007 22.4543 24.278L20.0497 23.5798C19.0624 23.2932 19.0983 21.8822 20.0989 21.6462L20.2265 21.6161C20.6032 21.5272 20.8948 21.2289 20.9751 20.8503L21.0023 20.7221C21.2156 19.7164 22.6254 19.6484 22.9343 20.629L23.6869 23.0171Z"
				fill={
					isArchived
						? 'rgba(0,0,0,0.38)'
						: isWarning
						? '#ED6C02'
						: !isActive
						? 'rgba(0, 0, 0, 0.54)'
						: customColor || getOldBlueColor()
				}
			/>
		</svg>
	) : (
		<span style={{ display: 'flex' }}>
			<svg
				width={width || '24'}
				height={width || '24'}
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5504 7.55058C17.2496 7.24975 16.8459 7.0835 16.4263 7.0835H10.75C9.87919 7.0835 9.16669 7.796 9.16669 8.66683V21.3335C9.16669 22.2043 9.87127 22.9168 10.7421 22.9168H20.25C21.1209 22.9168 21.8334 22.2043 21.8334 21.3335V12.4906C21.8334 12.071 21.6671 11.6672 21.3663 11.3743L17.5504 7.55058ZM17.875 19.7502H13.125C12.6896 19.7502 12.3334 19.3939 12.3334 18.9585C12.3334 18.5231 12.6896 18.1668 13.125 18.1668H17.875C18.3104 18.1668 18.6667 18.5231 18.6667 18.9585C18.6667 19.3939 18.3104 19.7502 17.875 19.7502ZM17.875 16.5835H13.125C12.6896 16.5835 12.3334 16.2272 12.3334 15.7918C12.3334 15.3564 12.6896 15.0002 13.125 15.0002H17.875C18.3104 15.0002 18.6667 15.3564 18.6667 15.7918C18.6667 16.2272 18.3104 16.5835 17.875 16.5835ZM16.2917 11.8335V8.271L20.6459 12.6252H17.0834C16.6479 12.6252 16.2917 12.2689 16.2917 11.8335Z"
					fill={customColor || color}
				/>
				<path
					d="M13.4263 4.0835C13.8459 4.0835 14.2496 4.24975 14.5504 4.55058L16.4809 6.0835C15.8069 6.0835 15.8143 6.0835 15.4263 6.0835H14.1042H13.2917H9.75002C8.87919 6.0835 8.16669 6.796 8.16669 7.66683V19.9168H7.7421C6.87127 19.9168 6.16669 19.2043 6.16669 18.3335V5.66683C6.16669 4.796 6.87919 4.0835 7.75002 4.0835H13.4263Z"
					fill={customColor || color}
				/>
				<path
					d="M10.4263 1.0835C10.8459 1.0835 11.2496 1.24975 11.5504 1.55058L13.4809 3.0835C12.8069 3.0835 12.8143 3.0835 12.4263 3.0835H11.1042H10.2917H6.75002C5.87919 3.0835 5.16669 3.796 5.16669 4.66683V16.9168H4.7421C3.87127 16.9168 3.16669 16.2043 3.16669 15.3335V2.66683C3.16669 1.796 3.87919 1.0835 4.75002 1.0835H10.4263Z"
					fill={customColor || color}
				/>
			</svg>
		</span>
	);
};

export default ApplicantProcessIcon;
