import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from '../app/App';
import {
	DASHBOARD,
	PROGRAMS,
	ERROR,
	SESSION_EXPIRED,
	INDEX,
	LOGIN,
	SETTINGS,
	PROFILE,
	TEMPLATES,
	FORMBUILDER,
	MULTIFORMVIEWER,
	SYSTEMWIDE_NOTIFICATIONS,
	TEMPLATE_CREATION,
	REVIEWS,
	APPLICATIONS,
	INVITATIONS
} from '../constants/routes';
import Login from '../pages/Login';
import Error404 from '../pages/Error404';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import NoApplicantProtection from '../components/ProtectedRoute/NoApplicantProtection';
import { Dashboard } from '../pages/Dashboard';
import { Programs } from '../pages/Programs';
import { IndividualProgram } from '../pages/IndividualProgram';
import { Settings } from '../pages/Settings';
import { Profile } from '../pages/Profile';
import { Templates } from '../pages/Templates';
import { MultiFormBuilder } from '../pages/MultiForm';
import { MultiFormViewer } from '../components/MultiFormViewer';
import { SystemNotifications } from '../pages/SystemNotifications';
import { CreateTemplate } from '../pages/CreateTemplate';
import { UserReviews } from '../pages/UserReviews/UserReviews';
import { UserApplications } from '../pages/UserApplications/UserApplications';
import SessionExpired from '../pages/SessionExpired';

export default function RouterApp() {
	return (
		<BrowserRouter>
			<Routes>
				{/* PAGES ROUTES WITHOUT LAYOUT */}
				<Route path={LOGIN} element={<Login />} />
				<Route path={ERROR} element={<Error404 />} />
				<Route path={SESSION_EXPIRED} element={<SessionExpired />} />
				{/* PAGES ROUTES WITHOUT LAYOUT */}

				{/* PAGES ROUTES WITH LAYOUT and FUTURE AUTH PROTECTION */}
				<Route path={INDEX} element={<ProtectedRoute component={App} />}>
					<Route path={INDEX} element={<NoApplicantProtection component={Programs} />} />
					<Route path={DASHBOARD} element={<NoApplicantProtection component={Dashboard} />} />
					<Route path={PROGRAMS}>
						<Route index element={<NoApplicantProtection component={Programs} />} />

						<Route
							path={':programID/overview'}
							element={<NoApplicantProtection component={IndividualProgram} />}
						/>
						<Route path={':programID/applications/'} element={<IndividualProgram />} />
						<Route path={':programID/applications/:appID/*'} element={<IndividualProgram />} />
						<Route
							path={':programID/reviews/:reviewID/*'}
							element={<NoApplicantProtection component={IndividualProgram} />}
						/>
						<Route
							path={':programID/application-setup/*'}
							element={<NoApplicantProtection component={IndividualProgram} />}
						/>
					</Route>
					<Route path={REVIEWS} element={<UserReviews />} />
					<Route path={APPLICATIONS} element={<UserApplications />} />
					<Route path={INVITATIONS} element={<UserApplications invitesOnly />} />
					<Route path={SETTINGS} element={<NoApplicantProtection component={Settings} />} />
					<Route path={PROFILE} element={<NoApplicantProtection component={Profile} />} />
					<Route path={TEMPLATES} element={<NoApplicantProtection component={Templates} />} />
					<Route
						path={FORMBUILDER}
						element={<NoApplicantProtection component={MultiFormBuilder} />}
					/>
					<Route
						path={MULTIFORMVIEWER}
						element={<NoApplicantProtection component={MultiFormViewer} />}
					/>
					<Route
						path={SYSTEMWIDE_NOTIFICATIONS}
						element={<NoApplicantProtection component={SystemNotifications} />}
					/>
					<Route
						path={TEMPLATE_CREATION}
						element={<NoApplicantProtection component={CreateTemplate} />}
					/>
				</Route>
				{/* PAGES ROUTES WITH LAYOUT and FUTURE AUTH PROTECTION */}
			</Routes>
		</BrowserRouter>
	);
}
