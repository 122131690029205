import { NativeSelect } from '@mui/material';
import { allStatuses } from './filterConfig';
export default function StatusInputField({ currentFilter, setCurrentFilter }) {
	return (
		<NativeSelect
			sx={{ fontSize: '14px' }}
			value={currentFilter.value ?? ''}
			inputProps={{
				name: 'value'
			}}
			onChange={(e) => {
				setCurrentFilter({ ...currentFilter, value: e.target.value });
			}}
		>
			<option value={''} disabled>
				{''}
			</option>
			{allStatuses?.map((e) => (
				<option key={`status-${e.value}`} value={e.value}>
					{e?.label}
				</option>
			))}
		</NativeSelect>
	);
}
