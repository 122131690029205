import styled from 'styled-components';

const TabPanel = styled.div`
	display: flex;
	justify-content: center;

	@media (max-width: 1400px) {
		justify-content: flex-start;
	}
`;

export default {
	TabPanel
};
