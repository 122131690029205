import { useState } from 'react';
import StyledWrapper from './style';
import Checkbox from '@mui/material/Checkbox';

const INPUT_COLOR_ERROR = 'red';

export default function InputCheckbox({
	fieldName,
	label,
	register,
	required,
	error,
	onChange,
	style,
	defaultChecked,
	id
}) {
	const [checked, setChecked] = useState(defaultChecked);

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	return (
		<>
			<StyledWrapper.Container>
				<Checkbox
					data-testid={id}
					checked={checked}
					style={{
						margin: '0px',
						...style
					}}
					{...register(fieldName, {
						required,
						onChange: (e) => {
							handleChange(e);
							onChange && onChange(e);
						}
					})}
					inputProps={{ 'aria-label': 'controlled' }}
				/>

				<StyledWrapper.Label>{label}</StyledWrapper.Label>
			</StyledWrapper.Container>
			{/* ERRORS MESSAGE */}
			{error?.message && <span style={{ color: INPUT_COLOR_ERROR }}>{error?.message}</span>}
			{error?.type === 'required' && <span style={{ color: INPUT_COLOR_ERROR }}>required*</span>}
		</>
	);
}
