import styled from 'styled-components';
import Button from '@mui/material/Button';

const Container = styled.div`
	position: absolute;
	top: 60px;
	right: 0;
	z-index: 1250;
	width: 200px;
	padding: 16px;
	background-color: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
		0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	@media (max-width: 650px) {
		position: fixed;
		top: 68px;
		right: 0px;
		left: 0px;
		margin: auto;
	}
	@media (max-width: 500px) {
		top: 60px;
	}
`;

const Title = styled.h6`
	color: rgba(0, 0, 0, 0.87);
	margin: 0px;
`;

const Subtitle = styled.h6`
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	margin: 0px 0px 16px 0px;
`;

const Divider = styled.div`
	height: 1px;
	width: 200px;
	background-color: rgba(0, 0, 0, 0.12);
	margin: 0px -16px;
`;

const LogOut = styled(Button)`
	display: flex !important;
	justify-content: flex-start !important;
	padding: 0px 16px !important;
	margin: 0px -16px 0px -16px !important;
	width: 200px !important;
	text-transform: uppercase !important;
	font-weight: 500 !important;
	font-family: Roboto !important;
	font-style: normal !important;
	font-size: 14px !important;
	color: rgba(0, 0, 0, 0.87) !important;
	margin-top: 16px !important;
	display: block !important;
	text-align: start !important;
`;

export default {
	Container,
	Title,
	Divider,
	Subtitle,
	LogOut
};
