import { useState, useEffect } from 'react';
import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function SaveDialog({
	title,
	isOpen,
	handleClose,
	handleSuccessClose,
	handleSave,
	isLoading,
	isSuccess,
	isError,
	isFinalizing,
	isYesNo
}) {
	const [open, setOpen] = useState(isOpen);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<div>
			<StyledWrapper.CustomDialog
				disableEscapeKeyDown
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				{isLoading ? (
					<Box sx={{ display: 'flex' }}>
						<CircularProgress />
					</Box>
				) : isSuccess ? (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<CheckCircleOutlineIcon color={'success'} />
							{isFinalizing ? `Finalized Successfully` : `Saved Successfully`}
						</StyledWrapper.CustomDialogTitle>
						<Button
							size="large"
							variant="contained"
							autoFocus
							onClick={handleSuccessClose || handleClose}
							sx={{ maxWidth: '70px', margin: 'auto' }}
						>
							Close
						</Button>
					</>
				) : isError ? (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<ErrorOutlineIcon color={'error'} />
							{isFinalizing ? `Finalizing Failed` : `Saving Failed!`}
						</StyledWrapper.CustomDialogTitle>
						<Button
							size="large"
							variant="contained"
							autoFocus
							onClick={handleClose}
							sx={{ maxWidth: '70px', margin: 'auto' }}
						>
							Close
						</Button>
					</>
				) : (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<WarningIcon color={'warning'} />
							{isFinalizing ? `${title}` : isYesNo ? `${title}` : `Saving ${title}`}
						</StyledWrapper.CustomDialogTitle>
						<StyledWrapper.CustomDialogContent>
							<DialogContentText sx={{ fontWeight: '500' }}>{`Are you sure?`}</DialogContentText>
						</StyledWrapper.CustomDialogContent>
						<DialogActions style={{ justifyContent: 'center' }}>
							<Button
								size="large"
								variant="outlined"
								color="warning"
								onClick={handleSave}
								autoFocus
							>
								{isFinalizing ? `Finalize` : isYesNo ? `Yes` : `Save`}
							</Button>
							<Button size="large" variant="contained" autoFocus onClick={handleClose}>
								Cancel
							</Button>
						</DialogActions>
					</>
				)}
			</StyledWrapper.CustomDialog>
		</div>
	);
}
