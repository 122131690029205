import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';

import { Notifications } from '../Notifications';
import { Bookmarks } from '../Bookmarks';
import { ProfileDropdown } from '../ProfileDropdown/ProfileDropdown';

import StyledWrapper from './style';

export default function InfoBar() {
	return (
		<StyledWrapper.InfoBarContainer data-testid={'infobar-container'}>
			<StyledWrapper.InnerContainer data-testid={'infobar-inner-container'}>
				{/* <Notifications /> */}
				{/* <Bookmarks /> */}
				<Box sx={{ position: 'relative' }} data-testid={'infobar-help-box'}>
					<IconButton
						color="default"
						onClick={() =>
							window.open('https://ecivis.atlassian.net/servicedesk/customer/portals', '_blank')
						}
					>
						<HelpIcon data-testid={'infobar-helpicon'} />
					</IconButton>
				</Box>
			</StyledWrapper.InnerContainer>
			<ProfileDropdown />
		</StyledWrapper.InfoBarContainer>
	);
}
