import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SectionCard } from '../../../../components/SectionCard';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import { LinkedToTable } from './LinkedTo';
import { AppliedToTable } from './AppliedTo';

export const UsageTable = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const stageCreationParam = searchParams.get('stageCreation');
	const [value, setValue] = useState(stageCreationParam !== 'process' ? 0 : 1);

	const handleChange = (event, newValue) => {
		searchParams.set('viewType', newValue === 0 ? 'linkedTo' : 'appliedTo');
		setSearchParams(searchParams);
	};

	useEffect(() => {
		const subPage = searchParams.get('viewType');
		subPage === 'appliedTo' || stageCreationParam === 'process' ? setValue(1) : setValue(0);
	}, [searchParams]);

	return (
		<>
			<SectionCard width={'100%'} height={'fit-content'} margin={'40px 0px 0px 0px'}>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					<TabContext value={value}>
						<Box>
							<TabList onChange={handleChange} aria-label="lab API tabs example">
								{stageCreationParam !== 'process' && <Tab label="LINKED TO" value={0} />}
								<Tab label="APPLIED TO" value={1} />
							</TabList>
						</Box>
						<TabPanel value={0}>
							<LinkedToTable />
						</TabPanel>
						<TabPanel value={1}>
							<AppliedToTable />
						</TabPanel>
					</TabContext>
				</Box>
			</SectionCard>
		</>
	);
};
