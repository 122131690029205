import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';

export default function BooleanInputField({ currentFilter, setCurrentFilter }) {
	return (
		<>
			<InputLabel variant="standard">Value</InputLabel>
			<NativeSelect
				sx={{ fontSize: '14px' }}
				value={currentFilter.value ?? ''}
				inputProps={{
					name: 'value'
				}}
				onChange={(e) => {
					setCurrentFilter({ ...currentFilter, value: e.target.value });
				}}
			>
				<option value={''} disabled>
					{''}
				</option>
				<option value={true}>{'True'}</option>
				<option value={false}>{'False'}</option>
			</NativeSelect>
		</>
	);
}
