import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';
import StyledWrapper from './style';

import FormHelperText from '@mui/material/FormHelperText';
import Rating from '@mui/material/Rating';

export const MUIRating = React.forwardRef((props, ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;
	const [value, setValue] = React.useState(defaultValue);

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<>
			<div className="inner-form-group" name={name}>
				<StyledWrapper.CustomLabel
					error={props?.data?.error}
					value={defaultValue}
					disabled={disabled ? true : false}
					required={props?.data?.required}
					htmlFor="outlined-adornment-password"
					shrink={true}
					className={'customLabel'}
				>
					{isFormBuilder && getElementIcon(props.data?.key)}
					{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					{isFormBuilder && (
						<div>
							{props.data?.unique_identifier
								? `(ff-${props.data?.unique_identifier})`
								: `(tmp-${props.data?.temp_order})`}
						</div>
					)}
				</StyledWrapper.CustomLabel>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={disabled ? true : false}
				>
					<Rating
						error={props?.data?.error}
						required={props?.data?.required}
						ref={ref}
						onChange={(e, newValue) => {
							setValue(newValue);
						}}
						defaultValue={value}
						value={value}
						size="large"
						disabled={disabled}
						data-value={value}
					/>
				</StyledWrapper.BorderOutline>
			</div>

			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</>
	);
});

MUIRating.displayName = 'MUI_Rating';
Registry.register('MUI_Rating', MUIRating);
