import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';
export const AppFormIcon = ({
	isActive,
	width,
	isBlueprint,
	isTemplate,
	isWarning,
	isArchived,
	customColor,
	status
}) => {
	const color = getIconColor(status);

	return isBlueprint ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0003 22.7745C17.7617 22.7745 22.4322 18.1039 22.4322 12.3426C22.4322 6.58117 17.7617 1.91064 12.0003 1.91064C6.23889 1.91064 1.56836 6.58117 1.56836 12.3426C1.56836 18.1039 6.23889 22.7745 12.0003 22.7745ZM13.4582 4.17221C13.8778 4.17221 14.2816 4.33846 14.5824 4.63929L18.3982 8.46304C18.6991 8.75596 18.8653 9.15971 18.8653 9.57929V18.4222C18.8653 19.293 18.1528 20.0055 17.282 20.0055H7.77406C6.90322 20.0055 6.19864 19.293 6.19864 18.4222V5.75554C6.19864 4.88471 6.91114 4.17221 7.78197 4.17221H13.4582ZM10.157 16.8389H14.907C15.3424 16.8389 15.6986 16.4826 15.6986 16.0472C15.6986 15.6118 15.3424 15.2555 14.907 15.2555H10.157C9.72155 15.2555 9.36531 15.6118 9.36531 16.0472C9.36531 16.4826 9.72155 16.8389 10.157 16.8389ZM10.157 13.6722H14.907C15.3424 13.6722 15.6986 13.316 15.6986 12.8805C15.6986 12.4451 15.3424 12.0889 14.907 12.0889H10.157C9.72155 12.0889 9.36531 12.4451 9.36531 12.8805C9.36531 13.316 9.72155 13.6722 10.157 13.6722ZM13.3236 5.35971V8.92221C13.3236 9.35763 13.6799 9.71388 14.1153 9.71388H17.6778L13.3236 5.35971Z"
				fill={isActive ? customColor || getOldBlueColor() : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	) : isTemplate ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9983 22.9947C17.7597 22.9947 22.4302 18.3242 22.4302 12.5628C22.4302 6.80139 17.7597 2.13086 11.9983 2.13086C6.23693 2.13086 1.56641 6.80139 1.56641 12.5628C1.56641 18.3242 6.23693 22.9947 11.9983 22.9947ZM13.4563 4.39243C13.8759 4.39243 14.2796 4.55868 14.5804 4.85951L18.3963 8.68326C18.6971 8.97618 18.8634 9.37993 18.8634 9.79951V18.6424C18.8634 19.5133 18.1509 20.2258 17.28 20.2258H7.7721C6.90127 20.2258 6.19669 19.5133 6.19669 18.6424V5.97576C6.19669 5.10493 6.90919 4.39243 7.78002 4.39243H13.4563ZM10.155 17.0591H14.905C15.3404 17.0591 15.6967 16.7028 15.6967 16.2674C15.6967 15.832 15.3404 15.4758 14.905 15.4758H10.155C9.7196 15.4758 9.36335 15.832 9.36335 16.2674C9.36335 16.7028 9.7196 17.0591 10.155 17.0591ZM10.155 13.8924H14.905C15.3404 13.8924 15.6967 13.5362 15.6967 13.1008C15.6967 12.6653 15.3404 12.3091 14.905 12.3091H10.155C9.7196 12.3091 9.36335 12.6653 9.36335 13.1008C9.36335 13.5362 9.7196 13.8924 10.155 13.8924ZM13.3217 5.57993V9.14243C13.3217 9.57784 13.6779 9.93409 14.1134 9.93409H17.6759L13.3217 5.57993Z"
				fill={
					isWarning
						? '#ED6C02'
						: isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M0.317037 2.12839C0.077019 1.36669 0.782692 0.644831 1.54964 0.867514L3.95423 1.56569C4.94153 1.85235 4.9056 3.26327 3.90499 3.49931L3.77741 3.52941C3.40068 3.61828 3.10907 3.91658 3.02876 4.29523L3.00157 4.42345C2.78828 5.42915 1.37853 5.49707 1.06956 4.51653L0.317037 2.12839Z"
				fill={
					isWarning
						? '#ED6C02'
						: isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M0.430318 23.0171C0.1903 23.7788 0.895974 24.5007 1.66292 24.278L4.06751 23.5798C5.05481 23.2932 5.01888 21.8822 4.01828 21.6462L3.89069 21.6161C3.51396 21.5272 3.22235 21.2289 3.14204 20.8503L3.11485 20.7221C2.90156 19.7164 1.49182 19.6484 1.18284 20.629L0.430318 23.0171Z"
				fill={
					isWarning
						? '#ED6C02'
						: isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M23.6869 2.10789C23.9269 1.34619 23.2212 0.624323 22.4543 0.847006L20.0497 1.54518C19.0624 1.83184 19.0983 3.24276 20.0989 3.4788L20.2265 3.5089C20.6032 3.59777 20.8948 3.89607 20.9751 4.27472L21.0023 4.40294C21.2156 5.40864 22.6254 5.47656 22.9343 4.49602L23.6869 2.10789Z"
				fill={
					isWarning
						? '#ED6C02'
						: isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
			<path
				d="M23.6869 23.0171C23.9269 23.7788 23.2212 24.5007 22.4543 24.278L20.0497 23.5798C19.0624 23.2932 19.0983 21.8822 20.0989 21.6462L20.2265 21.6161C20.6032 21.5272 20.8948 21.2289 20.9751 20.8503L21.0023 20.7221C21.2156 19.7164 22.6254 19.6484 22.9343 20.629L23.6869 23.0171Z"
				fill={
					isWarning
						? '#ED6C02'
						: isArchived
						? 'rgba(0,0,0,0.38)'
						: isActive
						? customColor || getOldBlueColor()
						: 'rgba(0, 0, 0, 0.54)'
				}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.5504 4.55009C14.2496 4.24926 13.8458 4.08301 13.4263 4.08301H7.75001C6.87917 4.08301 6.16667 4.79551 6.16667 5.66634V18.333C6.16667 19.2038 6.87125 19.9163 7.74209 19.9163H17.25C18.1208 19.9163 18.8333 19.2038 18.8333 18.333V9.49009C18.8333 9.07051 18.6671 8.66676 18.3663 8.37384L14.5504 4.55009ZM14.875 16.7497H10.125C9.68959 16.7497 9.33334 16.3934 9.33334 15.958C9.33334 15.5226 9.68959 15.1663 10.125 15.1663H14.875C15.3104 15.1663 15.6667 15.5226 15.6667 15.958C15.6667 16.3934 15.3104 16.7497 14.875 16.7497ZM14.875 13.583H10.125C9.68959 13.583 9.33334 13.2268 9.33334 12.7913C9.33334 12.3559 9.68959 11.9997 10.125 11.9997H14.875C15.3104 11.9997 15.6667 12.3559 15.6667 12.7913C15.6667 13.2268 15.3104 13.583 14.875 13.583ZM13.2917 8.83301V5.27051L17.6458 9.62467H14.0833C13.6479 9.62467 13.2917 9.26842 13.2917 8.83301Z"
				fill={isActive ? customColor || getOldBlueColor() : color}
			/>
		</svg>
	);
};

export default AppFormIcon;
