import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	useCreateFormMutation,
	usePostFormSchemaMutation
} from '../services/endpoints/formBuilderEndpoints';
import ID from '../components/FormBuilder/UUID';
import { getLocalAuth } from '../utils/environmentUtils';

const useCreateCoreInfo = () => {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);

	let { programID } = useParams();
	programID = programID?.replace('p-', '');

	const [createForm] = useCreateFormMutation();
	const [postForm] = usePostFormSchemaMutation();

	const createFormSchema = () => {
		let finalForm = [];

		finalForm.push(...CoreInfoBaseElements());
		finalForm.push(...OrganizationSubmittingApplication());
		finalForm.push(...OrganizationAddress());
		finalForm.push(...AddIndividual('AUTHORIZED REPRESENTATIVE(S)', 11));
		finalForm.push(...AddIndividual('BUSINESS/FINANCE REPRESENTATIVE(S)', 13));
		finalForm.push(...AddIndividual('PROJECT REPRESENTATIVE(S)', 15));
		finalForm.push(...AddIndividual('TEAM MEMBER(S)', 17));
		return finalForm;
	};

	const postFormSchema = (formId) => {
		let options = {
			id: formId,
			payload: createFormSchema(),
			apiToken: apiToken
		};

		postForm(options);
	};

	const createCoreInfo = (res) => {
		createForm({
			payload: {
				...coreMetaInfo,
				program: {
					programId: programID
				},
				form_type: 'application',
				stage: res?.stage_id,
				purpose: 'Core Information'
			},

			apiToken: apiToken
		})
			.unwrap()
			.then(async (res) => {
				await postFormSchema(res?.form_id);
				return 'success';
			})
			.catch((error) => {
				return 'failed';
			});
	};

	return {
		createCoreInfo
	};
};

export default useCreateCoreInfo;

const coreMetaInfo = {
	label: 'Core Information',
	form_type: 'application',
	instructions: 'Fill out Core Information',
	order: 1
};

const CoreInfoBaseElements = () => {
	const childItem1 = ID.uuid();
	const childItem2 = ID.uuid();
	const parentID = ID.uuid();
	return [
		{
			field_id: parentID,
			field_name: `two_col_row_${parentID}`,
			element: 'TwoColumnRow',
			text: 'Two Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			custom_options: ['not-editable'],
			child_items: [childItem1, childItem2],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		},
		{
			field_id: childItem1,
			field_name: `mui_input_${childItem1}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['not-editable', 'hideLabel', 'completely-disabled', 'core-application'],
			label: 'Application',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: parentID,
			parent_index: '0',
			col: '0',
			dirty: false
		},
		{
			field_id: childItem2,
			field_name: `mui_input_${childItem2}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['not-editable', 'hideLabel', 'project-title'],
			label: 'Project Title',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: parentID,
			parent_index: '0',
			col: '1',
			dirty: false,
			required: true
		}
	];
};

const OrganizationSubmittingApplication = () => {
	const row1 = ID.uuid();
	const row1_child1 = ID.uuid();
	const row1_child2 = ID.uuid();
	const row1_child3 = ID.uuid();

	return [
		{
			field_id: ID.uuid(),
			field_name: `mui_groupingStart_${ID.uuid()}`,
			created_on: '2023-08-08T13:31:00.595291Z',
			last_updated_on: '2023-08-08T13:31:00.595303Z',
			element: 'CustomElement',
			text: 'Grouping Start',
			group_name: 'Layout',
			custom: true,
			key: 'MUI_GroupingStart',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'ORGANIZATION SUBMITTING APPLICATION',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			dirty: false,
			bold: true
		},
		{
			field_id: ID.uuid(),
			field_name: `mui_input_${ID.uuid()}`,
			created_on: '2023-08-08T13:31:00.595480Z',
			last_updated_on: '2023-08-08T13:31:00.595489Z',
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom_options: ['not-editable', 'hideLabel', 'org-name'],
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			label: 'Organization Name',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			dirty: false,
			required: true
		},
		{
			field_id: row1,
			field_name: `three_col_row_${row1}`,
			element: 'ThreeColumnRow',
			text: 'Three Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			child_items: [row1_child1, row1_child2, row1_child3],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		},
		{
			field_id: row1_child1,
			field_name: `mui_input_${row1_child1}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Employer Identification Number (EIN)',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: '4',
			col: '0'
		},
		{
			field_id: row1_child2,
			field_name: `mui_input_${row1_child2}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Unique Entity Identifier (UEI)',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: '4',
			col: '1'
		},
		{
			field_id: row1_child3,
			field_name: `mui_input_${row1_child3}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'DUNS Number',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: '4',
			col: '2'
		},
		{
			field_id: ID.uuid(),
			field_name: `mui_groupingEnd_${ID.uuid()}`,
			element: 'CustomElement',
			text: 'Grouping End',
			group_name: 'Layout',
			custom: true,
			key: 'MUI_GroupingEnd',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Grouping End',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		}
	];
};

const OrganizationAddress = () => {
	const row1 = ID.uuid();
	const row1_child1 = ID.uuid();
	const row1_child2 = ID.uuid();
	const row2 = ID.uuid();
	const row2_child1 = ID.uuid();
	const row2_child2 = ID.uuid();
	const row2_child3 = ID.uuid();
	const row2_child4 = ID.uuid();

	const row3 = ID.uuid();
	const row3_child1 = ID.uuid();
	const row3_child2 = ID.uuid();
	const row3_child3 = ID.uuid();
	const row3_child4 = ID.uuid();

	const row4 = ID.uuid();
	const row4_child1 = ID.uuid();
	const row4_child2 = ID.uuid();

	return [
		{
			field_id: ID.uuid(),
			field_name: `mui_groupingStart_${ID.uuid()}`,
			element: 'CustomElement',
			text: 'Grouping Start',
			group_name: 'Layout',
			custom: true,
			key: 'MUI_GroupingStart',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'ORGANIZATION ADDRESS',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			bold: true
		},
		{
			field_id: row1,
			field_name: `two_col_row_${row1}`,
			element: 'TwoColumnRow',
			text: 'Two Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			child_items: [row1_child1, row1_child2],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		},
		{
			field_id: row1_child1,
			field_name: `mui_input_${row1_child1}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Street Address',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: '6',
			col: '0'
		},
		{
			field_id: row1_child2,
			field_name: `mui_input_${row1_child2}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Apt, Suite, etc.',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: '6',
			col: '1'
		},
		{
			field_id: row2,
			field_name: `four_col_row_${row2}`,
			element: 'MultiColumnRow',
			text: 'Four Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			child_items: [row2_child1, row2_child2, row2_child3, row2_child4],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			class_name: 'col-md-3'
		},
		{
			field_id: row2_child1,
			field_name: `mui_input_${row2_child1}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'City',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: '7',
			col: '0'
		},
		{
			field_id: row2_child2,
			field_name: `mui_statepicker_dropdown_${row2_child2}`,
			element: 'CustomElement',
			text: 'State Picker',
			group_name: 'Controls',
			options: [
				{
					key: 'statePicker_option_1',
					text: 'Alaska',
					value: 'AK'
				},
				{
					key: 'statePicker_option_2',
					text: 'Texas',
					value: 'TX'
				},
				{
					key: 'statePicker_option_3',
					text: 'Alabama',
					value: 'AL'
				},
				{
					key: 'statePicker_option_4',
					text: 'Arkansas',
					value: 'AR'
				},
				{
					key: 'statePicker_option_5',
					text: 'Arizona',
					value: 'AZ'
				},
				{
					key: 'statePicker_option_6',
					text: 'California',
					value: 'CA'
				},
				{
					key: 'statePicker_option_7',
					text: 'Colorado',
					value: 'CO'
				},
				{
					key: 'statePicker_option_8',
					text: 'Connecticut',
					value: 'CT'
				},
				{
					key: 'statePicker_option_9',
					text: 'District of Columbia',
					value: 'DC'
				},
				{
					key: 'statePicker_option_10',
					text: 'Delaware',
					value: 'DE'
				},
				{
					key: 'statePicker_option_11',
					text: 'Florida',
					value: 'FL'
				},
				{
					key: 'statePicker_option_12',
					text: 'Georgia',
					value: 'GA'
				},
				{
					key: 'statePicker_option_13',
					text: 'Hawaii',
					value: 'HI'
				},
				{
					key: 'statePicker_option_14',
					text: 'Iowa',
					value: 'IA'
				},
				{
					key: 'statePicker_option_15',
					text: 'Idaho',
					value: 'ID'
				},
				{
					key: 'statePicker_option_16',
					text: 'Illinois',
					value: 'IL'
				},
				{
					key: 'statePicker_option_17',
					text: 'Indiana',
					value: 'IN'
				},
				{
					key: 'statePicker_option_18',
					text: 'Kansas',
					value: 'KS'
				},
				{
					key: 'statePicker_option_19',
					text: 'Kentucky',
					value: 'KY'
				},
				{
					key: 'statePicker_option_20',
					text: 'Louisiana',
					value: 'LA'
				},
				{
					key: 'statePicker_option_21',
					text: 'Massachusetts',
					value: 'MA'
				},
				{
					key: 'statePicker_option_22',
					text: 'Maryland',
					value: 'MD'
				},
				{
					key: 'statePicker_option_23',
					text: 'Maine',
					value: 'ME'
				},
				{
					key: 'statePicker_option_24',
					text: 'Michigan',
					value: 'MI'
				},
				{
					key: 'statePicker_option_25',
					text: 'Minnesota',
					value: 'MN'
				},
				{
					key: 'statePicker_option_26',
					text: 'Missouri',
					value: 'MO'
				},
				{
					key: 'statePicker_option_27',
					text: 'Mississippi',
					value: 'MS'
				},
				{
					key: 'statePicker_option_28',
					text: 'Montana',
					value: 'MT'
				},
				{
					key: 'statePicker_option_29',
					text: 'North Carolina',
					value: 'NC'
				},
				{
					key: 'statePicker_option_30',
					text: 'North Dakota',
					value: 'ND'
				},
				{
					key: 'statePicker_option_31',
					text: 'Nebraska',
					value: 'NE'
				},
				{
					key: 'statePicker_option_32',
					text: 'New Hampshire',
					value: 'NH'
				},
				{
					key: 'statePicker_option_33',
					text: 'New Jersey',
					value: 'NJ'
				},
				{
					key: 'statePicker_option_34',
					text: 'New Mexico',
					value: 'NM'
				},
				{
					key: 'statePicker_option_35',
					text: 'Nevada',
					value: 'NV'
				},
				{
					key: 'statePicker_option_36',
					text: 'New York',
					value: 'NY'
				},
				{
					key: 'statePicker_option_37',
					text: 'Ohio',
					value: 'OH'
				},
				{
					key: 'statePicker_option_38',
					text: 'Oklahoma',
					value: 'OK'
				},
				{
					key: 'statePicker_option_39',
					text: 'Oregon',
					value: 'OR'
				},
				{
					key: 'statePicker_option_40',
					text: 'Pennsylvania',
					value: 'PA'
				},
				{
					key: 'statePicker_option_41',
					text: 'RhodeIsland',
					value: 'RI'
				},
				{
					key: 'statePicker_option_42',
					text: 'South Carolina',
					value: 'SC'
				},
				{
					key: 'statePicker_option_43',
					text: 'South Dakota',
					value: 'SD'
				},
				{
					key: 'statePicker_option_44',
					text: 'Tennessee',
					value: 'TN'
				},
				{
					key: 'statePicker_option_45',
					text: 'Texas',
					value: 'TX'
				},
				{
					key: 'statePicker_option_46',
					text: 'Utah',
					value: 'UT'
				},
				{
					key: 'statePicker_option_47',
					text: 'Virginia',
					value: 'VA'
				},
				{
					key: 'statePicker_option_48',
					text: 'Vermont',
					value: 'VT'
				},
				{
					key: 'statePicker_option_49',
					text: 'Washington',
					value: 'WA'
				},
				{
					key: 'statePicker_option_50',
					text: 'Wisconsin',
					value: 'WI'
				},
				{
					key: 'statePicker_option_51',
					text: 'West Virginia',
					value: 'WV'
				},
				{
					key: 'statePicker_option_52',
					text: 'Wyoming',
					value: 'WY'
				}
			],
			custom: true,
			key: 'StatePicker',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'State',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: '7',
			col: '1'
		},
		{
			field_id: row2_child3,
			field_name: `mui_input_${row2_child3}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'County',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: '7',
			col: '2'
		},
		{
			field_id: row2_child4,
			field_name: `mui_input_${row2_child4}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Congresional District/Region',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: '7',
			col: '3'
		},
		{
			field_id: row3,
			field_name: `four_col_row_${row3}`,
			element: 'MultiColumnRow',
			text: 'Four Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			child_items: [row3_child1, row3_child2, row3_child3, row3_child4],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			class_name: 'col-md-3'
		},
		{
			field_id: row3_child1,
			field_name: `mui_input_${row3_child1}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Zip',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row3,
			parent_index: '8',
			col: '0'
		},
		{
			field_id: row3_child2,
			field_name: `mui_phone_${row3_child2}`,
			element: 'CustomElement',
			text: 'Phone',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_PhoneNumber',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Phone',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row3,
			parent_index: '8',
			col: '1'
		},
		{
			field_id: row3_child3,
			field_name: `mui_number_${row3_child3}`,
			element: 'CustomElement',
			text: 'Number Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_NumberInput',
			forward_ref: true,
			custom_options: ['min_and_max', 'hideLabel'],
			label: 'Phone Extension',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row3,
			parent_index: '8',
			col: '2'
		},
		{
			field_id: row3_child4,
			field_name: `mui_number_${row3_child4}`,
			element: 'CustomElement',
			text: 'Number Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_NumberInput',
			forward_ref: true,
			custom_options: ['min_and_max', 'hideLabel'],
			label: 'Fax',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row3,
			parent_index: '8',
			col: '3'
		},
		{
			field_id: ID.uuid(),
			field_name: `mui_groupingEnd_${ID.uuid()}`,
			element: 'CustomElement',
			text: 'Grouping End',
			group_name: 'Layout',
			custom: true,
			key: 'MUI_GroupingEnd',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Grouping End',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		}
	];
};

const AddIndividual = (groupingName, parent_index) => {
	const row1 = ID.uuid();
	const row1_child1 = ID.uuid();
	const row1_child2 = ID.uuid();
	const row1_child3 = ID.uuid();

	const row2 = ID.uuid();
	const row2_child1 = ID.uuid();
	const row2_child2 = ID.uuid();
	const row2_child3 = ID.uuid();

	return [
		{
			field_id: ID.uuid(),
			field_name: `mui_groupingStart_${ID.uuid()}`,
			element: 'CustomElement',
			text: 'Grouping Start',
			group_name: 'Layout',
			custom: true,
			key: 'MUI_GroupingStart',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: groupingName,
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			bold: true
		},
		{
			field_id: row1,
			field_name: `three_col_row_${row1}`,
			element: 'ThreeColumnRow',
			text: 'Three Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			child_items: [row1_child1, row1_child2, row1_child3],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		},
		{
			field_id: row1_child1,
			field_name: `mui_input_${row1_child1}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'First Name',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: parent_index.toString(),
			col: '0',
			required: true
		},
		{
			field_id: row1_child2,
			field_name: `mui_input_${row1_child2}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Last Name',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: parent_index.toString(),
			col: '1',
			required: true
		},
		{
			field_id: row1_child3,
			field_name: `mui_input_${row1_child3}`,
			element: 'CustomElement',
			text: 'Text Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Input',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Title',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row1,
			parent_index: parent_index.toString(),
			col: '2'
		},
		{
			field_id: row2,
			field_name: `three_col_row_${row2}`,
			element: 'ThreeColumnRow',
			text: 'Three Column Row',
			group_name: 'Layout',
			is_container: true,
			label: 'Container',
			child_items: [row2_child1, row2_child2, row2_child3],
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		},
		{
			field_id: row2_child1,
			field_name: `mui_email_${row2_child1}`,
			element: 'CustomElement',
			text: 'Email',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_Email',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Email',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: (parent_index + 1).toString(),
			col: '0',
			required: true
		},
		{
			field_id: row2_child2,
			field_name: `mui_phone_${row2_child2}`,
			element: 'CustomElement',
			text: 'Phone',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_PhoneNumber',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Phone',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: (parent_index + 1).toString(),
			col: '1'
		},
		{
			field_id: row2_child3,
			field_name: `mui_number_${row2_child3}`,
			element: 'CustomElement',
			text: 'Number Input',
			group_name: 'Controls',
			custom: true,
			key: 'MUI_NumberInput',
			forward_ref: true,
			custom_options: ['min_and_max', 'hideLabel'],
			label: 'Phone Extension',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true,
			parent_id: row2,
			parent_index: (parent_index + 1).toString(),
			col: '2'
		},
		{
			field_id: ID.uuid(),
			field_name: `mui_groupingEnd_${ID.uuid()}`,
			element: 'CustomElement',
			text: 'Grouping End',
			group_name: 'Layout',
			custom: true,
			key: 'MUI_GroupingEnd',
			forward_ref: true,
			custom_options: ['hideLabel'],
			label: 'Grouping End',
			can_have_alternate_form: true,
			can_have_option_correct: true,
			can_populate_from_api: true,
			can_have_page_break_before: true,
			can_have_display_horizontal: true,
			can_have_option_value: true
		}
	];
};
