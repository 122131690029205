import styled from 'styled-components';

const OutermostContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-top: 30px;
	gap: 15px;
	word-break: break-all;
`;

const InnerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: no-wrap;
	gap: 15px;
	align-items: flex-start;
	justify-content: center;
	padding: ${(props) => props?.padding};
	margin: ${(props) => props?.margin};
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FormHeader = styled.div`
	display: flex;
	margin: 30px 0px;
	justify-content: space-between;
	align-items: center;
`;

const LastEdited = styled.h6`
	margin: 0px;
`;

const LastEditedActions = styled.div`
	display: flex;
	gap: 5px;
	margin-left: auto;
`;

const CardRowInfo = styled.h6`
	display: ${(props) => props?.display || 'block'};
	align-items: center;
	justify-content: ${(props) => props?.justifyContent || 'space-between'};
	gap: 20px;
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	color: ${(props) => (props?.status ? 'rgba(0, 0, 0, 0.38)' : 'black')};
	pointer-events: ${(props) => props?.status && 'none'};
	text-overflow: ellipsis;
	max-width: ${(props) => props?.maxWidth || '33ch'};
	white-space: nowrap;
	overflow: hidden;
	margin: 0px;
`;

export default {
	OutermostContainer,
	InnerContainer,
	FormContainer,
	FormHeader,
	LastEdited,
	LastEditedActions,
	CardRowInfo
};
