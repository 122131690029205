import WarningIcon from '@mui/icons-material/Warning';

const SessionExpired = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100vw',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<WarningIcon color="warning" />
			<h2>Session Expired</h2>
			<p>please login again to continue session</p>
		</div>
	);
};

export default SessionExpired;
