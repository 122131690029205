import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import StyledWrapper from '../../style';
import { Table } from '../../../../components/Table';
import { StageTemplateColumns, groupingColDef } from '../../components/stageTemplateColumns';
import { ButtonDropdown } from '../../../../components/core/ButtonDropdown/ButtonDropdown';
import { DeleteDialog } from '../../../../components/DeleteDialog';
import { FormBuilderModal } from '../../../FormBuilderModal/FormBuilderModal';
import StageTemplateCreation from '../../../StageTemplateCreationModal/StageTemplateCreation';
import { clearMetaInfo } from '../../../../components/FormBuilderLibrary/slice';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useTemplates } from '../../../../hooks/useTemplates';
import ChooseTemplateDialog from '../../../../components/ChooseTemplateDialog/ChooseTemplateDialog';
import useBulkTemplateActions from '../../../../hooks/useBulkTemplateActions';

const SubmissionStageTemplates = () => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [columnWidth, setColumnWidth] = useState({});
	const [alertBalloon, setAlertBalloon] = useState(false);

	const handleAlert = (message, status) => {
		setAlertBalloon({ isOpen: true, message, status });
		setTimeout(() => {
			setAlertBalloon(false);
		}, 3000);
	};
	const initialColumns = StageTemplateColumns(columnWidth, handleAlert);
	const getTreeDataPath = (row) => row.hierarchy;

	const [openFormModal, setOpenFormModal] = useState(false);
	const [openStageCreation, setOpenStageCreation] = useState(false);
	const [openTemplateSelection, setOpenTemplateSelection] = useState(false);
	const [formsHighestOrder] = useState(0);
	const [selectedRows, setSelectedRows] = useState([]);
	const [showValue, setShowValue] = useState('all');
	const [startFromTemplate, setStartFromTemplate] = useState();

	const { handleBulkAction } = useBulkTemplateActions('stage');
	const { data, pagination } = useTemplates(showValue);

	const handleChange = (event) => {
		setShowValue(event.target.value);
	};

	useEffect(() => {
		const isFormModal = searchParams.get('formBuilder');
		isFormModal && setOpenFormModal(isFormModal);

		const isStageCreation = searchParams.get('stageCreation');
		isStageCreation && setOpenStageCreation(isStageCreation);

		const isTemplateSelection = searchParams.get('templateSelection');
		isTemplateSelection && setOpenTemplateSelection(isTemplateSelection);
	}, [searchParams]);

	const bulkActionButton = {
		title: 'BULK ACTION',
		id: 1985921,
		tipPlacement: 'left',
		variant: 'outlined',
		marginBottom: '0px',
		popperPlacement: 'bottom-end',
		tipTitle: 'Please Select an Item',
		options: [
			{
				disabled: selectedRows?.length === 0,
				title: 'Archive Selected',
				onClick: () => {
					handleBulkAction(selectedRows, handleAlert, 'archive');
				}
			},
			{
				disabled: selectedRows?.length === 0,
				title: 'Unarchive Selected',
				onClick: () => {
					handleBulkAction(selectedRows, handleAlert, 'unarchive');
				}
			},
			{
				disabled: selectedRows?.length === 0,
				title: 'Delete Selected',
				onClick: () => {
					handleBulkAction(selectedRows, handleAlert, 'delete');
				}
			}
		]
	};

	return (
		<>
			<StyledWrapper.FormHeader>
				<StyledWrapper.LastEditedActions>
					<>
						<StyledWrapper.ShowDropdownContainer>
							Show
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<Select id="show-templates-dropdown" value={showValue} onChange={handleChange}>
									<MenuItem value={'all'}>All Templates</MenuItem>
									<MenuItem value={'active'}>Active Only</MenuItem>
									<MenuItem value={'archived'}>Archived Only</MenuItem>
								</Select>
							</FormControl>
						</StyledWrapper.ShowDropdownContainer>
						<StyledWrapper.ActionButtonContainer>
							<ButtonDropdown data={bulkActionButton} />
							<Button
								onClick={() => {
									dispatch(clearMetaInfo());
									setSearchParams({ ...searchParams, stageCreation: 'application' });
								}}
								size="small"
								data-testid={'templates-newTemplate-button'}
								variant="contained"
							>
								NEW SUBMISSION STAGE TEMPLATE
							</Button>
						</StyledWrapper.ActionButtonContainer>
					</>
				</StyledWrapper.LastEditedActions>
			</StyledWrapper.FormHeader>
			<Collapse in={alertBalloon}>
				<Alert
					severity={alertBalloon?.status}
					action={
						<IconButton
							aria-label="close"
							color={alertBalloon?.status}
							size="small"
							onClick={() => {
								setAlertBalloon(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2, mt: 2 }}
				>
					{alertBalloon?.message}
				</Alert>
			</Collapse>
			<Table
				customHeight={'64vh'}
				columns={initialColumns}
				rowHeight={true}
				enableAutoPageSize
				rows={data || []}
				rowCount={pagination?.rowCount}
				pagination={pagination?.pagination}
				pageSize={pagination?.pageSize}
				paginationMode={pagination?.paginationMode}
				onPageChange={(e) => pagination?.onPageChange(e)}
				onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
				page={pagination?.page}
				loading={pagination?.loading}
				sortingMode={pagination?.sortingMode}
				onSortModelChange={(e) => pagination?.onSortModelChange(e)}
				onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
				onSelectChange={(e) => setSelectedRows(e)}
				disableSelectionOnClick
				key={'SubmissionStageTemplates'}
				isRowSelectable={(params) => !params?.row?.type?.includes('Form')}
				treeData
				getTreeDataPath={getTreeDataPath}
				groupingColDef={() => groupingColDef(columnWidth)}
				checkboxSelection
				onColumnResize={(e) => {
					const newColumnSizes = e?.colDef?.field;
					const width = e?.width;
					setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
				}}
				hideDisabledCheckboxes
				addGroupDividers
				filterModel={pagination?.filterModel}
				sortModel={pagination?.sortModel}
				excludeColumns={['action', 'baseForms', 'adHocForms', 'linked_count']}
			/>

			{openFormModal && (
				<FormBuilderModal
					isTemplate
					closeModal={() => {
						dispatch(clearMetaInfo());
						setOpenFormModal(false);
					}}
					totalForms={formsHighestOrder}
				/>
			)}

			{openStageCreation && (
				<StageTemplateCreation isTemplate closeModal={() => setOpenStageCreation(false)} />
			)}

			{openTemplateSelection && (
				<ChooseTemplateDialog
					isOpen={openTemplateSelection}
					type={'application-form'}
					isLinking={true}
					handleAlert={handleAlert}
					handleClose={() => {
						setSearchParams({});
						setOpenTemplateSelection(false);
					}}
				/>
			)}

			<DeleteDialog
				refetch={pagination?.refreshCall}
				selectedRows={selectedRows}
				props={{ type: 'Submission stage', is_template: true }}
				handleAlert={handleAlert}
			/>

			{startFromTemplate && (
				<ChooseTemplateDialog
					isOpen={startFromTemplate}
					type={startFromTemplate}
					isLinking
					handleClose={() => {
						setSearchParams({});
						setStartFromTemplate(false);
					}}
					handleAlert={handleAlert}
				/>
			)}
		</>
	);
};

export default SubmissionStageTemplates;
