import { getIconColor, getOldBlueColor } from '../../utils/utilFunctions';

export const ReviewFormIcon = ({
	isActive,
	width,
	isBlueprint,
	customDisabledColor,
	customColor,
	status
}) => {
	const color = getIconColor(status);

	return isBlueprint ? (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0003 22.5352C17.7617 22.5352 22.4322 17.8647 22.4322 12.1033C22.4322 6.34191 17.7617 1.67139 12.0003 1.67139C6.23889 1.67139 1.56836 6.34191 1.56836 12.1033C1.56836 17.8647 6.23889 22.5352 12.0003 22.5352ZM14.5507 4.25032C14.2498 3.94948 13.8461 3.78323 13.4265 3.78323H7.75026C6.87942 3.78323 6.16692 4.49573 6.16692 5.36657V12.9086C7.72878 12.746 9.72078 13.3205 11.5375 14.5936C13.5761 16.0221 14.8632 17.9582 15.0559 19.6166H17.2503C18.1211 19.6166 18.8336 18.9041 18.8336 18.0332V9.19032C18.8336 8.77073 18.6673 8.36698 18.3665 8.07407L14.5507 4.25032ZM13.2919 8.53323V4.97073L17.6461 9.3249H14.0836C13.6482 9.3249 13.2919 8.96865 13.2919 8.53323ZM7.91033 17.6196C7.91033 17.0263 8.37679 16.5474 8.95464 16.5474C9.53249 16.5474 9.99895 17.0263 9.99895 17.6196C9.99895 18.2128 9.53249 18.6917 8.95464 18.6917C8.37679 18.6917 7.91033 18.2128 7.91033 17.6196ZM8.95464 14.9392C7.21413 14.9392 5.72773 16.0506 5.12552 17.6196C5.72773 19.1885 7.21413 20.2999 8.95464 20.2999C10.6951 20.2999 12.1815 19.1885 12.7838 17.6196C12.1815 16.0506 10.6951 14.9392 8.95464 14.9392ZM8.95464 19.4065C7.99388 19.4065 7.21413 18.6059 7.21413 17.6196C7.21413 16.6332 7.99388 15.8326 8.95464 15.8326C9.9154 15.8326 10.6951 16.6332 10.6951 17.6196C10.6951 18.6059 9.9154 19.4065 8.95464 19.4065Z"
				fill={
					isActive
						? color || customColor || getOldBlueColor()
						: customDisabledColor || 'rgba(0, 0, 0, 0.54)'
				}
			/>
		</svg>
	) : (
		<svg
			width={width || '30'}
			height={width || '30'}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.9367 2.17265C12.6358 1.87182 12.2321 1.70557 11.8125 1.70557H6.13625C5.26542 1.70557 4.55292 2.41807 4.55292 3.2889V13.7739C4.55292 14.3489 4.9855 14.2345 5.53011 14.0905C5.81022 14.0164 6.11996 13.9344 6.41573 13.9344L6.55292 5.2889C6.55292 4.41807 7.26542 3.70557 8.13625 3.70557H11.6779H12.4904H13.8125H14.8671L12.9367 2.17265ZM14.7598 4.78363C15.1793 4.78363 15.5831 4.94988 15.8839 5.25071L19.6998 9.07446C20.0006 9.36738 20.1668 9.77113 20.1668 10.1907V19.0336C20.1668 19.9045 19.4543 20.617 18.5835 20.617H16.3891C16.1964 18.9586 14.9094 17.0225 12.8708 15.594C11.054 14.3209 9.06204 13.7464 7.50018 13.909V6.36696C7.50018 5.49613 8.21268 4.78363 9.08352 4.78363H14.7598ZM14.6252 5.97113V9.53363C14.6252 9.96905 14.9814 10.3253 15.4168 10.3253H18.9793L14.6252 5.97113ZM8.8335 15.7003C6.56077 15.7003 4.61986 17.1516 3.8335 19.2003C4.61986 21.249 6.56077 22.7003 8.8335 22.7003C11.1062 22.7003 13.0471 21.249 13.8335 19.2003C13.0471 17.1516 11.1062 15.7003 8.8335 15.7003ZM8.8335 21.5336C7.57895 21.5336 6.56077 20.4883 6.56077 19.2003C6.56077 17.9123 7.57895 16.867 8.8335 16.867C10.088 16.867 11.1062 17.9123 11.1062 19.2003C11.1062 20.4883 10.088 21.5336 8.8335 21.5336ZM8.8335 17.8003C8.07895 17.8003 7.46986 18.4256 7.46986 19.2003C7.46986 19.975 8.07895 20.6003 8.8335 20.6003C9.58804 20.6003 10.1971 19.975 10.1971 19.2003C10.1971 18.4256 9.58804 17.8003 8.8335 17.8003Z"
				fill={isActive ? customColor || getOldBlueColor() : color || 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default ReviewFormIcon;
