import StyledWrapper from './style';
import { LegalFooterProps } from './LegalFooter.type';

export default function LegalFooter({ open }: LegalFooterProps) {
	const EUNA_SOLUTION_URL = 'https://eunasolutions.com/';
	return (
		<StyledWrapper.MainContainer data-testid={'legalFooter-container'}>
			<img
				data-testid={'legalFooter-image'}
				src="/images/euna-logo.svg"
				alt="euna-logo"
				onClick={() => {
					window.open(EUNA_SOLUTION_URL, '_blank');
				}}
				style={{ width: `${open ? '' : '28px'}`, height: 'auto', cursor: 'pointer' }}
			/>
			<StyledWrapper.InnerContainer>
				<StyledWrapper.ListContainer>
					<StyledWrapper.ListItem>
						<a href="#" style={{ color: 'rgba(0,0,0,0.6)' }}>
							Legal
						</a>
					</StyledWrapper.ListItem>
					<StyledWrapper.Bullet />
					<StyledWrapper.ListItem>
						<a href="#" style={{ color: 'rgba(0,0,0,0.6)' }}>
							About
						</a>
					</StyledWrapper.ListItem>
					<StyledWrapper.Bullet />
					<StyledWrapper.ListItem>
						<a href="#" style={{ color: 'rgba(0,0,0,0.6)' }}>
							Privacy
						</a>
					</StyledWrapper.ListItem>
				</StyledWrapper.ListContainer>
				<StyledWrapper.ListItem>© 2021 All rights reserved</StyledWrapper.ListItem>
			</StyledWrapper.InnerContainer>
		</StyledWrapper.MainContainer>
	);
}
