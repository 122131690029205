import { getOldBlueColor } from '../../utils/utilFunctions';

export const PasteIcon = ({ isActive, width, isWarning }) => {
	return (
		<svg
			width={width || '20'}
			height={width || '20'}
			viewBox="0 0 20 21"
			fill={isActive ? getOldBlueColor() : isWarning ? '#ED6C02' : 'rgba(0, 0, 0, 0.54)'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.75 5.08333H4.58333V7.25H6.75V5.08333ZM6.75 9.41667H4.58333V11.5833H6.75V9.41667ZM6.75 0.75C5.5475 0.75 4.58333 1.725 4.58333 2.91667H6.75V0.75ZM11.0833 13.75H8.91667V15.9167H11.0833V13.75ZM17.5833 0.75V2.91667H19.75C19.75 1.725 18.775 0.75 17.5833 0.75ZM11.0833 0.75H8.91667V2.91667H11.0833V0.75ZM6.75 15.9167V13.75H4.58333C4.58333 14.9417 5.5475 15.9167 6.75 15.9167ZM17.5833 11.5833H19.75V9.41667H17.5833V11.5833ZM17.5833 7.25H19.75V5.08333H17.5833V7.25ZM17.5833 15.9167C18.775 15.9167 19.75 14.9417 19.75 13.75H17.5833V15.9167ZM2.41667 5.08333H0.25V18.0833C0.25 19.275 1.21417 20.25 2.41667 20.25H15.4167V18.0833H2.41667V5.08333ZM13.25 2.91667H15.4167V0.75H13.25V2.91667ZM13.25 15.9167H15.4167V13.75H13.25V15.9167Z"
				fill={isActive ? getOldBlueColor() : isWarning ? '#ED6C02' : 'rgba(0, 0, 0, 0.54)'}
			/>
		</svg>
	);
};

export default PasteIcon;
