import styled from 'styled-components';

const CustomTable = styled.table`
	margin-top: 10px;
	border-bottom: '1px solid #e0e0e0';
	width: 100%;
	max-width: 1100px;
`;

const TH = styled.th`
	position: relative;
	text-align: ${(props) => props?.isFirst && 'left'};
	border-right: ${(props) => props?.isFirst && '2px solid #e0e0e0'};
	height: ${(props) => (props?.isFirst ? '44px' : '20px')};
	font-weight: 400;
	&::after {
		content: ${(props) => !props?.isFirst && "' '"};
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		width: 2px;
		height: 14px;
		background-color: #e0e0e0;
		display: block;
	}
`;

const TR = styled.tr``;

const TD = styled.td`
	text-align: ${(props) => !props?.isFirst && 'center'};
	border-right: ${(props) => props?.isFirst && '2px solid #E0E0E0'};
	font-size: 14px;
	font-weight: ${(props) => props?.isFirst && '500'};
`;

export default {
	CustomTable,
	TH,
	TR,
	TD
};
