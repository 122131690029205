import styled from 'styled-components';

const Container = styled.div`
	position: absolute;
	top: 50px;
	right: 0;
	z-index: 2250;
	width: 250px;
	padding: 16px;
	background-color: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
		0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	@media (max-width: 400px) {
		position: fixed;
		top: 68px;
		right: 0px;
		left: 0px;
		margin: auto;
	}
	@media (max-width: 500px) {
		top: 60px;
	}
`;

const Title = styled.h6`
	color: rgba(0, 0, 0, 0.87);
	margin: 0px;
`;

const Subtitle = styled.h6`
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	margin: 0px 0px 16px 0px;
`;

const Divider = styled.div`
	height: 1px;
	width: 250px;
	background-color: rgba(0, 0, 0, 0.12);
	margin: 0px -16px;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const SeeAll = styled.a`
	margin-top: 10px;
`;

export default {
	Container,
	Title,
	Subtitle,
	Divider,
	Footer,
	SeeAll
};
