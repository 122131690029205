import styled from 'styled-components';

const InfoBarContainer = styled.div`
	display: flex;
	gap: 20px;
`;

const InnerContainer = styled.div`
	display: flex;
	width: fit-content;
	align-items: center;
	gap: 25px;
	@media (max-width: 768px) {
		gap: 10px;
	}
`;

export default {
	InfoBarContainer,
	InnerContainer
};
