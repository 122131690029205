/* eslint-disable camelcase */
import React from 'react';

import ComponentHeader from '../form-elements/component-header';
import Dustbin from './dustbin';
import ItemTypes from '../ItemTypes';

const accepts = [ItemTypes.BOX, ItemTypes.CARD];

class MultiColumnRowBase extends React.Component {
	render() {
		const {
			controls,
			data,
			editModeOn,
			getDataById,
			setAsChild,
			removeChild,
			seq,
			className,
			index
		} = this.props;
		const { child_items, pageBreakBefore } = data;
		let baseClasses = 'SortableItem rfb-item';
		if (pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		return (
			<div
				style={{ ...this.props.style, padding: '0px' }}
				className={baseClasses}
				data-testid={'SortableItem'}
			>
				<ComponentHeader {...this.props} />
				<div>
					{/* <ComponentLabel {...this.props} /> */}
					<div className="row">
						{child_items.map((x, i) => (
							<div key={`${i}_${x || '_'}`} className={className}>
								{controls ? (
									controls[i].props?.children?.key !== 'individual-form-undefined' ? (
										controls[i]
									) : (
										''
									)
								) : (
									<Dustbin
										style={{ width: '100%' }}
										data={data}
										accepts={accepts}
										items={child_items}
										col={i}
										parent_index={index}
										editModeOn={editModeOn}
										_onDestroy={() => removeChild(data, i)}
										getDataById={getDataById}
										setAsChild={setAsChild}
										seq={seq}
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

const TwoColumnRow = ({ data, class_name, ...rest }) => {
	const className = class_name || 'col-md-6';
	if (!data.child_items) {
		// eslint-disable-next-line no-param-reassign
		data.child_items = [null, null];
		data.is_container = true;
	}
	return <MultiColumnRowBase {...rest} className={className} data={data} />;
};

const ThreeColumnRow = ({ data, class_name, ...rest }) => {
	const className = class_name || 'col-md-4';
	if (!data.child_items) {
		// eslint-disable-next-line no-param-reassign
		data.child_items = [null, null, null];
		data.is_container = true;
	}
	return <MultiColumnRowBase {...rest} className={className} data={data} />;
};

const MultiColumnRow = ({ data, ...rest }) => {
	const colCount = data.col_count || 4;
	const className = data.class_name || (colCount === 4 ? 'col-md-3' : 'col');
	if (!data.child_items) {
		// eslint-disable-next-line no-param-reassign
		data.child_items = Array.from({ length: colCount }, () => null);
		data.is_container = true;
	}
	return <MultiColumnRowBase {...rest} className={className} data={data} />;
};

export { TwoColumnRow, ThreeColumnRow, MultiColumnRow };
