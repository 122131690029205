import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { getOldBlueColor } from '../../utils/utilFunctions';

const BlueButton = styled(LoadingButton)(() => ({
	backgroundColor: getOldBlueColor(),
	width: '311px',
	height: '42px'
}));

export default function PrimaryButton({ id, text, type, onClick, disabled, loading }) {
	return (
		<BlueButton
			loading={loading}
			id={id}
			data-testid={id}
			variant="contained"
			type={type}
			onClick={(item) => onClick && onClick(item)}
			disabled={disabled}
		>
			<p style={{ color: 'white' }}>{!loading && text}</p>
		</BlueButton>
	);
}
