const Error404 = () => {
	return (
		<div>
			<h2>Error 404</h2>
			<p>Page Does not Exist</p>
		</div>
	);
};

export default Error404;
