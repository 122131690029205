import { GPM } from '../gpmAPI';

const projectTeamApi = GPM.injectEndpoints({
	endpoints: (builder) => ({
		getAllInvitations: builder.query({
			query: (props) => ({
				url: `applications/${props?.appID}/invitations/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['ProjectTeam']
		}),
		getUserInvites: builder.query({
			query: (props) => ({
				url: `applications/invitations/user_invites/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			providesTags: ['ProjectTeam']
		}),
		inviteTeamMember: builder.mutation({
			query: (props) => ({
				url: `applications/${props.applicationID}/invite/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['ProjectTeam']
		}),
		removeMember: builder.mutation({
			query: (props) => ({
				url: `applications/${props.applicationID}/remove_member/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['ProjectTeam']
		}),
		acceptInvitation: builder.mutation({
			query: (props) => ({
				url: `applications/invitations/${props.invitationID}/accept/`,
				method: 'POST',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['ProjectTeam', 'Stages', 'ApplicationProcess', 'IndividualForms']
		}),
		rejectInvitation: builder.mutation({
			query: (props) => ({
				url: `applications/invitations/${props.invitationID}/reject/`,
				method: 'POST',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['ProjectTeam']
		}),
		resendInvite: builder.mutation({
			query: (props) => ({
				url: `applications/invitations/${props.invitationID}/resend/`,
				method: 'POST',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['ProjectTeam']
		}),
		deleteInvite: builder.mutation({
			query: (props) => ({
				url: `applications/invitations/${props.invitationID}/`,
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['ProjectTeam']
		})
	}),
	overrideExisting: false
});

export const {
	useGetAllInvitationsQuery,
	useGetUserInvitesQuery,
	useInviteTeamMemberMutation,
	useAcceptInvitationMutation,
	useRejectInvitationMutation,
	useResendInviteMutation,
	useDeleteInviteMutation,
	useRemoveMemberMutation
} = projectTeamApi;
