import styled from 'styled-components';

const OuterMostContainer = styled.div`
	display: flex;
	border-radius: 3px;
	background-color: #ff8a80;
	width: 100%;
	height: 38px;
	margin: 26px 0px 43px 0px;
`;

const InnerScore = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px 0px 0px 3px;
	background-color: #81d4fa;
	width: ${(props) => props?.width || 'fit-content'};
`;

const Score = styled.p`
	font-size: 12px;
`;

export default {
	OuterMostContainer,
	InnerScore,
	Score
};
