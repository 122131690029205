import { GPM } from '../gpmAPI';

const stagesApi = GPM.injectEndpoints({
	tagTypes: ['Process', 'Stages', 'IndividualForms', 'Reviewers'],
	endpoints: (builder) => ({
		getAllProcessTemplates: builder.query({
			query: (props) => ({
				url: `form/templates/application-process/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}&templates=${props?.templateGeneralFilter}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Process', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Process', 'Stages', 'IndividualForms']
		}),
		getAllSubmissionStagesTemplates: builder.query({
			query: (props) => ({
				url: `form/templates/stage/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}&stage_type=application&templates=${props?.templateGeneralFilter}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Process', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		getAllEvalStagesTemplates: builder.query({
			query: (props) => ({
				url: `form/templates/stage/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}&stage_type=evaluation&templates=${props?.templateGeneralFilter}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Process', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		getTemplatesLinkage: builder.query({
			query: (props) => ({
				url: `form/templates/${props?.type}/parent/${props?.templateID}?page=${
					props?.page
				}&page_size=${props?.pageSize}${props?.finalQuery || ''}&templates=${
					props?.templateGeneralFilter
				}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Process', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		getTemplatesAppliedTo: builder.query({
			query: (props) => ({
				url: `form/templates/${props?.type}/usage/${props?.templateID}?page=${
					props?.page
				}&page_size=${props?.pageSize}${props?.finalQuery || ''}&templates=${
					props?.templateGeneralFilter
				}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Process', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		getTemplatesUsageInfo: builder.query({
			query: (props) => ({
				url: `form/templates/${props?.type}/usage-info/${props?.templateID}?&templates=${props?.templateGeneralFilter}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['Process', 'Stages', 'IndividualForms']
			}),
			providesTags: ['Stages', 'IndividualForms']
		}),
		getAllApplicationFormsTemplates: builder.query({
			query: (props) => ({
				url: `form/templates/form/?page=${props?.page}&page_size=${props?.pageSize}${
					props?.finalQuery || ''
				}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				providesTags: ['IndividualForms']
			}),
			providesTags: ['IndividualForms']
		}),
		postUnlinkTemplate: builder.mutation({
			query: (props) => ({
				url: `form/templates/unlink/`,
				method: 'DELETE',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms']
		}),
		postLinkTemplate: builder.mutation({
			query: (props) => ({
				url: `form/templates/link-template/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages', 'ApplicationProcess']
		}),
		postCopyTemplate: builder.mutation({
			query: (props) => ({
				url: `form/templates/copy-template/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages', 'ApplicationProcess']
		}),
		bulkActionTemplate: builder.mutation({
			query: (props) => ({
				url: `form/templates/bulk-action/`,
				method: 'POST',
				body: props?.payload,
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				}
			}),
			invalidatesTags: ['IndividualForms', 'Stages', 'ApplicationProcess']
		})
	}),
	overrideExisting: false
});

export const {
	useGetAllProcessTemplatesQuery,
	useGetAllSubmissionStagesTemplatesQuery,
	useGetAllEvalStagesTemplatesQuery,
	useGetAllFormsTemplatesQuery,
	usePostUnlinkTemplateMutation,
	usePostLinkTemplateMutation,
	usePostCopyTemplateMutation,
	useBulkActionTemplateMutation,
	useGetTemplatesLinkageQuery,
	useGetTemplatesAppliedToQuery,
	useGetTemplatesUsageInfoQuery
} = stagesApi;
