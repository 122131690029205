import { useEffect, useState } from 'react';

import StyledWrapper from '../style';
import { checkOverflow } from '../../../utils/utilFunctions';
import AssignedFormIcon from '../../../assets/customIcons/assignedForm';
import { formatTimestamp } from '../../../utils/dateUtils';
import PersonIcon from '../../../assets/customIcons/personIcon';
import AppFormIcon from '../../../assets/customIcons/appFormIcon';
import ReviewStageIcon from '../../../assets/customIcons/reviewStageIcon';
import { getChipColor, getChipTextColor } from '../../../utils/utilFunctions';
import ReviewFormIcon from '../../../assets/customIcons/reviewForm';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import TableCustomCheckbox from '../../../components/core/Checkbox/TableCustomCheckbox';

// openTooltip, setOpenTooltip
export const ManageReviewerCol = (columnWidths, handleRowSelection) => {
	const [openTooltip, setOpenTooltip] = useState(0);
	const columns = [
		{
			field: 'title',
			headerName: 'Project Title',
			description: 'Project Title',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isDisabled={cellValues?.row?.status === 'Submitted'}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
					/>
				);
			},

			flex: columnWidths.title || 1,
			resizable: true
		},
		{
			field: 'appId',
			headerName: 'Application ID',
			description: 'Application ID',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isLink
						cellValues={cellValues}
						emptyCell
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						StartIcon={AppFormIcon}
						isActive
					/>
				);
			},

			flex: columnWidths.appId || 1,
			resizable: true
		},
		{
			field: 'submitter',
			headerName: 'Submitter',
			description: 'Submitter',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isDisabled={cellValues?.row?.status === 'Submitted'}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
					/>
				);
			},

			flex: columnWidths.submitter || 1,
			resizable: true
		},
		TableCustomCheckbox,
		{
			field: 'stageName',
			headerName: 'Stage Name',
			description: 'Stage Name',
			renderCell: (cellValues) => {
				return (
					<>
						{/* {cellValues?.formattedValue && (
							<Checkbox
								onChange={(e) => {
									handleRowSelection(cellValues?.row, true);
								}}
							/>
						)} */}
						{cellValues?.formattedValue}
					</>
				);
			},
			flex: !columnWidths.stageName && 1
		},
		{
			field: 'stageId',
			headerName: 'Stage ID',
			description: 'Stage ID',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isLink
						cellValues={cellValues}
						emptyCell
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						StartIcon={ReviewStageIcon}
						isActive
					/>
				);
			},

			flex: columnWidths.stageId || 1,
			resizable: true
		},
		{
			field: 'status',
			headerName: 'Stage Status',
			description: 'Stage Status',
			renderCell: (cellValues) => {
				return (
					<>
						{cellValues?.formattedValue && (
							<Tooltip
								open={openTooltip === `status-${cellValues?.id}`}
								onOpen={(e) => checkOverflow(e) && setOpenTooltip(`status-${cellValues?.id}`)}
								onClose={() => setOpenTooltip(0)}
								arrow
								placement="top"
								title={cellValues?.formattedValue}
							>
								<Chip
									data-testid={`${cellValues?.field}-${cellValues?.id}`}
									label={cellValues?.formattedValue}
									sx={{
										backgroundColor: getChipColor(cellValues?.formattedValue),
										color: getChipTextColor(cellValues?.formattedValue)
									}}
									size="small"
								/>
							</Tooltip>
						)}
					</>
				);
			},
			flex: !columnWidths.status && 1
		},
		{
			field: 'formID',
			headerName: 'Form ID',
			description: 'Form ID',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isLink
						cellValues={cellValues}
						emptyCell
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						StartIcon={ReviewFormIcon}
						isActive
					/>
				);
			},

			flex: columnWidths.formID || 1,
			resizable: true
		},

		{
			field: 'reviewFormName',
			headerName: 'Review Form Name',
			description: 'Review Form Name',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isDisabled={cellValues?.row?.status === 'Submitted'}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
					/>
				);
			},

			flex: columnWidths.reviewFormName || 1,
			resizable: true
		},
		{
			field: 'reviewId',
			headerName: 'Review ID',
			description: 'Review ID',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isLink
						cellValues={cellValues}
						emptyCell
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						StartIcon={AssignedFormIcon}
						isActive
					/>
				);
			},

			flex: columnWidths.reviewId || 1,
			minWidth: 100,
			resizable: true
		}
	];

	return columns;
};

export const manageReviewGroupDef = (columnWidths) => {
	return {
		hideDescendantCount: true,
		field: 'reviewer',
		headerName: 'Reviewer',
		description: 'Reviewer',
		valueFormatter: (cellValues) => {
			const row = cellValues?.api?.getRow(cellValues?.id);
			const currentCell = {
				formattedValue: row?.reviewer,
				field: 'type',
				id: cellValues?.id
			};
			return <BasicCellText cellValues={currentCell} StartIcon={PersonIcon} isLink isActive />;
		},
		flex: !columnWidths.__tree_data_group__ && 2,
		resizable: true
	};
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate,
	isActive,
	emptyCell,
	isBlueprint,
	isDisabled
}) => {
	const formattedText =
		isDate && cellValues?.formattedValue !== '--'
			? formatTimestamp(cellValues?.formattedValue)
			: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : 'n/a'}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && (
					<StartIcon isActive={isActive} isBlueprint={isBlueprint} />
				)}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						status={isDisabled}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : emptyCell ? '' : ''}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						status={isDisabled}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue ? formattedText : emptyCell ? '' : ''}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
