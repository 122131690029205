import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import StyledWrapper from './style';
import { AppProcessWarnings } from '../../components/AppProcessWarnings/AppProcessWarnings';
import { Table } from '../Table';
import { ItemPageTitle } from '../ItemPageTitle';
import { BulkRevToEvalColumns } from './BulkRevToEvalColums';
import LinkReviewIcon from '../../assets/customIcons/linkReviewIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewForm';
import PersonIcon from '../../assets/customIcons/personIcon';
import { useGetAppToRevTable } from '../../hooks/useGetAppToRevTable';
import { useBulkAssignReviewMutation } from '../../services/endpoints/reviewEndpoints';
import { getLocalAuth } from '../../utils/environmentUtils';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	bgcolor: 'background.paper',
	borderRadius: '4px',
	boxShadow:
		'0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
	p: 4
};

export default function BulkRevToEvalModal({ openModal, closeModal, chosenReviews, handleAlert }) {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource);
	const [bulkAssign] = useBulkAssignReviewMutation();

	const [open, setOpen] = useState(false);
	const [columnWidth, setColumnWidth] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [subModal, setSubModal] = useState('');
	const [chosenEvalStages, setChosenEvalStages] = useState([]);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [tableRef, setTableRef] = useState();

	const finalTableRef = useRef();
	finalTableRef.current = tableRef?.current;

	const stateRef = useRef();
	stateRef.current = chosenEvalStages;

	const expandRow = (id) => {
		const rowNode = finalTableRef.current.getRowNode(id);
		finalTableRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
	};

	const initialColumns = BulkRevToEvalColumns(columnWidth, stateRef, expandRow);
	const [orderedColumns, setOrderedColumns] = useState(initialColumns);
	const updateOrderedColumns = (updatedOrderedColumns) => setOrderedColumns(updatedOrderedColumns);
	const { data, pagination } = useGetAppToRevTable();

	const getTreeDataPath = (row) => row?.hierarchy;

	const getPageTitle = () => {
		const pageTitle = (
			<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				<div style={{ marginTop: '-22px' }}>
					<PersonIcon width={23} />
				</div>
				<>
					{`Bulk assign the selected reviewers:`} <br />
					{chosenReviews?.map((e, index) => {
						return (
							<>
								{`${e?.first_name} ${e?.last_name}`}
								{index !== chosenReviews?.length - 1 && ', '}
							</>
						);
					})}
				</>
			</div>
		);
		return pageTitle;
	};

	const getSubmitButtonText = () => {
		return (
			<>
				{'ASSIGN'}
				<div style={{ height: '30px' }}>
					<LinkReviewIcon isActive={chosenEvalStages} color={'white'} />
					<ReviewFormIcon isActive={chosenEvalStages} isBlueprint color={'white'} />
				</div>
			</>
		);
	};

	const handleClose = () => {
		closeModal && closeModal();
		setOpen(false);
	};

	useEffect(() => {
		openModal && setOpen(true);
	}, [openModal]);

	useEffect(() => {
		//console.log(tableRef);
	}, [tableRef]);

	const handleSubmit = async () => {
		setIsLoading(true);
		const payload = {
			reviewers: chosenReviews?.map((e) => e?.reviewer_id),
			stages: chosenEvalStages?.map((e) => e?.stageId?.replace('esi-', ''))
		};

		const options = {
			payload: payload,
			apiToken: apiToken
		};

		await bulkAssign(options)
			.unwrap()
			.then(async () => {
				handleAlert('Bulk Assigned Succesfully!', 'success');
			})
			.catch(() => handleAlert('Bulk Assigned Failed!', 'error'));

		setSubModal(false);
		setIsLoading(false);
		handleClose();
	};

	return (
		<div>
			{!isLoading && subModal === '' && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					sx={{ maxHeight: '90vh' }}
				>
					<StyledWrapper.TableContainer hideDropdownCarot>
						<Box sx={style}>
							<div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
								<StyledWrapper.Title>{getPageTitle()}</StyledWrapper.Title>
								<AppProcessWarnings assigned={true} />
							</div>
							<Divider variant={'middle'} sx={{ '&::before, &::after': { borderTopWidth: '5px' } }}>
								Assign to
							</Divider>
							<Table
								customHeight={'50vh'}
								setOuterApiRef={setTableRef}
								columns={initialColumns}
								checkboxSelection
								rowHeight={52}
								enableAutoPageSize
								rows={data || []}
								isRowSelectable={(data) => data.row.stageId}
								isRowSelected={(e) => stateRef?.current?.findIndex((x) => x?.id === e?.id)}
								rowCount={pagination?.rowCount}
								pagination={pagination?.pagination}
								pageSize={pagination?.pageSize}
								paginationMode={pagination?.paginationMode}
								onPageChange={(e) => pagination?.onPageChange(e)}
								onPageSizeChange={(e) => pagination?.onPageSizeChange(e)}
								page={pagination?.page}
								loading={pagination?.loading}
								sortingMode={pagination?.sortingMode}
								onSortModelChange={(e) => pagination?.onSortModelChange(e)}
								onFilterModelChange={(e) => pagination?.onFilterModelChange(e)}
								onSelectChange={(e) => setChosenEvalStages(e)}
								disableSelectionOnClick
								key={'BulkRevToEvalModal'}
								onColumnResize={(e) => {
									const newColumnSizes = e?.colDef?.field;
									const width = e?.width;
									setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
								}}
								filterModel={pagination?.filterModel}
								sortModel={pagination?.sortModel}
								hideTableActions={true}
								isOpenedFilter={true}
								updateOrderedColumns={updateOrderedColumns}
							/>

							<StyledWrapper.ButtonContainer>
								<Button onClick={handleClose} variant="outlined" size="small">
									CANCEL
								</Button>
								<Button
									disabled={chosenEvalStages?.length === 0}
									onClick={handleSubmit}
									variant="contained"
									size="large"
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '10px'
									}}
								>
									{getSubmitButtonText()}
								</Button>
							</StyledWrapper.ButtonContainer>
						</Box>
					</StyledWrapper.TableContainer>
				</Modal>
			)}

			{!isLoading && subModal === 'failed' && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={{ ...style, maxWidth: '412px' }}>
						<StyledWrapper.Title>
							<WarningIcon sx={{ fill: '#FF9800' }} /> System unresponsive...
						</StyledWrapper.Title>
						<StyledWrapper.Description center>
							The request could not be processed.
						</StyledWrapper.Description>
						<StyledWrapper.Description center>
							Try again or contact us at itdesk@ecivis.com
						</StyledWrapper.Description>
						<StyledWrapper.ButtonContainer marginTop>
							<Button onClick={handleClose} variant="contained" size="large">
								CLOSE
							</Button>
						</StyledWrapper.ButtonContainer>
					</Box>
				</Modal>
			)}

			{!isLoading && subModal === 'success' && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={{ ...style, maxWidth: '412px' }}>
						<StyledWrapper.Title>
							<CheckCircleIcon color="success" /> Transaction successful!
						</StyledWrapper.Title>
						<StyledWrapper.Description center>
							The review form(s): {chosenEvalStages}
						</StyledWrapper.Description>
						<StyledWrapper.Description center>
							successfully assigned to <b>{chosenEvalStages?.stageId}</b>
						</StyledWrapper.Description>
						<StyledWrapper.ButtonContainer marginTop>
							<Button onClick={handleClose} variant="contained" size="large">
								OK
							</Button>
						</StyledWrapper.ButtonContainer>
					</Box>
				</Modal>
			)}

			{isLoading && (
				<Modal
					disableBackdropClick
					open={open}
					onClose={(e, reason) => {
						if (reason !== 'backdropClick') {
							handleClose();
						}
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={{ ...style, maxWidth: '412px' }}>
						<StyledWrapper.Title>processing...</StyledWrapper.Title>
						<StyledWrapper.Description center>
							<CircularProgress sx={{ margin: '50px 0px' }} />
						</StyledWrapper.Description>
					</Box>
				</Modal>
			)}
		</div>
	);
}
