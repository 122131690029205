import StyledWrapper from './style';
import { TErrorDisplay } from './ErrorDisplay.type';

export default function ErrorDisplay({ errorCode }: TErrorDisplay) {
	return (
		<StyledWrapper.Container>
			<img data-testid={`error-container`} src="/images/warning-icon.svg" alt="warning icon" />
			<StyledWrapper.TextContainer>
				<StyledWrapper.ErrorTitle data-testid={`error-title`}>
					Something went wrong...
				</StyledWrapper.ErrorTitle>
				<StyledWrapper.ErrorCode data-testid={`error-code`}>
					Please check with your system admin.
					<br />
					Error code: {errorCode}
				</StyledWrapper.ErrorCode>
			</StyledWrapper.TextContainer>
		</StyledWrapper.Container>
	);
}
