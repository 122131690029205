import React from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';

import StyledWrapper from './style';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

export const MUITags = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;

	const [tags, setTags] = React.useState(defaultValue || []);

	const handleChange = (event) => {
		const {
			target: { value }
		} = event;

		const chosenValue = typeof value === 'string' ? value.split(',') : value;
		setTags(chosenValue);
	};

	const getChipText = (value) => {
		const allOptions = props?.data?.options;
		const correctItem = allOptions?.find((e) => e?.key === value);
		return correctItem?.text;
	};

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	return (
		<FormControl fullWidth>
			<StyledWrapper.CustomLabel
				error={props?.data?.error}
				disabled={defaultValue && disabled}
				required={props?.data?.required}
				shrink={isFormBuilder || undefined}
			>
				{isFormBuilder && getElementIcon(props.data?.key)}
				{props?.data?.label}
				{isFormBuilder && (
					<div>
						{props.data?.unique_identifier
							? `(ff-${props.data?.unique_identifier})`
							: `(tmp-${props.data?.temp_order})`}
					</div>
				)}
			</StyledWrapper.CustomLabel>
			<Select
				error={props?.data?.error}
				inputProps={{ ref: ref }}
				id={`DropdownID_${props?.field_name}`}
				disabled={disabled}
				defaultValue={defaultValue}
				value={tags}
				onChange={handleChange}
				label={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
				name={name}
				multiple
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selected.map((value) => {
							const textValue = getChipText(value);
							return <Chip key={value} label={textValue} />;
						})}
					</Box>
				)}
			>
				{props?.data?.options?.map((e) => {
					return (
						<MenuItem key={e?.key} value={e?.key}>
							{e?.text}
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText error={props?.data?.error}>
				{props?.data?.error
					? props?.data?.error?.error
					: parsedHelperText?.[0]
					? parsedHelperText
					: props?.data?.help_message}
			</FormHelperText>
		</FormControl>
	);
});

MUITags.displayName = 'MUI_Tags';
Registry.register('MUI_Tags', MUITags);
