import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export function SectionCard(props) {
	return (
		<Box
			sx={{
				width: props?.width || '50%',
				overflow: props?.boxOverflowHidden && 'hidden',
				maxWidth: props?.maxWidth,
				minWidth: props?.minWidth
			}}
		>
			<Card
				data-testid={'sectionCard-container'}
				sx={{
					display: props?.display,
					justifyContent: props?.justifyContent,
					width: '100%',
					height: props?.height || '502px',
					padding: props?.padding || '32px 30px 0px 30px',
					overflowY: `${props?.noscroll ? 'hidden' : 'scroll'}`,
					margin: props?.margin || '0px'
				}}
				variant="outlined"
			>
				{props?.children}
			</Card>
		</Box>
	);
}
