import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from '../../components/Table';
import { StageColumns } from './stageColumns';
import { setFormMetaInfo } from '../../components/FormBuilderLibrary/slice';

import StyledWrapper from './style';

import Alert from '@mui/material/Alert';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { reOrderRows } from '../../utils/utilFunctions';

export const StageLogic = ({ individualStage, isLoading, isAppProcess, isReadOnly }) => {
	// Constants
	const dispatch = useDispatch();
	const [columnWidth, setColumnWidth] = useState({});
	const [hiddenColumns, setHiddenColumns] = useState([]);
	const initialColumns = StageColumns(columnWidth, isAppProcess);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);

	const [allRows, setAllRows] = useState([]);
	const [message, setMessage] = useState();
	function updateRowPosition(initialIndex, newIndex, rows) {
		return new Promise((resolve) => {
			setTimeout(() => {
				const rowsClone = [...rows];
				const row = rowsClone.splice(initialIndex, 1)[0];
				rowsClone.splice(newIndex, 0, row);
				resolve(rowsClone);
			}, Math.random() * 500 + 100); // simulate network latency
		});
	}

	const handleRowOrderChange = async (params) => {
		const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, allRows);
		const newOrder = isAppProcess ? newRows?.map((e) => e?.appId) : newRows?.map((e) => e?.form_id);

		dispatch(
			setFormMetaInfo({
				...formMetaInfo,
				order: newOrder
			})
		);
	};

	useEffect(() => {
		if (formMetaInfo?.sequential && !formMetaInfo?.order?.length > 0) {
			const newOrder = isAppProcess
				? allRows?.map((e) => e?.appId)
				: allRows?.map((e) => e?.form_id);
			dispatch(
				setFormMetaInfo({
					...formMetaInfo,
					order: newOrder
				})
			);
		}

		if (!formMetaInfo?.sequential && formMetaInfo?.order?.length > 0) {
			dispatch(
				setFormMetaInfo({
					...formMetaInfo,
					order: []
				})
			);
		}
	}, [formMetaInfo?.sequential]);

	useEffect(() => {
		setMessage(
			<StyledWrapper.CustomWarningText>
				{isAppProcess ? (
					<>There are no stages added. Save the Application Process first and add stages.</>
				) : individualStage?.stage_type === 'evaluation' ? (
					<>There are no forms added. Save the Review Process first and add Review Forms to it.</>
				) : (
					<>
						There are no forms added. Save the Submission Process first and add Application Forms to
						it.
					</>
				)}
			</StyledWrapper.CustomWarningText>
		);
	}, []);

	useEffect(() => {
		let formattedRows =
			individualStage?.forms?.map((e, index) => {
				return { ...e, id: index + 1 };
			}) || [];

		if (isAppProcess) {
			formattedRows = individualStage?.map((e) => {
				return {
					...e,
					form_type: e?.type,
					label: e?.title,
					form_id: e?.appId
				};
			});
		}

		//ReOrdering Function Here

		const finalRows = isAppProcess
			? reOrderRows(individualStage?.stage_order, formattedRows)
			: reOrderRows(individualStage?.form_order, formattedRows);
		setAllRows(
			finalRows?.map((e, index) => {
				e.id = index + 1;
				return e;
			})
		);
	}, [individualStage]);

	useEffect(() => {
		formMetaInfo?.sequential ? setHiddenColumns([]) : setHiddenColumns(['order', '__reorder__']);

		if (formMetaInfo?.order?.length > 0 && allRows?.length > 0) {
			//ReOrdering Function Here
			const finalRows = reOrderRows(formMetaInfo?.order, allRows);
			setAllRows(
				finalRows?.map((e, index) => {
					e.id = index + 1;
					return e;
				})
			);
		}
	}, [formMetaInfo]);

	return (
		<>
			{!isLoading && (
				<StyledWrapper.StageLogicContainer hideResizeIcon>
					{/* <StyledWrapper.TopInfo>
						<StyledWrapper.InfoLeft>
							Order matters only if “Sequential Steps” is ON
						</StyledWrapper.InfoLeft>
						<StyledWrapper.InfoLeft>
							The application process is not containing inter-stage business logic add this time!
							<SettingsSuggestIcon />
						</StyledWrapper.InfoLeft>
					</StyledWrapper.TopInfo> */}

					{/* REMOVE ! when BE is Connected to actually show warning first if available */}
					{(message && allRows?.length === 0) ||
					(message && isAppProcess && individualStage?.length === 0) ? (
						<Alert
							sx={{ background: 'white', marginBottom: '100px' }}
							variant="outlined"
							severity={'warning'}
						>
							{message}
						</Alert>
					) : (
						<div style={{ background: 'white' }}>
							<Table
								setHiddenColumns={hiddenColumns}
								customHeight={'50vh'}
								enableAutoPageSize
								disableSelectionOnClick
								disableReorder={isReadOnly}
								columns={initialColumns}
								rows={allRows}
								// rowReordering
								// onRowOrderChange={handleRowOrderChange}
								rowHeight={true}
								onColumnResize={(e) => {
									const newColumnSizes = e?.colDef?.field;
									const width = e?.width;
									setColumnWidth({ ...columnWidth, [newColumnSizes]: width });
								}}
							/>
						</div>
					)}
				</StyledWrapper.StageLogicContainer>
			)}
		</>
	);
};
