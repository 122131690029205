import { useState, useRef, Fragment } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';

export const ButtonDropdown = ({
	data,
	hidden,
	isHorizon,
	customOpenToggle,
	isOpen,
	noDropShadow
}) => {
	const [open, setOpen] = useState(isOpen || false);
	const anchorRef = useRef(null);
	const [openTooltip, setOpenTooltip] = useState(0);
	const [refresh, setRefresh] = useState(true);

	const handleMenuItemClick = (event, index) => {
		customOpenToggle && customOpenToggle(false);
		setOpen(false);
		event(index);
	};

	const handleToggle = () => {
		if (customOpenToggle) {
			customOpenToggle(!isOpen);
		} else {
			setOpen((prevOpen) => !prevOpen);
		}
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		customOpenToggle && customOpenToggle(false);
		setOpen(false);
	};

	React.useEffect(() => {
		setRefresh(!refresh);
	}, [data]);

	return (
		<>
			<ButtonGroup
				size="small"
				data-testid={'bulk-action-button'}
				variant={'contained'}
				ref={anchorRef}
				sx={{
					width: data?.width || 'fit-content',
					textWrap: 'nowrap',
					marginTop: data?.marginTop || '0px',
					marginBottom: data?.marginBottom || '20px',
					visibility: hidden && 'hidden',
					boxShadow: isHorizon || (noDropShadow && 'none'),
					height: data?.height || 'auto'
				}}
				aria-label="split button"
			>
				{isHorizon ? (
					<IconButton onClick={handleToggle} size="small" aria-label="More Horizons Button">
						<MoreHorizIcon sx={{ fill: '#FF9800' }} />
					</IconButton>
				) : (
					<Button variant={data?.variant || 'outlined'} size="small" onClick={handleToggle}>
						{data?.title}
						<ArrowDropDownIcon />
					</Button>
				)}
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement="bottom-end"
				modifiers={[
					{
						name: 'flip',
						enabled: true,
						options: {
							fallbackPlacements: ['bottom-end', 'top-end']
						}
					}
				]}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{data?.options?.map((option, index) => (
										<Tooltip
											key={`buttondropdown-item-${index}`}
											open={openTooltip === `${data?.id}-${option?.title}`}
											onOpen={() =>
												option?.disabled && setOpenTooltip(`${data?.id}-${option?.title}`)
											}
											onClose={() => setOpenTooltip(0)}
											arrow
											placement={data.tipPlacement || 'right'}
											title={
												option?.individiualTooltip ||
												data?.tipTitle ||
												'Select first in order to bulk action'
											}
										>
											<span>
												<MenuItem
													key={`${data?.id}-${option?.title}`}
													disabled={option?.disabled}
													onClick={() => handleMenuItemClick(option?.onClick, index)}
													style={{ display: 'flex', gap: '6px' }}
												>
													{option?.icon}
													{option?.title}
													{option?.endIcon}
												</MenuItem>
											</span>
										</Tooltip>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
