import { Input } from '../core/Input';

export default function InputPassword({
	register,
	required,
	error,
	style,
	nameInput,
	label,
	onChange,
	id
}) {
	return (
		<Input
			type="password"
			label={label}
			fieldName={nameInput}
			register={register}
			required={required}
			error={error[nameInput]}
			onChange={onChange}
			style={style}
			id={id}
		/>
	);
}
